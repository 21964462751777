//#region imports
import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { UserService } from '../../service/UserService';
import { Globals } from 'src/app/Globals';
import { Router, ActivatedRoute, UrlSegmentGroup } from '@angular/router';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data, event } from 'jquery';
import { StringValueToken } from 'html2canvas/dist/types/css/syntax/tokenizer';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
//#endregion

@Component({
  selector: 'app-reg-est-nskmatha',
  templateUrl: './reg-est-nskmatha.component.html',
  styleUrls: ['./reg-est-nskmatha.component.css']
})
export class RegEstNskmathaComponent implements OnInit {
   //#region public varaibale
   loading: string = '';
   step1_Form: FormGroup;
  
   public lang: string = 'mar';
   public step: number = 1;
   isSubmitted_step_1 = false;
 
   public EstID: number = 0;
   public LogMobNo: number = 0;
   public Enrty_Type: string = 'application';
 
   public UserType: string = '';
   public Log_EstID: number = 0;
   public Log_UserID: number = 0;
   public userID: number = 0;
   public Log_BoardID: number = 0;
   public Log_accessFor: string = '';
   appl_Type: string = '';
   public Appl_Status: string = 'Pending';
   //#endregion
  constructor(config: NgbAccordionConfig,
    private _userService: UserService,
    private globals: Globals,
    private _http: Http,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,) { 
    
    this.Log_EstID = Number(this.route.snapshot.queryParamMap.get('estID'));
    this.Log_BoardID = Number(this.route.snapshot.queryParamMap.get('boardID'));
    this.LogMobNo = Number(this.route.snapshot.queryParamMap.get('mob'));

    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    this.userID = this.Log_UserID;
    if (sessionStorage.getItem('mathaLog_UserType') != null && sessionStorage.getItem('mathaLog_UserType') != '') {
      this.UserType = sessionStorage.getItem('mathaLog_UserType');
    }
    
    }

  //#region "wating modal"
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion

  //#region  NgOnit
 
  ngOnInit(): void {
    this.Log_accessFor = ''
    if (this.UserType == 'Inspector') {
      this.Log_accessFor = 'Auth'
      this.appl_Type = 'estApplication';
    }
    else if (this.UserType == 'Secretary') {
      this.Log_accessFor = 'Auth'
      this.appl_Type = 'estApplication';
    }
    else if (this.UserType == 'Chairman') {
      this.Log_accessFor = 'Auth'
      this.appl_Type = 'estApplication';
    }
    else if (this.Log_EstID == 0) {
      this.appl_Type = 'estApplication';
    }

    this.step1_Form = this.formBuilder.group({
      est_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      phoneno_1: [this.LogMobNo],
      phoneno_2: [''],
      galano: [''],
      est_type: ['INDIVIDUAL FIRM', [Validators.required]],
      sel_Market: ['0', [Validators.required]],
      locname: [''],
      owner_name1: [''],
      owner_name2: [''],
      owner_name3: [''],
      owner_name4: [''],
      memorg: [''],
      memorg_no: [''],
      selbusiness_type: ['जनरल स्टोर्स', [Validators.required]],
      loadadd1: [''],
      loadadd2: [''],
      loadadd3: [''],
      contractorname: [''],
      tolino_1: [''],
      toliname_1: [''],
      tolino_2: [''],
      toliname_2: [''],
    });

    this.regDate = new Date().toISOString().substring(0, 10);

   this.set_error_value();
   this.fill_addmode_data();

  }
  //#endregion

  //#region return control reqiured feild error
  get errorControl() {
    return this.step1_Form.controls;
  }
  
  set_error_value() {
  }
  //#endregion

  //#region  set language/page navigations
  set_lang(langType:string){
    this.lang = langType;
  }
  set_step(step:number){
    this.step = step;
  }
  //#endregion

  //#region "fill add Mode data"
  comp_List: any = [];
  marketList: any = [];
  companyID: Number = 0;
  marketID: Number = 0;
 public Save_formData = new FormData();
  fill_addmode_data() {
    
    this.Save_formData = new FormData();

    this.comp_List = [];
    this.marketList = [];
    this.companyID = 0;
    this.marketID = 0;
    this.loading = 'y';
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_est_master_controlerName(this.Log_BoardID) + '/getaddmodedatalist/1';
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.comp_List = data.comp_List;
        this.marketList = data.marketList;

        if (Number(this.comp_List.length) > 0) {
          this.companyID = Number(this.comp_List[0]['companyID']);;
        }
        this.filter_marketList(this.companyID);
    
        this.loading = '';

        console.log(data);

        this.show_loadingModal(false);

        this.bind_data();

      },
      (err: HttpErrorResponse) => {
        this.appl_Type = 'error';
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });
  }
  
  filter_market_list: any = [];
  filter_marketList(companyID: Number) {
    this.filter_market_list = [];
    this.marketID = 0;
   //
   this.filter_market_list = this.marketList;
    if (Number(this.filter_market_list.length) > 0) {
      this.marketID = Number(this.filter_market_list[0]['marketID']);

    }
    this.step1_Form.controls['sel_Market'].setValue(this.marketID);

  }
  

  //#endregion

  //#region bind Data
  bind_estMst = [];
  bind_data(){
    this.Appl_Status = '';
    this.Save_formData = new FormData();

    this.appl_Type = '';
    this.bind_estMst = [];

    this.loading = 'y';
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_est_master_controlerName(this.Log_BoardID) + '/binddata/' + this.EstID + '/' + this.LogMobNo;
   // console.log(url);
    this._userService.get_Data(url).subscribe(
      (data) => {
        
        this.appl_Type = data.retMsg;

        this.show_loadingModal(false);
        this.bind_estMst = data.est_Master;

        console.log('estData ',this.bind_estMst)

        if (Number(this.bind_estMst.length) > 0) {
          this.EstID = Number(this.bind_estMst[0]["estID"]);

          this.step1_Form.controls['est_name'].setValue(this.bind_estMst[0]["estName"]);
          this.step1_Form.controls['address'].setValue(this.bind_estMst[0]["estAdd1"]);
          this.step1_Form.controls['phoneno_1'].setValue(this.bind_estMst[0]["estPhone1"]);
          this.step1_Form.controls['phoneno_2'].setValue(this.bind_estMst[0]["estPhone2"]);
          this.step1_Form.controls['galano'].setValue(this.bind_estMst[0]["estAdd3"]);
          this.step1_Form.controls['est_type'].setValue(this.bind_estMst[0]["typeEst"]);
          this.step1_Form.controls['sel_Market'].setValue(this.bind_estMst[0]["marketID"]);
          //this.step1_Form.controls['locname'].setValue(this.bind_estMst[0][""]);
          this.step1_Form.controls['owner_name1'].setValue(this.bind_estMst[0]["owner1"]);
          this.step1_Form.controls['owner_name2'].setValue(this.bind_estMst[0]["owner2"]);
          this.step1_Form.controls['owner_name3'].setValue(this.bind_estMst[0]["owner3"]);
          this.step1_Form.controls['owner_name4'].setValue(this.bind_estMst[0]["owner4"]);
          this.step1_Form.controls['memorg'].setValue(this.bind_estMst[0]["memoforg"]);
          this.step1_Form.controls['memorg_no'].setValue(this.bind_estMst[0]["shopEstNo"]);
          this.step1_Form.controls['selbusiness_type'].setValue(this.bind_estMst[0]["natureBus"]);
          this.step1_Form.controls['loadadd1'].setValue(this.bind_estMst[0]["loadPlace1"]);
          this.step1_Form.controls['loadadd2'].setValue(this.bind_estMst[0]["loadPlace2"]);
          this.step1_Form.controls['loadadd3'].setValue(this.bind_estMst[0]["loadPlace3"]);
          this.step1_Form.controls['contractorname'].setValue(this.bind_estMst[0]["conNameAdd"]);
          this.step1_Form.controls['tolino_1'].setValue(this.bind_estMst[0]["toliNo1"]);
          this.step1_Form.controls['toliname_1'].setValue(this.bind_estMst[0]["mukName1"]);
          this.step1_Form.controls['tolino_2'].setValue(this.bind_estMst[0]["toliNo2"]);
          this.step1_Form.controls['toliname_2'].setValue(this.bind_estMst[0]["mukName2"]);

          this.Appl_Status = this.bind_estMst[0]["estStatus"];

          this.rejRemark = this.bind_estMst[0]["remark"];
          
        }
        
        this.loading = '';

      },
      (err: HttpErrorResponse) => {
        this.appl_Type = 'error';
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });
  }
  clear_Data(){
    this.EstID = 0;

    this.step1_Form.controls['est_name'].setValue('');
    this.step1_Form.controls['address'].setValue('');
    this.step1_Form.controls['phoneno_1'].setValue('');
    this.step1_Form.controls['phoneno_2'].setValue('');
    this.step1_Form.controls['galano'].setValue('');
    this.step1_Form.controls['est_type'].setValue('');
    this.step1_Form.controls['sel_Market'].setValue('1');
    //this.step1_Form.controls['locname'].setValue(this.bind_estMst[0][""]);
    this.step1_Form.controls['owner_name1'].setValue('');
    this.step1_Form.controls['owner_name2'].setValue('');
    this.step1_Form.controls['owner_name3'].setValue('');
    this.step1_Form.controls['owner_name4'].setValue('');
    this.step1_Form.controls['memorg'].setValue('');
    this.step1_Form.controls['memorg_no'].setValue('');
    this.step1_Form.controls['selbusiness_type'].setValue('');
    this.step1_Form.controls['loadadd1'].setValue('');
    this.step1_Form.controls['loadadd2'].setValue('');
    this.step1_Form.controls['loadadd3'].setValue('');
    this.step1_Form.controls['contractorname'].setValue('');
    this.step1_Form.controls['tolino_1'].setValue('');
    this.step1_Form.controls['toliname_1'].setValue('');
    this.step1_Form.controls['tolino_2'].setValue('');
    this.step1_Form.controls['toliname_2'].setValue('');
  }
  //#endregion


  //#region validate date

  invalidDate = '';
  invalid_NomDob_Date = '';
  invalid_NomFrm_Date = '';
  vlaidateDate(event, fldName: string) {
    let flag: string = 'invalid';

    let chkDt = new Date(event.target.value);
    // 
    if (Number(chkDt.getFullYear()) < 1950 || Number(chkDt.getFullYear()) > 2099) {
      flag = 'invalid';
    }
    else {
      if (chkDt.getFullYear()) {
        flag = '';
      }
      else {

      }

    }

    if (fldName == 'regDate') {
      this.invalidDate = flag;
    }
    

  }

  regDate;
  setDate(event) {
    let chkDt = new Date(event.target.value);
    if (Number(chkDt.getFullYear()) < 1950 || Number(chkDt.getFullYear()) > 2099) {
    }
    else {
      this.regDate = new Date(chkDt).toISOString().substring(0, 10);;
    }
  }
  
  //#endregion

  //#region set value
  set_marketID(marketID: Number) {
    this.marketID = marketID;
    this.step1_Form.controls['sel_Market'].setValue(this.marketID);
  }
  //#endregion

  //#region "Save record"
  
  submit(){
    
    if (window.confirm('Are sure you to submit.?')) {
      let isValid = true;
      if (this.step1_Form.controls['est_name'].value == '' || this.step1_Form.controls['est_name'].value == null) {
        alert("Enter establishment name.")
        isValid = false;
        return;
      }
      else if (this.step1_Form.controls['address'].value == '' || this.step1_Form.controls['address'].value == null) {
        alert("Enter establishment address.")
        isValid = false;
        return;
      }
      else if (this.step1_Form.controls['phoneno_1'].value == '' || this.step1_Form.controls['phoneno_1'].value == null) {
        alert("Enter phone number.")
        isValid = false;
        return;
      }
      else if (this.step1_Form.controls['owner_name1'].value == '' || this.step1_Form.controls['owner_name1'].value == null) {
        alert("Enter owner name.")
        isValid = false;
        return;
      }
      else{
//this.step2_Form.controls['rel_name1'].value,
        let save_Data: any = {
          "EstID": this.EstID,
          "MobNo": this.LogMobNo,
         // "EstRegNo": this.step1_Form.controls[''].value,
          "EstRegdt": this.regDate,
          "EstName": this.step1_Form.controls['est_name'].value,
          "EstAdd1": this.step1_Form.controls['address'].value,
          "EstAdd2": '',
          "EstAdd3": this.step1_Form.controls['galano'].value,
          "EstPhone1": this.step1_Form.controls['phoneno_1'].value,
          "EstPhone2": this.step1_Form.controls['phoneno_2'].value,
          "TypeEstID": 0,
          "TypeEst": this.step1_Form.controls['est_type'].value,
          "OwnerPrefix1": '',
          "Owner1": this.step1_Form.controls['owner_name1'].value,
          "OwnerPrefix2": '',
          "Owner2": this.step1_Form.controls['owner_name2'].value,
          "OwnerPrefix3":'',
          "Owner3": this.step1_Form.controls['owner_name3'].value,
          "OwnerPrefix4": '',
          "Owner4": this.step1_Form.controls['owner_name4'].value,
          "Memoforg": this.step1_Form.controls['memorg'].value,
          "ShopEstNo": this.step1_Form.controls['memorg_no'].value,
          "NatureBus": this.step1_Form.controls['selbusiness_type'].value,
          "LoadPlace1": this.step1_Form.controls['loadadd1'].value,
          "LoadPlace2": this.step1_Form.controls['loadadd2'].value,
          "LoadPlace3": this.step1_Form.controls['loadadd3'].value,
          "ConNameAdd": this.step1_Form.controls['contractorname'].value,
          "ToliNo1": this.step1_Form.controls['tolino_1'].value,
          "ToliNo2": this.step1_Form.controls['tolino_2'].value,
          "MukName1": this.step1_Form.controls['toliname_1'].value,
          "MukName2": this.step1_Form.controls['toliname_2'].value,
          "CompanyID": 1,
          "UserID": this.Log_UserID,
          //"Fayear": this.step1_Form.controls[''].value,
          "Lock": 'No',
          "MarketID": this.step1_Form.controls['sel_Market'].value,
         // "RecNo": this.step1_Form.controls[''].value,
         // "RefNo": this.step1_Form.controls[''].value,
         // "RecAmt": this.step1_Form.controls[''].value,
         // "DocID": this.step1_Form.controls[''].value,
          "EstStatus": 'Active',
          "BussinessTypeID": 0,
          "Remark": '',
         // "GeneralUserID": this.step1_Form.controls[''].value,
          "ApplType": this.appl_Type
        }

        let body = new FormData();
        body = save_Data;

        console.log(save_Data);

        let url = 'api/' + this._userService.get_est_master_controlerName(this.Log_BoardID) + '/saveestmst';
        //alert(url);
        this._userService.post_data(url, body).subscribe(
          (data) => {

            if (data == "Record Saved Sucessfully") {
              this.ngxService.stop();
              alert(data);
              window.location.reload();
            }
            else if (data == "-1") {
              this.ngxService.stop();
              alert('Mobile number ' + this.step1_Form.controls['phoneno_1'].value + ' allready exists.');
             
            }
            else {
              this.ngxService.stop();
              alert('Something went wrong internal server error.')
            }
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            this.ngxService.stop();
            alert('Something went wrong internal server error.')
          });

      }

    }
    
  }
  //#endregion

  //#region mobile verification
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  //#endregion

  //#region authorize application
  set_rejRemark(event) {
    this.rejRemark = event.target.value;
  }
  rejRemark: string = '';
  authorize_appl(flag) {
    //rejremark
    if (flag == 'reject' && this.rejRemark == '') {
      alert('Enter remark')
      return;
    }
    else {

      let status: string = '';
      if (flag == 'accept') {
        if (this.UserType == 'Inspector') {
          status = 'Inspector Approve';
        }
        else if (this.UserType == 'Secretary') {
          status = 'Secretary Approve';
        }
        else if (this.UserType == 'Chairman') {
          status = 'Chairman Approve';
        }
      }
      else {
        status = 'Reject';
      }

      let saveNom: any = {
        "EstID": this.EstID,
        "EstStatus": status,
        "remark": this.rejRemark,
      }

      let body = new FormData();
      body = saveNom;
      let url = 'api/' + this._userService.get_est_master_controlerName(this.Log_BoardID) + '/estapllauth';
      //alert(url);
      this._userService.post_data(url, body).subscribe(
        (data) => {

          if (data == "Record Saved Sucessfully") {
            this.ngxService.stop();
            alert(data);

            this.go_back();
          }
          else {
            this.ngxService.stop();
            alert('Something went wrong internal server error.')
          }
        },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          this.ngxService.stop();
          alert('Something went wrong internal server error.')
        });
    }
  }
  //#endregion

  //#region go back
  go_back() {
    this.router.navigate(['dashboard']);
  }
  //#endregion

}
