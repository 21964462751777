import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/UserService';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../Globals'
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-lab-login',
  templateUrl: './lab-login.component.html',
  styleUrls: ['./lab-login.component.css']
})
export class LabLoginComponent implements OnInit {

  public mobno:string='';
  otpval: string = '';
  txtotpval: string = '';
  entryType:Number = 0;
  //entryType = 0 then lab,entryType = 1 then est

  constructor(public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals) {
      this.entryType = Number(this.route.snapshot.queryParamMap.get('entryType'));
     }

  ngOnInit(): void {
    this.get_allBoard();
  }

  boardList: any = [];
  get_allBoard() {
    this.ngxService.start();
    this.boardList = [];
    this._userService.get_allBoard().subscribe((data: any) => {
      
      this.boardList = data;

      this.boardList = data.filter(x => (Number(x.boardID)) != 101);

      this.boardList = this.boardList.filter(x => x.boardID == 6);
      
      console.log('bordList',data);
      this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
         this.ngxService.stop();
         alert(err);
      });
  }

  genrateOTP() {
    
    // this.StartTimer();
     let mob_no: string = this.mobno.toString();
     
     if (mob_no.toString().length != 10) {
      alert('मोबाईल क्रमांक तपासा.')
     }
     else {
       let mobile = mob_no;
       var headers = new Headers()
       headers.append('Content-Type', 'application/x-www-form-urlencoded');
       let options = new RequestOptions({ headers: headers });
       this.otpval = (Math.floor(Math.random() * 9000) + 1000).toString();

       this.otpval = this._userService.getMobileotp(mob_no);

      // console.log(this.otpval);

       let sms_url = 'https://sms.happysms.in/api/sendhttp.php?authkey=363372A5aL0uyItx60d9aa30P1&mobiles='
       + mobile + '&message=Your OTP from KIT is ' + this.otpval + '&sender=KITLtd&route=4&country=91&DLT_TE_ID=1707163937683822686';
        this._userService.get_smsApi_method(sms_url)
        .then(data => {
          //console.log('smsData : ', data);

        })
        .catch(err => {
         // console.log('sms_error : ', err);
          //this.showErrorAlert(err);

        }); 

 
      this.otpLeble = '';
      this.resenotp = 'Yes';
      this.maxTime = 20;
       this.StartTimer();
      
     }
 
   }

   verifyOTP(boardID){
     console.log(boardID)
    if (this.txtotpval == this.otpval){
      sessionStorage.setItem('mathaLog_UserID', '0');
      sessionStorage.setItem('mathaLog_UserType', '');

      let parameters = {
        labID: 0,
        mob: this.mobno,
        boardID:boardID
       };

       //boardID == 6 pimpari
       
       if (boardID == 6) {
        // this.router.navigate(['paidbankList/lab-paidbank-list'], { queryParams : parameters });
        if (this.entryType == 0){
          this.router.navigate(['reg-lab-pimmatha'], { queryParams: parameters });
        }
        else if (this.entryType == 1)
        {
          this.router.navigate(['reg-est-nskmatha'], { queryParams: parameters });
        }
        
      }
      else{
        alert('काम प्रगतीपथावर आहे.लवकरच कार्यरत होईल.');
      }
    }
    else{
      alert("अचूक ओ.टी.पी. टाका");
    }
   }

   resenotp:string='No';
  otpLeble:string = 'ओ टी पि मिळवा';
  maxTime: any=0;
  timer:any=0;
  StartTimer(){
    this.timer = setTimeout(x => 
      {
          if(this.maxTime <= 0) { 

          }
          else{
           this.maxTime -= 1; 
          }
          
          if(this.maxTime>0){
           // this.hidevalue = false;
            this.StartTimer();
          }
          else{
             // this.hidevalue = true;
          }
       // console.log(this.maxTime);
      }, 1000);
      this.otpLeble = 'पुन्हा ओ टी पि मिळवा';

  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
