import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../service/UserService';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response,  RequestOptions } from '@angular/http';
//import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../Globals'
import { Injectable } from '@angular/core';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class reportsComponent implements OnInit {
  public isCollapsed = true;
  IsUserLoggedIn: boolean = false;
  log_userName:string='';

  
  constructor(public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    // private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals) {
      this.log_userName = this.globals.log_userName;
    }

  ngOnInit(): void {
    if (sessionStorage.getItem('mathaUserName') != null){
      this.globals.log_userName = sessionStorage.getItem('mathaUserName');
      this.globals.is_login = true;
      this.IsUserLoggedIn= true;
    }
    else{
      this.globals.log_userName = '';
      this.globals.is_login = false;
      this.IsUserLoggedIn= false;
    }
    
  }
  LogOut() {
        this.globals.log_userName ='';
        this.globals.is_login = false;
        sessionStorage.setItem('mathaUserToken', '');
        sessionStorage.setItem('mathaLog_Email','');
        sessionStorage.setItem('mathaLog_UserTypeID', '');
        sessionStorage.setItem('mathaLog_UserMob', '');
        sessionStorage.setItem('mathaUserName', '');
        sessionStorage.setItem('mathaLog_BoardID', '');
        sessionStorage.setItem('mathaLog_accessFor', '');
        sessionStorage.setItem('mathafilterBoradID', '');
        sessionStorage.setItem('mathaLog_UserType', '');
        sessionStorage.clear();
        this.IsUserLoggedIn = false;
        this.globals.log_userName = '';
        this.globals.is_login = false;
        this.IsUserLoggedIn= false;
        window.location.replace('/');
  }
  userList:any=[];
  fill_userList(){
    this.userList = [
      { id: 11, name: 'Dr Nice' },
      { id: 12, name: 'Narco' },
      { id: 13, name: 'Bombasto' },
      { id: 14, name: 'Celeritas' },
      { id: 15, name: 'Magneta' },
      { id: 16, name: 'RubberMan' },
      { id: 17, name: 'Dynama' },
      { id: 18, name: 'Dr IQ' },
      { id: 19, name: 'Magma' },
      { id: 20, name: 'Tornado' }
    ];
  }

}
