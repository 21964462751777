<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/login">Login</a></li>
      <li class="breadcrumb-item active" aria-current="page">Forgot Password?</li>
    </ol>
  </nav>	
  <div class="col-sm-5 offset-sm-4 logindmain">	
  <img src="assets/images/login-forgotpassword-page-img.png" alt="login" title="login" class="img-fluid login-img">
  <h1 class="text-center">Forgot Password?</h1>
  <p class="title-message">Enter the phone number to <span>retrieve your password</span></p>
  <form>
      <div class="form-group">
          <label for="email-mobile"> Mobile no <span class="red">*</span></label>
          <input type="text" #mobile id="email-mobile" class="form-control" />        
      </div>  
      <div class="clearfix"></div>
      <div class="login-bt-section"><button  type="button" (click)="goto_OtpSereen(mobile.value)" class="btn btn-primary">Retrieve Password</button></div>    
  </form>
  </div>
  </div>
  <div class="clearfix"></div>