import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-popularhelptopics',
  templateUrl: './popularhelptopics.component.html',
  styleUrls: ['./popularhelptopics.component.css']
})
export class PopularhelptopicsComponent implements OnInit {

  constructor(public router: Router,) { }

  ngOnInit(): void {
  }

  goto_Page(pgName:string){
    if (pgName == 'lab'){
      let parameters = {
        entryType: 0,
       };
      this.router.navigate(['lablogin'], { queryParams: parameters });
    }
    else if (pgName == 'est'){
     // this.router.navigate(['reg-est-nskmatha']);
     let parameters = {
      entryType: 1,
     };
    this.router.navigate(['lablogin'], { queryParams: parameters });
    }
    
    
  }
  view_PdfFile(fileName): any {

    window.open("https://webapi.pcmcmathadi.in/" + fileName);

  }

}
