<div class="container dashboardmain">
    <div class="row">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
          <li class="breadcrumb-item " aria-current="page">अस्थापना नोंदणी अर्ज </li>
        </ol>
      </nav>
    
    </div>

    <!-- waiting modal -->

    <div class="container">

        <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <!-- Modal body -->
                    <div class="modal-body">

                        <div style="text-align: center;">

                            Please wait ...
                            <div class="spinner-grow text-success"></div>
                            <div class="spinner-grow text-info"></div>
                            <div class="spinner-grow text-warning"></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- waiting modal -->

    <div *ngIf="appl_Type == 'error'" class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
        <h2 style="font-weight: bold;color: red; text-align: center;">
            <i class='fab fa-github' style='font-size:36px;color: red;'></i> Something went wrong.</h2>
    </div>
   
    <section id="company-dashboard" style="margin-top: 5px;" *ngIf="appl_Type != 'error'">
      <div class="container"  >

        <div *ngIf="appl_Type == 'estMst'" class="col-sm-11 col-md-11 col-lg-10 col-xl-9" class="blink">
            <i class='fas fa-arrow-right' style="color: red;"></i>
        </div>

        <div *ngIf="appl_Type == 'estMst'" class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
            <h2 style="font-weight: bold;color: #1b998b;">मंडळाकडून नोंदणी यशस्वीरीत्या अधिकृत झाली असुन.अर्जात
                कुठलाही बदल करता येणार नाही.<a style="font-size: small;"> अधिक माहितीसाठी मंडळात संपर्क
                    साधा.</a></h2>
        </div>

        <div *ngIf="appl_Type == 'estApplication' && Log_accessFor == '' && Appl_Status == 'Reject'"
                    class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
                    <h2 style="font-weight: bold;color: red;">नोंदणी रद्द केली आहे.</h2>
                    <a style="font-size: medium;color: blue;"> {{rejRemark}}</a>

                </div>

        <div *ngIf="appl_Type == 'estApplication' && Log_accessFor == '' && Appl_Status == 'Pending'"
                    class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
                    <h2 style="font-weight: bold;color: blue;">नोंदणी तपासणीसाठी सादर.</h2>
                </div>

                <div *ngIf="appl_Type == 'estApplication' && Log_accessFor == '' && Appl_Status != 'Pending' && Appl_Status != 'Reject' && Appl_Status != ''"
                class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
                <h2 style="font-weight: bold;color: blue;">नोंदणी तपासणीसाठी सादर असुन बदल करता येणार नाही. </h2>
            </div>
  
        <div class="row">
          <div class="col-sm-11 col-md-11 col-lg-10 col-xl-9">
            <ul class="header-menus">
                <li>
                    <ul class="social">
                        <li (click)="set_lang('mar')" class="button_lang">मराठी</li>
                        <li (click)="set_lang('en')" class="button_lang current_lang">En</li>
                    </ul>
                </li>
            </ul>
        </div>
        </div>
  
        <!-- step--1 Start -->
  
        <div class="row">
         <form [formGroup]="step1_Form">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="content-panel">
                  <div class="row">
                      <h4 class="col-sm-12">Estmaster</h4>
                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">Date <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">दिनांक <strong>*</strong></label>
                          <div class="input-group mb-3">
                            <input type="date" class="form-control" (input)="vlaidateDate($event,'regDate')"
                                (change)="setDate($event)" value="{{regDate}}" readonly="true" />
                            <span *ngIf="invalidDate!=''" style="font-size: small;color:red">Invalid
                                Date</span>
                        </div>

                      </div>
                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">Est Name <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">आस्थापनाचे नाव <strong>*</strong></label>
                          <input type="text" class="form-control" formControlName="est_name" placeholder="">
                      </div>
                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">Est Address <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">आस्थापनाचा पत्ता <strong>*</strong></label>
                          <input type="text" class="form-control" formControlName="address" placeholder="">
                      </div>
                      <div class="form-group col-sm-6" style="display: none;">
                          <label *ngIf="lang == 'en'">Mobile Number <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">मोबाईल नंबर <strong>*</strong></label>
                          <input type="text" class="form-control" (keypress)="onlyNumberKey($event)" maxlength="10" formControlName="phoneno_1" maxlength="10" placeholder=""  >
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Phone No.</label>
                          <label *ngIf="lang == 'mar'">दूरध्वनी क्रमांक</label>
                          <input type="text" class="form-control" formControlName="phoneno_2" placeholder="">
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Shop No. </label>
                          <label *ngIf="lang == 'mar'">गाळा नंबर </label>
                          <input type="text" class="form-control" formControlName="galano" placeholder="">
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Est Type <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">आस्थापनाचे प्रकार <strong>*</strong></label>
                          <select class="form-control" formControlName="est_type">
                              <option value="INDIVIDUAL FIRM">INDIVIDUAL FIRM</option>
                              <option value="PARTNERSHIP FIRM">PARTNERSHIP FIRM</option>
                            </select>
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Division Name <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">विभागाचे नाव <strong>*</strong></label>
                          <select class="form-control" formControlName="sel_Market">
                            (change)="set_marketID($event.target.value)">
                            <option *ngFor='let rec of filter_market_list;let i = index'
                                value="{{rec.marketID}}" [selected]="rec.marketID===marketID">
                                {{rec.marketName}}</option>
                          </select>
                      </div>
                     
                      <div class="form-group col-sm-6" style="display: none;">
                          <label *ngIf="lang == 'en'">Location Name </label>
                          <label *ngIf="lang == 'mar'">स्थानाचे नाव </label>
                          <input type="text" class="form-control" formControlName="locname" placeholder="">
                      </div>

                      <div class="form-group mb-0 col-sm-12">
                          <label *ngIf="lang == 'en'">Owner Name <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">मालकाचे नाव <strong>*</strong></label>
                      </div>
                      <div class="form-group col-sm-6">
                          <input type="text" class="form-control" formControlName="owner_name1" placeholder="Owner Name 1">
                      </div>
                      <div class="form-group col-sm-6">
                          <input type="text" class="form-control" formControlName="owner_name2" placeholder="Owner Name 2">
                      </div>
                      <div class="form-group col-sm-6">
                          <input type="text" class="form-control" formControlName="owner_name3" placeholder="Owner Name 3">
                      </div>
                      <div class="form-group col-sm-6">
                          <input type="text" class="form-control" formControlName="owner_name4" placeholder="Owner Name 4">
                      </div>

                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">Give details of membership of any organization (Memoforg) </label>
                          <label *ngIf="lang == 'mar'">कोणत्याही संस्थेचे सदस्यत्व असल्यास तपशील द्या </label>
                          <textarea class="form-control" formControlName="memorg"></textarea>
                      </div>
                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">Are you registered under Establishment Act 1948? If so, give the registration number (ShopEstNo.) </label>
                          <label *ngIf="lang == 'mar'">आस्थापना कायदा १९४८ अंतर्गत तुम्ही नोंदणीकृत आहात का ? असल्यास नोदणी क्रमांक द्या </label>
                          <textarea class="form-control" formControlName="memorg_no"></textarea>
                      </div>
                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">Establishment Type of Business (Type EST) <strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">आस्थापना व्यवसायाचा प्रकार <strong>*</strong></label>
                          <select class="form-control" formControlName="selbusiness_type">
                              <option value="जनरल स्टोर्स">जनरल स्टोर्स</option>
                              <option value="कृषी उत्पन्न बाजार समिती">कृषी उत्पन्न बाजार समिती </option>
                              <option value="जनरल ट्रान्सपोर्ट व्यवसाय">जनरल ट्रान्सपोर्ट व्यवसाय</option>
                              <option value="जनरल क्लॉथ आणि गारमेंट स्टोर्स">जनरल क्लॉथ आणि गारमेंट स्टोर्स</option>
                              <option value="भाजीपाला मार्केट">भाजीपाला मार्केट </option>
                              <option value="खोके बनवणार">खोके बनवणार </option>
                          </select>
                      </div>
                      <div class="form-group mb-0 col-sm-12">
                          <label *ngIf="lang == 'en'">As per your organization's requirements, handling of goods such as loading, unloading, stacking, picking, transportation etc. Full address including place of business where work related to business is done<strong>*</strong></label>
                          <label *ngIf="lang == 'mar'">आपल्या संस्थेचे ज्या ठिकाणी मालाची चढ उतार, थप्पी लावणे, निवडणे, वाहतूक इ. धंदा बाबतची कामे केली जातात त्या कामाच्या ठिकाणासह पूर्ण पत्ता </label>
                      </div>
                      <div class="form-group col-sm-6">
                          <input type="text" class="form-control" formControlName="loadadd1" placeholder="Add 1">
                      </div>
                      <div class="form-group col-sm-6">
                          <input type="text" class="form-control" formControlName="loadadd2" placeholder="Add 2">
                      </div>
                      <div class="form-group col-sm-12">
                          <input type="text" class="form-control" formControlName="loadadd3" placeholder="Add 3">
                      </div>

                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">Have you employed labor through a contractor? Name of contractor and address </label>
                          <label *ngIf="lang == 'mar'">आपण कंत्राटदारा मार्फत कामगार लाविला आहात का ? असल्यास कंत्राटदाराचे नाव व पत्ता </label>
                          <textarea class="form-control" formControlName="contractorname" placeholder="आपण कंत्राटदारा मार्फत कामगार लाविला आहात का ? असल्यास कंत्राटदाराचे नाव व पत्ता"></textarea>
                      </div>
                      <div class="form-group col-sm-12">
                          <label *ngIf="lang == 'en'">If you are a gang of workers, the name of the gang or all the workers </label>
                          <label *ngIf="lang == 'mar'">आपण कामगाराच्या टोळींना कामावर लावली आहात का असल्यास टोळीच्या मुकादमाचे नाव किंवा सर्व कामगाराचे नावे </label>
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Toli no.1</label>
                          <label *ngIf="lang == 'mar'">टोळी क्रमांक.१</label>
                          <input type="text" class="form-control" (keypress)="onlyNumberKey($event)" formControlName="tolino_1" placeholder="">
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Mukadam Name.1</label>
                          <label *ngIf="lang == 'mar'">मुकादमाचे नाव.१</label>
                          <input type="text" class="form-control" formControlName="toliname_1" placeholder="">
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Toli no.2</label>
                          <label *ngIf="lang == 'mar'">टोळी क्रमांक.२</label>
                          <input type="text" class="form-control" (keypress)="onlyNumberKey($event)" formControlName="tolino_2" placeholder="">
                      </div>
                      <div class="form-group col-sm-6">
                          <label *ngIf="lang == 'en'">Mukadam Name.2</label>
                          <label *ngIf="lang == 'mar'">मुकादमाचे नाव २</label>
                          <input type="text" class="form-control" formControlName="toliname_2" placeholder="">
                      </div>

                      <div *ngIf="Log_accessFor == 'Auth'" class="form-group col-sm-12">
                        <label *ngIf="lang == 'en'"> <strong>* If Application Reject Enter
                                Remark</strong></label>
                        <label *ngIf="lang == 'mar'"><strong>* नोंदणी मंजुरी रद्द असेल तर तपशील आवश्यक
                                आहे</strong></label>
                        <input type="text" class="form-control" [value]="rejRemark"
                            (change)="set_rejRemark($event)" placeholder="">
                    </div>

                      <div class="col-sm-12 text-right" *ngIf="Log_accessFor != 'Auth' && (Appl_Status == 'Pending' || Appl_Status == 'Reject' || Appl_Status == '')">
                          <a class="btn-fullfil btn-comman mr-2 float-right" (click)="submit()" *ngIf="lang == 'en'">Submit</a>
                          <a class="btn-fullfil btn-comman mr-2 float-right" (click)="submit()" *ngIf="lang == 'mar'">सबमिट</a>
                      </div>

                      <div *ngIf="Log_accessFor == 'Auth'" class="col-sm-12 text-right">
                        <button type="button" *ngIf="lang == 'en'" (click)="authorize_appl('reject')"
                            class="btn btn-outline-danger btn-xs">Reject</button>&nbsp;&nbsp;
                        <button type="button" *ngIf="lang == 'mar'" (click)="authorize_appl('reject')"
                            class="btn btn-outline-danger btn-xs">मंजुरी रद्द</button>

                        <button type="button" *ngIf="lang == 'en'" (click)="authorize_appl('accept')"
                            class="btn btn-outline-primary btn-xs">Accept</button> &nbsp;&nbsp;
                        <button type="button" *ngIf="lang == 'mar'" (click)="authorize_appl('accept')"
                            class="btn btn-outline-primary btn-xs">मंजुरी आहे</button>

                    </div>

                  </div>
              </div>

          </div>
        </form>
      </div>
         
      </div>
  
    
      
  </section>

</div>