import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private _userService: UserService
    , public router: Router
    , public http: Http, ) { }

  ngOnInit(): void {
  }

  userData:any = [];
  goto_OtpSereen(MobNo: string) {
   //alert(MobNo);
    if (MobNo.length != 10) {
      alert('Enter Valid Mobile No.')
    }
    else {
      sessionStorage.setItem('Mob_OTP', '');
      let IsValidNo = 'no';
    //alert(sessionStorage.getItem('mathaLog_UserType'));
    
    let usertype:string = sessionStorage.getItem('mathaLog_UserType');


    if (usertype == 'Staff' || usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector'){
     let url = 'getLoginUserDt/0/' + MobNo;
        this._userService.get_Dashborad_Data(url).subscribe((data: any) => {
        if (data.length > 0) {
           
           this.userData = data;
           sessionStorage.setItem('userData', data);

           IsValidNo = 'yes';
           let otpval:any = "********####";//Math.floor(Math.random() * 9000) + 1000;
           otpval = this._userService.getMobileotp(MobNo);
           //alert(otpval);
           sessionStorage.setItem('Mob_OTP', otpval.toString());
           let parameters = {
             mob: MobNo
           };
           this.router.navigate(['/verifiedcode'], { queryParams: parameters });

         }
         else {
           alert('Enter Valid Registor Mobile No.')
         }
         },
       (err: HttpErrorResponse) => {
         // this.ngxService.stop();
         alert('Enter Valid Registor Mobile No.')
       }); 
   }

   }

  }

}
