<div class="container dashboardmain">

  <body>
    <section id="company-dashboard">
      
      <app-reports></app-reports>

      <div class="content-panel">
        <div class="row">
          <div class="col-md-4 col-lg-4">
            <div class="form-group">
              <div class="form-group has-search">
                <i class="fa fa-search form-control-feedback"></i>
                <input type="text" class="form-control" id="search-toli" aria-describedby="search-toli"
                  [(ngModel)]="search_rpt" (input)="search_rptName()" placeholder="Enter text to search report"
                  autofocus>

                <div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item nav-item" *ngFor="let rec of filter_rpt" appHighlight
                      [searchedWord]="search_rpt" [content]="rec.rptName" [classToApply]="'font-weight-bold'"
                      [setTitle]="'true'" (click)="set_searchRpt(rec.rptID,rec.rptName);">
                      <!-- <button type="button" class="button button4" >{{rec.rptName}}</button> -->
                      <!-- <a href="javascript:void(0)">{{rec.rptName}}</a> -->
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-8 col-lg-8">

            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <h4>{{selected_rptName}}</h4>
              </div>

            </div>

            <div class="row" style="margin-top: 5px;" *ngIf="show_searchToli == 'yes'" >
              <div class="col-md-12">
                <div class="form-group has-search">
                  <i class="fa fa-search form-control-feedback"></i>
                  <input type="text" class="form-control" id="search-toli" aria-describedby="search-toli"
                  [(ngModel)]="searchToli" (input)="filter_toli()" placeholder="Search gat by number or name" autofocus>

                  <div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item nav-item" *ngFor="let rec of filter_toli_list" appHighlight
                        [searchedWord]="searchToli" [content]="rec.toliNo - rec.mukName" [classToApply]="'font-weight-bold'"
                        [setTitle]="'true'" (click)="set_searchToli(rec.toliId,rec.toliNo,rec.mukName);"
                        style="background-color: #dcf9f6;text-align: start;font-size: medium;font-weight: bold;">
                        {{rec.toliNo}} - {{rec.mukName}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="form-group col-md-8">
                <div class="input-group" style="color: blue;">
                  {{toliInfo}}
                   
                </div>
            </div>

            

            </div>

            <div class="row" *ngIf="show_wagyrmonDt == 'yes'">
              <div class="col-md-6">
                <label for="Financial-Year">Wage Year </label>
                <input type="text" class="form-control" name="txtwageYr" id="txtwageYr"  [(ngModel)]="filter_wageYr" value="{{filter_wageYr}}" placeholder="" (keypress)="onlyNumberKey($event)" maxlength="4">
              </div>
              <div class="col-md-6" style="background-color: yello">
                <label for="Financial-Year">Wage Month </label>
                <input type="text" class="form-control" name="txtwageMon" id="txtwageMon"  [(ngModel)]="filter_wageMonth" value="{{filter_wageMonth}}" placeholder="" (keypress)="onlyNumberKey($event)" maxlength="2">
              </div>
            </div>

            <div class="row" *ngIf="selected_rptID == '17'">
              <div class="col-md-6">
                <label for="Financial-Year">Iway Date </label>
                <input type="date" class="form-control" (change)="set_iwayDate($event)" value="{{iway_Date}}" />
              </div>
              
            </div>

            <!-- *ngIf="show_frmtoDt == 'yes'" -->
            <div class="row" >
              <div class="col-md-6">
                <label for="Financial-Year">From Date </label>
                <input type="date" class="form-control" (change)="set_frmDate($event)" value="{{frm_Date}}" />
              </div>
              <div class="col-md-6" style="background-color: yello">
                <label for="Financial-Year">To Date </label>
                <input type="date" class="form-control" (change)="set_toDate($event)" value="{{to_Date}}" />
              </div>
            </div>
<br>
            <div class="row" *ngIf="show_selpayDt == 'yes'">
              <div class="col-md-12">
                <!-- <label for="Financial-Year">Select Payment Date </label> -->
                <select  class="form-control select-t board-name" id="sel_payDt">
                  <option [value]='0'>Select Payment Date </option>
                </select>
              </div>
              
            </div>

            <!-- *ngIf="show_btngenratePdf == 'yes'" -->
            <div class="row" >
              <div class="col-md-12" style="text-align: right;">
                <button type="button" class="btn btn-primary" style="margin-top: 5px;" (click)="get_Data(selected_rptID)" >Genrate PDf</button>
              </div>

            </div>

          </div>
        </div>

      </div>

    </section>
    <!--  -->
    <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'" >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
  
          <!-- Modal Header -->
          <div class="modal-header" style="background-color: #1A5276;color: white;">
            <h4 class="modal-title">Unpaid Payment Details</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="hide_unpaidlist_modal()"
              style="color: white;">&times;</button>
          </div>
  
          <!-- Modal body -->
          <div class="modal-body">
           
            <table class="table table-hover table-responsive-sm labour-table table-striped " >
              <thead>
                  <tr>
                      
                      <th class="th-sm">Gat No.</th>
                      <th class="th-sm">Name</th>
                      <th class="th-sm">Wage Year</th>
                      <th class="th-sm">Wage Month</th>
                      <th class="th-sm">Pay Date</th>
                      <th class="th-sm">Gross Amt.</th>
                      <th class="th-sm">Paid</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
  
                  <tr class="sub-tr" *ngFor='let rec of toli_unpaidList;let i = index'>
                      
                      <td>{{rec.toliNo}}</td>
                      <td style="text-align: left;">{{rec.toliName}}</td>
                      <td>{{rec.wageYr}}</td>
                      <td>{{rec.wageMon}}</td>
                      <td>{{rec.payDt | date: 'dd/MM/yyyy'}}</td>
                      <td>{{rec.grossAmt}}</td>
                      <td>{{rec.paid}}</td>
                     
                      <td></td>
                  </tr>
  
              </tbody>
           
          </table>
  
          </div>
        </div>
      </div>
    </div>

  </body>
</div>


  
