import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { UserService } from '../../service/UserService';
import { Globals } from 'src/app/Globals';
import { Router, ActivatedRoute, UrlSegmentGroup } from '@angular/router';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data, event } from 'jquery';
import { StringValueToken } from 'html2canvas/dist/types/css/syntax/tokenizer';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

@Component({
  selector: 'app-reg-lab-pimmatha',
  templateUrl: './reg-lab-pimmatha.component.html',
  styleUrls: ['./reg-lab-pimmatha.component.css']
})
export class RegLabPimmathaComponent implements OnInit {

  //#region public varaibale
  loading: string = '';
  step1_Form: FormGroup;
  step2_Form: FormGroup;
  step3_Form: FormGroup;
  step4_Form: FormGroup;

  public lang: string = 'mar';
  public step: number = 1;
  isSubmitted_step_1 = false;

  public LabourID: number = 0;
  public LogMobNo: number = 0;
  public Enrty_Type: string = 'application';
  public UserType: string = '';
  public Log_labourID: number = 0;
  public Log_UserID: number = 0;
  public userID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';
  public Appl_Status: string = 'Pending';

  //#endregion

  constructor(config: NgbAccordionConfig,
    private _userService: UserService,
    private globals: Globals,
    private _http: Http,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,) {

    this.Log_labourID = Number(this.route.snapshot.queryParamMap.get('labID'));
    this.Log_BoardID = Number(this.route.snapshot.queryParamMap.get('boardID'));
    this.LogMobNo = Number(this.route.snapshot.queryParamMap.get('mob'));

    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_UserType') != null && sessionStorage.getItem('mathaLog_UserType') != '') {
      this.UserType = sessionStorage.getItem('mathaLog_UserType');
    }
    // if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
    //   this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    // }
    this.userID = this.Log_UserID;
  }

  //#region "wating modal"
  showloading_Modal: boolean;
  public show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion

  //#region go back
  go_back() {
    this.router.navigate(['dashboard']);
  }
  //#endregion

  //#region  NgOnit

  ngOnInit(): void {
    this.Log_accessFor = ''
    if (this.UserType == 'Inspector') {
      this.Log_accessFor = 'Auth'
      this.appl_Type = 'labApplication';
    }
    else if (this.UserType == 'Secretary') {
      this.Log_accessFor = 'Auth'
      this.appl_Type = 'labApplication';
    }
    else if (this.UserType == 'Chairman') {
      this.Log_accessFor = 'Auth'
      this.appl_Type = 'labApplication';
    }
    else if (this.Log_labourID == 0) {
      this.appl_Type = 'labApplication';
    }

    console.log('Log_accessFor : ', this.Log_accessFor)
    console.log('appl_Type : ', this.appl_Type)
    console.log('UserType : ', this.UserType)
    console.log('Appl_Status : ', this.Appl_Status)


    this.step1_Form = this.formBuilder.group({
      eng_name: ['', [Validators.required]],
      mar_name: ['', [Validators.required]],
      mother_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      peraddress: ['', [Validators.required]],
      malefemail: ['', [Validators.required]],
      rdbgender: ['male', [Validators.required]],
      mobno: ['', [Validators.required]],
      otp: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      ifsccode: ['', [Validators.required]],
      accno: ['', [Validators.required]],
      bankinfo: ['', [Validators.required]],
      aadhar: ['', [Validators.required]],
      panno: ['', [Validators.required]],
      selworktype: ['Hamal', [Validators.required]],

    });

    this.step2_Form = this.formBuilder.group({
      rel_name1: [''],
      address1: [''],
      mobno1: [''],
      rel_name2: [''],
      address2: [''],
      mobno2: [''],
    });



    this.step3_Form = this.formBuilder.group({
      nomeditIndex: -1,
      NomID: 0,
      NomName: '',
      NomAge: '',
      NomAdd: '',
      NomRelation: '',
      NomDOB: new Date().toISOString().substring(0, 10),
      NomType: '',
      NomFrmDt: new Date().toISOString().substring(0, 10),
      NomineePercent: '',
      NomWitName1: '',
      NomWitName2: '',
      NomRemark: '',
    });

    this.dob = new Date().toISOString().substring(0, 10);

    this.set_error_value();
    this.fill_addmode_data();

  }
  //#endregion

  //#region "fill add Mode data"
  cast_list: any = [];
  subcast_list: any = [];
  comp_List: any = [];
  marketList: any = [];
  toli_List: any = [];
  union_List: any = [];
  nom_list: any = [];

  lab_DocList: any = [];
  Doc_List: any = [];
  DocType_List: any = [];

  filter_subcast_list: any = [];
  castID: Number = 0;
  subCastID: Number = 0;
  companyID: Number = 0;
  marketID: Number = 0;
  toliID: Number = 0;
  unionID: Number = 0;
  fill_addmode_data() {
    this.Save_DocformData = new FormData();
    this.Save_formData = new FormData();

    this.cast_list = [];
    this.subcast_list = [];
    this.comp_List = [];
    this.marketList = [];
    this.filter_subcast_list = [];
    this.toli_List = [];
    this.union_List = [];
    this.nom_list = [];

    this.lab_DocList = [];
    this.Doc_List = [];
    this.DocType_List = [];

    this.castID = 0;
    this.subCastID = 0;
    this.companyID = 0;
    this.marketID = 0;

    this.toliID = 0;
    this.unionID = 0;

    this.loading = 'y';
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/getaddmodedatalist/1';
    this._userService.get_Data(url).subscribe(
      (data) => {

        //console.log('data : ', data)

        this.cast_list = data.cast_List;
        this.subcast_list = data.subCast_List;
        this.comp_List = data.comp_List;
        this.marketList = data.marketList;

        this.DocType_List = data.lab_Document_Type;
        this.Doc_List = data.lab_Document;

        this.fill_DocList(this.DocType_List, this.Doc_List);


        this.toli_List = data.toliLogin;
        this.union_List = data.unionComapnyDt;


        if (Number(this.cast_list.length) > 0 && Number(this.subcast_list.length) > 0) {
          if (Number(this.cast_list.length) > 0) {
            this.castID = Number(this.cast_list[0]['castID']);
            this.subCastID = 0;
            this.filter_subCastList(Number(this.castID))

          }

        }




        //console.log('this.marketList', this.marketList)

        if (Number(this.comp_List.length) > 0) {
          this.companyID = Number(this.comp_List[0]['companyID']);;
        }
        this.filter_marketList(this.companyID);
        // if (Number(this.marketList.length) > 0){
        //   this.marketID=Number(this.marketList[0]['marketID']);;
        // }

        if (Number(this.toli_List.length) > 0) {
          this.toliID = Number(this.toli_List[0]['toliID']);;
        }

        if (Number(this.union_List.length) > 0) {
          this.unionID = Number(this.union_List[0]['unionID']);;
        }


        this.loading = '';

        this.show_loadingModal(false);

        this.bind_data();

      },
      (err: HttpErrorResponse) => {
        this.appl_Type = 'error';
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });
  }
  fill_DocList(Docu_Type: any = [], Doc_List: any = [],) {
    this.lab_DocList = [];
    if (Number(Docu_Type.length) > 0 && Number(Doc_List.length) > 0) {
      for (let k = 0; k < Docu_Type.length; k++) {
        this.lab_DocList.push({
          LabourID: this.LabourID,
          DocTypeID: Docu_Type[k]['docTypeID'],
          DocID: 0,
          DocType: Docu_Type[k]['docType'],
          DocName: '',
          FlName: '',
          isSel: false,
          isFlSel: false,

        })

        for (let i = 0; i < Doc_List.length; i++) {
          if (Doc_List[i]['docTypeID'] == Docu_Type[k]['docTypeID']) {
            this.lab_DocList.push({
              LabourID: this.LabourID,
              DocTypeID: 0,
              DocID: Doc_List[i]['docID'],
              DocType: '',
              DocName: Doc_List[i]['docName'],
              FlName: '',
              isSel: false,
              isFlSel: false,
            })
          }


        }


      }
    }

    //console.log('labDocList ', this.lab_DocList);

  }
  filter_market_list: any = [];
  filter_marketList(companyID: Number) {
    this.filter_market_list = [];
    this.marketID = 0;
    this.filter_market_list = this.marketList.filter(x => (Number(x.companyID)) == Number(companyID));
    if (Number(this.filter_market_list.length) > 0) {
      this.marketID = Number(this.filter_market_list[0]['marketID']);
    }

  }
  filter_subCastList(castID: Number) {
    this.filter_subcast_list = [];
    this.subCastID = 0;
    this.filter_subcast_list = this.subcast_list.filter(x => (Number(x.castID)) == Number(castID));
    if (Number(this.filter_subcast_list.length) > 0) {
      this.subCastID = Number(this.filter_subcast_list[0]['subCastID']);
    }

  }

  //#endregion

  //#region setValue
  set_divID(compID: Number) {
    this.companyID = compID;
    this.filter_marketList(this.companyID)
  }
  set_marketID(marketID: Number) {
    this.marketID = marketID;
  }
  set_subCastID(subCastID: Number) {
    this.subCastID = subCastID;
  }
  gender: string = 'Male';
  chk_Mlgender: boolean = true;
  chk_FMlgender: boolean = false;
  set_gender(event, gender: string) {

    // if (gender == 'Male'){
    //   this.chk_FMlgender = false;
    //   this.chk_Mlgender = true;

    // }
    // else{
    //   this.chk_Mlgender = false;
    //   this.chk_FMlgender = true;

    // }
    if (gender == '') {
      this.gender = event.target.value;
    }
    else {
      this.gender = gender;
    }
    // if (this.gender == 'Female'){
    //   this.chk_FMlgender = true;
    //   this.chk_Mlgender = false;
    // }
    // else{
    //   this.chk_FMlgender = false;
    //   this.chk_Mlgender = true;
    // }

    console.log('this.gender', this.chk_Mlgender);
    console.log('this.gender', this.chk_FMlgender);
  }
  set_toliID(toliID: Number) {
    this.toliID = toliID;

  }
  set_unionID(unionID: Number) {
    this.unionID = unionID;

  }
  marStatus: string = 'Married';
  chk_Married: boolean = true;
  chk_UnMarried: boolean = false;
  set_marStatus(marStatus: string) {
    // if (marStatus == 'Married'){
    //  // this.chk_UnMarried = false;
    //   //this.chk_Married = true;
    //   this.marStatus= marStatus;
    // }
    // else{
    //  // this.chk_Married = false;
    //  // this.chk_UnMarried = true;
    //   this.marStatus= marStatus;
    // }

    this.marStatus = marStatus;
    //console.log('this.marStatus', this.marStatus);

  }
  ischecked_asAbove: boolean = false;
  set_asAbove_address() {
    if (this.ischecked_asAbove == false) {
      this.ischecked_asAbove = true;
    }
    else {
      this.ischecked_asAbove = false;
    }

    // console.log(this.ischecked_asAbove);

    if (this.ischecked_asAbove == true) {
      this.step1_Form.controls['peraddress'].setValue(this.step1_Form.controls['address'].value);

    }
    // this.step1_Form.controls['peraddress'].setValue(this.step1_Form.controls['address'].value);
  }

  workerType: string = 'Hamal';
  workerType_selectedIndex = 0;
  set_workerType(workerType: string) {
    this.workerType = workerType;
    //console.log('this.workerType', this.workerType);
    if (workerType == 'Hamal') {
      this.workerType_selectedIndex = 0;
    }
    else if (workerType == 'Bharai') {
      this.workerType_selectedIndex = 1;
    }
    else if (workerType == 'Tolai') {
      this.workerType_selectedIndex = 2;
    }
    else if (workerType == 'Varai') {
      this.workerType_selectedIndex = 3;
    }
    else if (workerType == 'Bardana') {
      this.workerType_selectedIndex = 4;
    }
    else {
      this.workerType_selectedIndex = 0;
    }
  }
  ploce_verf: string = 'Yes';
  policeverf_selectedIndex = 0;
  set_policeverf(ploce_verf: string) {
    this.ploce_verf = ploce_verf;
    //console.log('this.ploce_verf', this.ploce_verf);
    if (ploce_verf == 'Yes') {
      this.workerType_selectedIndex = 0;
    }
    else if (ploce_verf == 'No') {
      this.workerType_selectedIndex = 1;
    }

  }
  set_doc_Sel(i) {
    console.log(this.lab_DocList[i]['isSel'])
    if (this.lab_DocList[i]['isSel'] == true) {
      this.lab_DocList[i]['isSel'] = false;
    }
    else {
      this.lab_DocList[i]['isSel'] = true;
    }
    //console.log(this.lab_DocList[i]['isSel'])
    //console.log(this.lab_DocList)

    //console.log('doc_sel', this.lab_DocList[i]['isSel']);
  }

  set_Sel_docFile(event, i) {
    this.Save_DocformData.append(this.lab_DocList[i]['DocID'], event.target.files[0], event.target.files[0].fileName);
    this.lab_DocList[i]['FlName'] = '';
    this.lab_DocList[i]['isFlSel'] = true;
  }
  //#endregion

  //#region "bind data"
  appl_Type: string = '';
  bind_labMst = [];
  bind_data() {
    this.Save_DocformData = new FormData();
    this.Save_formData = new FormData();

    this.bind_labMst = [];

    this.loading = 'y';
    this.show_loadingModal(true);

    let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/binddata/' + this.Log_labourID + '/' + this.LogMobNo + '/' + this.appl_Type;
    console.log(url);
    this._userService.get_Data(url).subscribe(
      (data) => {
        // alert(data.retMsg);
        this.appl_Type = data.retMsg;

        this.show_loadingModal(false);
        this.bind_labMst = data.lab_Master;

//        console.log('data : ', data)
  //      console.log('this.appl_Type ', this.appl_Type)

        if (Number(this.bind_labMst.length) > 0) {
          this.LabourID = Number(this.bind_labMst[0]["labourID"]);

          this.bind_step_1(this.bind_labMst);
          // console.log('step_1')
          this.bind_step_2(this.bind_labMst);
          // console.log('step_2')
          this.bind_step_3(this.bind_labMst, data.labNom_Master);
          // console.log('step_3')
          this.bind_step_4(data.lab_Document);
          // console.log('step_4')
        }
        this.loading = '';
      },
      (err: HttpErrorResponse) => {
        this.appl_Type = 'error';
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });
  }
  bind_step_1(lab_data) {
    this.Appl_Status = '';

    let chkDt = new Date(lab_data[0]["dob"]);
    this.dob = new Date(chkDt).toISOString().substring(0, 10);

    this.step1_Form.controls['eng_name'].setValue(lab_data[0]["labName"]);
    this.step1_Form.controls['mar_name'].setValue(lab_data[0]["mLabName"]);
    this.step1_Form.controls['mother_name'].setValue(lab_data[0]["motherName"]);
    this.step1_Form.controls['address'].setValue(lab_data[0]["resiAdd1"]);
    this.step1_Form.controls['peraddress'].setValue(lab_data[0]["perAdd1"]);
    this.step1_Form.controls['malefemail'].setValue(lab_data[0]["sex"]);

    this.step1_Form.controls['mobno'].setValue(lab_data[0]["mobNo"]);
    this.step1_Form.controls['dob'].setValue(lab_data[0]["dob"]);
    this.step1_Form.controls['ifsccode'].setValue(lab_data[0]["ifscCode"]);
    this.step1_Form.controls['accno'].setValue(lab_data[0]["accNo"]);
    this.step1_Form.controls['aadhar'].setValue(lab_data[0]["aadharNo"]);
    this.step1_Form.controls['panno'].setValue(lab_data[0]["pan"]);
    this.step1_Form.controls['selworktype'].setValue(lab_data[0]["labourType"]);

    this.age = lab_data[0]["age"];
    this.imgSrc = this.globals.BaseURL + "/PimMathaLabImg/" + lab_data[0]["labImgNm"];
    this.imgName = lab_data[0]["labImgNm"];

    this.bankName = lab_data[0]["bankName"];
    this.branchName = lab_data[0]["branchName"];
    this.ifscCode = lab_data[0]["ifscCode"];
    this.bankaddress = lab_data[0]["bankAddress"];

    this.set_gender('', lab_data[0]["sex"]);
    this.set_marStatus(lab_data[0]["marStatus"]);
    this.set_workerType(lab_data[0]["labourType"]);

    this.Appl_Status = lab_data[0]["status"];

    this.rejRemark = lab_data[0]["remark"];

    //: ['', [Validators.required]],

    this.set_step_disbl_enb(Number(lab_data[0]["stepComplit"]) + 1);

  }

  bind_step_2(lab_data) {

    this.toliID = lab_data[0]["toliID"],
      this.unionID = lab_data[0]["unionID"],

      this.set_toliID(this.toliID);
    this.set_unionID(this.unionID);

    this.step2_Form.controls['rel_name1'].setValue(lab_data[0]["accName1"]);
    this.step2_Form.controls['address1'].setValue(lab_data[0]["acc1Add1"]);
    this.step2_Form.controls['rel_name2'].setValue(lab_data[0]["accName2"]);
    this.step2_Form.controls['address2'].setValue(lab_data[0]["acc1Add2"]);
    this.step2_Form.controls['mobno1'].setValue(lab_data[0]["phone1"]);
    this.step2_Form.controls['mobno2'].setValue(lab_data[0]["phone2"]);

    this.ploce_verf = lab_data[0]["policeVerification"];
    this.set_policeverf(this.ploce_verf);

    //this.set_step_disbl_enb(Number(lab_data[0]["stepComplit"]) + 1);

  }

  bind_step_3(lab_data, lab_NomDt: any = []) {
    this.nom_list = [];

    if (lab_NomDt.length > 0) {
      for (let k = 0; k < lab_NomDt.length; k++) {
        this.nom_list.push({
          NomID: lab_NomDt[k]['nomID'],
          Name: lab_NomDt[k]['name'],
          Age: lab_NomDt[k]['age'],
          NomAdd: lab_NomDt[k]['nomAdd'],
          Relation: lab_NomDt[k]['relation'],
          DOB: lab_NomDt[k]['dob'],
          NomType: lab_NomDt[k]['nomType'],
          FrmDt: lab_NomDt[k]['frmDt'],
          NomineePercent: lab_NomDt[k]['nomineePercent'],
          NomWitName1: lab_data[0]["witName1"],
          NomWitName2: lab_data[0]["witName2"],
          NomRemark: lab_data[0]["remark"],
          isedit: ''
        })

      }
    }

    this.step3_Form.controls['NomWitName1'].setValue(lab_data[0]["witName1"]);
    this.step3_Form.controls['NomWitName2'].setValue(lab_data[0]["witName2"]);
    this.step3_Form.controls['NomRemark'].setValue(lab_data[0]["remark"]);


  }
  bind_step_4(lab_Doc: any = []) {
    //console.log(this.lab_DocList)
    //console.log(lab_Doc)
    if (Number(this.lab_DocList.length) > 0 && Number(lab_Doc.length) > 0) {
      for (let k = 0; k < this.lab_DocList.length; k++) {
        for (let i = 0; i < lab_Doc.length; i++) {
          if (this.lab_DocList[k]['DocID'] == lab_Doc[i]['docID']) {
            this.lab_DocList[k]['isSel'] = true;
            this.lab_DocList[k]['isFlSel'] = true;
            this.lab_DocList[k]['FlName'] = lab_Doc[i]['flName'];
          }

        }

      }
    }
  }
  view_uploadFile(fileName): any {

    window.open(this.globals.BaseURL + "/" + this._userService.get_LabMst_DocFolderName(this.Log_BoardID) + "/" + fileName);

  }
  //#endregion

  //#region bank details using IFSc Code

  bankName;
  branchName;
  ifscCode;
  bankaddress;
  get_bankDt_fromIFSSc(ifscCode) {
    this.bankName = '';
    this.branchName = '';
    this.ifscCode = '';
    this.bankaddress = '';

    let ifscCodeList: any = [];

    let url = 'https://ifsc.razorpay.com/' + ifscCode;
    this._userService.get_public_API_Data(url).subscribe(
      (data) => {
        ifscCodeList = data.json();
        this.bankName = ifscCodeList.BANK;
        this.branchName = ifscCodeList.BRANCH;
        this.ifscCode = ifscCodeList.IFSC;
        this.bankaddress = ifscCodeList.ADDRESS;

        //console.log('ifscdata : ', ifscCodeList)
        //console.log('MICR : ', this.bankName)
        this.loading = '';
        this.show_loadingModal(false);

      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
      });
  }

  //#endregion

  //#region return control reqiured feild error
  get errorControl() {
    return this.step1_Form.controls;
  }
  err_eng_name: string = ''; err_mar_name: string = ''; err_mother_name: string = '';
  set_error_value() {
    if (this.lang == 'en') {
      this.err_eng_name = "Labour name is required.";
    }
    else {
      this.err_eng_name = "कामगाराचे नाव प्रविष्ट करा.";
    }

  }
  //#endregion

  //#region  set language/page navigations
  set_lang(langType: string) {
    this.lang = langType;
    this.set_error_value();

  }
  step2_disable: boolean = true;
  step3_disable: boolean = true;
  step4_disable: boolean = true;
  set_step(step: number) {
    this.step = step;
    //this.set_step_disbl_enb(step);
  }
  set_step_disbl_enb(step: number) {
    this.step2_disable = true;
    this.step3_disable = true;
    this.step4_disable = true;

    if (Number(step) == 2) {
      this.step2_disable = false;
    }
    else if (Number(step) == 3) {
      this.step2_disable = false;
      this.step3_disable = false;
    }
    else if (Number(step) == 4) {
      this.step2_disable = false;
      this.step3_disable = false;
      this.step4_disable = false;
    }
    else if (Number(step) == 5) {
      this.step2_disable = false;
      this.step3_disable = false;
      this.step4_disable = false;
    }
  }
  //#endregion

  //#region "set photo image"
  imgName: string = '';
  public Save_formData = new FormData();
  public Save_DocformData = new FormData();
  imgSrc: any;
  set_file(event: any) {
    this.imgName = '';
    this.Save_formData = new FormData();
    this.Save_formData.append("", event.target.files[0], event.target.files[0].fileName);
    this.imgName = event.target.files[0].fileName;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgSrc = event.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }
  //#endregion

  //#region validate date
  invalidDate = '';
  invalid_NomDob_Date = '';
  invalid_NomFrm_Date = '';
  vlaidateDate(event, fldName: string) {
    let flag: string = 'invalid';

    let chkDt = new Date(event.target.value);
    // 
    if (Number(chkDt.getFullYear()) < 1950 || Number(chkDt.getFullYear()) > 2099) {
      flag = 'invalid';
    }
    else {
      if (chkDt.getFullYear()) {
        flag = '';
      }
      else {

      }

    }

    if (fldName == 'labdob') {
      this.invalidDate = flag;
    }
    else if (fldName == 'nomdob') {
      this.invalid_NomDob_Date = flag;
    }
    else if (fldName == 'nomfrmdt') {
      this.invalid_NomFrm_Date = flag;
    }

  }

  dob;
  public age: Number = 0;
  setDate(event) {
    this.age = 0;
    let chkDt = new Date(event.target.value);
    if (Number(chkDt.getFullYear()) < 1950 || Number(chkDt.getFullYear()) > 2099) {
    }
    else {
      this.dob = new Date(chkDt).toISOString().substring(0, 10);;
    }

    let timeDiff = Math.abs(Date.now() - chkDt.getTime());
    if (new Date(chkDt) < new Date()) {
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    }


  }
  nomdob;
  nomfrmdt;
  nomAge = 0;
  setnom_DOB_Date(event, fldName: string) {

    let chkDt = new Date(event.target.value);
    if (Number(chkDt.getFullYear()) < 1950 || Number(chkDt.getFullYear()) > 2099) {
    }
    else {

      if (fldName == 'nomdob') {
        this.nomAge = 0;
        this.nomdob = new Date(chkDt).toISOString().substring(0, 10);
        let timeDiff = Math.abs(Date.now() - chkDt.getTime());
        if (new Date(chkDt) < new Date()) {
          this.nomAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        }
      }
      else if (fldName == 'nomfrmdt') {
        this.nomfrmdt = new Date(chkDt).toISOString().substring(0, 10);
      }
    }

  }
  //#endregion

  //#region genrate OTP
  otpval;
  genrateOTP() {

    // this.StartTimer();
    let mob_no: string = this.step1_Form.controls['mobno'].value.toString();

    if (mob_no.toString().length != 10) {
      alert('मोबाईल क्रमांक तपासा.')
    }
    else {
      let mobile = mob_no;
      var headers = new Headers()
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      let options = new RequestOptions({ headers: headers });
      this.otpval = (Math.floor(Math.random() * 9000) + 1000).toString();

      this.otpval = this._userService.getMobileotp(mob_no);

      //console.log(this.otpval);

      //  let sms_url = 'https://sms.happysms.in/api/sendhttp.php?authkey=363372A5aL0uyItx60d9aa30P1&mobiles='
      //  + mobile + '&message=Your OTP from KIT is ' + this.otpval + '&sender=KITLtd&route=4&country=91&DLT_TE_ID=1707163937683822686';
      //   this._userService.get_smsApi_method(sms_url)
      //   .then(data => {
      //     //console.log('smsData : ', data);

      //   })
      //   .catch(err => {
      //    // console.log('sms_error : ', err);
      //     //this.showErrorAlert(err);

      //   }); 
    }

  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  //#endregion

  //#region "Nominee details"
  nomRelation = 'Wife';
  nomType = 'Major';
  selrel_selectedIndex = 0;
  selnomtype_selectedIndex = 0;
  set_nomRelation(rel: string) {

    this.nomRelation = rel;
    if (rel == 'Wife') {
      this.selrel_selectedIndex = 0;
    }
    else if (rel == 'Husbund') {
      this.selrel_selectedIndex = 1;
    }
    else if (rel == 'Mother') {
      this.selrel_selectedIndex = 2;
    }
    else if (rel == 'Father') {
      this.selrel_selectedIndex = 3;
    }
    else if (rel == 'Brother') {
      this.selrel_selectedIndex = 4;
    }
    else if (rel == 'Son') {
      this.selrel_selectedIndex = 5;
    }
    else if (rel == 'Doughter') {
      this.selrel_selectedIndex = 6;
    }
    else if (rel == 'GrandFather') {
      this.selrel_selectedIndex = 7;
    }
    else if (rel == 'GrandMother') {
      this.selrel_selectedIndex = 8;
    }
    else {
      this.selrel_selectedIndex = 0;
    }
  }
  set_nomType(nomType: string) {
    this.nomType = nomType;
    if (nomType == 'Major') {
      this.selnomtype_selectedIndex = 0;
    }
    else {
      this.selnomtype_selectedIndex = 1;
    }

  }
  add_nominee() {
    if (this.step3_Form.controls['NomName'].value == '' || this.step3_Form.controls['NomName'].value == null) {
      alert('Enter nominee name.');
      return;
    }
    else if (Number(this.nomAge) <= 0) {
      alert('Enter valid Date Of Birth.');
      return;
    }
    else if (Number(this.nomAge) < 18 && this.nomType == 'Major') {
      alert('Enter valid Date Of Birth.Relation type Major not allow below age 18.');
      return;
    }
    else if (this.step3_Form.controls['NomAdd'].value == '' || this.step3_Form.controls['NomAdd'].value == null) {
      alert('Enter address.');
      return;
    }
    else if (Number(this.step3_Form.controls['NomineePercent'].value) <= 0) {
      alert('Enter valid percent.');
      return;
    }
    else {
      let NomPer = 0;
      let EditNomPer = 0;
      let editInd = -1;
      if (Number(this.nom_list.length) > 0) {
        if (Number(this.step3_Form.controls['nomeditIndex'].value) >= 0) {
          editInd = Number(this.step3_Form.controls['nomeditIndex'].value);
        }

        for (let k = 0; k < this.nom_list.length; k++) {
          if (k == Number(editInd)) {
            EditNomPer = Number(this.nom_list[k]['NomineePercent']);
          }
          NomPer = Number(NomPer) + Number(this.nom_list[k]['NomineePercent']);
        }
      }

      NomPer = Number(NomPer) + Number(this.step3_Form.controls['NomineePercent'].value);

      // alert(NomPer)
      //  alert('edit NomPer' + EditNomPer)

      NomPer = Number(NomPer) - Number(EditNomPer);

      //  alert('NomPer' + NomPer)

      if (Number(NomPer) > 100) {
        alert('Nominee Percent > 100.Enter valid percent.');
        return;
      }
      else {

        if (Number(this.nom_list.length) > 0) {

          console.log('editIndex : ', Number(this.step3_Form.controls['nomeditIndex'].value));

          if (Number(this.step3_Form.controls['nomeditIndex'].value) >= 0) {
            let i = Number(this.step3_Form.controls['nomeditIndex'].value);
            this.nom_list[i]['Name'] = this.step3_Form.controls['NomName'].value;
            this.nom_list[i]['Age'] = this.nomAge;
            this.nom_list[i]['NomAdd'] = this.step3_Form.controls['NomAdd'].value;
            this.nom_list[i]['Relation'] = this.nomRelation;
            this.nom_list[i]['DOB'] = this.step3_Form.controls['NomDOB'].value;
            this.nom_list[i]['NomType'] = this.nomType;
            this.nom_list[i]['FrmDt'] = this.step3_Form.controls['NomFrmDt'].value;
            this.nom_list[i]['NomineePercent'] = this.step3_Form.controls['NomineePercent'].value;
            this.nom_list[i]['NomWitName1'] = this.step3_Form.controls['NomWitName1'].value;
            this.nom_list[i]['NomWitName2'] = this.step3_Form.controls['NomWitName2'].value;
            this.nom_list[i]['NomRemark'] = this.step3_Form.controls['NomRemark'].value;
          }
          else {
            console.log('2');
            this.nom_list.push({
              Name: this.step3_Form.controls['NomName'].value,
              Age: this.nomAge,
              NomAdd: this.step3_Form.controls['NomAdd'].value,
              Relation: this.nomRelation,
              DOB: this.step3_Form.controls['NomDOB'].value,
              NomType: this.nomType,
              FrmDt: this.step3_Form.controls['NomFrmDt'].value,
              NomineePercent: this.step3_Form.controls['NomineePercent'].value,
              NomWitName1: this.step3_Form.controls['NomWitName1'].value,
              NomWitName2: this.step3_Form.controls['NomWitName2'].value,
              NomRemark: this.step3_Form.controls['NomRemark'].value,
              isedit: ''
            })
          }

        }
        else {
          console.log('3');
          this.nom_list.push({
            Name: this.step3_Form.controls['NomName'].value,
            Age: this.nomAge,
            NomAdd: this.step3_Form.controls['NomAdd'].value,
            Relation: this.nomRelation,
            DOB: this.step3_Form.controls['NomDOB'].value,
            NomType: this.nomType,
            FrmDt: this.step3_Form.controls['NomFrmDt'].value,
            NomineePercent: this.step3_Form.controls['NomineePercent'].value,
            NomWitName1: this.step3_Form.controls['NomWitName1'].value,
            NomWitName2: this.step3_Form.controls['NomWitName2'].value,
            NomRemark: this.step3_Form.controls['NomRemark'].value,
            isedit: ''
          })
        }

//        console.log('nomList ', this.nom_list)

        this.step3_Form.controls['nomeditIndex'].setValue(-1);
        this.step3_Form.controls['NomName'].setValue('');
        this.step3_Form.controls['NomAge'].setValue(0);
        this.step3_Form.controls['NomAdd'].setValue('');
        this.step3_Form.controls['NomDOB'].setValue(new Date().toISOString().substring(0, 10));
        this.step3_Form.controls['NomFrmDt'].setValue(new Date().toISOString().substring(0, 10));
        this.step3_Form.controls['NomineePercent'].setValue('');
        this.nomAge = 0

      }
    }
  }
  set_editNominee(editIndex: number) {

    let date: Date;
    date = new Date(this.nom_list[editIndex]['DOB']);

    if (this.nom_list[editIndex]['isedit'] == 'yes') {
    }
    else {
      date.setDate(date.getDate() + 1);
    }

    date.setDate(date.getDate());
    this.nomdob = new Date(date).toISOString().substring(0, 10);


    date = new Date(this.nom_list[editIndex]['FrmDt']);

    if (this.nom_list[editIndex]['isedit'] == 'yes') {
    }
    else {
      date.setDate(date.getDate() + 1);
    }

    date.setDate(date.getDate());
    this.nomfrmdt = new Date(date).toISOString().substring(0, 10);

    this.nom_list[editIndex]['isedit'] == 'yes';

    this.step3_Form.controls['nomeditIndex'].setValue(editIndex);
    this.step3_Form.controls['NomName'].setValue(this.nom_list[editIndex]['Name']);
    this.step3_Form.controls['NomAge'].setValue(this.nom_list[editIndex]['Age']);
    this.step3_Form.controls['NomAdd'].setValue(this.nom_list[editIndex]['NomAdd']);
    this.step3_Form.controls['NomDOB'].setValue(this.nomdob);
    this.step3_Form.controls['NomFrmDt'].setValue(this.nomfrmdt);
    this.step3_Form.controls['NomineePercent'].setValue(this.nom_list[editIndex]['NomineePercent']);
    this.set_nomRelation(this.nom_list[editIndex]['Relation']);
    this.set_nomType(this.nom_list[editIndex]['NomType']);
    this.nomAge = this.nom_list[editIndex]['Age']
  }
  del_nominee(i: number) {
    if (window.confirm('Are sure you to delete.?')) {
      this.nom_list[i]['Name'] = '';
    }
  }
  //#endregion

  //#region submit
  save_Data: any = [];
  status: string = '';
  submit_step_1() {
    //console.log('Age : ',this.age);
    // alert('age : '+ this.age);
    if (window.confirm('Are sure you to update.?')) {
      let isValid = true;

      // if (this.otpval != this.step1_Form.controls['otp'].value) {
      //   if (this.LabourID <= 0) {
      //     alert("Enter valid OTP.")
      //     isValid = false;
      //     return;
      //   }

      // }

      // if (this.appl_Type != "labMst") {
      //   if (this.step1_Form.controls['mobno'].value != this.LogMobNo) {
      //     alert("Enter login mobile number.")
      //     isValid = false;
      //     return;
      //   }
      // }

      if (Number(this.LabourID) == 0) {
        if (this.imgName == '') {
          alert("Select photo.")
          isValid = false;
          return;
        }

      }

      if (this.step1_Form.controls['eng_name'].value == '' || this.step1_Form.controls['eng_name'].value == null) {
        alert("Enter english name.")
        isValid = false;
        return;
      }
      else if (this.step1_Form.controls['mar_name'].value == '' || this.step1_Form.controls['mar_name'].value == null) {
        alert("Enter marathi name.")
        isValid = false;
        return;
      }
      else if (this.step1_Form.controls['mother_name'].value == '' || this.step1_Form.controls['mother_name'].value == null) {
        alert("Enter mother name.")
        isValid = false;
        return;
      }
      else if (this.step1_Form.controls['address'].value == '' || this.step1_Form.controls['address'].value == null) {
        alert("Enter address.")
        isValid = false;
        return;
      }
      else if (Number(this.LogMobNo.toString().length) != 10) {
        alert("Invalid mobile number.")
        isValid = false;
        return;
      }
      else if (Number(this.age) < 19) {
        alert("Age should be > 18.Enter valid date of birth.")
        isValid = false;
        return;
      }
      else if (this.bankName == '' || this.bankName == null) {
        alert("Enter valid IFSC Code.")
        isValid = false;
        return;
      }
      else if (this.step1_Form.controls['accno'].value == '' || this.step1_Form.controls['accno'].value == null) {
        alert("Enter bank account number.")
        isValid = false;
        return;
      }
      else if (Number(this.step1_Form.controls['aadhar'].value.length) != 12) {
        alert("Enter valid Aadhar number.")
        isValid = false;
        return;
      }
      else if (Number(this.step1_Form.controls['panno'].value.length) != 10) {
        alert("Enter valid PAN number.")
        isValid = false;
        return;
      }
      else if (Number(this.marketID) <= 0) {
        alert("Select sub division.")
        isValid = false;
        return;
      }
      else if (Number(this.companyID) <= 0) {
        alert("Select division.")
        isValid = false;
        return;
      }
      else if (Number(this.subCastID) <= 0) {
        alert("Select sub-cast.")
        isValid = false;
        return;
      }
      else {
        let step1_Data: any = {
          "LabourID": this.LabourID,
          "LabName": this.step1_Form.controls['eng_name'].value,
          "MLabName": this.step1_Form.controls['mar_name'].value,
          "MotherName": this.step1_Form.controls['mother_name'].value,
          "ResiAdd1": this.step1_Form.controls['address'].value,
          "PerAdd1": this.step1_Form.controls['peraddress'].value,
          "Sex": this.gender,
          "MarStatus": this.marStatus,
          "MobNo": this.LogMobNo, //this.step1_Form.controls['mobno'].value,
          "DOB": this.dob,
          "Age": this.age,
          "SubCastID": this.subCastID,
          "LabourType": this.workerType,
          "IFSCCode": this.step1_Form.controls['ifsccode'].value,
          "BankName": this.bankName,
          "BranchName": this.branchName,
          "BankAddress": this.bankaddress,
          "AccNo": this.step1_Form.controls['accno'].value,
          "AadharNo": this.step1_Form.controls['aadhar'].value,
          "PAN": this.step1_Form.controls['panno'].value,
          "MarketID": this.marketID,
          "CompanyID": this.companyID,
          "UserID": this.Log_UserID,
          "ApplType": this.appl_Type,
          "Status": this.status
        }

        //console.log(this.save_Data);

        if (isValid == true) {

          let body = new FormData();
          body = step1_Data;
          let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/savelabmststep1';
          //alert(url);
          this._userService.post_data(url, body).subscribe(
            (data) => {

              if (Number(data) > 0) {
                this.LabourID = data;
                //this.LogMobNo = this.step1_Form.controls['mobno'].value;
                this.ngxService.stop();
                alert('Record saved successfully.');
                // this.bind_data();
                this.set_step_disbl_enb(2);
                this.set_step(2);


                this.save_Data = [];
                this.save_Data = [{
                  "LabourID": this.LabourID,
                  "LabName": this.step1_Form.controls['eng_name'].value,
                  "MLabName": this.step1_Form.controls['mar_name'].value,
                  "MotherName": this.step1_Form.controls['mother_name'].value,
                  "ResiAdd1": this.step1_Form.controls['address'].value,
                  "PerAdd1": this.step1_Form.controls['peraddress'].value,
                  "Sex": this.gender,
                  "MarStatus": this.marStatus,
                  "MobNo": this.step1_Form.controls['mobno'].value,
                  "DOB": this.dob,
                  "Age": this.age,
                  "SubCastID": this.subCastID,
                  "LabourType": this.workerType,
                  "IFSCCode": this.step1_Form.controls['ifsccode'].value,
                  "BankName": this.bankName,
                  "BranchName": this.branchName,
                  "BankAddress": this.bankaddress,
                  "AccNo": this.step1_Form.controls['accno'].value,
                  "AadharNo": this.step1_Form.controls['aadhar'].value,
                  "PAN": this.step1_Form.controls['panno'].value,
                  "MarketID": this.marketID,
                  "CompanyID": this.companyID,
                  "UserID": this.Log_UserID,
                  "ApplType": this.appl_Type,
                  "Status": this.status
                }]

                url = this.globals.BaseURL + 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/savelabimg/' + this.LabourID;

                this.Save_formData.append("LabMaster", JSON.stringify(this.save_Data));
                // this.Save_formData.append("ExlInsPolicyDt", JSON.stringify(this.excle_policyLabList));

                $.ajax({
                  url: url,
                  type: 'POST',
                  data: this.Save_formData,
                  cache: false,
                  contentType: false,
                  processData: false,
                  success: function (fileName) {
                    //console.log(fileName)
                    //  alert(fileName)
                    if (fileName == 'Saved SuccessFully.') {

                      // window.location.reload();
                      // this.set_step_disbl_enb(2);
                      // this.set_step(2);

                    }


                  }
                });

              }
              else if (data == "-1") {
                alert('Mobile number allready exists');
              }
              else if (data == "-2") {
                alert('AadharNo number allready exists');
              }
              else if (data == "-3") {
                alert('PAN number allready exists');
              }
              else if (data == "-4") {
                alert('Account number allready exists');
              }
              else {
                this.ngxService.stop();
                alert(data);
                //alert('Something went wrong internal server error.')
              }
            },
            (err: HttpErrorResponse) => {
              this.show_loadingModal(false);
              this.ngxService.stop();
              alert('Something went wrong internal server error.')
            });


        }

      }
    }
    else {
      return;
    }
    //after success go to next step
    //this.set_step(2);
  }

  submit_step_2() {
    if (window.confirm('Are sure you to update.?')) {
      let isValid = true;

      if (this.LabourID <= 0) {
        alert("Invalid application.Step-1 not completed.")
        isValid = false;
        return;
      }

      if (this.step2_Form.controls['rel_name1'].value == '' || this.step2_Form.controls['rel_name1'].value == null) {
        alert("Enter relative/friends name 1.")
        isValid = false;
        return;
      }
      else if (this.step2_Form.controls['address1'].value == '' || this.step2_Form.controls['address1'].value == null) {
        alert("Enter relative/friends name 1 address.")
        isValid = false;
        return;
      }
      else if (Number(this.step2_Form.controls['mobno1'].value.length) != 10) {
        alert("Enter valid mobile number.")
        isValid = false;
        return;
      }

      else if (Number(this.toliID) <= 0) {
        alert("Select toli.")
        isValid = false;
        return;
      }
      else if (Number(this.unionID) <= 0) {
        alert("Select union.")
        isValid = false;
        return;
      }

      //console.log(this.save_Data);

      if (isValid == true) {

        let step2_Data: any = {
          "LabourID": this.LabourID,
          "ToliID": this.toliID,
          "UnionID": this.unionID,
          "AccName1": this.step2_Form.controls['rel_name1'].value,
          "Acc1Add1": this.step2_Form.controls['address1'].value,
          "AccName2": this.step2_Form.controls['rel_name2'].value,
          "Acc1Add2": this.step2_Form.controls['address2'].value,
          "Phone1": this.step2_Form.controls['mobno1'].value,
          "Phone2": this.step2_Form.controls['mobno2'].value,
          "PoliceVerification": this.ploce_verf,
          "ApplType": this.appl_Type
        }

        let body = new FormData();
        body = step2_Data;
        let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/savelabmststep2';
        //alert(url);
        this._userService.post_data(url, body).subscribe(
          (data) => {

            if (data == "Record Saved Sucessfully") {
              this.ngxService.stop();
              alert(data);
              //  this.bind_data();
              this.set_step_disbl_enb(3);
              this.set_step(3);
            }
            else {
              this.ngxService.stop();
              alert('Something went wrong internal server error.')
            }
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            this.ngxService.stop();
            alert('Something went wrong internal server error.')
          });

      }

    }

  }

  submit_step_3() {
    if (window.confirm('Are sure you to update.?')) {
      let isValid = true;

      if (this.LabourID <= 0) {
        alert("Invalid application.Step-1 not completed.")
        isValid = false;
        return;
      }
      else if (Number(this.nom_list.length) <= 0) {
        alert("Add nominne details")
        isValid = false;
        return;
      }
      else {
//        console.log(this.save_Data);

        if (isValid == true) {
          let saveNom: any = {
            "LabNom_Master": this.nom_list,
            "LabourID": this.LabourID,
            "ApplType": this.appl_Type,
            "witness1": this.step3_Form.controls['NomWitName1'].value,
            "witness2": this.step3_Form.controls['NomWitName2'].value,
            "remark": this.step3_Form.controls['NomRemark'].value,
          }

          let body = new FormData();
          body = saveNom;
          let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/savelabmststep3';
          //alert(url);
          this._userService.post_data(url, body).subscribe(
            (data) => {

              if (data == "Record Saved Sucessfully") {
                this.ngxService.stop();
                alert(data);

                this.bind_data();
                this.set_step_disbl_enb(4);
                this.set_step(4);
              }
              else {
                this.ngxService.stop();
                alert('Something went wrong internal server error.')
              }
            },
            (err: HttpErrorResponse) => {
              this.show_loadingModal(false);
              this.ngxService.stop();
              alert('Something went wrong internal server error.')
            });

        }


      }

    }

  }
  submit_step_4() {
    // 
    let isValid = true;
    for (let i = 0; i < this.lab_DocList.length; i++) {
      if (Number(this.lab_DocList[i]['DocID']) > 0) {
        if (this.lab_DocList[i]['isSel'] == true && this.lab_DocList[i]['isFlSel'] == false) {
          isValid = false;
          alert('Select dcoument file ' + this.lab_DocList[i]['DocName'].toString());
          return;
        }
      }

    }

    if (isValid == true) {
      if (window.confirm('Are sure you to update.?')) {
        this.show_loadingModal(true);

        this.save_Data = [];
        this.save_Data = this.lab_DocList;

        // console.log(this.save_Data);

        if (isValid == true) {

          let url = this.globals.BaseURL + 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/savelabmststep4/' + this.LabourID + '/' + this.appl_Type;

          this.Save_DocformData.append("LabDoc", JSON.stringify(this.save_Data));
          // this.Save_formData.append("ExlInsPolicyDt", JSON.stringify(this.excle_policyLabList));

          $.ajax({
            url: url,
            type: 'POST',
            data: this.Save_DocformData,
            cache: false,
            contentType: false,
            processData: false,
            success: function (fileName) {

              if (fileName == 'Saved SuccessFully.') {
                alert('Updated successfully.')
                window.location.reload();
              }
              else {
                this.showloading_Modal = false;
                alert('Something went wrong internal server error.');

                return;
              }

            }
          }),
            (err: HttpErrorResponse) => {
              this.showloading_Modal = false;
              alert(err);
            };
        }

      }
    }

  }
  //#endregion

  //#region authorize application
  set_rejRemark(event) {
    this.rejRemark = event.target.value;
  }
  rejRemark: string;
  authorize_appl(flag) {
    //rejremark
    if (flag == 'reject' && this.rejRemark == '') {
      alert('Enter remark')
      return;
    }
    else {

      let status: string = '';
      if (flag == 'accept') {
        if (this.UserType == 'Inspector') {
          status = 'Inspector Approve';
        }
        else if (this.UserType == 'Secretary') {
          status = 'Secretary Approve';
        }
        else if (this.UserType == 'Chairman') {
          status = 'Chairman Approve';
        }
      }
      else {
        status = 'Reject';
      }

      let saveNom: any = {
        "LabourID": this.LabourID,
        "Status": status,
        "remark": this.rejRemark,
      }

      let body = new FormData();
      body = saveNom;
      let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/labapllauth';
      //alert(url);
      this._userService.post_data(url, body).subscribe(
        (data) => {

          if (data == "Record Saved Sucessfully") {
            this.ngxService.stop();
            alert(data);

            this.go_back();
          }
          else {
            this.ngxService.stop();
            alert('Something went wrong internal server error.')
          }
        },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          this.ngxService.stop();
          alert('Something went wrong internal server error.')
        });
    }
  }
  //#endregion

}
