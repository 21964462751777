//#region imports
import { Component, OnInit, ViewChild, ElementRef, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Globals } from 'src/app/Globals';

import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';
// export interface EmpFilter {
//   name:string;
//   options:string[];
//   defaultValue:string;
// }

import jspdf from 'jspdf';

import html2canvas from 'html2canvas';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import jsPDF from 'jspdf';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as XLSX from 'xlsx';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, AlertPromise } from 'selenium-webdriver';

//import { HttpClient } from '@angular/common/http';
//import { read, utils } from "xlsx";

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from "rxjs";

import * as $ from 'jquery';

import { DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';



//#endregion


@Component({
  selector: 'app-clerk-dahsboard',
  templateUrl: './clerk-dahsboard.component.html',
  styleUrls: ['./clerk-dashboard.component.css']
})

export class ClerkDahsboardComponent implements OnInit {
  //@ViewChild('tabset', { static: true }) tabset: any;

  //#region  public declartion
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;

  active;
  disabled = true;

  onNavChange(changeEvent: NgbNavChangeEvent) {
    // if (changeEvent.nextId === 3) {
    //   changeEvent.preventDefault();
    // }
  }

  toggleDisabled() {
    // this.disabled = !this.disabled;
    // if (this.disabled) {
    //   this.active = 1;
    // }
  }

  public YrList: any = [];
  public MonthList: any = [];
  public boardlist: any = [];
  public labourData: any = [];
  public filterFayear = 2023;
  public filterYr: number = 0;
  public filterMonth: number = 0;
  public filterBoradID: number = 0;

  frm_authDate;
  to_authDate;

  frm_paidBankDate = new Date().toISOString().substring(0, 10);
  to_paidBankDate = new Date().toISOString().substring(0, 10);

  authDate;
  paidChqDate = new Date().toISOString().substring(0, 10);
  labPaidBankDate = new Date().toISOString().substring(0, 10);

  filtersubDivID: Number = 0;

  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';

  elements: any = [
    { id: 1, first: '1', last: 'साळवी स्टॉप टोळी, रत्न्नागिरी' },
    { id: 2, first: '30', last: 'अेक्सेल लॉजिस्टिक, झाडगाव, रत्न्नागिरी' },
    { id: 3, first: '45', last: 'शासकीय धान्न्य गोदाम विजयदूर्ग' },
    { id: '', first: '', last: '', edit: 'Go To Receipt' },
  ];

  headElements = ['Sr.No.', 'Toli No.', 'Toli Name', ''];
  //#endregion

  //#region constructor
  constructor(config: NgbAccordionConfig
    , private _userService: UserService
    , public router: Router
    , private globals: Globals
    , private route: ActivatedRoute
    , private ngxService: NgxUiLoaderService,
    private cdr: ChangeDetectorRef
  ) {
    config.closeOthers = true;
    config.type = 'info';
  }
  //#endregion

  // #region ngAfterViewChecked
  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  // #endregion

  //#region Nog OnInit
  public user_AuthList: any = [];
  show_tab = 'no';
  ngOnInit(): void {
    // this.ngxService.start();

    if (sessionStorage.getItem('mathaLog_accessFor') != null && sessionStorage.getItem('mathaLog_accessFor') != '') {
      this.Log_accessFor = sessionStorage.getItem('mathaLog_accessFor');
    }
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));

    }
    if (sessionStorage.getItem('mathafilterBoradID') != null && sessionStorage.getItem('mathafilterBoradID') != '') {
      this.filterBoradID = Number(sessionStorage.getItem('mathafilterBoradID'));
    }
    if (sessionStorage.getItem('mathafilterMonth') != null && sessionStorage.getItem('mathafilterMonth') != '') {
      this.filterMonth = Number(sessionStorage.getItem('mathafilterMonth'));
      //alert(this.filterMonth);
    }
    if (sessionStorage.getItem('mathafilterYr') != null && sessionStorage.getItem('mathafilterYr') != '') {
      this.filterYr = Number(sessionStorage.getItem('mathafilterYr'));

    }
    if (sessionStorage.getItem('mathafiltersubDivID') != null && sessionStorage.getItem('mathafiltersubDivID') != '') {
      this.filtersubDivID = Number(sessionStorage.getItem('mathafiltersubDivID'));
    }

    if (sessionStorage.getItem('mathafilterFayear') != null && sessionStorage.getItem('mathafilterFayear') != '') {
      this.filterFayear = Number(sessionStorage.getItem('mathafilterFayear'));
    }

    this.frm_authDate = new Date().toISOString().substring(0, 10);
    this.to_authDate = new Date().toISOString().substring(0, 10);
    this.authDate = new Date().toISOString().substring(0, 10);

    this.user_AuthList = this._userService.get_userAuthDt();

    this.fill_FinYr();
    this.fill_Market_List();

    this.fill_ins_pat_union_CompListDt();
    this.fill_labPaidBankListNo(0);


    this.active = 1;

  }
  //#endregion

  //#region other functions
  fill_allData() {
    this.fill_recDt();

  }
  fill_FinYr() {
    this.YrList = [];
    this.ngxService.start();
    this._userService.get_Dashborad_Data('getFayear').subscribe(
      (data) => {
        this.ngxService.stop();
        this.YrList = data;
        if (this.filterFayear == 0) {
          this.fill_Month(this.YrList[data.length - 1]['fayear']);
        }
        else {
          this.fill_Month(this.filterFayear);
        }
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });

    let tddate = new Date();
    let mon = tddate.getMonth() + 1;
    if (this.filterFayear == 0) {
      if (mon < 4) {
        this.filterFayear = Number(tddate.getFullYear()) - 1;
      }
      else {
        this.filterFayear = tddate.getFullYear();
      }
    }


  }
  Set_Fayear(event) {
    this.clear_Data();
    this.fill_Month(event.target.value);
    this.filterFayear = event.target.value;

  }
  Set_FiterYrMon(event) {
    this.clear_Data();
    this.filterMonth = Number(event.target.value);
    if (Number(this.filterMonth) < 4) {
      this.filterYr = this.filterFayear + 1
    }
    else {
      this.filterYr = this.filterFayear
    }
    if (Number(this.filterMonth) == 0) {
      this.filterYr = 0
    }


  }
  fill_Month(Yr) {
    //this.clear_Data();
    this.MonthList = [];
    this.MonthList = [
      { mon: 0, yr: Yr, dispmon: "All" },
      { mon: 4, yr: Yr, dispmon: "Apr-" + Yr },
      { mon: 5, yr: Yr, dispmon: "May-" + Yr },
      { mon: 6, yr: Yr, dispmon: "Jun-" + Yr },
      { mon: 7, yr: Yr, dispmon: "Jul-" + Yr },
      { mon: 8, yr: Yr, dispmon: "Aug-" + Yr },
      { mon: 9, yr: Yr, dispmon: "Sep-" + Yr },
      { mon: 10, yr: Yr, dispmon: "Oct-" + Yr },
      { mon: 11, yr: Yr, dispmon: "Nov-" + Yr },
      { mon: 12, yr: Yr, dispmon: "Dec-" + Yr },
      { mon: 1, yr: Yr + 1, dispmon: "Jan-" + (Number(Yr) + 1) },
      { mon: 2, yr: Yr + 1, dispmon: "Feb-" + (Number(Yr) + 1) },
      { mon: 3, yr: Yr + 1, dispmon: "Mar-" + (Number(Yr) + 1) },
    ];

    if (this.filterMonth == 0) {
      let tddate = new Date();
      let mon = tddate.getMonth() + 1;
      this.filterMonth = mon;
    }
    if (this.filterYr == 0) {
      let tddate = new Date();
      let yr = tddate.getFullYear();
      this.filterYr = yr;
    }


    //  this.filterYr = 0;

  }

  clear_Data() {

  }
  market_List: any = [];
  filterMarketID: number = 0;
  fill_Market_List() {
    this.market_List = [];
    this.filterMarketID = 0;

    this.filterMarketID = 1;
    this.fill_allData();
    return

    this.ngxService.start();
    let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getMarketList';
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.ngxService.stop()
        this.market_List = data;


      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  Set_MarketID(event) {
    this.clear_Data();
    this.filterMarketID = Number(event.target.value);
  }
  recDate;
  setDate(event) {
    this.recDate = event.target.value;
  }
  getTotal(data, fldname) {
    if (fldname == 'wagesAmt') {
      return data.reduce((sum, curr) => sum + curr.wagesAmt, 0);
    }
    else if (fldname == 'levyAmt') {
      return data.reduce((sum, curr) => sum + curr.levyAmt, 0);
    }
    else if (fldname == 'anamatAmt') {
      return data.reduce((sum, curr) => sum + curr.anamatAmt, 0);
    }
    //
    else if (fldname == 'totAmt') {
      return data.reduce((sum, curr) => sum + curr.totAmt, 0);
    }
    else if (fldname == 'netPay') {
      return data.reduce((sum, curr) => sum + curr.netPay, 0);
    }
    else if (fldname == 'recWageAmt') {
      return data.reduce((sum, curr) => sum + curr.recWageAmt, 0);
    }
    else if (fldname == 'recPaidAmt') {
      return data.reduce((sum, curr) => sum + curr.recPaidAmt, 0);
    }


  }

  show_recDoneTot: string = '';
  pageChanged(event, pageNm) {

    if (pageNm == 'recdone') {
      this.pg_RecDone_config.currentPage = event;
      // itemsPerPage: 5,
      // currentPage: 1,
      // totalItems: this.rec_DoneToli.length
      if ((this.pg_RecDone_config.totalItems / 5) == event) {
        this.show_recDoneTot = 'yes';
      }
      else {
        this.show_recDoneTot = '';
      }
    }
    else if (pageNm == 'recnotdone') {
      this.pg_RecDone_config.currentPage = event;
    }

  }
  pg_RecDone_config: any;
  pg_RecNotDone_config: any;
  set_paging_Config(pageNm, perpgCnt, data) {
    // this.pg_RecDone_config = {
    //   itemsPerPage: 5,
    //   currentPage: 1,
    //   totalItems: this.filter_rec_notDoneToli.length
    // };
  }
  tabClick(tab) {

    if (tab.nextId == "ngb-tab-0") {
      this.pg_RecDone_config = {
        itemsPerPage: 5,
        currentPage: 1,
        totalItems: this.filter_rec_notDoneToli.length
      };
      //alert(this.filter_rec_notDoneToli.length);
    }
    else if (tab.nextId == "ngb-tab-1") {
      this.pg_RecDone_config = {
        itemsPerPage: 5,
        currentPage: 1,
        totalItems: this.rec_DoneToli.length
      };
      //alert(this.rec_DoneToli.length);
    }
  }

  go_to_receipt(docID, toliID, print) {
    //console.log('user_AuthList',this.user_AuthList);
    let actionType: string = 'na';
    if (Number(docID) == 0) {
      actionType = 'add';
    }
    else {
      actionType = 'edit';
    }

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    let authFor = "Wages/Levy Rec";

    let url = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url).subscribe((data) => {

      // console.log('retData',data);
      auth_List = data;
      console.log('auth_List:', auth_List)
      if (authFor != '') {
        let filterList = [];
        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'edit') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'delete') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else {
          alert('You have no authority.')
          return;
        }

        if (Number(filterList.length) > 0) {
          //alert(Number(filterList.length));
          let parameters = {
            docid: docID,
            toliID: toliID,
            fayear: this.filterFayear,
            print: print
          };

          if (this.Log_BoardID == 1) {
            return ''
          }
          else if (this.Log_BoardID == 2) {
            return ''
            // this.router.navigate(['receipt/wl-receipt-nskmatha'], { queryParams: parameters });
          }
          else if (this.Log_BoardID == 3) {
            return ''
          }
          else if (this.Log_BoardID == 4) {
            return ''
            //this.router.navigate(['receipt/wl-receipt-rmatha'], { queryParams: parameters });
          }
          else if (this.Log_BoardID == 6) {
            this.router.navigate(['receipt/wl-receipt-pimmatha'], { queryParams: parameters });
          }
        }
        else {
          alert('You have no authority.')
          return;
        }

      }
      else {
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        alert('You have no authority.')
        return;
      });


    //alert(toliID);


  }
  go_to_labpay(payDt, toliID, print, wageyr, wagemon, payMode) {

    var date_to_parse = new Date();
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();

    // alert(month);

    if (Number(wageyr) == 0) {
      if (Number(month) == 1) {
        wagemon = 12;
        wageyr = Number(year) - 1;
      }
      else {
        wagemon = Number(month) - 1;
        wageyr = Number(year);
      }
    }
    if (payDt == '') {
      payDt = new Date().toISOString().substring(0, 10);;
    }
    else {
      let date: Date;
      date = new Date(payDt);
      date.setDate(date.getDate() + 1);
      payDt = new Date(date).toISOString().substring(0, 10);
    }

    //alert(toliID);
    let parameters = {
      payDt: payDt,
      toliID: toliID,
      fayear: this.filterFayear,
      wageyr: wageyr,
      wagemon: wagemon,
      print: print,
      payMode: payMode
    };


    let actionType: string = 'na';
    actionType = 'add';

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    let authFor = "Lab. Payment";

    let url = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url).subscribe((data) => {

      // console.log('retData',data);
      auth_List = data;
      console.log('auth_List:', auth_List)
      if (authFor != '') {
        let filterList = [];
        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'edit') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'delete') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else {
          alert('You have no authority.')
          return;
        }

        if (Number(filterList.length) > 0) {
          //alert(Number(filterList.length));

          if (this.Log_BoardID == 6) {
            this.router.navigate(['payment/pimmatha/lab-payment-pimmatha'], { queryParams: parameters });
          }

        }
        else {
          alert('You have no authority.')
          return;
        }

      }
      else {
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        alert('You have no authority.')
        return;
      });

  }
  //#endregion

  //#region filter records
  rec_DtToli: any[];
  rec_notDoneToli: any[];
  filter_rec_notDoneToli: any[];
  rec_DoneToli: any[];
  pay_notDoneToli: any[];
  filter_pay_notDoneToli: any[];

  pay_DoneToli: any[];
  filter_pay_DoneToli: any[];

  filter_RecNo: string = '';
  filter_docdt: string = '';
  filter_toliNo: string = '';
  filter_mukName: string = '';
  filter_wageyr: string = '';
  filter_wageMon: string = '';
  filter_wagesAmt: string = '';
  filter_levyAmt: string = '';
  filter_anamatAmt: string = '';
  filter_totAmt: string = '';

  filter_NoRec_toliNo: string = '';
  filter_NoRec_mukName: string = '';

  filter_Nopay_toliNo: string = '';
  filter_Nopay_mukName: string = '';

  filter_pay_listno: string = '';
  filter_pay_paydt: string = '';
  filter_pay_toliNo: string = '';
  filter_pay_mukName: string = '';
  filter_pay_wageyr: string = '';
  filter_pay_wageMon: string = '';
  filter_pay_wagesAmt: string = '';
  filter_pay_levyAmt: string = '';
  filter_pay_netpayAmt: string = '';
  filter_pay_finalized: string = '';
  filter_pay_authorized: string = '';

  fill_recDt() {
    this.loading = 'y';
    this.show_loadingModal(true);

    this.clear_filterVal();
    this.rec_DtToli = [];
    this.rec_DoneToli = [];
    this.rec_notDoneToli = [];
    this.filter_rec_notDoneToli = [];

    this.pay_notDoneToli = [];
    this.filter_pay_notDoneToli = [];
    this.pay_DoneToli = [];
    this.filter_pay_DoneToli = [];

    this.ngxService.start();

    let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getWlToliList/' + 'NoRec' + '/' + this.filterMarketID + '/' + this.filterFayear + '/' + this.filterYr + '/' + this.filterMonth;
    this._userService.get_Data(url).subscribe(
      (data) => {

        this.rec_notDoneToli = data;
        this.filter_rec_notDoneToli = data;
        console.log('recnotdone:', data)
        this.set_paging_Config('recnotdone', 5, this.filter_rec_notDoneToli);

        // this.pg_RecDone_config = {
        //   itemsPerPage: 5,
        //   currentPage: 1,
        //   totalItems: this.filter_rec_notDoneToli.length
        // };

        //this.ngxService.stop();
        // this.show_loadingModal(true);
        url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getWlToliList/' + 'RecDone' + '/' + this.filterMarketID + '/' + this.filterFayear + '/' + this.filterYr + '/' + this.filterMonth;
        this._userService.get_Data(url).subscribe(
          (data) => {

            this.rec_DtToli = data;
            this.rec_DoneToli = data;

            console.log('recDone', data);
            //this.show_loadingModal(false);
            //

            url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getWlToliList/' + 'NoPay' + '/' + this.filterMarketID + '/' + this.filterFayear + '/' + this.filterYr + '/' + this.filterMonth;
            // alert(url);
            this._userService.get_Data(url).subscribe(
              (data) => {

                this.pay_notDoneToli = data;
                this.filter_pay_notDoneToli = data;
                //this.ngxService.stop();

                url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getWlToliList/' + 'PayDone' + '/' + this.filterMarketID + '/' + this.filterFayear + '/' + this.filterYr + '/' + this.filterMonth;
                //alert(url);
                this._userService.get_Data(url).subscribe(
                  (data) => {

                    this.pay_DoneToli = data;
                    this.filter_pay_DoneToli = data;
                    console.log('payDoneToli ', data);
                    this.ngxService.stop();

                    this.show_loadingModal(false);
                  },
                  (err: HttpErrorResponse) => {
                    this.loading = '';
                    this.show_loadingModal(false);
                    this.ngxService.stop();
                    alert(err);
                    return;
                  });


              },
              (err: HttpErrorResponse) => {
                this.loading = '';
                this.show_loadingModal(false);
                this.ngxService.stop();
                alert(err);
                return;
              });

            this.loading = '';
            // this.show_loadingModal(false);


          },
          (err: HttpErrorResponse) => {
            this.ngxService.stop();
            this.loading = '';
            this.show_loadingModal(false);

            alert(err);
            return;
          });

        this.loading = '';
        // this.show_loadingModal(false);

      },
      (err: HttpErrorResponse) => {
        this.loading = '';
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(err);
        return;
      });



  }
  filter_No_recDone(filterCol, filterval) {
    this.set_NoRec_filterVal(filterCol, filterval);
    this.filter_rec_notDoneToli = this.rec_notDoneToli;

    if (this.filter_NoRec_toliNo.toString() != '') {
      //this.filter_rec_notDoneToli = this.filter_rec_notDoneToli.filter(x => x.toliNo.toString() == this.filter_NoRec_toliNo);

      this.filter_rec_notDoneToli = this.filter_rec_notDoneToli.filter((item) => {
        return ((item.toliNo.toString()).indexOf(this.filter_NoRec_toliNo.toString()) > -1);
      });
    }
    if (this.filter_NoRec_mukName.toString() != '') {
      this.filter_rec_notDoneToli = this.filter_rec_notDoneToli.filter((item) => {
        return ((item.mukName.toString()).indexOf(this.filter_NoRec_mukName.toString().toLowerCase()) > -1);
      });
    }
    //this.set_paging_Config('recnotdone',5,this.filter_rec_notDoneToli);

    this.pg_RecDone_config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.filter_rec_notDoneToli.length
    };

  }
  filter_No_payDone(filterCol, filterval) {
    this.set_Nopay_filterVal(filterCol, filterval);
    this.filter_pay_notDoneToli = this.pay_notDoneToli;

    if (this.filter_Nopay_toliNo.toString() != '') {
      this.filter_pay_notDoneToli = this.filter_pay_notDoneToli.filter(x => x.toliNo.toString() == this.filter_Nopay_toliNo);
    }
    if (this.filter_Nopay_mukName.toString() != '') {
      this.filter_pay_notDoneToli = this.filter_pay_notDoneToli.filter((item) => {
        return ((item.mukName.toString()).indexOf(this.filter_Nopay_mukName.toString().toLowerCase()) > -1);
      });
    }

  }
  filter_recDone(filterCol, filterval) {
    this.set_filterVal(filterCol, filterval);
    this.rec_DoneToli = this.rec_DtToli;
    let isFilterVal = '';
    // let filterData:any =[];
    // filterData = this.rec_DtToli;
    if (this.filter_RecNo.toString() != '') {

      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.receiptNO.toString()).indexOf(this.filter_RecNo.toString().toLowerCase()) > -1);
      //  });

      this.rec_DoneToli = this.rec_DtToli.filter(x => x.receiptNO.toString() == this.filter_RecNo);
    }
    if (this.filter_docdt.toString() != '') {
      isFilterVal = 'yes';
      this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
        return ((item.docdt.toString()).indexOf(this.filter_docdt.toString().toLowerCase()) > -1);
      });
    }
    if (this.filter_toliNo.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.toliNo.toString() == this.filter_toliNo);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.toliNo.toString()).indexOf(this.filter_toliNo.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_mukName.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
        return ((item.mukName.toString()).indexOf(this.filter_mukName.toString().toLowerCase()) > -1);
      });
    }
    if (this.filter_wageyr.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.wageyr.toString() == this.filter_wageyr);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.wageyr.toString()).indexOf(this.filter_wageyr.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_wageMon.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.wageMon.toString() == this.filter_wageMon);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.wageMon.toString()).indexOf(this.filter_wageMon.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_wagesAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.wagesAmt.toString() == this.filter_wagesAmt);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.wagesAmt.toString()).indexOf(this.filter_wagesAmt.toString().toLowerCase()) > -1);
      //  });
    }
    if (this.filter_levyAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.levyAmt.toString() == this.filter_levyAmt);

    }
    if (this.filter_anamatAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.anamatAmt.toString() == this.filter_anamatAmt);

    }
    if (this.filter_totAmt.toString() != '') {
      this.rec_DoneToli = this.rec_DoneToli.filter(x => x.totAmt.toString() == this.filter_totAmt);
      // this.rec_DoneToli = this.rec_DoneToli.filter((item) => {
      //   return ((item.totAmt.toString()).indexOf(this.filter_totAmt.toString().toLowerCase()) > -1);
      //  });
    }

    this.pg_RecDone_config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.rec_DoneToli.length
    };


  }
  filter_payDone(filterCol, filterval) {
    this.set_filter_payDone_Val(filterCol, filterval);
    this.filter_pay_DoneToli = this.pay_DoneToli;
    let isFilterVal = '';

    if (this.filter_pay_listno.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.listNo.toString() == this.filter_pay_listno);
    }
    if (this.filter_pay_paydt.toString() != '') {
      isFilterVal = 'yes';
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter((item) => {
        return ((item.payDt.toString()).indexOf(this.filter_pay_paydt.toString().toLowerCase()) > -1);
      });
    }
    if (this.filter_pay_toliNo.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.toliNo.toString() == this.filter_pay_toliNo);

    }
    if (this.filter_pay_mukName.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter((item) => {
        return ((item.mukName.toString()).indexOf(this.filter_pay_mukName.toString().toLowerCase()) > -1);
      });
    }
    if (this.filter_pay_wageyr.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.wageyr.toString() == this.filter_pay_wageyr);

    }
    if (this.filter_pay_wageMon.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.wageMon.toString() == this.filter_pay_wageMon);

    }
    if (this.filter_pay_wagesAmt.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.wagesAmt.toString() == this.filter_pay_wagesAmt);

    }
    if (this.filter_pay_levyAmt.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.levyAmt.toString() == this.filter_pay_levyAmt);

    }
    if (this.filter_pay_netpayAmt.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.netPay.toString() == this.filter_pay_netpayAmt);

    }
    if (this.filter_pay_finalized.toString() != '') {
      //this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter(x => x.finalized.toString().toLowerCase() == this.filter_pay_finalized.toString().toLowerCase());

      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter((item) => {
        return ((item.finalized.toString().toLowerCase()).indexOf(this.filter_pay_finalized.toString().toLowerCase()) > -1);
      });

    }
    if (this.filter_pay_authorized.toString() != '') {
      this.filter_pay_DoneToli = this.filter_pay_DoneToli.filter((item) => {
        return ((item.authorized.toString().toLowerCase()).indexOf(this.filter_pay_authorized.toString().toLowerCase()) > -1);
      });

    }
    //filter_pay_authorized



  }
  clear_filterVal() {
    this.filter_RecNo = '';
    this.filter_docdt = '';
    this.filter_toliNo = '';
    this.filter_mukName = '';
    this.filter_wageyr = '';
    this.filter_wageMon = '';
    this.filter_wagesAmt = '';
    this.filter_levyAmt = '';
    this.filter_anamatAmt = '';
    this.filter_totAmt = '';

    this.filter_NoRec_toliNo = '';
    this.filter_NoRec_mukName = '';
  }

  set_NoRec_filterVal(filterCol, filterval) {
    if (filterCol == 'toliNo') {
      this.filter_NoRec_toliNo = filterval.toString();
    }
    else if (filterCol == 'mukName') {
      this.filter_NoRec_mukName = filterval.toString();
    }

  }
  set_Nopay_filterVal(filterCol, filterval) {
    if (filterCol == 'toliNo') {
      this.filter_Nopay_toliNo = filterval.toString();
    }
    else if (filterCol == 'mukName') {
      this.filter_Nopay_mukName = filterval.toString();
    }

  }

  set_filterVal(filterCol, filterval) {
    if (filterCol == 'receiptNO') {
      this.filter_RecNo = filterval.toString();
    }
    else if (filterCol == 'docdt') {
      this.filter_docdt = filterval.toString();
    }
    else if (filterCol == 'toliNo') {
      this.filter_toliNo = filterval.toString();
    }
    else if (filterCol == 'mukName') {
      this.filter_mukName = filterval.toString();
    }
    else if (filterCol == 'wageyr') {
      this.filter_wageyr = filterval.toString();
    }
    else if (filterCol == 'wageMon') {
      this.filter_wageMon = filterval.toString();
    }
    else if (filterCol == 'wagesAmt') {
      this.filter_wagesAmt = filterval.toString();
    }
    else if (filterCol == 'levyAmt') {
      this.filter_levyAmt = filterval.toString();
    }
    else if (filterCol == 'anamatAmt') {
      this.filter_anamatAmt = filterval.toString();
    }
    else if (filterCol == 'totAmt') {
      this.filter_totAmt = filterval.toString();
    }
  }
  set_filter_payDone_Val(filterCol, filterval) {
    if (filterCol == 'listNo') {
      this.filter_pay_listno = filterval.toString();
    }
    else if (filterCol == 'payDt') {
      this.filter_pay_paydt = filterval.toString();
    }
    else if (filterCol == 'toliNo') {
      this.filter_pay_toliNo = filterval.toString();
    }
    else if (filterCol == 'mukName') {
      this.filter_pay_mukName = filterval.toString();
    }
    else if (filterCol == 'wageyr') {
      this.filter_pay_wageyr = filterval.toString();
    }
    else if (filterCol == 'wageMon') {
      this.filter_pay_wageMon = filterval.toString();
    }
    else if (filterCol == 'wagesAmt') {
      this.filter_pay_wagesAmt = filterval.toString();
    }
    else if (filterCol == 'levyAmt') {
      this.filter_pay_levyAmt = filterval.toString();
    }
    else if (filterCol == 'netPay') {
      this.filter_pay_netpayAmt = filterval.toString();
    }
    else if (filterCol == 'finalized') {
      this.filter_pay_finalized = filterval.toString();
    }
    else if (filterCol == 'authorized') {
      this.filter_pay_authorized = filterval.toString();
    }
  }

  //#endregion

  //#region modal hide show
  showModal: boolean;
  content: string;
  // title: string;
  show() {
    this.showModal = true; // Show-Hide Modal Check
    this.content = '';// "This is content!!"; // Dynamic Data
    this.title = "";    // Dynamic Data
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

  paidBank_showModal: boolean;

  // title: string;
  paidBank_show_modal() {
    this.paidBank_showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  paidBank_hide_modal() {
    this.paidBank_showModal = false;
  }
  //#endregion

  //#region Create Pdf
  public generatePdf_paidLabbankList() {
    this.ngxService.start();
    var data = document.getElementById('rpt_labpaidbank_pdf');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      // var imgWidth =208;
      // var pageHeight = 295;

      var imgWidth = 208;
      var pageHeight = 295;
      var position = 10;

      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      //imgHeight = 84;



      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF

      //var position = 10;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;

        pdf.addPage();

        // pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight);
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight;
      }

      // pdf.addPage();
      // pdf.addImage(contentDataURL, 'PNG',0, position, imgWidth, imgHeight)

      let flName = this.TransBanklistID.toString() + "paidbanklist.pdf"
      pdf.save(flName);

      this.ngxService.stop();
      this.show_paidbanklisRpt = 'rpt';

    });

    //this.rpt_print = '';
    // this.docID = 0;

    //this.router.navigate(['/dashboard/clerk-dashboard']);

  }
  items = [{ title: 'first' }, { title: 'second' }] // Content of the pages
  counter: number
  length: number

  generatePDF() {
    let doc = new jspdf('p', 'pt', 'a4');
    html2canvas(document.getElementById('rpt_labpaidbank_pdf')).then(function (canvas) {
      let width = doc.internal.pageSize.getWidth();
      let height = doc.internal.pageSize.getHeight();
      let dataURL = canvas.toDataURL('image/jpeg', 1.0);
      doc.addImage(dataURL, 'jpg', 0, 0, width, height, 'none');
      doc.save('mypdf.pdf');
    });
  }
  public dataLocalUrl: any;
  downloadAsPDF() {
    // const doc = new jspdf();

    //   const pdfTable = this.pdfTable.nativeElement;

    //   var html = htmlToPdfmake(pdfTable.innerHTML);

    //   const documentDefinition = { content: html };

    //   pdfMake.createPdf(documentDefinition).open(); 

    // var docDefinition = { content: 'शासकीय धान्न्य गोदाम चिपळूण' };
    // pdfMake.createPdf(docDefinition).download('filename.pdf');

    return;

    let url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/createpdf';
    this._userService.get_Data(url).subscribe(
      (data) => {
        console.log(data);
        window.open(this.globals.BaseURL + "/PdfRptFile/Sample-DOC-File.docx");
      });

    //this.fileOpener.open('https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf', 'myletter.pdf');
    return;
  }

  somePDFCreator() {
    const doc = new jspdf();
    //
  }
  //#endregion

  //#region "insurance approval"
  sel_policyApp_Status = "Pending";
  public set_policyApproval_Status(event) {
    this.sel_policyApp_Status = event.target.value;
    //
  }

  //#region approval pending list\
  Save_formData = new FormData();
  lab_approval_pendList = [];
  fill_approval_pendingList() {

    ///////////////

    let actionType: string = 'na';
    actionType = 'add';

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    let authFor = "InsurancePolicyAuth";

    let url = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url).subscribe((data) => {

      // console.log('retData',data);
      auth_List = data;
      console.log('auth_List:', auth_List)
      if (authFor != '') {
        let filterList = [];
        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'edit') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'delete') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else {
          alert('You have no authority.')
          return;
        }

        if (Number(filterList.length) > 0) {
          //alert(Number(filterList.length));

          this.lab_approval_pendList = [];
          this.ngxService.start();
          let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getapprovalstatuslist/' + this.ins_Appr_PenCompID;
          this._userService.get_Data(url).subscribe((data) => {
            // 
            this.ngxService.stop();
            if (this.sel_policyApp_Status == 'All') {
              this.lab_approval_pendList = data;

            }
            else {

              this.lab_approval_pendList = data.filter(x => x.status.toString().toLowerCase() == this.sel_policyApp_Status.toString().toLowerCase())
            }
            if (this.lab_approval_pendList.length == 0) {
              alert('No record found.');
            }
          },
            (err: HttpErrorResponse) => {
              this.ngxService.stop();
              //alert(err);
              return;
            });

        }
        else {
          alert('You have no authority.')
          return;
        }

      }
      else {
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        alert('You have no authority.')
        return;
      });

    //////////////////

    // this.lab_approval_pendList = [];
    // this.ngxService.start();
    // let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/getapprovalstatuslist/' + this.ins_Appr_PenCompID;
    // this._userService.get_Data(url).subscribe((data) => {
    //  // 
    //   this.ngxService.stop();
    //   if (this.sel_policyApp_Status == 'All') {
    //     this.lab_approval_pendList = data;

    //   }
    //   else {

    //     this.lab_approval_pendList = data.filter(x => x.status.toString().toLowerCase() == this.sel_policyApp_Status.toString().toLowerCase())
    //   }
    //   if (this.lab_approval_pendList.length == 0) {
    //     alert('No record found.');
    //   }
    // },
    //   (err: HttpErrorResponse) => {
    //     this.ngxService.stop();
    //     //alert(err);
    //     return;
    //   });
  }
  set_approval_pendList_editcontrol(i, isEdit: boolean) {
    this.lab_approval_pendList = [];
    this.Save_formData = new FormData();
    for (let k = 0; k < this.lab_approval_pendList.length; k++) {
      this.lab_approval_pendList[k]['isEdit'] = false;
    }
    this.lab_approval_pendList[i]['isEdit'] = isEdit;

  }
  view_uploadFile(fileName, filefor): any {
    if (filefor == "ins") {
      window.open(this.globals.BaseURL + "/" + this._userService.get_insCompDashboard_DocFolderName(this.Log_BoardID) + "/" + fileName);
    }
    else if (filefor == "pat") {
      window.open(this.globals.BaseURL + "/" + this._userService.get_patCompDashboard_DocFolderName(this.Log_BoardID) + "/" + fileName);
    }
    else if (filefor == "union") {
      window.open(this.globals.BaseURL + "/" + this._userService.get_unionCompDashboard_DocFolderName(this.Log_BoardID) + "/" + fileName);
    }

  }
  //#endregion



  //#endregion

  //#region  insurance comapny login
  ins_compListDt = [];
  pat_compListDt = [];
  union_compListDt = [];
  async fill_ins_pat_union_CompListDt() {
    this.ins_compListDt = [];
    this.pat_compListDt = [];
    this.union_compListDt = [];

    this.ngxService.start();

    if (Number(this.Log_BoardID) > 0) {
      let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/inscomplist'
      //alert(url);
      this._userService.get_Data(url).subscribe((data) => {

        //
        this.ngxService.stop();
        this.ins_compListDt = data;
      },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          // alert(err);
          return;
        });

      this.ngxService.start();
      url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/patcomplist'
      this._userService.get_Data(url).subscribe((data) => {
        // 
        this.ngxService.stop();
        this.pat_compListDt = data;
      },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          // alert(err);
          return;
        });

      this.ngxService.start();

      url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/unionlist'
      this._userService.get_Data(url).subscribe((data) => {
        //
        this.ngxService.stop();
        this.union_compListDt = data;
      },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          //alert(err);
          return;
        });

    }


  }
  ins_Appr_PenCompID = -1
  public set_policyApproval_insComapny(event) {
    this.lab_approval_pendList = [];
    this.ins_Appr_PenCompID = event.target.value;

  }
  //#endregion

  //#region policy reject modal

  showModal_policyRej: boolean;

  // title: string;
  show_modal_policyRej(policyAuthID, i) {
    this.rej_policyRindex = i;
    this.rej_policyAuthID = policyAuthID;
    this.showModal_policyRej = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide_modal_policyRej() {
    this.rej_policyRindex = -1;
    this.rej_policyAuthID = 0;
    this.showModal_policyRej = false;
  }
  rej_policyAuthID = 0;
  rej_policyRindex = -1;
  Str_policyrejremark = '';
  update_new_policyReject() {
    if (this.Str_policyrejremark == '' || this.Str_policyrejremark == null) {
      alert('Enter remark.')
      return false;
    }
    else {
      let update_approval_policyList = [];
      if (window.confirm('Are sure you to update.?')) {
        let isValid = true;
        update_approval_policyList = [{
          "PolicyAuthID": this.rej_policyAuthID,
          "Status": 'Reject',
          "Remark": this.Str_policyrejremark,
          "AuthUserID": this.Log_UserID
        },]

        //


        if (isValid == true) {
          let saveData: any = {
            "InsPolicyAuthMaster": update_approval_policyList
          }
          let body = new FormData();
          body = saveData;
          let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/updateinspolicyauthreject';
          this._userService.post_data(url, body).subscribe(
            (data) => {
              if (data == 'Saved SuccessFully.') {
                this.lab_approval_pendList[this.rej_policyRindex]['status'] = "Reject";
                this.lab_approval_pendList[this.rej_policyRindex]['remark'] = this.Str_policyrejremark;
                this.Str_policyrejremark = "";
                this.hide_modal_policyRej();
                alert('Record saved successfully.');
                update_approval_policyList = [];

              }
              else {
                alert(data);
              }
            });
          this.ngxService.stop();

        }
      }

    }
  }
  update_new_policy_Accept(rindex) {

    let update_approval_policyList = [];
    if (window.confirm('Are sure you to update.?')) {


      update_approval_policyList = [{
        "PolicyAuthID": this.lab_approval_pendList[rindex]['policyAuthID'],
        "InsCompID": this.lab_approval_pendList[rindex]['insCompID'],
        "LabourID": this.lab_approval_pendList[rindex]['labourID'],
        "RegNo": this.lab_approval_pendList[rindex]['labRegNo'],
        "LabName": this.lab_approval_pendList[rindex]['labName'],
        "exlLabName": this.lab_approval_pendList[rindex]['labName'],
        "PolicyNo": this.lab_approval_pendList[rindex]['policyNo'],
        "PolicyAmt": this.lab_approval_pendList[rindex]['policyAmt'],
        "DedMonth": this.lab_approval_pendList[rindex]['dedMonth'],
        "DedYear": this.lab_approval_pendList[rindex]['dedYear'],
        "AuthUserID": this.Log_UserID,
        "DocName": this.lab_approval_pendList[rindex]['docName'],

      },]

      //


      let saveData: any = {
        "InsPolicyAuthMaster": update_approval_policyList
      }
      let body = new FormData();
      body = saveData;
      let url = 'api/' + this._userService.get_insCompDashboard_controlerName(this.Log_BoardID) + '/updateinspolicyauthaccept';
      this._userService.post_data(url, body).subscribe(
        (data) => {
          if (data == 'Saved SuccessFully.') {
            this.lab_approval_pendList[rindex]['policyAuthID'] = 0;
            alert('Record saved successfully.');
            update_approval_policyList = [];

          }
          else {
            alert(data);
          }
        });
      this.ngxService.stop();


    }


  }
  //#endregion

  //#region patpedhi tab

  fill_pat_approval_pendingList() {
    this.patlab_approval_pendList = [];
    this.ngxService.start();
    let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/getapprovalstatuslist/' + this.pat_Appr_PenCompID;
    this._userService.get_Data(url).subscribe((data) => {
      // 
      // 
      this.patlab_approval_pendList = data;
      this.ngxService.stop();
      if (this.sel_patApp_Status == 'All') {
        this.patlab_approval_pendList = data;

      }
      else {

        this.patlab_approval_pendList = data.filter(x => x.status.toString().toLowerCase() == this.sel_patApp_Status.toString().toLowerCase())
      }
      if (this.patlab_approval_pendList.length == 0) {
        alert('No record found.');
      }
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }

  patlab_approval_pendList = [];

  pat_Appr_PenCompID = -1
  public set_patApproval_patComapny(event) {
    this.patlab_approval_pendList = [];
    this.pat_Appr_PenCompID = event.target.value;

  }
  sel_patApp_Status = "Pending";
  public set_patApproval_Status(event) {
    this.sel_patApp_Status = event.target.value;
    //
  }
  update_new_patpedi_Accept(rindex) {
    //
    //return false

    let update_approval_patpediList = [];
    if (window.confirm('Are sure you to update.?')) {


      update_approval_patpediList = [{
        "PatpediAuthID": this.patlab_approval_pendList[rindex]['patpediAuthID'],
        "PatPediID": this.patlab_approval_pendList[rindex]['patPediID'],
        "LabourID": this.patlab_approval_pendList[rindex]['labourID'],
        "RegNo": this.patlab_approval_pendList[rindex]['labRegNo'],
        "LabName": this.patlab_approval_pendList[rindex]['labName'],
        "exlLabName": this.patlab_approval_pendList[rindex]['labName'],
        "DedAmt": this.patlab_approval_pendList[rindex]['dedAmt'],
        "DedMonth": this.patlab_approval_pendList[rindex]['dedMonth'],
        "DedYear": this.patlab_approval_pendList[rindex]['dedYear'],
        "AuthUserID": this.Log_UserID,
        "DocName": this.patlab_approval_pendList[rindex]['docName'],

      },]

      //



      let saveData: any = {
        "PatAuthMaster": update_approval_patpediList
      }
      let body = new FormData();
      body = saveData;
      let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/updatepatpediauthaccept';
      this._userService.post_data(url, body).subscribe(
        (data) => {
          if (data == 'Saved SuccessFully.') {
            this.patlab_approval_pendList[rindex]['patpediAuthID'] = 0;
            alert('Record saved successfully.');
            update_approval_patpediList = [];

          }
          else {
            alert(data);
          }
        });
      this.ngxService.stop();


    }


  }
  set_approval_pat_pendList_editcontrol(i, isEdit: boolean) {
    this.patlab_approval_pendList = [];
    this.Save_formData = new FormData();
    for (let k = 0; k < this.patlab_approval_pendList.length; k++) {
      this.patlab_approval_pendList[k]['isEdit'] = false;
    }
    this.patlab_approval_pendList[i]['isEdit'] = isEdit;

  }

  showModal_patpediRej: boolean;

  // title: string;
  show_modal_patpediRej(patpediAuthID, i) {
    this.rej_patpediRindex = i;
    this.rej_patpediAuthID = patpediAuthID;
    this.showModal_patpediRej = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide_modal_patpediRej() {
    this.rej_patpediRindex = -1;
    this.rej_patpediAuthID = 0;
    this.showModal_patpediRej = false;
    this.Str_patpedirejremark = '';
  }
  rej_patpediAuthID = 0;
  rej_patpediRindex = -1;
  Str_patpedirejremark = '';

  update_new_patpedhiReject() {
    if (this.Str_patpedirejremark == '' || this.Str_patpedirejremark == null) {
      alert('Enter remark.')
      return false;
    }
    else {
      let update_approval_patpediList = [];
      if (window.confirm('Are sure you to update.?')) {
        let isValid = true;
        update_approval_patpediList = [{
          "PatpediAuthID": this.rej_patpediAuthID,
          "Status": 'Reject',
          "Remark": this.Str_patpedirejremark,
          "AuthUserID": this.Log_UserID,
        },]

        //


        if (isValid == true) {
          let saveData: any = {
            "PatAuthMaster": update_approval_patpediList
          }
          let body = new FormData();
          body = saveData;
          let url = 'api/' + this._userService.get_patCompDashboard_controlerName(this.Log_BoardID) + '/updatepatpediauthreject';
          this._userService.post_data(url, body).subscribe(
            (data) => {
              if (data == 'Saved SuccessFully.') {
                this.patlab_approval_pendList[this.rej_patpediRindex]['status'] = "Reject";
                this.patlab_approval_pendList[this.rej_patpediRindex]['remark'] = this.Str_patpedirejremark;
                this.Str_patpedirejremark = "";
                this.hide_modal_unionRej();
                alert('Record saved successfully.');
                update_approval_patpediList = [];

              }
              else {
                alert(data);
              }
            });
          this.ngxService.stop();

        }
      }

    }
  }

  //#endregion

  //#region union tab


  fill_union_approval_pendingList() {
    this.unionlab_approval_pendList = [];
    this.ngxService.start();
    let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/getapprovalstatuslist/' + this.union_Appr_PenCompID;
    this._userService.get_Data(url).subscribe((data) => {
      //
      this.ngxService.stop();
      if (this.sel_unionApp_Status == 'All') {
        this.unionlab_approval_pendList = data;

      }
      else {

        this.unionlab_approval_pendList = data.filter(x => x.status.toString().toLowerCase() == this.sel_unionApp_Status.toString().toLowerCase())
      }
      if (this.unionlab_approval_pendList.length == 0) {
        alert('No record found.');
      }
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }

  unionlab_approval_pendList = [];

  union_Appr_PenCompID = -1
  public set_unionApproval_unionComapny(event) {
    this.unionlab_approval_pendList = [];
    this.union_Appr_PenCompID = event.target.value;

  }
  sel_unionApp_Status = "Pending";
  public set_unionApproval_Status(event) {
    this.sel_unionApp_Status = event.target.value;
    //
  }
  update_new_union_Accept(rindex) {
    if (window.confirm('Are sure you to update.?')) {
      let saveData: any = {
        "UnionAuthID": this.unionlab_approval_pendList[rindex]['unionAuthID'],
        "UnionID": this.unionlab_approval_pendList[rindex]['unionID'],
        "LabourID": this.unionlab_approval_pendList[rindex]['labourID'],
        "CompanyID": this.Log_BoardID,
        "AuthUserID": this.Log_UserID,
        "DocName": this.unionlab_approval_pendList[rindex]['docName'],
      }
      let body = new FormData();
      body = saveData;
      let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/updateunionauthaccept';
      this._userService.post_data(url, body).subscribe(
        (data) => {
          if (data == 'Saved SuccessFully.') {
            this.unionlab_approval_pendList[rindex]['unionAuthID'] = 0;
            alert('Record saved successfully.');

          }
          else {
            alert(data);
          }
        });
      this.ngxService.stop();
    }
  }
  set_approval_union_pendList_editcontrol(i, isEdit: boolean) {
    this.unionlab_approval_pendList = [];
    this.Save_formData = new FormData();
    for (let k = 0; k < this.unionlab_approval_pendList.length; k++) {
      this.unionlab_approval_pendList[k]['isEdit'] = false;
    }
    this.unionlab_approval_pendList[i]['isEdit'] = isEdit;

  }

  showModal_unionRej: boolean;

  // title: string;
  show_modal_unionRej(unionAuthID, i) {
    this.rej_unionRindex = i;
    this.rej_unionAuthID = unionAuthID;
    this.showModal_unionRej = true; // Show-Hide Modal Check

  }

  //Bootstrap Modal Close event
  hide_modal_unionRej() {
    this.rej_unionRindex = -1;
    this.rej_unionAuthID = 0;
    this.showModal_unionRej = false;
    this.Str_unionrejremark = '';
  }
  rej_unionAuthID = 0;
  rej_unionRindex = -1;
  Str_unionrejremark = '';

  update_new_unionReject() {
    if (this.Str_unionrejremark == '' || this.Str_unionrejremark == null) {
      alert('Enter remark.')
      return false;
    }
    else {
      let update_approval_unionList = [];
      if (window.confirm('Are sure you to update.?')) {
        let isValid = true;
        update_approval_unionList = [{
          "UnionAuthID": this.rej_unionAuthID,
          "Status": 'Reject',
          "Remark": this.Str_unionrejremark,
          "AuthUserID": this.Log_UserID
        },]

        //


        if (isValid == true) {
          let saveData: any = {
            "UnionAuthID": this.rej_unionAuthID,
            "Status": 'Reject',
            "Remark": this.Str_unionrejremark,
          }
          let body = new FormData();
          body = saveData;
          let url = 'api/' + this._userService.get_unionCompDashboard_controlerName(this.Log_BoardID) + '/updateunionauthreject';
          this._userService.post_data(url, body).subscribe(
            (data) => {
              if (data == 'Saved SuccessFully.') {
                this.unionlab_approval_pendList[this.rej_unionRindex]['status'] = "Reject";
                this.unionlab_approval_pendList[this.rej_unionRindex]['remark'] = this.Str_unionrejremark;
                this.Str_patpedirejremark = "";
                this.hide_modal_unionRej();
                alert('Record saved successfully.');
                update_approval_unionList = [];

              }
              else {
                alert(data);
              }
            });
          this.ngxService.stop();

        }
      }

    }
  }

  //#endregion

  //#region loading modal
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion

  //#region NskMatha PaidBank Authorization
  go_to_labpaidbankList(payDt, toliID, print) {
    //alert(toliID);
    let parameters = {
      payDt: payDt,
      toliID: toliID,
      fayear: this.filterFayear,
      print: print
    };


    if (this.Log_BoardID == 1) {
      return ''
    }
    else if (this.Log_BoardID == 2) {
      return ''
    }
    else if (this.Log_BoardID == 3) {
      return ''
    }
    else if (this.Log_BoardID == 4) {
      // this.router.navigate(['paidbankList/lab-paidbank-list'], { queryParams : parameters });
      this.router.navigate(['paidbankList/lab-paidbank-list-rmatha'], { queryParams: parameters });
    }

  }
  lab_authList: any = [];
  lab_noBankAcList: any = [];
  lab_noChqClearList: any = [];
  filter_lab_noChqClearList: any = [];
  chq_clrDate = new Date().toISOString().substring(0, 10);
  get_labPayAuthList() {
    this.lab_authList = [];
    this.lab_noBankAcList = [];
    this.lab_noChqClearList = [];
    this.filter_lab_noChqClearList = [];
    this.lab_authListPaidBank = [];
    this.TransBanklistID = 0;
    let flag: string = "";


    if (this.isAuthSel == true) {
      flag = "auth";
    }
    else {
      flag = "unauth";
    }

    let date: Date;
    let frmDt_;
    let toDt_;
    date = new Date(this.frm_authDate);
    date.setDate(date.getDate());
    frmDt_ = new Date(date).toISOString().substring(0, 10);

    date = new Date(this.to_authDate);
    date.setDate(date.getDate());
    toDt_ = new Date(date).toISOString().substring(0, 10);

    console.log(frmDt_);

    // let filterDate: any = {
    //   "frmDt":frmDt_,
    //   "toDt": toDt_,
    //   "flag": flag
    // }

    let filterDate: any = {
      "frmDt": this.frm_authDate,
      "toDt": this.to_authDate,
      "flag": flag
    }


    ///////////////

    let actionType: string = 'na';
    actionType = 'add';

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    let authFor = "Lab. Payment Authorization";

    let url = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url).subscribe((data) => {

      // console.log('retData',data);
      auth_List = data;
      console.log('auth_List:', auth_List)
      if (authFor != '') {
        let filterList = [];
        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'edit') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'delete') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else {
          alert('You have no authority.')
          return;
        }

        if (Number(filterList.length) > 0) {
          //alert(Number(filterList.length));
          this.ngxService.start();
          let body = new FormData();
          body = filterDate;
          let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpayforauthorization';
          this._userService.post_data(url, body).subscribe(
            (data) => {
              console.log('paidbankAuth List :', data);
              this.lab_authList = data.listPaidBankAuth;
              this.lab_noBankAcList = data.listPaidBankAuth_No_BankAcNo;
              this.lab_noChqClearList = data.listPaidBankAuth_No_ChqClear;
              // this.filter_lab_noChqClearList =  data.listPaidBankAuth_No_ChqClear;

              if (Number(this.lab_authList.length) == 0) {
                alert("There is no record to display.")
              }
              this.ngxService.stop();
            },
            (err: HttpErrorResponse) => {
              this.ngxService.stop();
              console.log('test - 2 err ', err);
              return;
            });


        }
        else {
          alert('You have no authority.')
          return;
        }

      }
      else {
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        alert('You have no authority.')
        return;
      });

    //////////////////

    // let body = new FormData();
    // body = filterDate;
    // let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpayforauthorization';
    //   this._userService.post_data(url, body).subscribe(
    //   (data) => {
    //     this.lab_authList = data.listPaidBankAuth;
    //     this.lab_noBankAcList = data.listPaidBankAuth_No_BankAcNo;
    //     if (Number(this.lab_authList.length) == 0) {
    //       alert("There is no record to display.")
    //     }
    //     this.ngxService.stop();
    //   },
    //   (err: HttpErrorResponse) => {
    //     this.ngxService.stop();
    //        console.log('test - 2 err ', err);
    //     return;
    //   });

  }
  filter_nochq_clearList() {
    this.filter_lab_noChqClearList = [];



    //this.filter_lab_noChqClearList = this.lab_noChqClearList.filter(x => Number(x.toliID) == Number(this.lab_authList[i]['toliID']) && (x.wageMon) == Number(this.lab_authList[i]['wageMon']) && Number(x.wageyr) == Number(this.lab_authList[i]['wageyr']));
    console.log('lab_noChqClearList', this.lab_noChqClearList)
    if (this.lab_authList.length > 0) {
      for (let k = 0; k < this.lab_authList.length; k++) {
        // console.log('checkStatus',this.lab_authList[k]['checkStatus'])
        if (this.lab_authList[k]['checkStatus'] == true) {
          let filter_: any = [];
          // if (Number(this.filter_lab_noChqClearList.length) == 0){
          //   this.filter_lab_noChqClearList = this.lab_noChqClearList.filter(x => Number(x.toliID) == Number(this.lab_authList[k]['toliID']) && Number(x.wageMon) == Number(this.lab_authList[k]['wageMon']) && Number(x.wageyr) == Number(this.lab_authList[k]['wageYr']));
          // }
          // else{
          //   filter_ = this.lab_noChqClearList.filter(x => Number(x.toliID) == Number(this.lab_authList[k]['toliID']) && (x.wageMon) == Number(this.lab_authList[k]['wageMon']) && Number(x.wageyr) == Number(this.lab_authList[k]['wageYr']));
          //   if (Number(filter_.length) > 0){
          //     for (let i = 0; i < filter_.length; i++) {
          //       this.filter_lab_noChqClearList.push=({
          //         "billDt":filter_[i]['billDt'],
          //         "billNo":filter_[i]['billNo'],
          //         "chequeClearingDt":filter_[i]['chequeClearingDt'],
          //         "chqAmt":filter_[i]['chqAmt'],
          //         "docID":filter_[i]['docID'],
          //         "docdt":filter_[i]['docdt'],
          //         "eAccName":filter_[i]['eAccName'],
          //         "flag":filter_[i]['flag'],
          //         "receiptNO":filter_[i]['receiptNO'],
          //         "retVal":filter_[i]['retVal'],
          //         "toliID":filter_[i]['toliID'],
          //         "transChqID":filter_[i]['transChqID'],
          //         "wageMon":filter_[i]['wageMon'],
          //         "wageyr":filter_[i]['wageyr'],
          //       });
          //     }

          //   }
          // }

          filter_ = this.lab_noChqClearList.filter(x => Number(x.toliID) == Number(this.lab_authList[k]['toliID']) && Number(x.wageMon) == Number(this.lab_authList[k]['wageMon']) && Number(x.wageyr) == Number(this.lab_authList[k]['wageYr']));
          if (Number(filter_.length) > 0) {
            for (let i = 0; i < filter_.length; i++) {
              if (Number(this.filter_lab_noChqClearList.length) == 0) {
                this.filter_lab_noChqClearList = [{
                  "billDt": filter_[i]['billDt'],
                  "billNo": filter_[i]['billNo'],
                  "chequeClearingDt": filter_[i]['chequeClearingDt'],
                  "chqAmt": filter_[i]['chqAmt'],
                  "docID": filter_[i]['docID'],
                  "docdt": filter_[i]['docdt'],
                  "eAccName": filter_[i]['eAccName'],
                  "flag": filter_[i]['flag'],
                  "receiptNO": filter_[i]['receiptNO'],
                  "retVal": filter_[i]['retVal'],
                  "toliID": filter_[i]['toliID'],
                  "transChqID": filter_[i]['transChqID'],
                  "wageMon": filter_[i]['wageMon'],
                  "wageyr": filter_[i]['wageyr'],
                  "invalidchqDate":'',
                },];
              }
              else {
                this.filter_lab_noChqClearList.push({
                  "billDt": filter_[i]['billDt'],
                  "billNo": filter_[i]['billNo'],
                  "chequeClearingDt": filter_[i]['chequeClearingDt'],
                  "chqAmt": filter_[i]['chqAmt'],
                  "docID": filter_[i]['docID'],
                  "docdt": filter_[i]['docdt'],
                  "eAccName": filter_[i]['eAccName'],
                  "flag": filter_[i]['flag'],
                  "receiptNO": filter_[i]['receiptNO'],
                  "retVal": filter_[i]['retVal'],
                  "toliID": filter_[i]['toliID'],
                  "transChqID": filter_[i]['transChqID'],
                  "wageMon": filter_[i]['wageMon'],
                  "wageyr": filter_[i]['wageyr'],
                  "invalidchqDate":'',
                });
              }

            }

          }

        }
      }

      console.log('filter_lab_noChqClearList', this.filter_lab_noChqClearList)
    }
  }
  set_chq_clearDate(event, i) {
    this.filter_lab_noChqClearList[i]['invalidchqDate'] = 'invalid';

    this.filter_lab_noChqClearList[i]['chequeClearingDt'] = event.target.value;
   
   // console.log('filter_lab_noChqClearList', this.filter_lab_noChqClearList);

    let chkDt = new Date(event.target.value);
    // 
    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099) {
      this.filter_lab_noChqClearList[i]['invalidchqDate'] = 'invalid';
    }
    else {
      if (chkDt.getFullYear()) {
        this.filter_lab_noChqClearList[i]['invalidchqDate'] = '';
      }
      else {

      }

    }
  }
  save_labPay_chqClear() {
    if (Number(this.filter_lab_noChqClearList.length) > 0) {
      let is_valid = true;
      for (let i = 0; i < this.filter_lab_noChqClearList.length; i++) {
        if (new Date(this.filter_lab_noChqClearList[i]['chequeClearingDt']) < new Date(this.filter_lab_noChqClearList[i]['docdt'])) {
          is_valid = false;
          this.filter_lab_noChqClearList[i]['invalidchqDate'] = 'invalid';
          alert("Enter cheque clear date >= rec.date.");
          return;
        }
        else if (this.filter_lab_noChqClearList[i]['invalidchqDate'] != ''){
          is_valid = false;
          alert("Enter valid cheque clear date.");
          return;
        }
      }

      if (is_valid == true) {
        if (confirm("Are you sure to submit.?")) {

          this.ngxService.start();
          this.loading = 'y';
          this.show_loadingModal(true);
          let body = new FormData();
          let saveauthChqClearList: any = {
            "SavePaidBankChqClr": this.filter_lab_noChqClearList
          }
          body = saveauthChqClearList;
          console.log('body', body);
          let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/SavePaidBankChqClear/' + this.Log_UserID;
          console.log(url);
          this._userService.post_data(url, body).subscribe(
            (data) => {

              this.ngxService.stop();
              if (Number(data) > 0) {
                this.loading = '';
                this.show_loadingModal(false);
                if (confirm("Record saved successfully.")) {
                  this.filter_lab_noChqClearList = [];
                }

              }
              else {
                this.ngxService.stop();
                this.loading = '';
                this.show_loadingModal(false);
                alert(data);

              }
            });

        }
      }
    }
  }
  goto_reports() {

    this.router.navigate(['rptlabpay']);
  }
  lab_authListPaidBank: any = [];
  get_labPayAuthListPaidBank() {
    this.loading = 'y';

    this.show_paidbanklisRpt = '';

    this.lab_authListPaidBank = [];
    let flag: string = "";

    flag = "paidbankauth";

    let filterDate: any = {
      "frmDt": this.frm_paidBankDate,
      "toDt": this.to_paidBankDate,
      "flag": flag
    }

    ///////////////

    let actionType: string = 'na';
    actionType = 'add';

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    let authFor = "Lab. PaidBank List";

    let url = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url).subscribe((data) => {

      // console.log('retData',data);
      auth_List = data;
      console.log('auth_List:', auth_List)
      if (authFor != '') {
        let filterList = [];
        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'edit') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'delete') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else {
          alert('You have no authority.')
          return;
        }

        if (Number(filterList.length) > 0) {
          //alert(Number(filterList.length));
          this.ngxService.start();
          this.show_loadingModal(true);

          let body = new FormData();
          body = filterDate;
          if (Number(this.TransBanklistID > 0)) {
            let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpaidbanklistdone/' + this.TransBanklistID;
            this._userService.get_Data(url).subscribe(
              (data) => {

                this.lab_authListPaidBank = data;

                console.log('lab_authListPaidBank ', data)

                if (Number(this.lab_authListPaidBank.length) == 0) {
                  this.loading = '';
                  this.show_loadingModal(false);
                  alert("There is no record to display.")
                }
                else {
                  this.loading = '';
                  this.show_loadingModal(false);
                  this.show_paidbanklisRpt = 'rpt'
                }
                this.loading = '';
                this.ngxService.stop();
              });
          }
          else {
            let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpayforauthorization';
            //alert('test - 1 ' + this.frm_paidBankDate);
            this._userService.post_data(url, body).subscribe(
              (data) => {
                // console.log('test - 1 ', data);
                this.lab_authListPaidBank = data.listPaidBankAuth;

                if (Number(this.lab_authListPaidBank.length) == 0) {
                  this.loading = '';
                  this.show_loadingModal(false);
                  alert("There is no record to display.")
                }
                this.loading = '';
                this.show_loadingModal(false);
                this.ngxService.stop();
              },
              (err: HttpErrorResponse) => {
                this.ngxService.stop();
                this.loading = '';
                this.show_loadingModal(false);
                console.log('test - 1 err ', err);
                return;
              });

          }

        }
        else {
          alert('You have no authority.')
          return;
        }

      }
      else {
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        alert('You have no authority.')
        return;
      });

    //////////////////

    // let body = new FormData();
    // body = filterDate;
    // if (Number(this.TransBanklistID > 0)) {
    //   let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpaidbanklistdone/' + this.TransBanklistID;
    //   this._userService.get_Data(url).subscribe(
    //     (data) => {

    //       this.lab_authListPaidBank = data;

    //       if (Number(this.lab_authListPaidBank.length) == 0) {
    //         this.loading='';
    //         this.show_loadingModal(false);
    //         alert("There is no record to display.")
    //       }
    //       else {
    //         this.loading='';
    //         this.show_loadingModal(false);
    //        // this.show_paidbanklisRpt = 'rpt'
    //       }
    //       this.loading='';
    //       this.ngxService.stop();
    //     });
    // }
    // else {
    //   let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpayforauthorization';
    //   //alert('test - 1 ' + this.frm_paidBankDate);
    //   this._userService.post_data(url, body).subscribe(
    //     (data) => {
    //      // console.log('test - 1 ', data);
    //       this.lab_authListPaidBank = data.listPaidBankAuth;

    //       if (Number(this.lab_authListPaidBank.length) == 0) {
    //         this.loading='';
    //                 this.show_loadingModal(false);
    //         alert("There is no record to display.")
    //       }
    //       this.loading='';
    //                 this.show_loadingModal(false);
    //       this.ngxService.stop();
    //     },
    //     (err: HttpErrorResponse) => {
    //       this.ngxService.stop();
    //       this.loading='';
    //       this.show_loadingModal(false);
    //       console.log('test - 1 err ', err);
    //       return;
    //     });

    // }

    this.ngxService.stop();
  }
  bank_AuthSelunSel: string = "Select All"
  set_selAll_AuthToli(event: any, i) {
    if (i == -1) {
      let setVal = false;
      if (this.bank_AuthSelunSel == "Select All") {
        this.bank_AuthSelunSel = "Un-Select All";
        setVal = true;
      }
      else {
        this.bank_AuthSelunSel = "Select All";
        setVal = false;
      }

      if (this.lab_authList.length > 0) {
        for (let k = 0; k < this.lab_authList.length; k++) {
          this.lab_authList[k]['checkStatus'] = setVal;
        }
      }
      this.filter_nochq_clearList();
    }
    else {
      this.lab_authList[i]['checkStatus'] = event.currentTarget.checked;

      this.filter_nochq_clearList();

    }

  }
  paidbank_SelunSel: string = "Select All"
  set_selAll_paidbank(event: any, i) {
    if (i == -1) {
      let setVal = false;
      if (this.paidbank_SelunSel == "Select All") {
        this.paidbank_SelunSel = "Un-Select All";
        setVal = true;
      }
      else {
        this.paidbank_SelunSel = "Select All";
        setVal = false;
      }

      if (this.lab_authListPaidBank.length > 0) {
        for (let k = 0; k < this.lab_authListPaidBank.length; k++) {
          this.lab_authListPaidBank[k]['checkStatus'] = setVal;
        }
      }
    }
    else {
      this.lab_authListPaidBank[i]['checkStatus'] = event.currentTarget.checked;
    }

  }
  save_paidBank_Authorization() {
    this.TransBanklistID = 0;
    this.PaidbankCnt_disabled = false;
    // 
    // return;

    if (this.lab_authList.length > 0) {
      if (confirm("Are you sure to submit.?")) {
        let flag: string = "";
        if (this.isAuthSel == true) {
          flag = "auth";
        }
        else {
          flag = "unauth";
        }

        let setVal = false;
        for (let k = 0; k < this.lab_authList.length; k++) {
          this.lab_authList[k]['frmDate'] = this.frm_authDate;
          this.lab_authList[k]['toDate'] = this.to_authDate;
          this.lab_authList[k]['authDate'] = this.authDate;
          this.lab_authList[k]['userID'] = this.Log_UserID;

          if (this.lab_authList[k]['checkStatus'] == true) {
            setVal = true;
          }
        }
        if (setVal == false) {
          alert('No record selected to authorized.')
          return;
        }
        else {
          let isSave: boolean = true;
          let mon, yr, fayear;
          mon = new Date(this.frm_authDate).getMonth() + 1;
          yr = new Date(this.frm_authDate).getFullYear();

          if (mon < 4) {
            fayear = yr - 1;
          }
          else {
            fayear = yr;
          }

          if (fayear != this.filterFayear) {
            alert('Enter from date selected finacial year.')
            isSave = false;
            return;
          }

          mon = new Date(this.to_authDate).getMonth() + 1;
          yr = new Date(this.to_authDate).getFullYear();

          if (mon < 4) {
            fayear = yr - 1;
          }
          else {
            fayear = yr;
          }


          if (fayear != this.filterFayear) {
            alert('Enter to date selected finacial year.')
            isSave = false;
            return;
          }

          mon = new Date(this.authDate).getMonth() + 1;
          yr = new Date(this.authDate).getFullYear();

          if (mon < 4) {
            fayear = yr - 1;
          }
          else {
            fayear = yr;
          }


          if (flag == "auth") {
            if (fayear != this.filterFayear) {
              alert('Enter authorize date selected finacial year.')
              isSave = false;
              return;
            }
          }

          if (isSave == true) {

            this.ngxService.start();
            this.loading = 'y';
            this.show_loadingModal(true);
            let body = new FormData();
            let saveauthList: any = {
              "SavePaidBankAuth": this.lab_authList
            }
            body = saveauthList;

            //

            let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/savelabpayforauthorization/' + flag;
            //alert(url);
            this._userService.post_data(url, body).subscribe(
              (data) => {

                this.ngxService.stop();
                if (data == "-1") {
                  this.loading = '';
                  this.show_loadingModal(false);
                  alert('Record allready exists.Click on Show and try agian.');
                }
                else if (Number(data) > 0) {
                  if (flag == "auth") {
                    this.loading = '';
                    this.show_loadingModal(false);
                    //alert('Record authorized successfully.');
                    if (confirm("Record authorized successfully.")) {
                      //this.fill_allData();
                      this.active = 4;
                      this.TransBanklistID = 0;
                      this.frm_paidBankDate = this.frm_authDate;
                      this.to_paidBankDate = this.to_authDate;
                      this.get_labPayAuthListPaidBank();
                    }

                  }
                  else {
                    //alert('Record un-authorized successfully.');
                    this.ngxService.stop();

                    //this.fill_allData();
                    this.loading = '';
                    this.show_loadingModal(false);

                  }
                  this.lab_authList = [];
                  // this.get_labPayAuthList();
                }
                else {
                  this.ngxService.stop();
                  this.loading = '';
                  this.show_loadingModal(false);
                  alert(data);

                }
              });
          }

        }
      }
    }
    else {
      this.ngxService.stop();
      this.loading = '';
      this.show_loadingModal(false);
      alert('No record to authorized.')
    }
  }
  isAuthSel: boolean = true;
  click_Auth_unAuth_Option(selectedChoice: boolean) {
    this.lab_authList = [];
    this.isAuthSel = selectedChoice;

  }

  list_labpaidBankNo: any = [];
  fill_labPaidBankListNo(ListNo:number) {
    this.list_labpaidBankNo = [];
    this.TransBanklistID = 0;
    this.ngxService.start();
    let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpaidbanklistno';
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.ngxService.stop();
        this.list_labpaidBankNo = data;
          if (ListNo > 0){

            this.TransBanklistID = ListNo;
            this.get_labPayAuthListPaidBank();
          }
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        return;
      });
  }
  //#endregion

  //#region NskMatha PaidBank List
  show_paidbanklisRpt = '';
  rpt_labpaidprint = "no";

  paid_bankIDList: any;
  paid_bank_toliIDList: any;
  paid_bank_BOM_bankIDlist: any;
  paid_bank_BOB_bankIDlist: any;
  paid_bank_UCO_bankIDlist: any;

  paid_bank_BOM_Home_bankIDlist: any;
  paid_bank_BOB_Home_bankIDlist: any;
  paid_bank_UCO_Home_bankIDlist: any;

  arrayBuffer: any;

  rptLabPaiddBankList: any = [];
  PiadBank_EmialList: any = [];
  excle_policyLabList: any = [];
  BOM_PiadBankList: any = [];
  BOB_PiadBankList: any = [];
  UCO_PiadBankList: any = [];

  BOM_Home_PiadBankList: any = [];
  BOB_Home_PiadBankList: any = [];
  UCO_Home_PiadBankList: any = [];

  new_labpaidBankChq = [];

  upload_fileName: File;

  TransBanklistID = -1;

  PaidbankCnt_disabled = false;
  loading: string = '';
  send_mailPaidBank: string = '';
  Set_piadbankListNo(event) {
    this.lab_authListPaidBank = [];
    this.rptLabPaiddBankList = [];
    this.show_paidbanklisRpt = '';
    this.send_mailPaidBank = '';
    this.invalid_frmpaidBankDate = '';
    this.invalid_topaidBankDate = '';
    this.TransBanklistID = event.target.value;

    let get_filter_date = this.list_labpaidBankNo.filter(x => x.transBanklistID.toString() == this.TransBanklistID);

    if (Number(get_filter_date.length) > 0) {
      this.PaidbankCnt_disabled = true;
      //alert(get_filter_date[0]["frmDt"]);
      let date: Date;
      date = new Date(get_filter_date[0]["frmDt"]);
      date.setDate(date.getDate() + 1);
      this.frm_paidBankDate = new Date(date).toISOString().substring(0, 10);

      date = new Date(get_filter_date[0]["toDt"]);
      date.setDate(date.getDate() + 1);
      this.to_paidBankDate = new Date(date).toISOString().substring(0, 10);

    }
    else {
      this.frm_paidBankDate = new Date().toISOString().substring(0, 10);
      this.to_paidBankDate = new Date().toISOString().substring(0, 10);
      this.PaidbankCnt_disabled = false;
    }

    //this.frm_paidBankDate = 
  }

  fill_labPaidBank_New(showmodal) {

    this.new_labpaidBankChq = [];
    this.send_mailPaidBank = '';

    if (this.lab_authListPaidBank.length > 0) {

      let setVal = false;
      for (let k = 0; k < this.lab_authListPaidBank.length; k++) {

        if (this.lab_authListPaidBank[k]['checkStatus'] == true) {
          setVal = true;
        }
      }
      if (setVal == false) {
        alert('No record selected.')
        return;
      }
      else {
        let isshow: boolean = true;

        this.loading = 'y';
        this.show_loadingModal(true);


        let filterDate: any = {
          "frmDt": this.frm_paidBankDate,
          "toDt": this.to_paidBankDate,
          "flag": ""
        }
        if (isshow == true) {
          let bodyList: any = {
            "SavePaidBankAuth": this.lab_authListPaidBank,
            "filterdata": filterDate,
          }
          this.ngxService.start()

          let body = new FormData();
          body = bodyList;
          let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/getlabpaidbank/' + this.TransBanklistID + '/' + this.filterFayear;
          //alert(url);
          this._userService.post_data(url, body).subscribe(
            (data) => {

              this.new_labpaidBankChq = data;
              //

              if (Number(this.new_labpaidBankChq.length) == 0) {
                this.ngxService.stop();

                this.loading = '';
                this.show_loadingModal(false);

                alert("There is no record to display.")
              }
              else {
                let date: Date;
                date = new Date(this.new_labpaidBankChq[0]["docdt"]);
                //chqNo
                if (this.new_labpaidBankChq[0]["chqNo"] == '') {
                  date.setDate(date.getDate());
                }
                else {
                  date.setDate(date.getDate() + 1);
                }

                this.labPaidBankDate = new Date(date).toISOString().substring(0, 10);

                console.log('this.new_labpaidBankChq', this.new_labpaidBankChq);

                for (let k = 0; k < this.new_labpaidBankChq.length; k++) {
                  let date: Date;
                  date = new Date(this.new_labpaidBankChq[k]["chqDate"]);

                  //date.setDate(date.getDate() + 1);

                  // date.setDate(date.getDate() + 1);

                  if (this.new_labpaidBankChq[0]["chqNo"] == '') {
                    date.setDate(date.getDate());
                  }
                  else {
                    date.setDate(date.getDate() + 1);
                  }

                  this.new_labpaidBankChq[k]["chqDate"] = new Date(date).toISOString().substring(0, 10);
                }

                this.ngxService.stop();
                this.loading = '';
                this.show_loadingModal(false);

                if (showmodal == 'yes') {
                  this.show();
                }

                // this.ngxService.stop();
                // this.show();
              }

            });
        }

      }


    }
    else {
      this.loading = '';
      this.show_loadingModal(false);
      alert('No record.')
    }

  }

  set_labPaidBankChqNo(event, i) {
    this.new_labpaidBankChq[i]['chqNo'] = event.target.value;

  }
  set_labPaidBankChqDate(event, i) {
    // console.log('this.new_labpaidBankChq',this.new_labpaidBankChq)

    this.new_labpaidBankChq[i]['invalidchqDate'] = 'invalid';

    this.new_labpaidBankChq[i]['chqDate'] = new Date(event.target.value).toISOString().substring(0, 10);

    let chkDt = new Date(event.target.value);
    // 
    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099) {
      this.new_labpaidBankChq[i]['invalidchqDate'] = 'invalid';
    }
    else {
      if (chkDt.getFullYear()) {
        this.new_labpaidBankChq[i]['invalidchqDate'] = '';
      }
      else {

      }

    }

  }

  ishow_save_paidbankList_btn: string = 'yes';
  save_labPaidBankList() {
    let chq_Detail: any = [];
    this.ishow_save_paidbankList_btn = 'no';
    // 
    // return;
    if (this.new_labpaidBankChq.length > 0) {
      let issave: boolean = true;
      for (let k = 0; k < this.new_labpaidBankChq.length; k++) {
        if (this.new_labpaidBankChq[k]['chqNo'] == '') {
          issave = false;
          alert('Enter Cheque No.')
          this.ishow_save_paidbankList_btn = 'yes';
          return;
        }
        if (this.new_labpaidBankChq[k]['chqDate'] == '') {
          issave = false;
          this.new_labpaidBankChq[k]['invalidchqDate'] = 'invalid';
          alert('Enter Cheque Date.')
          this.ishow_save_paidbankList_btn = 'yes';
          return;
        }
        else if (new Date(this.new_labpaidBankChq[k]['chqDate']) < new Date(this.labPaidBankDate)) {
          issave = false;
          this.new_labpaidBankChq[k]['invalidchqDate'] = 'invalid';
          alert("Enter cheque date >= paid date.");
          this.ishow_save_paidbankList_btn = 'yes';
          return;
        }
        else if (this.new_labpaidBankChq[k]['invalidchqDate'] != ''){
          issave = false;
          alert("Enter valid cheque date.");
          this.ishow_save_paidbankList_btn = 'yes';
          return;
        }

        chq_Detail.push({
          "accName": this.new_labpaidBankChq[k]['accName'],
          "bankID": this.new_labpaidBankChq[k]['bankID'],
          "chqDate": this.new_labpaidBankChq[k]['chqDate'],
          "chqNo": this.new_labpaidBankChq[k]['chqNo'],
          "docID": this.new_labpaidBankChq[k]['docID'],
          "docdt": this.new_labpaidBankChq[k]['docdt'],
          "netPay": this.new_labpaidBankChq[k]['netPay'],
          "paidBankAccID": this.new_labpaidBankChq[k]['paidBankAccID'],
          "transBanklistID": this.new_labpaidBankChq[k]['transBanklistID'],
          "transChqID": this.new_labpaidBankChq[k]['transChqID'],
          "bankGroupID": this.new_labpaidBankChq[k]['bankGroupID'],
          "bankType": this.new_labpaidBankChq[k]['bankType'],
          "accShortName": this.new_labpaidBankChq[k]['accShortName'],
          "clrType": this.new_labpaidBankChq[k]['clrType'],
        })
      }

      let mon, yr, fayear;
      mon = new Date(this.labPaidBankDate).getMonth() + 1;
      yr = new Date(this.labPaidBankDate).getFullYear();

      if (mon < 4) {
        fayear = yr - 1;
      }
      else {
        fayear = yr;
      }

      if (fayear != this.filterFayear) {
        alert('Enter paid date selected finacial year.')
        issave = false;
        this.ishow_save_paidbankList_btn = 'yes';
        return;
      }

      let filterDate: any = {
        "frmDt": this.frm_paidBankDate,
        "toDt": this.to_paidBankDate,
        "flag": ""
      }

      //"ChqDt": this.new_labpaidBankChq,

      if (issave == true) {
        let formData: any = {
          "SavePaidBankAuth": this.lab_authListPaidBank,
          "ChqDt": chq_Detail,
          "paidDate": this.labPaidBankDate,
          "filterdata": filterDate,
        }

        // 

        // return;
        this.show_loadingModal(true);

        this.ngxService.start()

        let body = new FormData();
        body = formData;
        let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/savelabpaidbanklist/' + this.TransBanklistID + '/' + this.filterFayear + '/' + this.Log_UserID;
        //alert(url);
        this._userService.post_data(url, body).subscribe(
          (data) => {

            if (Number(data) > 0) {
              this.show_loadingModal(false);
              alert("Paid Bank List Entry Saved Successfully.List No : - " + data.toString());
              this.lab_authListPaidBank = [];
              this.new_labpaidBankChq = [];
              this.fill_labPaidBankListNo(Number(data));
              this.hide();
              this.ishow_save_paidbankList_btn = 'yes';

            }
            else {
              this.show_loadingModal(false);
              alert(data);
              this.ishow_save_paidbankList_btn = 'yes';
            }
            this.ngxService.stop();


          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            this.ngxService.stop();
            //alert(err);
            return;
          });

        this.ngxService.stop();
      }

    }
    else {
      this.show_loadingModal(false);
      alert('No record.')
      this.ishow_save_paidbankList_btn = 'yes';
    }
  }

  del_labPaidBankList() {

    ///////////////

    let actionType: string = 'na';
    actionType = 'delete';

    let auth_List: any = [];
    let userTypeID: Number = -1;
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
    }

    let Log_BoardID: Number = -1;
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    let authFor = "Lab. PaidBank List";
    this.show_loadingModal(true);
    let url = 'getUserAuthDt/' + Log_BoardID
    this._userService.get_Dashborad_Data(url).subscribe((data) => {

      // console.log('retData',data);
      auth_List = data;
      console.log('auth_List:', auth_List)
      if (authFor != '') {
        let filterList = [];
        if (actionType.toString().toLocaleLowerCase() == 'add') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'edit') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else if (actionType.toString().toLocaleLowerCase() == 'delete') {
          filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
        }
        else {
          this.show_loadingModal(false);
          alert('You have no authority.')
          return;
        }

        if (Number(filterList.length) > 0) {
          //alert(Number(filterList.length));
          this.show_loadingModal(false);
          if (Number(this.TransBanklistID > 0)) {
            if (window.confirm('Are sure you want to delete this entry ?')) {
              this.ngxService.start();
              this.show_loadingModal(true);
              let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/dellabpaidbanklist/' + this.TransBanklistID;
              this._userService.get_Data(url).subscribe(
                (data) => {
                  this.ngxService.stop();
                  if (data == 'Record deleted succesfully.') {
                    this.show_loadingModal(false);
                    alert(data);

                    this.lab_authListPaidBank = [];
                    this.new_labpaidBankChq = [];
                    this.fill_labPaidBankListNo(0);
                    this.hide();
                  }
                  else {
                    this.show_loadingModal(false);
                    alert(data);
                  }

                },
                (err: HttpErrorResponse) => {
                  this.show_loadingModal(false);
                  this.ngxService.stop();
                  //alert(err);
                  return;
                });
            }

          }
          else {
            this.show_loadingModal(false);
            alert("List no. not select.");
          }

        }
        else {
          this.show_loadingModal(false);
          alert('You have no authority.')
          return;
        }

      }
      else {
        this.show_loadingModal(false);
        alert('You have no authority.')
        return;
      }
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('You have no authority.')
        return;
      });

    //////////////////


    // if (Number(this.TransBanklistID > 0)) {
    //   if (window.confirm('Are sure you want to delete this entry ?')) {
    //     this.ngxService.start();
    //     let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/dellabpaidbanklist/' + this.TransBanklistID;
    //     this._userService.get_Data(url).subscribe(
    //       (data) => {
    //         this.ngxService.stop();
    //         if (data == 'Record deleted succesfully.') {
    //           alert(data);

    //           this.lab_authListPaidBank = [];
    //           this.new_labpaidBankChq = [];
    //           this.fill_labPaidBankListNo();
    //           this.hide();
    //         }
    //         else {
    //           alert(data);
    //         }

    //       });
    //   }

    // }
    // else {
    //   alert("List no. not select.");
    // }

  }
  srNO_rpt: number = 0;
  set_srno_0() {
    this.srNO_rpt = 0;
    return '';
  }
  add_srno() {
    this.srNO_rpt = Number(this.srNO_rpt) + 1;
    return this.srNO_rpt;
  }
  bob_salaryDecs: string = '';
  uco_salaryDecs: string = '';
  fill_rpt_paidLabbankList() {
    this.ngxService.start();
    this.show_paidbanklisRpt = '';
    this.send_mailPaidBank = 'no';
    let url_: string = '';
    this.show_loadingModal(true);
    url_ = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getLabPaidBankList/' + this.TransBanklistID;
    //console.log('url', url_);
    this._userService.get_DataFile(url_).subscribe((data) => {
      console.log('pdfRec', data);
      if (data.statusText == "OK") {
        this.show_loadingModal(false);
        this.ngxService.stop();
        let blob: any = new Blob([data.blob()], { type: 'application/pdf; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } else if (data.statusText == "Error") {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert('Something went wrong.');
      } else {
        this.show_loadingModal(false);
        this.ngxService.stop();
        alert(data.statusText);
      }
      this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        this.ngxService.stop();
        //alert(err);
        return;
      });

    return;



    this.ngxService.start();
    this.rptLabPaiddBankList = [];
    this.PiadBank_EmialList = [];

    this.BOM_PiadBankList = [];
    this.BOB_PiadBankList = [];
    this.UCO_PiadBankList = [];

    this.paid_bank_BOM_bankIDlist = [];
    this.paid_bank_BOB_bankIDlist = [];
    this.paid_bank_UCO_bankIDlist = [];

    this.BOM_Home_PiadBankList = [];
    this.BOB_Home_PiadBankList = [];
    this.UCO_Home_PiadBankList = [];

    this.paid_bank_BOM_Home_bankIDlist = [];
    this.paid_bank_BOB_Home_bankIDlist = [];
    this.paid_bank_UCO_Home_bankIDlist = [];


    this.bob_salaryDecs = '';
    this.uco_salaryDecs = '';
    this.send_mailPaidBank = '';
    //this.show_paidbanklisRpt = 'yes';

    // 

    let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/rptgetlabpaidbank/' + this.TransBanklistID;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.rptLabPaiddBankList = data;

        console.log('paidBankList ', data);

        this.paid_bankIDList = [...new Set(data.map(item => item.bankID))];
        this.paid_bank_toliIDList = [...new Set(data.map(item => item.toliID))];

        console.log('paidBankList ToliID ', this.paid_bank_toliIDList);

        this.BOM_Home_PiadBankList = data.filter(x => x.bankType.toString().toLocaleLowerCase() == 'bom' && x.clrType.toString().toLocaleLowerCase() == 'home');
        this.BOB_Home_PiadBankList = data.filter(x => x.bankType.toString().toLocaleLowerCase() == 'bob' && x.clrType.toString().toLocaleLowerCase() == 'home');
        this.UCO_Home_PiadBankList = data.filter(x => x.bankType.toString().toLocaleLowerCase() == 'uco' && x.clrType.toString().toLocaleLowerCase() == 'home');

        this.BOM_PiadBankList = data.filter(x => x.bankType.toString().toLocaleLowerCase() == 'bom' && x.clrType.toString().toLocaleLowerCase() == 'other');
        this.BOB_PiadBankList = data.filter(x => x.bankType.toString().toLocaleLowerCase() == 'bob' && x.clrType.toString().toLocaleLowerCase() == 'other');
        this.UCO_PiadBankList = data.filter(x => x.bankType.toString().toLocaleLowerCase() == 'uco' && x.clrType.toString().toLocaleLowerCase() == 'other');

        this.paid_bank_BOM_Home_bankIDlist = [...new Set(this.BOM_Home_PiadBankList.map(item => item.bankID))];
        this.paid_bank_BOB_Home_bankIDlist = [...new Set(this.BOB_Home_PiadBankList.map(item => item.bankID))];
        this.paid_bank_UCO_Home_bankIDlist = [...new Set(this.UCO_Home_PiadBankList.map(item => item.bankID))];

        this.paid_bank_BOM_bankIDlist = [...new Set(this.BOM_PiadBankList.map(item => item.bankID))];
        this.paid_bank_BOB_bankIDlist = [...new Set(this.BOB_PiadBankList.map(item => item.bankID))];
        this.paid_bank_UCO_bankIDlist = [...new Set(this.UCO_PiadBankList.map(item => item.bankID))];

        // let dd, mm, yy;
        // dd = new Date(this.frm_paidBankDate).getDate();
        // mm = new Date(this.frm_paidBankDate).getMonth() + 1;
        // yy = new Date(this.frm_paidBankDate).getFullYear();

        console.log('this.paid_bankIDList ', this.paid_bankIDList);
        console.log('this.paid_bank_BOM_bankIDlist ', this.paid_bank_BOM_bankIDlist);

        for (let k = 0; k < this.paid_bankIDList.length; k++) {
          let filtRec = data.filter(x => Number(x.bankID) == Number(this.paid_bankIDList[k]));
          if (Number(filtRec.length) > 0) {
            if (Number(this.PiadBank_EmialList.length == 0)) {

              this.PiadBank_EmialList = [{
                "bankID": Number(this.paid_bankIDList[k]),
                "bankName": filtRec[0]['bankName'],
                "branchName": filtRec[0]['clrType'],
                "emailID": filtRec[0]['emailID'],
                "fileName": '',
                "imageFile": '',
                "frmDate": filtRec[0]['frmDate'],
                "ToDate": filtRec[0]['toDate'],
                "ListNo": this.TransBanklistID,

              },];

            }
            else {
              this.PiadBank_EmialList.push({
                "bankID": Number(this.paid_bankIDList[k]),
                "bankName": filtRec[0]['bankName'],
                "branchName": filtRec[0]['clrType'],
                "emailID": filtRec[0]['emailID'],
                "fileName": '',
                "imageFile": '',
                "frmDate": filtRec[0]['frmDate'],
                "ToDate": filtRec[0]['toDate'],
                "ListNo": this.TransBanklistID,

              });

            }
          }
        }



        console.log('bankList', this.PiadBank_EmialList);

        this.show_paidbanklisRpt = 'download';

        let EntryDate: Date = new Date(data[0]['docdt']);
        let EntryMon = EntryDate.getMonth() + 1;
        let EntryYr = EntryDate.getFullYear();


        //console.log('entryMonth',EntryDate);

        this.bob_salaryDecs = 'Salary ' + EntryYr.toString() + '-' + this.get_monthName_short(EntryMon);
        this.uco_salaryDecs = 'Salary ' + EntryYr.toString() + '-' + this.get_monthName_short(EntryMon);

        this.ngxService.stop();

      });
  }
  download_lab_paidLabbankList_excel() {
    this.send_mailPaidBank = 'no';
    let flnameList: any = [];
    this.PiadBank_EmialList = [];
    this.ngxService.start();
    let url_: string = '';
    url_ = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/getLabPaidBankListExcelFile/' + this.TransBanklistID;
    //console.log('url', url_);
    this._userService.get_Data(url_).subscribe((data) => {
      console.log('exl-flnameList', data);
      this.PiadBank_EmialList = data.paidbankemailList;
      flnameList = data.paidbankfileNameList;

      if (Number(flnameList.length) > 0) {
        for (let k = 0; k < flnameList.length; k++) {
          window.open(this.globals.BaseURL + "/PimMathaLabPaidBankListFile/" + flnameList[k]['flName']);
        }
      }
      this.ngxService.stop();
      alert('Excel File Created Successfully.Find File In Downloads.')
      this.send_mailPaidBank = 'yes';
    });

    //  window.open(this.globals.BaseURL + "/PimMathaLabPaidBankListFile/BOM.xlsx");
    // window.open(this.globals.BaseURL + "/FileFormatToDownload/BOM.xls");

  }
  get_monthName_short(mon: Number) {
    if (mon == 1) {
      return 'Jan'
    }
    else if (mon == 2) {
      return 'Feb'
    }
    else if (mon == 3) {
      return 'Mar'
    }
    else if (mon == 4) {
      return 'Apr'
    }
    else if (mon == 5) {
      return 'May'
    }
    else if (mon == 6) {
      return 'Jun'
    }
    else if (mon == 7) {
      return 'Jul'
    }
    else if (mon == 8) {
      return 'Aug'
    }
    else if (mon == 9) {
      return 'Spt'
    }
    else if (mon == 10) {
      return 'Oct'
    }
    else if (mon == 11) {
      return 'Nov'
    }
    else if (mon == 12) {
      return 'Dec'
    }

  }
  get_filter_BOM_bankIDWise_Data(data: any, bankID: Number) {
    return data.filter(x => Number(x.bankID) == bankID);
    //return this.BOM_PiadBankList.filter(x => Number(x.bankID) == bankID);
  }
  get_filter_BOB_bankIDWise_Data(data: any, bankID: Number) {
    return data.filter(x => Number(x.bankID) == bankID);
    //return this.BOB_PiadBankList.filter(x => Number(x.bankID) == bankID);
  }
  get_filter_UCO_bankIDWise_Data(data: any, bankID: Number) {
    return data.filter(x => Number(x.bankID) == bankID);
    // return this.UCO_PiadBankList.filter(x => Number(x.bankID) == bankID);
  }

  getTotal_bankwise(data, fldname, bankID) {
    if (fldname == 'wagesAmt') {
      return data.reduce((sum, curr) => sum + curr.wagesAmt, 0);
    }
    else if (fldname == 'levyAmt') {
      return data.reduce((sum, curr) => sum + curr.levyAmt, 0);
    }
    else if (fldname == 'totAmt') {
      return data.reduce((sum, curr) => sum + curr.totAmt, 0);
    }
    else if (fldname == 'netPay') {
      let _filterData = data.filter(x => Number(x.bankID) == Number(bankID));
      return _filterData.reduce((sum, curr) => sum + curr.netPay, 0);
      //return data.reduce((sum, curr) => sum + curr.netPay, 0);
    }
    else if (fldname == 'recWageAmt') {
      return data.reduce((sum, curr) => sum + curr.recWageAmt, 0);
    }
    else if (fldname == 'recPaidAmt') {
      return data.reduce((sum, curr) => sum + curr.recPaidAmt, 0);
    }


  }
  get_paidBank_Details(data, fldname, bankID) {
    let _filterData = data.filter(x => Number(x.bankID) == Number(bankID));
    if (Number(_filterData.length > 0)) {
      if (fldname == 'accName') {
        return _filterData[0]['accName'];
      }
      else if (fldname == 'billNo') {
        return _filterData[0]['billNo'];
      }
      else if (fldname == 'chqDate') {
        return _filterData[0]['chqDate'];
      }
      else if (fldname == 'bankName') {
        return _filterData[0]['bankName'];
      }
      else if (fldname == 'bankType') {
        return _filterData[0]['bankType'];
      }
      else if (fldname == 'branchName') {
        return _filterData[0]['branchName'];
      }
      else if (fldname == 'chqAmt') {
        return _filterData[0]['chqAmt'];
      }
      else if (fldname == 'emailID') {
        return _filterData[0]['emailID'];
      }
      else if (fldname == 'frmDate') {
        return _filterData[0]['frmDate'];
      }
      else if (fldname == 'toDate') {
        return _filterData[0]['toDate'];
      }
      else if (fldname == 'docdt') {
        return _filterData[0]['docdt'];
      }
      else if (fldname == 'bankAccNo') {
        return _filterData[0]['bankAccNo'];
      }
      else if (fldname == 'clrType') {
        return _filterData[0]['clrType'];
      }
      else {
        return '';
      }

    }
    else {
      return '';
    }



  }

  hide_paidbanklist_downlod() {
    this.show_paidbanklisRpt = '';
    this.send_mailPaidBank = '';
  }
  create_NskMtha_paidbankListExcel_file(): void {
    this.create_NskMtha_BOM_paidbankListExcel_file('home', this.paid_bank_BOM_Home_bankIDlist, this.BOM_Home_PiadBankList);
    this.create_NskMtha_BOM_paidbankListExcel_file('other', this.paid_bank_BOM_bankIDlist, this.BOM_PiadBankList);

    this.create_NskMtha_BOB_paidbankListExcel_file('home', this.paid_bank_BOB_Home_bankIDlist, this.BOB_Home_PiadBankList);
    this.create_NskMtha_BOB_paidbankListExcel_file('other', this.paid_bank_BOB_bankIDlist, this.BOB_PiadBankList);

    this.create_NskMtha_UCO_paidbankListExcel_file('home', this.paid_bank_UCO_Home_bankIDlist, this.UCO_Home_PiadBankList);
    this.create_NskMtha_UCO_paidbankListExcel_file('other', this.paid_bank_UCO_bankIDlist, this.UCO_PiadBankList);

    alert('File created successfully.Find file in downloads.')
    this.send_mailPaidBank = 'yes';
  }

  create_NskMtha_BOM_paidbankListExcel_file(list_Type: string, bankID_Data: any, bankList_Data: any): void {
    for (let k = 0; k < bankID_Data.length; k++) {
      let fitRec = bankList_Data.filter(x => Number(x.bankID) == Number(bankID_Data[k]));

      let dd, mm, yy;
      dd = new Date(this.frm_paidBankDate).getDate();
      mm = new Date(this.frm_paidBankDate).getMonth() + 1;
      yy = new Date(this.frm_paidBankDate).getFullYear();


      let strFrmDt = dd + '/' + mm + '/' + yy;

      dd = new Date(this.to_paidBankDate).getDate();
      mm = new Date(this.to_paidBankDate).getMonth() + 1;
      yy = new Date(this.to_paidBankDate).getFullYear();

      let strToDt = dd + '/' + mm + '/' + yy;

      let fileName = '';// 'BOM - ' + fitRec[0]['branchName'] + '-नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx'
      /* table id is passed over here */

      let element;
      if (list_Type == 'home') {
        fileName = 'BOM - Home - नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx'
        element = document.getElementById('excel-table-bom-home' + bankID_Data[k]);
      }
      else {
        fileName = 'BOM - Other - नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx'
        element = document.getElementById('excel-table-bom' + bankID_Data[k]);
      }


      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();



      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      if (list_Type == 'home') {
      }
      else {
        let element1;
        element1 = document.getElementById('excel-table-bom-sheet2' + bankID_Data[k]);
        const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element1, { raw: true });
        XLSX.utils.book_append_sheet(wb, ws1, 'Sheet2');
      }




      //ws.cellFormat({ borderTop: '1px solid #e0e0e0', backgroundColor: '#EEEEEE' }, 'A1:F20')

      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  }
  create_NskMtha_BOB_paidbankListExcel_file(list_Type: string, bankID_Data: any, bankList_Data: any): void {
    for (let k = 0; k < bankID_Data.length; k++) {
      let fitRec = bankList_Data.filter(x => Number(x.bankID) == Number(bankID_Data[k]));

      let dd, mm, yy;
      dd = new Date(this.frm_paidBankDate).getDate();
      mm = new Date(this.frm_paidBankDate).getMonth() + 1;
      yy = new Date(this.frm_paidBankDate).getFullYear();


      let strFrmDt = dd + '/' + mm + '/' + yy;

      dd = new Date(this.to_paidBankDate).getDate();
      mm = new Date(this.to_paidBankDate).getMonth() + 1;
      yy = new Date(this.to_paidBankDate).getFullYear();

      let strToDt = dd + '/' + mm + '/' + yy;

      let fileName = '';// 'BOB - ' + fitRec[0]['branchName'] + '-' + strFrmDt + ' To ' + strToDt + '.xlsx'
      /* table id is passed over here */

      let element;
      if (list_Type == 'home') {
        fileName = 'BOB - Home - ' + strFrmDt + ' To ' + strToDt + '.xlsx';
        element = document.getElementById('excel-table-bob-home' + bankID_Data[k]);
      }
      else {
        fileName = 'BOB - ' + strFrmDt + ' To ' + strToDt + '.xlsx';
        element = document.getElementById('excel-table-bob' + bankID_Data[k]);
      }


      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();



      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


      //ws.cellFormat({ borderTop: '1px solid #e0e0e0', backgroundColor: '#EEEEEE' }, 'A1:F20')

      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  }
  create_NskMtha_UCO_paidbankListExcel_file(list_Type: string, bankID_Data: any, bankList_Data: any): void {
    for (let k = 0; k < bankID_Data.length; k++) {
      let fitRec = bankList_Data.filter(x => Number(x.bankID) == Number(bankID_Data[k]));

      let dd, mm, yy;
      dd = new Date(this.frm_paidBankDate).getDate();
      mm = new Date(this.frm_paidBankDate).getMonth() + 1;
      yy = new Date(this.frm_paidBankDate).getFullYear();

      let strFrmDt = dd + '/' + mm + '/' + yy;

      dd = new Date(this.to_paidBankDate).getDate();
      mm = new Date(this.to_paidBankDate).getMonth() + 1;
      yy = new Date(this.to_paidBankDate).getFullYear();

      let strToDt = dd + '/' + mm + '/' + yy;

      let fileName = '';// 'UCO - ' + fitRec[0]['branchName'] + '-नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx'
      /* table id is passed over here */



      let element;
      if (list_Type == 'home') {
        fileName = 'UCO - Home - नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx';
        element = document.getElementById('excel-table-uco-home' + bankID_Data[k]);
      }
      else {
        fileName = 'UCO - नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx';
        element = document.getElementById('excel-table-uco' + bankID_Data[k]);
      }

      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      //ws.cellFormat({ borderTop: '1px solid #e0e0e0', backgroundColor: '#EEEEEE' }, 'A1:F20')

      /* save to file */
      XLSX.writeFile(wb, fileName);

    }
  }

  // create_NskMtha_BOM_paidbankListExcel_file(): void {
  //   for (let k = 0; k < this.paid_bank_BOM_bankIDlist.length; k++) {
  //     let fitRec = this.BOM_PiadBankList.filter(x => Number(x.bankID) == Number(this.paid_bank_BOM_bankIDlist[k]));

  //     let dd, mm, yy;
  //     dd = new Date(this.frm_paidBankDate).getDate();
  //     mm = new Date(this.frm_paidBankDate).getMonth() + 1;
  //     yy = new Date(this.frm_paidBankDate).getFullYear();


  //     let strFrmDt = dd + '/' + mm + '/' + yy;

  //     dd = new Date(this.to_paidBankDate).getDate();
  //     mm = new Date(this.to_paidBankDate).getMonth() + 1;
  //     yy = new Date(this.to_paidBankDate).getFullYear();

  //     let strToDt = dd + '/' + mm + '/' + yy;

  //     let fileName = 'BOM - ' + fitRec[0]['branchName'] + '-नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx'
  //     /* table id is passed over here */

  //     let element = document.getElementById('excel-table-bom' + this.paid_bank_BOM_bankIDlist[k]);
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

  //     /* generate workbook and add the worksheet */
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();



  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


  //     //ws.cellFormat({ borderTop: '1px solid #e0e0e0', backgroundColor: '#EEEEEE' }, 'A1:F20')

  //     /* save to file */
  //     XLSX.writeFile(wb, fileName);
  //   }
  // }
  // create_NskMtha_BOB_paidbankListExcel_file(): void {
  //   for (let k = 0; k < this.paid_bank_BOB_bankIDlist.length; k++) {
  //     let fitRec = this.BOB_PiadBankList.filter(x => Number(x.bankID) == Number(this.paid_bank_BOB_bankIDlist[k]));

  //     let dd, mm, yy;
  //     dd = new Date(this.frm_paidBankDate).getDate();
  //     mm = new Date(this.frm_paidBankDate).getMonth() + 1;
  //     yy = new Date(this.frm_paidBankDate).getFullYear();


  //     let strFrmDt = dd + '/' + mm + '/' + yy;

  //     dd = new Date(this.to_paidBankDate).getDate();
  //     mm = new Date(this.to_paidBankDate).getMonth() + 1;
  //     yy = new Date(this.to_paidBankDate).getFullYear();

  //     let strToDt = dd + '/' + mm + '/' + yy;

  //     let fileName = 'BOB - ' + fitRec[0]['branchName'] + '-' + strFrmDt + ' To ' + strToDt + '.xlsx'
  //     /* table id is passed over here */

  //     let element = document.getElementById('excel-table-bob' + this.paid_bank_BOB_bankIDlist[k]);
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

  //     /* generate workbook and add the worksheet */
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();



  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');


  //     //ws.cellFormat({ borderTop: '1px solid #e0e0e0', backgroundColor: '#EEEEEE' }, 'A1:F20')

  //     /* save to file */
  //     XLSX.writeFile(wb, fileName);
  //   }
  // }
  // create_NskMtha_UCO_paidbankListExcel_file(): void {
  //   for (let k = 0; k < this.paid_bank_UCO_bankIDlist.length; k++) {
  //     let fitRec = this.UCO_PiadBankList.filter(x => Number(x.bankID) == Number(this.paid_bank_UCO_bankIDlist[k]));

  //     let dd, mm, yy;
  //     dd = new Date(this.frm_paidBankDate).getDate();
  //     mm = new Date(this.frm_paidBankDate).getMonth() + 1;
  //     yy = new Date(this.frm_paidBankDate).getFullYear();

  //     let strFrmDt = dd + '/' + mm + '/' + yy;

  //     dd = new Date(this.to_paidBankDate).getDate();
  //     mm = new Date(this.to_paidBankDate).getMonth() + 1;
  //     yy = new Date(this.to_paidBankDate).getFullYear();

  //     let strToDt = dd + '/' + mm + '/' + yy;

  //     let fileName = 'UCO - ' + fitRec[0]['branchName'] + '-नाशिक माथाडी व असंरक्षित कामगार मंडळ, नाशिक-' + strFrmDt + ' To ' + strToDt + '.xlsx'
  //     /* table id is passed over here */

  //     let element = document.getElementById('excel-table-uco' + this.paid_bank_UCO_bankIDlist[k]);
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

  //     /* generate workbook and add the worksheet */
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();

  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //     //ws.cellFormat({ borderTop: '1px solid #e0e0e0', backgroundColor: '#EEEEEE' }, 'A1:F20')

  //     /* save to file */
  //     XLSX.writeFile(wb, fileName);

  //   }
  // }

  send_paidbankListMail() {
    if (Number(this.PiadBank_EmialList.length) > 0) {
      let sendEmail = 'no';

      for (let k = 0; k < this.PiadBank_EmialList.length; k++) {
        if (this.PiadBank_EmialList[k]['imageFile'] == 'yes') {
          sendEmail = 'yes';
        }
      }

      // this.PiadBank_EmialList[i]['imageFile'] = 'yes';

      if (sendEmail == 'yes') {
        let url = this.globals.BaseURL + 'api/SendMail/pimmathasendpaidbankemail';

        this.Save_formData.append("PimMathaPaidBankList", JSON.stringify(this.PiadBank_EmialList));

        $.ajax({
          url: url,
          type: 'POST',
          data: this.Save_formData,
          cache: false,
          contentType: false,
          processData: false,
          success: function (fileName) {

            $("#fileProgress").hide();

            this.paidBank_showModal = false;

            if (fileName == 'Send SuccessFully.') {
              this.paidBank_showModal = false;
              alert('Mail Send successfully.');

            }
            else {
              console.log('mailErr ', fileName);
              alert('Something went wrong internal server error.')

            }

          },
          xhr: function () {
            var fileXhr = $.ajaxSettings.xhr();
            if (fileXhr.upload) {
              $("progress").show();
              fileXhr.upload.addEventListener("progress", function (e) {
                if (e.lengthComputable) {
                  $("#fileProgress").attr({
                    value: e.loaded,
                    max: e.total
                  });
                }
              }, false);
            }
            return fileXhr;
          }
        });

      }
      else {
        alert('Select file to send mail.')
      }


    }
    else {
      alert('Record not found.')
    }
  }

  set_uploadfile(event, i, bankID) {
    this.PiadBank_EmialList[i]['imageFile'] = 'yes';
    this.Save_formData.append(bankID, event.target.files[0], event.target.files[0].fileName);
  }

  upload_excel() {
    //  this.Save_formData = new FormData();

    if (this.upload_fileName == null) {
      alert('Select file.')
      return false;
    }
    else {
      let url = this.globals.BaseURL + 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/uploadexcel';


      this.Save_formData.append("uplodfile", JSON.stringify(this.excle_policyLabList));
      $.ajax({
        url: url,
        type: 'POST',
        data: this.Save_formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (fileName) {
          $("#fileProgress").hide();


          if (fileName == 'Saved SuccessFully.') {

            alert('File uploaded successfully.')
            window.location.reload();
            // this.loading = '';
          }
          else {
            this.loading = '';
            alert('Something went wrong internal server error.')
          }

        },
        xhr: function () {
          var fileXhr = $.ajaxSettings.xhr();
          if (fileXhr.upload) {
            $("progress").show();
            fileXhr.upload.addEventListener("progress", function (e) {
              if (e.lengthComputable) {
                $("#fileProgress").attr({
                  value: e.loaded,
                  max: e.total
                });
              }
            }, false);
          }
          //  this.ngxService.start();
          return fileXhr;
        }
      });



    }

  }
  //#endregion

  //#region validate dates
  invalid_frmAuthDate = '';
  invalid_toAuthDate = '';
  invalid_AuthDate = '';
  invalid_chqclrDate = '';
  invalid_frmpaidBankDate = '';
  invalid_topaidBankDate = '';
  invalid_labPaidBankDate = '';
  invalid_labPaidBankChqDate = '';
  validate_Date(event, fldName: string) {
    if (fldName == 'frmauthDate') {
      this.invalid_frmAuthDate = 'invalid';
    }
    else if (fldName == 'toauthDate') {
      this.invalid_toAuthDate = 'invalid';
    }
    else if (fldName == 'authDate') {
      this.invalid_AuthDate = 'invalid';
    }
    else if (fldName == 'chqclrDate') {
      this.invalid_chqclrDate = 'invalid';
    }
    else if (fldName == 'frmpaidBankDate') {
      this.invalid_frmpaidBankDate = 'invalid';
    }
    else if (fldName == 'topaidBankDate') {
      this.invalid_topaidBankDate = 'invalid';
    }
    else if (fldName == 'labPaidBankDate') {
      this.invalid_labPaidBankDate = 'invalid';
    }
    else if (fldName == 'labPaidBankChqDate') {
      this.invalid_labPaidBankChqDate = 'invalid';
    }

    let chkDt = new Date(event.target.value);
    // 
    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099) {
      if (fldName == 'frmauthDate') {
        this.invalid_frmAuthDate = 'invalid';
      }
      else if (fldName == 'toauthDate') {
        this.invalid_toAuthDate = 'invalid';
      }
      else if (fldName == 'authDate') {
        this.invalid_AuthDate = 'invalid';
      }
      else if (fldName == 'chqclrDate') {
        this.invalid_chqclrDate = 'invalid';
      }
      else if (fldName == 'frmpaidBankDate') {
        this.invalid_frmpaidBankDate = 'invalid';
      }
      else if (fldName == 'topaidBankDate') {
        this.invalid_topaidBankDate = 'invalid';
      }
      else if (fldName == 'labPaidBankDate') {
        this.invalid_labPaidBankDate = 'invalid';
      }
      else if (fldName == 'labPaidBankChqDate') {
        this.invalid_labPaidBankChqDate = 'invalid';
      }
    }
    else {
      if (chkDt.getFullYear()) {
        if (fldName == 'frmauthDate') {
          this.invalid_frmAuthDate = '';
        }
        else if (fldName == 'toauthDate') {
          this.invalid_toAuthDate = '';
        }
        else if (fldName == 'authDate') {
          this.invalid_AuthDate = '';
        }
        else if (fldName == 'chqclrDate') {
          this.invalid_chqclrDate = '';
        }
        else if (fldName == 'frmpaidBankDate') {
          this.invalid_frmpaidBankDate = '';
        }
        else if (fldName == 'topaidBankDate') {
          this.invalid_topaidBankDate = '';
        }
        else if (fldName == 'labPaidBankDate') {
          this.invalid_labPaidBankDate = '';
        }
        else if (fldName == 'labPaidBankChqDate') {
          this.invalid_labPaidBankChqDate = '';
        }
      }
      else {

      }

    }
  }
  set_Date(event, fldName: string) {
    // this.recDate = event.target.value;

    let chkDt = new Date(event.target.value);
    //

    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099) {
    }
    else {

      if (fldName == 'frmauthDate') {
        this.frm_authDate = new Date(chkDt).toISOString().substring(0, 10);
      }
      else if (fldName == 'toauthDate') {
        this.to_authDate = new Date(chkDt).toISOString().substring(0, 10);
      }
      else if (fldName == 'authDate') {
        this.authDate = new Date(chkDt).toISOString().substring(0, 10);
      }
      else if (fldName == 'frmpaidBankDate') {
        this.frm_paidBankDate = new Date(chkDt).toISOString().substring(0, 10);
      }
      else if (fldName == 'topaidBankDate') {
        this.to_paidBankDate = new Date(chkDt).toISOString().substring(0, 10);
      }
      else if (fldName == 'labPaidBankDate') {
        this.labPaidBankDate = new Date(chkDt).toISOString().substring(0, 10);
      }
    }

  }
  //#endregion


  test_sendMail() {

  }
  test_rptPDF() {
    let url: string = '';
    url = 'api/' + this._userService.get_rpt_controlerName(this.Log_BoardID) + '/EstListReport';
    console.log('url', url);
    this._userService.get_DataFile(url).subscribe((data) => {
      console.log('pdfRec', data);
      if (data.statusText == "OK") {
        let blob: any = new Blob([data.blob()], { type: 'application/pdf; charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } else if (data.statusText == "Error") {
        alert('Est List PDF file not generated.Conatact to Administration');
      } else {
        alert(data.statusText);
      }
    });
  }

}
