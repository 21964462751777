<div class="container dashboardmain">

    <section id="company-dashboard" style="margin-top: 5px;">
        <div class="container">
            <div class="content-panel">
                <div class="row">
                    <h4 class="col-sm-12">मोबाईल नंबर पडताळणी</h4>

                    <div class="form-group col-sm-12">
                        <label for="email-mobile">मंडळाचे नाव निवडा <strong>*</strong></label>
                        <select #selboard id="selboard" class="form-control">
                            <option *ngFor="let brd of boardList; let i=index" value="{{brd.boardID}}">{{brd.boardName}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-sm-12">
                        <label>मोबाईल नंबर <strong>*</strong></label>
                        <input type="text" (keypress)="onlyNumberKey($event)" class="form-control" id="mobno"
                            value="{{mobno}}" [(ngModel)]="mobno" maxlength="10"
                            placeholder="१० अंकी मोबाईल नंबर प्रविष्ट करा">
                    </div>

                    <div *ngIf="mobno.length == 10" (click)="genrateOTP()" class="form-group col-sm-12"><a
                            class="btn btn-dark btn-sm" style="color: white;">ओ.टी.पी. मिळवा</a></div>
                    <div *ngIf="otpval != ''" class="form-group col-sm-12">
                        <label>ओ.टी.पी. प्रविष्ट करा <strong>*</strong></label>
                        <input type="password" class="form-control" id="txtotpval" value="{{txtotpval}}"
                            [(ngModel)]="txtotpval" placeholder="ओ.टी.पी. प्रविष्ट करा">
                    </div>
                    <div *ngIf="otpval != ''" (click)="verifyOTP(selboard.value)" class="form-group col-sm-12"><a
                            class="btn btn-dark btn-sm" style="color: white;">पुष्टी करा </a></div>

                </div>
            </div>
        </div>
    </section>
</div>