import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
  }
  view_PdfFile(fileName): any {

    window.open("https://webapi.pcmcmathadi.in/" + fileName);

  }
  got_page(){
    this.router.navigate(['privacy-policy']);
  }

}
