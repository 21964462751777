import { Component, OnInit } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { data } from 'jquery';

@Component({
  selector: 'app-labour-dashboard',
  templateUrl: './labour-dashboard.component.html',
  styleUrls: ['./labour-dashboard.component.css']
})
export class LabourDashboardComponent implements OnInit {
  expandContent = true;
  public dasbordfor: string = '';
  public YrList: any = [];
  public MonthList: any = [];
  public boardlist: any = [];
  public labourData: any = [];
  public brdwise_labData: any = [];
  public branchwise_labData: any = [];
  public filter_newApll_labData: any = [];
  public monthl_labData: any = [];
  public filterFayear;
  public filterYr: number = 0;
  public filterMonth: number = 0;
  public filterBoradID: number = 0;
  public UserType: string = '';
  public Applstatus:string = '';

  constructor(
    config: NgbAccordionConfig,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.filterBoradID = Number(this.route.snapshot.queryParamMap.get('BoradID'));
    this.filterMonth = Number(this.route.snapshot.queryParamMap.get('month'));
    this.filterYr = Number(this.route.snapshot.queryParamMap.get('year'));
    this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));
    this.dasbordfor = this.route.snapshot.queryParamMap.get('dasbordfor');

    if (sessionStorage.getItem('mathaLog_UserType') != null && sessionStorage.getItem('mathaLog_UserType') != '') {
      this.UserType = sessionStorage.getItem('mathaLog_UserType');
    }
  }
  go_dashboard() {
    //   let parameters = { 
    //     BoradID : this.filterBoradID,
    //     month: this.filterMonth,
    //     year: this.filterYr,
    //     fayear: this.filterFayear,

    //  };
    //this.router.navigate(['/dashboard'], { queryParams : parameters });

    sessionStorage.setItem('mathaLog_BoardID', this.filterBoradID.toString())
    sessionStorage.setItem('mathafilterMonth', this.filterMonth.toString())
    sessionStorage.setItem('mathafilterYr', this.filterYr.toString())
    sessionStorage.setItem('mathafilterFayear', this.filterFayear.toString())


  }
  ngOnInit(): void {
    if (this.UserType == 'Inspector'){
      this.Applstatus = 'Pending'
    }
    else if (this.UserType == 'Secretary'){
      this.Applstatus = 'Inspector Approve'
    }
    else if (this.UserType == 'Chairman'){
      this.Applstatus = 'Secretary Approve'
    }
    else if (this.UserType == 'HO'){
      this.Applstatus = 'NA'
    }

    console.log('this.Applstatus',this.Applstatus)

    this.fill_FinYr();
    this.Get_AllBoard();

  }
  fill_Data() {
    // this.clear_Data();
    if (this.dasbordfor == 'Labour' || this.dasbordfor == 'LabourAppl') {

      this.get_LabourDetails();
    }
    else if (this.dasbordfor == 'Establishment' || this.dasbordfor == 'EstAppl') {
      this.get_EstDetails();
    }
    else if (this.dasbordfor == 'WagesLevy') {
      this.get_WLDetails();
    }
    else if (this.dasbordfor == 'FD') {
      this.get_FDDetails()
    }


  }

  fill_FinYr() {
    this.YrList = [];
    //this.filterFayear = 0;
    this.show_loadingModal(true);
    this._userService.get_Dashborad_Data('getFayear').subscribe(
      (data) => {
        this.YrList = data;
        // this.filterFayear = Number(this.YrList[data.length - 1]['fayear']);

        //this.fill_Month(this.YrList[data.length - 1]['fayear']);

        if (this.filterFayear > 0) {
          this.fill_Month(this.filterFayear);
        }
        else {
          this.fill_Month(this.YrList[data.length - 1]['fayear']);
        }
        this.show_loadingModal(false);
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });
    //console.log(this.filterFayear);
    // for (let i = 1970; i < 2051; i++) {
    //   this.YrList.push(i);
    // }
  }
  Set_Fayear(event) {
    this.fill_Month(event.target.value);
    this.filterFayear = event.target.value;
    this.clear_Data();
    this.fill_Data();
  }
  Set_FiterYrMon(event) {
    this.filterMonth = Number(event.target.value);
    //alert(this.filterMonth)
    if (Number(this.filterMonth) < 4) {
      this.filterYr = this.filterFayear + 1
    }
    else {
      this.filterYr = this.filterFayear
    }
    if (this.filterMonth == 0) {
      this.filterYr = 0;
    }
    this.clear_Data();
    this.fill_Data();
  }
  Set_FilterBoardID(event) {
    this.filterBoradID = Number(event.target.value);
    this.clear_Data();
    this.fill_Data();
  }
  Get_AllBoard() {
    // this.filterBoradID = 0;
    this.show_loadingModal(true);
    this._userService.get_Dashborad_Data('getallboardname').subscribe(
      (data) => {
        this.show_loadingModal(false);
        this.boardlist = data;
        // this.filterBoradID = 0;//Number(this.boardlist[0]['boardID']);

        //only NskMatha Working
        this.boardlist = this.boardlist.filter(x => x.boardID == 6);

        this.fill_Data();
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });
    //this.clear_Data();
  }

  fill_Month(Yr) {
    this.MonthList = [];
    this.MonthList = [
      { mon: 4, yr: Yr, dispmon: "Apr-" + Yr },
      { mon: 5, yr: Yr, dispmon: "May-" + Yr },
      { mon: 6, yr: Yr, dispmon: "Jun-" + Yr },
      { mon: 7, yr: Yr, dispmon: "Jul-" + Yr },
      { mon: 8, yr: Yr, dispmon: "Aug-" + Yr },
      { mon: 9, yr: Yr, dispmon: "Sep-" + Yr },
      { mon: 10, yr: Yr, dispmon: "Oct-" + Yr },
      { mon: 11, yr: Yr, dispmon: "Nov-" + Yr },
      { mon: 12, yr: Yr, dispmon: "Dec-" + Yr },
      { mon: 1, yr: Yr + 1, dispmon: "Jan-" + (Number(Yr) + 1) },
      { mon: 2, yr: Yr + 1, dispmon: "Feb-" + (Number(Yr) + 1) },
      { mon: 3, yr: Yr + 1, dispmon: "Mar-" + (Number(Yr) + 1) },
    ];

    // this.clear_Data();
  }
  lab_opnActiveCnt: any = [];
  lab_newApllList: any = [];
  public get_LabourDetails() {
    this.show_loadingModal(true);

    this.lab_opnActiveCnt = [];
    this.labourData = [];
    this.brdwise_labData = [];
    this.lab_newApllList = [];

    let url: string = 'get_MonthWiseLabMonthly/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID + '/' + this.Applstatus;
    // console.log(url);
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        console.log(data);
        this.labourData = data.clsLabourMonthly;
        this.lab_newApllList = data.lab_Master;
        this.filter_newApll_labData = data.lab_Master;
        this.show_loadingModal(false);

        /////////////Boardwise Lab Deatil
        this.show_loadingModal(true);

        url = 'get_BrdWiseLabMonthly/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID+ '/' + this.Applstatus;
        this._userService.get_Dashborad_Data(url).subscribe(
          (data) => {
            this.show_loadingModal(false);
            this.brdwise_labData = data;
            console.log('brdwise_labData' ,this.brdwise_labData )
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            alert('something went wrong');
            return;
          });


      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });

  }
  private get_monthname(month) {
    if (month == 4) {
      return 'Apr'
    }
    else if (month == 5) {
      return 'May'
    }
    else if (month == 6) {
      return 'Jun'
    }
    else if (month == 7) {
      return 'Jul'
    }
    else if (month == 8) {
      return 'Aug'
    }
    else if (month == 9) {
      return 'Sep'
    }
    else if (month == 10) {
      return 'Oct'
    }
    else if (month == 11) {
      return 'Nov'
    }
    else if (month == 12) {
      return 'Dec'
    }
    else if (month == 1) {
      return 'Jan'
    }
    else if (month == 2) {
      return 'Feb'
    }
    else if (month == 3) {
      return 'Mar'
    }
    else {
      return ''
    }
  }

  estData: any = [];
  brdwise_estData: any = [];
  branchwise_estData: any = [];
  est_newApllList: any = [];
  filter_newApll_estData: any = [];
  public get_EstDetails() {

    this.show_loadingModal(true);
    this.estData = [];
    this.branchwise_estData = [];
    this.brdwise_estData = [];
    this.est_newApllList = [];
    this.filter_newApll_estData=[];

    this.wlData = [];
    this.brdwise_wlData = [];
    this.branchwise_wlData = [];

    let url: string = 'get_MonthWiseEstMonthlycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID+ '/' + this.Applstatus;
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        // this.estData = data;
        // this.wlData = data;

        console.log('estApllDt',data)

        this.estData = data.clsEstCollection;
        this.wlData = data.clsEstCollection;

        this.est_newApllList = data.est_Master;
        this.filter_newApll_estData = data.est_Master;

        console.log('estApllDt',this.filter_newApll_estData)

        this.show_loadingModal(false);

        this.show_loadingModal(true);
        url = 'get_BrdWiseEstMonthlycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
        this._userService.get_Dashborad_Data(url).subscribe(
          (data) => {
            this.brdwise_estData = data;
            this.brdwise_wlData = data;
            this.show_loadingModal(false);
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            alert('something went wrong');
            return;
          });
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });

  }
  wlData: any = [];
  brdwise_wlData: any = [];
  branchwise_wlData: any = [];
  public get_WLDetails() {
    this.get_EstDetails();

    // this.show_loadingModal(true);
    // this.estData = [];
    // this.brdwise_wlData = [];
    // this.branchwise_wlData = [];
    // let url: string = 'get_MonthWiseMonthlyWagesLevycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.wlData = data;
    //   },
    //   (err: HttpErrorResponse) => {
    //     this.show_loadingModal(false);
    //     alert('something went wrong');
    //     return;
    //   });

    // url = 'get_BrdWiseMonthlyWagesLevycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.brdwise_wlData = data;
    //   },
    //   (err: HttpErrorResponse) => {
    //     this.show_loadingModal(false);
    //     alert('something went wrong');
    //     return;
    //   });

    // url = 'getBranchWiseWageLevycollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.show_loadingModal(false);
    //     this.branchwise_wlData = data;

    //   },
    //   (err: HttpErrorResponse) => {
    //     this.show_loadingModal(false);
    //     alert('something went wrong');
    //     return;
    //   });

  }

  fdData: any = [];
  brdwise_fdData: any = [];
  baranchwise_fdData: any = [];
  public get_FDDetails() {
    this.show_loadingModal(true);
    this.fdData = [];
    this.brdwise_fdData = [];
    this.baranchwise_fdData = [];
    let url: string = 'get_MonthFDMonthlyRec/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {

        this.fdData = data;

        url = 'get_BrdFDMonthlyRec/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
        this._userService.get_Dashborad_Data(url).subscribe(
          (data) => {

            this.brdwise_fdData = data;
            // this.baranchwise_fdData = data;
            this.show_loadingModal(false);
          },
          (err: HttpErrorResponse) => {
            this.show_loadingModal(false);
            alert('something went wrong');
            return;
          });


      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });

    // console.log('fddata url :', url);
    // console.log('fddata :', this.fdData);




    // url = 'get_MonthFDMonthlyRec/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.baranchwise_fdData = data;
    // });
  }
  clear_Data() {
    this.labourData = [];
    this.brdwise_labData = [];
    this.branchwise_labData = [];
    this.estData = [];
    this.branchwise_estData = [];
    this.brdwise_estData = [];
    this.estData = [];
    this.brdwise_wlData = [];
    this.branchwise_wlData = [];
    this.fdData = [];
    this.brdwise_fdData = [];
    this.baranchwise_fdData = [];
  }

  findDetails(data) {
    if (this.filterBoradID == 0) {
      if (this.dasbordfor == 'Labour') {
        return this.brdwise_labData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'Establishment') {
        return this.brdwise_estData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'WagesLevy') {
        return this.brdwise_wlData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'FD') {
        return this.brdwise_fdData.filter(x => x.month === data.month);
      }
    }
    else {
      if (this.dasbordfor == 'Labour') {
        return this.branchwise_labData.filter(x => x.month === data.month);
      }
      if (this.dasbordfor == 'NewApplLabour') {
        return this.filter_newApll_labData.filter(x => x.month === data.month);
      }
      //
      else if (this.dasbordfor == 'Establishment') {
        return this.branchwise_estData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'WagesLevy') {
        return this.branchwise_wlData.filter(x => x.month === data.month);
      }
      else if (this.dasbordfor == 'FD') {
        return this.baranchwise_fdData.filter(x => x.month === data.month);
      }
    }

  }

  getTotal(data, fldname) {
    if (fldname == 'newResigstration') {
      return data.reduce((sum, curr) => sum + curr.newResigstration, 0);
    }
    else if (fldname == 'newApplication') {
      return data.reduce((sum, curr) => sum + curr.newApplication, 0);
    }
    else if (fldname == 'resigned') {
      return data.reduce((sum, curr) => sum + curr.resigned, 0);
    }
    else if (fldname == 'death') {
      return data.reduce((sum, curr) => sum + curr.death, 0);
    }
    else if (fldname == 'cancellation') {
      return data.reduce((sum, curr) => sum + curr.cancellation, 0);
    }
    else if (fldname == 'estnewRegistration') {
      return data.reduce((sum, curr) => sum + curr.newRegistration, 0);
    }
    else if (fldname == 'preCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.preCollectionAmt, 0);
    }
    else if (fldname == 'penaltyCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.penaltyCollectionAmt, 0);
    }
    else if (fldname == 'pendingCollection') {
      return data.reduce((sum, curr) => sum + curr.pendingCollection, 0);
    }
    else if (fldname == 'wagesCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.wagesCollectionAmt, 0);
    }
    else if (fldname == 'levyCollectionAmt') {
      return data.reduce((sum, curr) => sum + curr.levyCollectionAmt, 0);
    }
    else if (fldname == 'newFDAmt') {
      return data.reduce((sum, curr) => sum + curr.newFDAmt, 0);
    }
    else if (fldname == 'renewalFDAmt') {
      return data.reduce((sum, curr) => sum + curr.renewalFDAmt, 0);
    }
    else if (fldname == 'withdrwalFDAmt') {
      return data.reduce((sum, curr) => sum + curr.withdrwalFDAmt, 0);
    }
    else {
      return 0;
    }
  }
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  show_newLabApplDt(mobno){
    let parameters = {
      labID: 0,
      mob: mobno,
      boardID:this.filterBoradID
     };

     if (this.filterBoradID == 6) {
      this.router.navigate(['reg-lab-pimmatha'], { queryParams: parameters });
      
    }
    else{
      alert('काम प्रगतीपथावर आहे.लवकरच कार्यरत होईल.');
    }
  }
  show_newEstApplDt(mobno){
    let parameters = {
      estID: 0,
      mob: mobno,
      boardID:this.filterBoradID
     };

   if (this.filterBoradID == 6) {
      this.router.navigate(['reg-est-pimmatha'], { queryParams: parameters });
      
    }
    else{
      alert('काम प्रगतीपथावर आहे.लवकरच कार्यरत होईल.');
    }
  }

}
