import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import '@angular/localize/init';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { SliderComponent } from './home/slider/slider.component';
import { PopularhelptopicsComponent } from './home/popularhelptopics/popularhelptopics.component';
import { ImportantdatesComponent } from './home/importantdates/importantdates.component';
import { NewsUpdatesEventsComponent } from './home/news-updates-events/news-updates-events.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagenotfoundcomponentComponent } from './pagenotfoundcomponent/pagenotfoundcomponent.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http'; 
import { UserService   } from './service/UserService';
import { Globals } from './Globals';
import { LabourDashboardComponent } from './dashboard/labour-dashboard/labour-dashboard.component';
import { LoginComponent } from './login/login.component';
import { SelloginComponent } from './login/sellogin/sellogin.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgotpasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { VerifiedcodeComponent } from './login/verifiedcode/verifiedcode.component';
import { ResetpasswordComponent } from './login/resetpassword/resetpassword.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule }   from '@angular/forms';
import { ClerkDahsboardComponent } from './dashboard/clerk-dahsboard/clerk-dahsboard.component';

import { NgxPaginationModule } from 'ngx-pagination'

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { WlReceiptRmathaComponent } from './receipt/wl-receipt-rmatha/wl-receipt-rmatha.component';

import { HighlightDirective } from './highlight.directive';
import { FilterPipe } from './filter.pipe';
import { LabPaymentRmathaComponent } from './payment/lab-payment-rmatha/lab-payment-rmatha.component';
import { LabPaidbankListRmathaComponent } from './paidbankList/lab-paidbank-list-rmatha/lab-paidbank-list-rmatha.component';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { RptLabpaymentsComponent } from './reports/rpt-labpayments/rpt-labpayments.component';
import { reportsComponent } from './Reports-Menu/reports.component';
import { InscompDashboardComponent } from './dashboard/inscomp-Dashboard/inscomp-dashboard/inscomp-dashboard.component';
import { PatcompDashboardComponent } from './dashboard/patcomp-Dashboard/patcomp-dashboard/patcomp-dashboard.component';
import { UnioncompDashboardComponent } from './dashboard/unioncomp-dashboard/unioncomp-dashboard.component';
import { WlReceiptPimmathaComponent } from './receipt/wl-receipt-pimmatha/wl-receipt-pimmatha.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LabPaymentpimmathaComponent } from './payment/pimmatha/lab-payment-pimmatha/lab-payment-pimmatha.component';
import { SetUserauthComponent } from './admin/set-userauth/set-userauth.component';
import { NgxPrintModule } from 'ngx-print';
import { WlRecrptComponent } from './Reports/wl-recrpt/wl-recrpt.component';
import { AmountInWordsPipe } from "./amount-in-words.pipe";
import { RegLabPimmathaComponent } from './registration/reg-lab-pimmatha/reg-lab-pimmatha.component';
import { RegEstNskmathaComponent } from './registration/reg-est-nskmatha/reg-est-nskmatha.component';
import { LabLoginComponent } from './login/lab-login/lab-login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DownloadListComponent } from './download-list/download-list.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    PopularhelptopicsComponent,
    ImportantdatesComponent,
    NewsUpdatesEventsComponent,
    DashboardComponent,
    PagenotfoundcomponentComponent,
    LabourDashboardComponent,
    LoginComponent,
    ForgotpasswordComponent,
    VerifiedcodeComponent,
    ResetpasswordComponent,
    SelloginComponent,
    ClerkDahsboardComponent,
    WlReceiptRmathaComponent,
    HighlightDirective,
    FilterPipe,
    LabPaymentRmathaComponent,
    LabPaidbankListRmathaComponent,
    RptLabpaymentsComponent,
    reportsComponent,
    InscompDashboardComponent,
    PatcompDashboardComponent,
    UnioncompDashboardComponent,
    WlReceiptPimmathaComponent,
    LabPaymentpimmathaComponent,
    SetUserauthComponent,
    WlRecrptComponent,
    AmountInWordsPipe,
    RegLabPimmathaComponent,
    RegEstNskmathaComponent,
    LabLoginComponent,
    PrivacyPolicyComponent,
    DownloadListComponent,
    
  ],
  imports: [
  BrowserModule,
  NgbModule,
  BrowserModule,
  AppRoutingModule,
  HttpModule,
  FormsModule,
  NgxUiLoaderModule,
  ReactiveFormsModule,
  HttpClientModule,
  BrowserAnimationsModule,
  NgxPaginationModule,
  MDBBootstrapModule.forRoot(),
  NgxPrintModule
  ],
 
  providers: [UserService,Globals,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
