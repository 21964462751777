import { Component, OnInit } from '@angular/core';
import { Globals } from '../../Globals'

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {




  constructor(public globals: Globals) { }
  boardName:string= '';
  ngOnInit() {
    if (this.globals.BaseURL == 'https://webapi.mathadi.in/'){
     // this.boardName= 'Mathadi And Unprotected Labour Board';
     this.boardName = this.globals.boardName
      //
    }
    else{
      this.boardName = 'Testing Application';
    }
  }

  

}
