<div id="footer">
<div class="container">
<div class="row">
<div class="col-sm-6 col-md-3 contact-information">
<div class="title" >contact Us</div>
<!-- <p class="contact-cell">
Help Desk Number: <br>
<span><a href="tel:01204888999">0120-4888999</a></span>
</p> -->
<p class="log-track">
Click here to:<br>
<span>Register & track
your Complaint</span> 
</p>
</div>
<div class="col-sm-6 col-md-3">
<div class="title">Quick Links</div>
<ul>
<li><a (click)="view_PdfFile('ACT_Rules.pdf')">Act & Rules</a></li>	
<li><a href="javascript:void(0)">Minimum Wages</a></li>
<li><a href="javascript:void(0)">Government Resolutions</a></li>
<li><a href="javascript:void(0)">Forms & Downloads</a></li>
</ul>
</div>
<div class="col-sm-6 col-md-3 border-none">
<!-- <div class="title">Related Sites</div> -->
<ul>
<li><a href="javascript:void(0)">Registered Labours</a></li>	
<li><a href="javascript:void(0)">Notifications</a></li>
<li><a href="javascript:void(0)">Circulars</a></li>
<li><a href="javascript:void(0)">Tenders</a></li>
</ul>
</div>
<div class="col-sm-6 col-md-3 border-none">
<div class="title">Help</div>
<ul>
<li><a href="javascript:void(0)">System Requirements</a></li>	
<li><a href="javascript:void(0)">User Manuals, Videos and FAQs</a></li>
<!-- <li><a href="javascript:void(0)">Media</a></li> -->
<li><a href="javascript:void(0)">Site Map</a></li>
<li><a href="javascript:void(0)" (click)="got_page()">Privacy Policy</a></li>
<!-- <li><a href="javascript:void(0)">Grievance Nodal Officers</a></li> -->
</ul>
</div>

</div>
</div>

<!-- <div style="text-align: center;"> 
    <img src="../../assets/images/google-play.png" href="javascript:void(0)" style="height: 150px;width: 150px;" alt="GET IT ON GOOGLE PLAY">
    
</div> -->


    <!-- <div style="text-align: center;"> <a href="https://play.google.com/console/u/0/developers/7323344779863400280/app/4972257768264580269/policy-center" routerLinkActive="active" ><img src="../../assets/images/google-play.png" style="height: 150px;width: 150px;" alt="GET IT ON GOOGLE PLAY" title="GET IT ON GOOGLE PLAY" class="float-center img-fluid logo"></a> -->

        <div style="text-align: center;"> <a href="https://play.google.com/store/apps/details?id=com.mahamathadi.app&pli=1" routerLinkActive="active" ><img src="../../assets/images/google-play.png" style="height: 150px;width: 150px;" alt="GET IT ON GOOGLE PLAY" title="GET IT ON GOOGLE PLAY" class="float-center img-fluid logo"></a>
     
     
    </div>
  
    <div class="clearfix"></div>
  

<div class="col-sm-12 copyright">
<div class="container">
<div class="pull-left">&copy; 2018-20 <span>Mathadi And Unprotected Labour Board.</span> Designed & Developed by KIT</div>

<div class="pull-right">Site Last Updated on <span>12-02-2020</span></div>
<div class="clearfix"></div>
</div>
</div>	


</div>

