<ngb-carousel>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="assets/images/slider/slider1.jpg" alt="Random first slide">
    </div>
    <div class="carousel-caption">
      <!-- <h3>Mathadi And <br>Unprotected Labour Board</h3>       -->
      <h3>{{boardName}}</h3>
      
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="assets/images/slider/slider2.jpg" alt="Random second slide">
    </div>
    <div class="carousel-caption">
      <!-- <h3>Lorem Ipsum<br>been the industry's</h3>        -->
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
</a>
  </ng-template>
</ngb-carousel>