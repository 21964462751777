//#region imports
import { Component, OnInit } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
//#endregion

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [NgbAccordionConfig] // add the NgbAccordionConfig to the component providers
})
export class DashboardComponent implements OnInit {
  public YrList: any = [];
  public MonthList: any = [];
  public boardlist: any = [];
  public labourData: any = [];
  public filterFayear;
  public filterYr: number = 0;
  public filterMonth: number = 0;
  public filterBoradID: number = 0;
  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public filtersubDivID: number = 0;
  public Log_accessFor: string = '';
  public UserType: string = '';
  public Applstatus: string = '';

//#region constructor
 constructor(config: NgbAccordionConfig
    , private _userService: UserService
    , public router: Router
    , private route: ActivatedRoute,
  ) {
    config.closeOthers = true;
    config.type = 'info';

    // this.filterBoradID = Number(this.route.snapshot.queryParamMap.get('BoradID'));
    // this.filterMonth = Number(this.route.snapshot.queryParamMap.get('month'));
    // this.filterYr = Number(this.route.snapshot.queryParamMap.get('year'));
    // this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));

    if (sessionStorage.getItem('mathaLog_accessFor') != null && sessionStorage.getItem('mathaLog_accessFor') != '') {
      this.Log_accessFor = sessionStorage.getItem('mathaLog_accessFor');
    }
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));

    }
    if (sessionStorage.getItem('mathafilterBoradID') != null && sessionStorage.getItem('mathafilterBoradID') != '') {
      this.filterBoradID = Number(sessionStorage.getItem('mathafilterBoradID'));
    }
    if (sessionStorage.getItem('mathafilterMonth') != null && sessionStorage.getItem('mathafilterMonth') != '') {
      this.filterMonth = Number(sessionStorage.getItem('mathafilterMonth'));
      //alert(this.filterMonth);
    }
    if (sessionStorage.getItem('mathafilterYr') != null && sessionStorage.getItem('mathafilterYr') != '') {
      this.filterYr = Number(sessionStorage.getItem('mathafilterYr'));

    }
    if (sessionStorage.getItem('mathafiltersubDivID') != null && sessionStorage.getItem('mathafiltersubDivID') != '') {
      this.filtersubDivID = Number(sessionStorage.getItem('mathafiltersubDivID'));
    }

    if (sessionStorage.getItem('mathaLog_UserType') != null && sessionStorage.getItem('mathaLog_UserType') != '') {
      this.UserType = sessionStorage.getItem('mathaLog_UserType');
    }

    if (sessionStorage.getItem('mathafilterFayear') != null && sessionStorage.getItem('mathafilterFayear') != '') {
      this.filterFayear = Number(sessionStorage.getItem('mathafilterFayear'));
    }
    else {
      let tddate = new Date();
      let mon = tddate.getMonth() + 1;
      if (mon < 4) {
        this.filterFayear = Number(tddate.getFullYear()) - 1;
      }
      else {
        this.filterFayear = tddate.getFullYear();
      }
    }

    //console.log(this.UserType)


    // usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector'

  }
//#endregion
 
//#region ngOnit
  
  ngOnInit(): void {
    if (this.UserType == 'Inspector') {
      this.Applstatus = 'Pending'
    }
    else if (this.UserType == 'Secretary') {
      this.Applstatus = 'Inspector Approve'
    }
    else if (this.UserType == 'Chairman') {
      this.Applstatus = 'Secretary Approve'
    }
    else if (this.UserType == 'HO') {
      this.Applstatus = 'NA'
    }

    this.fill_FinYr();
    this.Get_AllBoard();
    this.fill_Data();
  }

  //#endregion
  
//#region fill Fayear
  fill_FinYr() {
    this.YrList = [];
    this._userService.get_Dashborad_Data('getFayear').subscribe(
      (data) => {
        this.YrList = data;
        if (this.filterFayear == 0) {
          this.fill_Month(this.YrList[data.length - 1]['fayear']);
        }
        else {
          this.fill_Month(this.filterFayear);
        }
      });

    let tddate = new Date();
    let mon = tddate.getMonth() + 1;
    if (this.filterFayear == 0) {
      if (mon < 4) {
        this.filterFayear = Number(tddate.getFullYear()) - 1;
      }
      else {
        this.filterFayear = tddate.getFullYear();
      }
    }
  }
  Set_Fayear(event) {
    this.clear_Data();
    this.fill_Month(event.target.value);
    this.filterFayear = event.target.value;
    this.fill_Data();
  }
  Set_FiterYrMon(event) {
    this.clear_Data();
    this.filterMonth = Number(event.target.value);
    if (Number(this.filterMonth) < 4) {
      this.filterYr = this.filterFayear + 1
    }
    else {
      this.filterYr = this.filterFayear
    }
    if (Number(this.filterMonth) == 0) {
      this.filterYr = 0
    }

    this.fill_Data();
  }
  Set_FilterBoardID(event) {
    this.clear_Data();
    this.filterBoradID = Number(event.target.value);
    this.fill_Data();
  }
  //#endregion

//#region fill all board
  Get_AllBoard() {
    //this.clear_Data();
    this.show_loadingModal(true);
    this._userService.get_Dashborad_Data('getallboardname').subscribe(
      (data) => {
        if (this.Log_BoardID > 0) {
          this.boardlist = data.filter(x => x.boardID == Number(this.Log_BoardID) && x.boardID != 101);
        }
        else {
          this.boardlist = data.filter(x => x.boardID != 101);
        }

        //only PimMatha Working
        this.boardlist = this.boardlist.filter(x => x.boardID == 6);

      });
    this.show_loadingModal(false);
  }
//#endregion

//#region fill month
fill_Month(Yr) {
    //this.clear_Data();
    this.MonthList = [];
    this.MonthList = [
      { mon: 4, yr: Yr, dispmon: "Apr-" + Yr },
      { mon: 5, yr: Yr, dispmon: "May-" + Yr },
      { mon: 6, yr: Yr, dispmon: "Jun-" + Yr },
      { mon: 7, yr: Yr, dispmon: "Jul-" + Yr },
      { mon: 8, yr: Yr, dispmon: "Aug-" + Yr },
      { mon: 9, yr: Yr, dispmon: "Sep-" + Yr },
      { mon: 10, yr: Yr, dispmon: "Oct-" + Yr },
      { mon: 11, yr: Yr, dispmon: "Nov-" + Yr },
      { mon: 12, yr: Yr, dispmon: "Dec-" + Yr },
      { mon: 1, yr: Yr + 1, dispmon: "Jan-" + (Number(Yr) + 1) },
      { mon: 2, yr: Yr + 1, dispmon: "Feb-" + (Number(Yr) + 1) },
      { mon: 3, yr: Yr + 1, dispmon: "Mar-" + (Number(Yr) + 1) },
    ];
    // this.filterMonth = 0;
    //  this.filterYr = 0;

  }
//#endregion
 
//#region fill data
  fill_Data() {
    sessionStorage.setItem('filterBoradID', this.filterBoradID.toString());
    sessionStorage.setItem('filterMonth', this.filterMonth.toString());
    sessionStorage.setItem('filterYr', this.filterYr.toString());
    sessionStorage.setItem('filterFayear', this.filterFayear.toString());


    //this.clear_Data();
    this.get_LabourDetails();

  }
//#endregion

//#region fill labDetails
    newApplication;
    newResigstration;
    resigned;
    death;
    cancellation;
    public get_LabourDetails() {
      this.show_loadingModal(true);
      this.labourData = [];
      this.newApplication = '0';
      this.newResigstration = '0';
      this.resigned = '0';
      this.death = '0';
      this.cancellation = '0';
      let boardID: Number = 0;


      //alert(this.Applstatus);
      let url: string = 'getLabourDetails/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID + '/' + this.Applstatus;
      console.log(url);
      this._userService.get_Dashborad_Data(url).subscribe(
        (data) => {
          // console.log('LabourDetails ',data)
          this.labourData = data;
          //this.labourData = data.clsLabourMonthly;

          this.newApplication = this.labourData[0]['newApplication'];
          this.newResigstration = this.labourData[0]['newResigstration'];
          this.resigned = this.labourData[0]['resigned'];
          this.death = this.labourData[0]['death'];
          this.cancellation = this.labourData[0]['cancellation'];
          this.show_loadingModal(false);

          this.get_EstDetails();

        },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          alert('something went wrong');
          return;
        });

    }
//#endregion
 
//#region fill estDetails
 estData: any = [];
  estnewApplication;
  estnewResigstration;
  preCollectionAmt;
  penaltyCollectionAmt;
  pendingCollection;
  public get_EstDetails() {
    this.show_loadingModal(true);
    this.estData = [];
    let url: string = 'getEstcollection/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID + '/' + this.Applstatus;
    //
    this.estnewApplication = '0';
    this.estnewResigstration = '0';
    this.preCollectionAmt = '0';
    this.penaltyCollectionAmt = '0';
    this.pendingCollection = '0';

    this.wagesCollectionAmt = '0';
    this.levyCollectionAmt = '0';

    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        this.estData = data;
        //console.log('this.estData',this.estData);
        this.estnewApplication = this.estData[0]['estnewApplication'];
        this.estnewResigstration = this.estData[0]['newRegistration'];
        this.preCollectionAmt = this.estData[0]['preCollectionAmt'];
        this.penaltyCollectionAmt = this.estData[0]['penaltyCollectionAmt'];
        this.pendingCollection = this.estData[0]['pendingCollection'];

        this.wagesCollectionAmt = this.estData[0]['wagesCollectionAmt'];
        this.levyCollectionAmt = this.estData[0]['levyCollectionAmt'];
        this.show_loadingModal(false);
        this.get_FDDetails();
        //
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });
  }
//#endregion

//#region fill wlDetails
  wlData: any = [];
  wagesCollectionAmt;
  levyCollectionAmt;

  public get_WLDetails() {
    //     this.estData = [];
    //     let url: string = 'getMonthlyWageLevyDetails/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    // //
    //     this.wagesCollectionAmt = '0';
    //     this.levyCollectionAmt = '0';
    //     this._userService.get_Dashborad_Data(url).subscribe(
    //       (data) => {
    //         this.wlData = data;
    //         this.wagesCollectionAmt = this.wlData[0]['wagesCollectionAmt'];
    //         this.levyCollectionAmt = this.wlData[0]['levyCollectionAmt'];
    //         //
    //       },
    //       (err: HttpErrorResponse) => {
    //         this.show_loadingModal(false);
    //         alert('something went wrong');
    //         return;
    //       });
  }
//#endregion
  
//#region fill fdDetails
fdData: any = [];
  newFDAmt;
  renewalFDAmt;
  withdrwalFDAmt;
  public get_FDDetails() {
    this.show_loadingModal(true);
    this.fdData = [];
    let url: string = 'getFDDetails/' + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID;
    //
    this.newFDAmt = '0';
    this.renewalFDAmt = '0';
    this.withdrwalFDAmt = '0';
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        this.fdData = data;
        this.newFDAmt = data[0]['newFDAmt'];
        this.renewalFDAmt = data[0]['renewalFDAmt'];
        this.withdrwalFDAmt = data[0]['withdrwalFDAmt'];
        this.show_loadingModal(false);
        //
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('something went wrong');
        return;
      });
  }
//#endregion

//#region clear data
clear_Data() {
    this.newResigstration = '';
    this.resigned = '';
    this.death = '';
    this.cancellation = '';
    this.estnewResigstration = '';
    this.preCollectionAmt = '';
    this.penaltyCollectionAmt = '';
    this.pendingCollection = '';
    this.wagesCollectionAmt = '';
    this.levyCollectionAmt = '';
    this.newFDAmt = '';
    this.renewalFDAmt = '';
    this.withdrwalFDAmt = '';
  }
//#endregion
  
//#region goTo dashboard
public goto_Dtdashboard(dasbordfor) {
    // sessionStorage.setItem('filterBoradID', this.filterBoradID.toString());
    // sessionStorage.setItem('filterMonth', this.filterMonth.toString());
    // sessionStorage.setItem('filterYr', this.filterYr.toString());
    // sessionStorage.setItem('filterFayear', this.filterFayear.toString());


    //*** */ ho could not show new application details as per niket sir 25Aug23 ***

    if (this.UserType.toString().toLocaleLowerCase() == 'ho' && dasbordfor == 'LabourAppl') {
      dasbordfor = 'Labour'
    }
    else if (this.UserType.toString().toLocaleLowerCase() == 'ho' && dasbordfor == 'EstAppl') {
      dasbordfor = 'Establishment'
    }

    ///**** */

    sessionStorage.setItem('mathaLog_BoardID', this.filterBoradID.toString());
    sessionStorage.setItem('mathafilterMonth', this.filterMonth.toString());
    sessionStorage.setItem('mathafilterYr', this.filterYr.toString());
    sessionStorage.setItem('mathafilterFayear', this.filterFayear.toString());
    sessionStorage.setItem('matha_labdashbrd', "yes");

    let parameters = {
      BoradID: this.filterBoradID,
      month: this.filterMonth,
      year: this.filterYr,
      fayear: this.filterFayear,
      dasbordfor: dasbordfor
    };
    this.router.navigate(['dashboard/labour-dashboard'], { queryParams: parameters });

  }
//#endregion
  
//#region show loading
 showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
//#endregion
 
}
