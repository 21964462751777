<div class="container dashboardmain">
<div class="row">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
    <li class="breadcrumb-item"> <a href="javascript:void(0)" routerLink="../">Head Office Dashboard</a></li>
    <!-- (click)="go_dashboard()" -->
    <li class="breadcrumb-item active" aria-current="page">{{dasbordfor}}</li>
  </ol>
</nav>
</div>
<form class="search">
<div class="row">
    <div class="col-md-3">
    <label for="Financial-Year">Financial Year</label>
    <select (change) ="Set_Fayear($event)"  class="form-control select-t" id="Financial-Year">
      <option *ngFor='let yr of YrList' [value]="yr.fayear" [selected]="yr.fayear=== filterFayear" >{{yr.displayYear}} </option>
       <!-- [selected]="yr.fayear=== filterFayear" -->
  </select>
  </div>
  <div class="col-md-3">
    <label for="month-year">Month - Year</label>
    <select (change) ="Set_FiterYrMon($event)" class="form-control select-t" id="month-year">
      <option  [value] = '0'>All</option>
     <option *ngFor='let mon of MonthList' [value]='mon.mon' [selected]="mon.mon=== filterMonth">{{mon.dispmon}}</option>
  </select>
  </div>
  <div class="col-md-6">
    <label for="board-name" class="board-name-label">Board Name</label>
    <select (change) ="Set_FilterBoardID($event)" class="form-control select-t board-name" id="board-name">
      <option *ngIf="UserType == 'HO'" [value] = '0'>All</option>
     <option *ngFor ='let brd of boardlist' [value] = 'brd.boardID'  [selected]="brd.boardID=== filterBoradID">{{brd.boardName}}</option>
  </select>
    <div class="search-bt" (click)="fill_Data();"><i class="fa fa-refresh" aria-hidden="true"></i></div>
  </div>
</div>
</form>

<!-- waiting modal -->

<div class="container">

  <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body">

          <div style="text-align: center;">

            Please wait ...
            <div class="spinner-grow text-success"></div>
            <div class="spinner-grow text-info"></div>
            <div class="spinner-grow text-warning"></div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

<!-- waiting modal -->


<table *ngIf="dasbordfor == 'Labour'" class="table table-hover table-responsive-xl labour-table">
	<thead>
		<th>Months Report </th>
		<th>New Application Pending </th>
		<th>New Registration </th>
		<th>Resigned</th>
		<th>Death</th>
		<th>Cancelled</th>
		<!-- <th>Month Closing</th> -->
	</thead>
	<tbody>

<ng-container *ngFor="let data of labourData">
  <tr  class="sub-tr">
    <td (click)="data.expanded = !data.expanded"> <div *ngIf="filterBoradID == 0"  [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div> {{data.monthYearName}}</td> 
    <!-- <td (click)="data.expanded = !data.expanded">
       <div *ngIf="filterBoradID > 0 && data.newApplication > 0 && UserType != 'HO'"  [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div>
       {{data.newApplication}}  
      <span><i *ngIf="data.newApplication > 0 && UserType != 'HO'"
      class="fas fa-edit" ngbTooltip="View List" aria-hidden="true"
      style="color: blue;"
      (click)="show_newLabApplDt()" ></i></span>
     </td>  -->
    <td>{{data.newApplication}}</td> 
    <td>{{data.newResigstration}}</td>
    <td>{{data.resigned}}</td>
    <td>{{data.death}}</td>
    <td>{{data.cancellation}}</td>
    
  </tr>
  <ng-container *ngIf="data.expanded && filterBoradID == 0">
    <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
      <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
      <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
      <td> {{data.newApplication}} </td>
      <td> {{data.newResigstration}} </td>
      <td> {{data.resigned}} </td>
      <td> {{data.death}} </td>
      <td> {{data.cancellation}} </td>
      <!-- <td> {{data.opnBal + data.newResigstration - data.resigned - data.death - data.cancellation}} </td>      -->
     </tr>
  </ng-container>

  
</ng-container>
	</tbody>
  <tfoot>
    <tr Class="table-footer">
      <td>Total</td>
      <td>{{getTotal(labourData,'newApplication')}}</td>
      <td>{{getTotal(labourData,'newResigstration')}}</td>
      <td>{{getTotal(labourData,'resigned')}}</td>
      <td>{{getTotal(labourData,'death')}}</td>
      <td>{{getTotal(labourData,'cancellation')}}</td>
      <!-- <td>-</td> -->
    </tr>
  </tfoot>	
</table>

<table *ngIf="dasbordfor == 'LabourAppl'" class="table table-hover table-responsive-xl labour-table">
	<thead>
		<th>Application Date </th>
		<th>Name </th>
		<th>Address </th>
		<th>Mobile Number</th>
    <th>View Application</th>
	</thead>
	<tbody>

<ng-container *ngFor="let data of filter_newApll_labData">
  <tr  class="sub-tr">
    <td>{{data.labRegdt | date: 'dd/MM/yyyy'}}</td> 
    <td>{{data.labName}}</td>
    <td>{{data.resiAdd1}}</td>
    <td>{{data.mobNo}}</td>
    <td><i class="fas fa-eye" ngbTooltip="View List" aria-hidden="true"
      style="color: blue;"
      (click)="show_newLabApplDt(data.mobNo)" ></i></td>
  </tr>
  
</ng-container>
	</tbody>
 	
</table>

<table *ngIf="dasbordfor == 'EstAppl'" class="table table-hover table-responsive-xl labour-table">
	<thead>
		<th>Application Date </th>
		<th>Name </th>
		<th>Address </th>
		<th>Mobile Number</th>
    <th>View Application</th>
	</thead>
	<tbody>

<ng-container *ngFor="let data of filter_newApll_estData">
  <tr  class="sub-tr">
    <td>{{data.estRegdt | date: 'dd/MM/yyyy'}}</td> 
    <td>{{data.estName}}</td>
    <td>{{data.estAdd1}}</td>
    <td>{{data.mobNo}}</td>
    <td><i class="fas fa-eye" ngbTooltip="View List" aria-hidden="true"
      style="color: blue;"
      (click)="show_newEstApplDt(data.mobNo)" ></i></td>
  </tr>
  
</ng-container>
	</tbody>
 	
</table>

<table *ngIf="dasbordfor == 'Establishment'" class="table table-hover table-responsive-xl labour-table">
	<thead>
   <th>Months Report </th>
    <!--  <th>Opening Registration </th> -->
    <th>New Registration </th>
		<th>Collection Amount </th>
		<th>Penalty Collection</th>
		<th>No Collection</th>
		
	</thead>
	<tbody>

<ng-container *ngFor="let data of estData">
  <tr (click)="data.expanded = !data.expanded" class="sub-tr">
    <td> <div [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div> {{data.monthYearName}}</td> 
    <!-- <td> {{data.opnRegistrationBal}} </td> -->
    <td> {{data.newRegistration}} </td>
    <td> {{data.preCollectionAmt}} </td>
    <td> {{data.penaltyCollectionAmt}} </td>
    <td> {{data.pendingCollection}} </td>
    
  </tr>
  <ng-container *ngIf="data.expanded">
    <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
      <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
      <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
      <!-- <td> {{data.opnRegistrationBal}} </td> -->
      <td> {{data.newRegistration}} </td>
      <td> {{data.preCollectionAmt}} </td>
      <td> {{data.penaltyCollectionAmt}} </td>
      <td> {{data.pendingCollection}} </td>
      
     </tr>
  </ng-container>
</ng-container>
	</tbody>
  <tfoot>
    <tr Class="table-footer">
      <td>Total</td>
      <!-- <td>-</td> -->
      
      <td>{{getTotal(estData,'estnewRegistration')}}</td>
      <td>{{getTotal(estData,'preCollectionAmt')}}</td>
      <td>{{getTotal(estData,'penaltyCollectionAmt')}}</td>
      <td>-</td>
      <!-- {{getTotal(estData,'pendingCollection')}} -->
    
    </tr>
  </tfoot>	
</table>

<table *ngIf="dasbordfor == 'WagesLevy'" class="table table-hover table-responsive-xl labour-table">
	<thead>
    <th>Months Report </th>
    <th>Wages Collection </th>
		<th>Levy Collection </th>
			
	</thead>
	<tbody>

<ng-container *ngFor="let data of wlData">
  <tr (click)="data.expanded = !data.expanded" class="sub-tr">
    <td> <div [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div> {{data.monthYearName}}</td> 
    <td> {{data.wagesCollectionAmt}} </td>
    <td> {{data.levyCollectionAmt}} </td>
   
    
  </tr>
  <ng-container *ngIf="data.expanded">
    <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
      <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
      <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
      <td> {{data.wagesCollectionAmt}} </td>
      <td> {{data.levyCollectionAmt}} </td>
     
      
     </tr>
  </ng-container>
</ng-container>
	</tbody>
  <tfoot>
    <tr Class="table-footer">
      <td>Total</td>
      <td>{{getTotal(wlData,'wagesCollectionAmt')}}</td>
      <td>{{getTotal(wlData,'levyCollectionAmt')}}</td>
    </tr>
  </tfoot>	
</table>

<table *ngIf="dasbordfor == 'FD'" class="table table-hover table-responsive-xl labour-table">
	<thead>
    <th>Months Report </th>
    <th>New F.D.’S </th>
		<th>Renewal F.D.’S </th>
    <th>Withdrawal F.D.’S </th>
	</thead>
	<tbody>

<ng-container *ngFor="let data of fdData">
  <tr (click)="data.expanded = !data.expanded" class="sub-tr">
    <td> <div [className]="data.expanded ? 'fa fa-minus' : 'fa fa-plus'"></div> {{data.monthYearName}}</td> 
    <td> {{data.newFDAmt}} </td>
    <td> {{data.renewalFDAmt}} </td>
    <td> {{data.withdrwalFDAmt}} </td>
   
    
  </tr>
  <ng-container *ngIf="data.expanded">
    <tr *ngFor="let data of findDetails(data)" Class="sub-sub-tr">
      <td *ngIf="filterBoradID == 0"> {{data.boardName}} </td>
      <td *ngIf="filterBoradID > 0"> {{data.branchName}} </td>
      <td> {{data.newFDAmt}} </td>
      <td> {{data.renewalFDAmt}} </td>
      <td> {{data.withdrwalFDAmt}} </td>
     
      
     </tr>
  </ng-container>
</ng-container>
	</tbody>
  <tfoot>
    <tr Class="table-footer">
      <td>Total</td>
      <td>{{getTotal(fdData,'newFDAmt')}}</td>
      <td>{{getTotal(fdData,'renewalFDAmt')}}</td>
      <td>{{getTotal(fdData,'withdrwalFDAmt')}}</td>
    </tr>
  </tfoot>	
</table>

</div>