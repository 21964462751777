import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Globals } from './Globals'
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  //title = 'Mathadi And Unprotected Labour Board';
  constructor(public router: Router, private titleService: Title,
    private globals: Globals,) {}

  ngOnInit() {
    if (this.globals.BaseURL == 'https://webapi.pcmcmathadi.in:9443/'){
      this.titleService.setTitle(this.globals.boardName);
      //
    }
    else{
      this.titleService.setTitle('Testing Application');
    }

    
  
  }
  goto_Page(pgName:string){
    if (pgName == 'lab'){
      this.router.navigate(['reg-lab-nskmatha']);
    }
    else if (pgName == 'est'){
      this.router.navigate(['reg-est-nskmatha']);
    }
    
    
  }


}
