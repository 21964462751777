import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-updates-events',
  templateUrl: './news-updates-events.component.html',
  styleUrls: ['./news-updates-events.component.css']
})
export class NewsUpdatesEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
