//#region imports
import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
//#endregion 

//#region companent
@Component({
  selector: 'app-rpt-labpayments',
  templateUrl: './rpt-labpayments.component.html',
  styleUrls: ['./rpt-labpayments.component.css'],
  providers: [NgbAccordionConfig]
})
//#endregion

export class RptLabpaymentsComponent implements OnInit {
  
  //#region  public variable
  search_rpt:string='';
  selected_rptID:string='';
  selected_rptName:string='';

  searchToli:string='';
  
  filter_wageYr:Number = 0;
  filter_wageMonth:Number = 0;
  filter_toliID:Number = 0;

  

  Log_UserID;
  Log_BoardID;
  userID;
  CompanyID = 1;

  filter_rpt:any=[];
  rptname_list:any=[];
  toli_list:any=[];

  frm_Date = new Date("2021-04-01").toISOString().substring(0, 10);
  to_Date = new Date("2022-03-31").toISOString().substring(0, 10);

  iway_Date = new Date("2021-04-30").toISOString().substring(0, 10);

  // frm_Date = new Date().toISOString().substring(0, 10);
  // to_Date = new Date().toISOString().substring(0, 10);

  // iway_Date = new Date().toISOString().substring(0, 10);

  //#endregion

  //#region constructor
  constructor(private calendar: NgbCalendar,
    config: NgbAccordionConfig,
    public http: Http,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef) { }

  //#endregion

  //#region ngOnit
  ngOnInit(): void {
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
    this.userID = this.Log_UserID;

    this.rptname_list = [{rptName:"Gatwise Payment Register",rptID:"1"}, {rptName:"Gatwise Paid Bank List", rptID:"2"},
    {rptName:"Gatwise Unpaid Bank List",rptID:"3"},{rptName:"Payment Slip",rptID:"4"},{rptName:"Iway Reports",rptID:"17"},
    {rptName:"Gatwise Pagar Bill",rptID:"3"},{rptName:"Yearly Payment Slip",rptID:"4"},{rptName:"Disbursement Reports",rptID:"5"},  
  ]
    this.filter_rpt = this.rptname_list;
  }
  //#endregion

  //#region search rpt
  search_rptName(){
    this.filter_rpt = [];
    this.selected_rptID = '';
    this.selected_rptName = '';
    this.clear_Data();
    
    this.filter_rpt = this.rptname_list.filter((item) => {
      return ((item.rptName.toString().toLowerCase()).indexOf(this.search_rpt.toString().toLowerCase()) > -1);
    });
  }
  set_searchRpt(rptID,rptName){
    this.selected_rptID = rptID;
    this.selected_rptName = rptName;
    if(rptID == 1){
      this.fill_toliList();
      
    }
  }
  //#endregion

//#region ToliList
filter_toli_list:any=[];
fill_toliList(){
  this.toli_list = [];
  this.filter_toli_list = [];
  this.toliInfo = '';
  this.searchToli = '';
  this.show_searchToli='no';
  let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/gettolilist/' + this.CompanyID;
      this._userService.get_Data(url).subscribe(
        (data) => {
          this.ngxService.stop();
          this.toli_list = data;
          //
          this.show_searchToli='yes';
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
  });
}
filter_toli(){
  this.filter_toliID = 0;
  this.toliInfo = '';
  this.filter_toli_list = [];
    this.filter_toli_list = this.toli_list.filter((item) => {
      return ((item.toliNo.toString() + item.mukName.toString()).indexOf(this.searchToli.toString().toLowerCase()) > -1);
    });
  
}
toliInfo:string='';
set_searchToli(toliID, toliNo, toliName) {
  this.filter_toliID = 0;
  this.toliInfo = '';
  this.searchToli = '';
  this.filter_toliID = toliID;
  this.toliInfo = toliNo.toString() + ' - ' + toliName;
  this.filter_toli_list = [];
  if(Number(this.selected_rptID) == 1){
    this.show_wagyrmonDt='yes';
    this.show_frmtoDt='no';
    this.show_selpayDt='yes';
  }
  
}
//#endregion

  //#region get RptData
  rptLabPaiddBankList:any=[];
  toli_unpaidList:any=[];
  TransBanklistID:Number=0;
  get_Data(rptID){
    this.toli_unpaidList = [];
    rptID = 17;
    if (Number(rptID) == 1){
      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlabpaywagereg';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 2){
      //paySlip 

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlabpayslip/1581';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else if (Number(rptID == 3)){
      this.ngxService.start();
      this.rptLabPaiddBankList=[];
      this.TransBanklistID = 42;
      let url = 'api/' + this._userService.get_paidbankList_controlerName(this.Log_BoardID) + '/rptgetlabpaidbank/' + this.TransBanklistID;
          this._userService.get_Data(url).subscribe(
            (data) => {
              this.rptLabPaiddBankList = data; 
              
              this.ngxService.stop();

        },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });
    }
    else  if (Number(rptID) == 4){
      //paySlip 

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/gettolipagarbill';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 5){
      //paySlip yearly 

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlabpayslipyearly/1581';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 6){
      //disbusment

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getdisburment';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 7){
      //getlistforitapplicable

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlistforitapplicable/200000';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 8){
      //getlabpaypaidchequedt

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlabpaypaidchequedt';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 9){
      //getlabunpaidlist
      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlabunpaidpayreg/' + this.CompanyID;
      
      this._userService.get_Data(url).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 10){
      //getauthpagarbill

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getauthpagarbill';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 11){
      //getlabpaynote

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;
//Payment,Resignation,NonRefAdv
      let flag:string="";

      flag ="Payment";

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlabpaynote/200/' + flag;
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 12){
      //getlevybifurgation

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;
//Payment,Resignation,NonRefAdv
      let flag:string="";

      flag ="Payment";

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlevybifurgation/2021/' + this.CompanyID;
      
      this._userService.get_Data(url).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 13){
      //getwlrecdt


      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getwlrecdt/3/' + this.CompanyID;
      
      this._userService.get_Data(url).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 14){
      //getwlrecreg

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getwlrecreg';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 15){
      //getwlrecreg

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getchqdocket/507';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 16){
      //getestledger

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

     


      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getestledger/88';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          
       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });


    }
    else  if (Number(rptID) == 17){
      //getiway

      // let filter: any = {
      //   "payDt": this.frm_Date,
      //   "Wageyr": this.filter_wageYr,
      //   "WageMon": this.filter_wageMonth,
      //   "ToliId": this.filter_toliID,
      //   "CompanyID": 1,
      //   "frmDt": this.frm_Date,
      //   "toDt": this.to_Date,
        
      // }

      //Test 

      
      var frmDt_Yr = new Date(this.frm_Date).getFullYear();
      var toDt_Yr = new Date(this.to_Date).getFullYear();
      var iwayDt_Yr = new Date(this.iway_Date).getFullYear();

      var frmDt_month = new Date(this.frm_Date).getMonth()+1;
      var toDt_month = new Date(this.to_Date).getMonth()+1;
      var iwayDt_month = new Date(this.iway_Date).getMonth()+1;

      var frmDt_day = new Date(this.frm_Date).getDate();
      var toDt_day = new Date(this.to_Date).getDate();

      var daysInMon = new Date(frmDt_Yr,frmDt_month,0).getDate();

      
      

      
      
      
      
      

      let filter: any = {
        "payDt": this.frm_Date,
        "Wageyr": 2021,
        "WageMon": 4,
        "ToliId": 20,
        "CompanyID": 1,
        "frmDt": this.frm_Date,
        "toDt": this.to_Date,
        
      }

      let body = new FormData();
      body = filter;

      this.ngxService.start();

      let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getmaxpaydate';
      
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();

          
          let max_payDt = new Date(data);

          var maxpayDt_Yr = new Date(max_payDt).getFullYear();
          var maxpayDt_month = new Date(max_payDt).getMonth()+1;

          if (Number(frmDt_Yr) != Number(toDt_Yr)){
            alert('Enter Period Between 1 Month.')
            return false
          }
          else if(Number(frmDt_month) != Number(toDt_month)){
            alert('Enter Period Between 1 Month.')
            return false
          }
          else if(Number(frmDt_day) != 1){
            alert('Enter From Date Period From Day 1.')
            return false
          }
          else if(Number(toDt_day) != Number(daysInMon)){
            alert('Enter End Date Day ' + daysInMon.toString())
            return false
          }
          else if(new Date(this.iway_Date) < new Date(max_payDt)){
            alert('Enter Iway Date >= ' + max_payDt)
            return false
          }
          else if(Number(iwayDt_month) != Number(maxpayDt_month)){
            alert('Enter Iway Date Between Selected Period.')
            return false
          }
          else if(Number(iwayDt_Yr) != Number(maxpayDt_Yr)){
            alert('Enter Iway Date Between Selected Period.')
            return false
          }
          else{

          url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getunatuhpaymentlist';
          this._userService.post_data(url, body).subscribe(
            (data) => {
              
              if (Number(data.length) > 0){
                this.toli_unpaidList = data;
                this.show_unpaidlist_modal();
              }
              else{
                    let filter: any = {
                      "payDt": this.iway_Date,
                      "Wageyr": 2021,
                      "WageMon": 4,
                      "ToliId": 20,
                      "CompanyID": 1,
                      "frmDt": this.frm_Date,
                      "toDt": this.to_Date,

                    }

                    let body = new FormData();
                    body = filter;

                    this.ngxService.start();

                    let url = 'api/' + this._userService.get_labpay_Reports_controlerName(this.Log_BoardID) + '/getlabpayiway';
                    
                    this._userService.post_data(url, body).subscribe(
                      (data) => {
                        this.ngxService.stop();
                        
                      },
                      (err: HttpErrorResponse) => {
                        this.ngxService.stop();
                        alert(err);
                      });
              }
            },
            (err: HttpErrorResponse) => {
              this.ngxService.stop();
              alert(err);
            });

          }

       },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });

      


      


    }
  }
  //#endregion

  //#region clear data
  show_frmtoDt='no';
  show_wagyrmonDt='no';
  show_searchToli='no';
  show_btngenratePdf='no';
  show_selpayDt='no';
  clear_Data(){
    this.show_btngenratePdf='no';
    this.show_frmtoDt='no';
    this.show_searchToli='no';
    this.show_wagyrmonDt='no';
    this.show_selpayDt='no';
  }
  //#endregion
  // #region keyPress event only numbers
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  // #endregion

  //#region  set Frm To Date
  set_frmDate(event) {
    this.frm_Date = event.target.value;
    
  }
  set_toDate(event) {
    this.to_Date = event.target.value;
    
  }
  set_iwayDate(event) {
    this.iway_Date = event.target.value;
    
  }
  //#endregion

  //#region Unauthorized payment list modal
  showModal: boolean;
  content: string;
  title: string;
  show_unpaidlist_modal() {
    this.showModal = true; // Show-Hide Modal Check
    this.content = '';// "This is content!!"; // Dynamic Data
    this.title = "";    // Dynamic Data
  }
  //Bootstrap Modal Close event
  hide_unpaidlist_modal() {
    this.showModal = false;
  }
  //#endregion

}
