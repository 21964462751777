
<div class="popular-topics">
<h1><span>Popular help topics</span> 
    <!-- <span class="pull-right view-all">
    <a href="javascript:void(0)">View All</a></span> -->
</h1> 
<ul class="popular-topics-list">
<li><a (click)="view_PdfFile('NOTIFICATION AND SCHEME (2).pdf')">Mathadi Scheme</a>
    <!-- <embed src="/assets/pdf/NOTIFICATION AND SCHEME (2).pdf" 
    style="width: 100%;height: 500px" 
    type="application/pdf"> -->
</li>
<li><a (click)="view_PdfFile('ACT_1969_2012 (2).pdf')">Mathadi Act</a></li>	
<!-- <li><a href="javascript:void(0)">How do I apply for refund?</a></li>	 -->
<li><a (click)="goto_Page('est')">Establishment Registration Application Form</a></li>	
<li><a (click)="goto_Page('lab')">Labour Registration Application Form</a></li>	
<!-- <li><a href="javascript:void(0)">How do I register with GST?</a></li> -->
</ul>

</div>
