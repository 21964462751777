<div class="container dashboardmain">
    
  <div class="row">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
              <li class="breadcrumb-item " aria-current="page">Reports </li>
          </ol>
      </nav>
  </div>

  <body>
   

      <section id="company-dashboard">
    <div class="container">
        <div class="row">

            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: center;">
                <div class="content-panel">
                    <button type="button" class="button button4" >Registration</button>
                    <button type="button" class="button button4" >Receipts</button>
                    <button type="button" class="button button4" >Payments</button>
                    <button type="button" class="button button4" >Accounts</button>
                    <button type="button" class="button button4" >FD</button>
                    <button type="button" class="button button4" >PF</button>
                    
                </div>
               
            </div>
           
        </div>
    </div>
</section>


</body>



</div>    

