
<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Reset Password</li>
    </ol>
  </nav>	
  <div class="col-sm-5 offset-sm-4 logindmain">	
  <img src="assets/images/login-forgotpassword-page-img.png" alt="login" title="login" class="img-fluid login-img">
  <h1 class="text-center">Reset Password</h1>
  <form>
      <div class="form-group">
          <label for="email-mobile">New Password</label>
          <input type="password" placeholder="Password" #newpsw id="newpsw" class="form-control" />        
      </div>
      <div class="form-group">
          <label for="password">Confirm Password</label>
          <input type="password" #confirmpsw id="confirmpsw" class="form-control" />        
      </div> 
      <div class="clearfix"></div>
      <div class="login-bt-section"><button  type="button" class="btn btn-primary" (click)="update_newpsw(newpsw.value,confirmpsw.value)">Submit</button></div>    
  </form>
  </div>
  
  </div>
  <div class="clearfix"></div>
  
  
  
  
   
  