<div class="important-dates">
<h2 class="defult-title"><span>Important dates</span></h2>
<ul class="important-date-list">
<li>01 May<span>Kamgar Din</span></li>
<!-- <li>CMP-08(Jan-Mar, 2020)<span>Apr 18th, 2020</span></li>	
<li>GSTR-5(Jan, 2020)<span>Feb 20th, 2020</span></li>	
<li>GSTR-5A(Jan, 2020)<span>Feb 20th, 2020</span></li>	
<li>GSTR-6(Jan, 2020)<span>Feb 13th, 2020</span></li>	
<li>GSTR-7(Jan, 2020)<span>Feb 10th, 2020</span></li>
<li>GSTR-9(2018-19)<span>Feb 10th, 2020</span></li>
<li>GSTR-9A(2018-19)<span>Mar 31st, 2020</span></li>
<li>GSTR-7(Jan, 2020)<span>Mar 31st, 2020</span></li>
<li>GSTR-7(Jan, 2020)<span>Mar 31st, 2020</span></li> -->
</ul>
<div class="clearfix"></div>
<!-- <div class="title-main">
<div class="lt">RFD-10 -</div>
<div class="rt">Eighteen months after end of the quarter for which refund is to be claimed</div>
<div class="clearfix"></div>
</div> -->
<!-- <div class="row">
<div class="col-sm-6">
<div class="section-title">GSTR-1 for registered persons with
aggregate turnover up to ₹ 1.5 Crores</div>
<ul class="important-date-list ul-full-width">
<li class="full-width">GSTR-1(Jan-Mar, 2020)<span>Apr 30th, 2020</span></li>
</ul>
</div>
<div class="col-sm-6">
<div class="section-title">GSTR-1 for registered persons with
aggregate turnover up to ₹ 1.5 Crores</div>
<ul class="important-date-list ul-full-width">
<li class="full-width">GSTR-1(Jan, 2020)<span>Feb 11th, 2020</span></li>
</ul>
</div>
</div> -->


</div>
