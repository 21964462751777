import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { UserService } from '../../service/UserService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {Globals} from '../../Globals'

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  mobNo:string='';
  OTP:string = '';
  psw:string = ''
  constructor(public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private globals: Globals) { 
      this.mobNo = this.route.snapshot.queryParamMap.get('mob');
    }

  ngOnInit(): void {

  }
  update_newpsw(password,confirmpsw){
   // alert(password.toString().length);
   
    if (Number(this.mobNo.toString().length) != 10){
      alert("Enter valid mobile no.")
      return;
    }
    else if (password == ''){
      alert("Enter password.")
      return;
    }
    else if (Number(password.toString().length) < 4){
      alert("Enter minimum 4 characters.")
      return;
    }
    else if (password != confirmpsw){
      alert("Enter password and confirm password not match.")
      return;
    }
    else{
      let usertype:string = sessionStorage.getItem('mathaLog_UserType');

      if (usertype == 'Staff' || usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector'){
        let url = 'setstaffnewpsw/' + this.mobNo + '/' + confirmpsw;
        //  alert(url);
        this._userService.get_Dashborad_Data(url).subscribe((data: any) => {
          
          if (data == 'Done') {
            alert('New password updated successfully.')
            //this._userService.staff_login(this.mobNo,confirmpsw,'Staff',0);
            this.router.navigate(['/login']);
          }

        },
          (err: HttpErrorResponse) => {
            //   this.ngxService.stop();
          });

          
      }
      }
      

  }

}
