import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../service/UserService';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
//import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../Globals'
import { Injectable } from '@angular/core';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-download-list',
  templateUrl: './download-list.component.html',
  styleUrls: ['./download-list.component.css']
})
export class DownloadListComponent implements OnInit {
  show_LablisRpt = '';
  listType = '';
  listFor = '';
  lab_ActiveList:any=[];
  constructor(
    public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    
    this.listType = String(this.route.snapshot.queryParamMap.get('listType'));


    if (this.listType === '0') {
      this.listFor='Labour List';
      this.fill_activeLabList();
    }
    else if (this.listType === '1'){
      this.listFor='Establishment List';
      this.fill_activeEstList();

    }
  }
  
 //#region "wating modal"
 showloading_Modal: boolean;
 public show_loadingModal(showhide: boolean) {
   this.showloading_Modal = showhide;
 }
 //#endregion
  public Log_BoardID: number = 6;
  isData ='';
  fill_activeLabList(){
    this.isData = '';
    this.show_loadingModal(true);
    this.lab_ActiveList = [];
    let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/getLabActiveList';
    //alert(url);
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.lab_ActiveList = data;
        console.log('lab_ActiveList ',this.lab_ActiveList);
        this.show_loadingModal(false);
        if (Number(this.lab_ActiveList.length) > 0){
          this.isData = 'yes';
        }
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('somthing went wrong.')
      });


  }
  est_ActiveList:any=[];
  fill_activeEstList(){
    this.isData = '';
    this.show_loadingModal(true);
    this.est_ActiveList = [];
    let url = 'api/' + this._userService.get_labmaster_controlerName(this.Log_BoardID) + '/getEstActiveList';
   // alert(url);
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.est_ActiveList = data;
        console.log('est_ActiveList ',this.est_ActiveList);
        this.show_loadingModal(false);
        if (Number(this.est_ActiveList.length) > 0){
          this.isData = 'yes';
        }
      },
      (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
        alert('somthing went wrong.')
      });

  }
  srNO_rpt: number = 0;
  set_srno_0() {
    this.srNO_rpt = 0;
    return '';
  }
  add_srno() {
    this.srNO_rpt = Number(this.srNO_rpt) + 1;
    return this.srNO_rpt;
  }

}
