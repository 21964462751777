

<div class="container">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Login To</li>
  </ol>
</nav>	
<div class="col-sm-5 offset-sm-4 logindmain">	
<!-- <img src="assets/images/login-forgotpassword-page-img.png" alt="login" title="login" class="img-fluid login-img"> -->
<h1 class="text-center">Login To</h1>
<!-- <p class="title-message">Enter the email & phone number to <span>retrieve your password</span></p> -->
<form [formGroup]="SelLogin">
    <div class="form-group">
      <!-- <label for="board-name" class="board-name-label">Board Name</label> -->
      <select class="form-control select-t board-name" id="brdname" #brdname required >
          <option  [value] = '-1' selected>Select Login To....</option>
          <option *ngFor ='let brd of acessfor;let i = index' [value] = i  >{{brd.boardName}}</option>
      </select>
    </div>  
    <div class="clearfix"></div>
    <div class="login-bt-section"><button  type="button" (click)="nextevent(brdname.value)" class="btn btn-primary">Next</button></div>    
   
</form>

</div>
</div>
<div class="clearfix"></div>

<!--Modal Popup Eg-->

<!-- <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Here I am!
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
  </ng-template>

  <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">Open My Modal</button> -->

  <!--Modal Popup Eg-->
 

