import { Component, OnInit } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { Globals } from '../../Globals';

@Component({
  selector: 'app-sellogin',
  templateUrl: './sellogin.component.html',
  styleUrls: ['./sellogin.component.css']
})
export class SelloginComponent implements OnInit {
  public acessfor: any = [];
  public filterBoradID: number = 0;
  SelLogin: FormGroup;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  userType :string;
  modalOptions: NgbModalOptions;
  constructor(
    private formBuilder: FormBuilder,
    config: NgbAccordionConfig
    , private _userService: UserService
    , public router: Router
    , private route: ActivatedRoute
    , private modalService: NgbModal
    , public http: Http
    , private ngxService: NgxUiLoaderService
    ,private location: Location
    ,public globals: Globals
  ) {
    //MOdal Eg
    // this.modalOptions = {
    //   backdrop:'static',
    //   backdropClass:'customBackdrop'
    // }
    //MOdal Eg
  }
  //MOdal Eg
  // open(content) {
  //   this.modalService.open(content, this.modalOptions).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return  `with: ${reason}`;
  //   }
  // }
  //MOdal Eg

  ngOnInit(): void {
    this.SelLogin = this.formBuilder.group({
      brdname: ['', Validators.required]
    });

    this.userType = sessionStorage.getItem('mathaLog_UserType');

    this.Get_AccessFor();
  }
  ngAfterViewInit() {
   // this.Get_AccessFor();
  }
  // public sel_Rindex:number = -1;
  // set_selrIndex(event){
  //   this.sel_Rindex = Number(event.target.value);
  // }
  brd_List=[];
  Get_AccessFor() {
    this.acessfor = [];
    let url = 'getLoginUserDt/' + sessionStorage.getItem('mathaLog_UserID') + '/' + "''";
      // alert(url);
      this._userService.get_Dashborad_Data(url).subscribe((data: any) => {
      
      this.acessfor = data;
     
      
    },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
      });

  }
  //   Set_FilterBoardID(event) {
  //        this.filterBoradID = Number(event.target.value);
  //  }
  nextevent(sel_index) {
    
    // alert(accessFor);
    if (sel_index == '-1' || sel_index == '') {
      alert('Select Login To.')
    }
    else {
      
      
      //sessionStorage.setItem('mathaLog_accessFor', boardID.toString());

      sessionStorage.setItem('mathafilterBoradID', this.acessfor[Number(sel_index)]['boardID'].toString());
        sessionStorage.setItem('mathaLog_Email', this.acessfor[Number(sel_index)]['emailID'].toString());
        sessionStorage.setItem('mathaLog_UserTypeID', this.acessfor[Number(sel_index)]['userTypeID'].toString());
        sessionStorage.setItem('mathaLog_UserMob', this.acessfor[Number(sel_index)]['mobileNo'].toString());
        sessionStorage.setItem('mathaUserName', this.acessfor[Number(sel_index)]['userName'].toString());
        sessionStorage.setItem('mathaLog_BoardID', this.acessfor[Number(sel_index)]['boardID'].toString());
       // sessionStorage.setItem('mathaLog_accessFor', this.acessfor[Number(sel_index)]['accessFor'].toString());
        sessionStorage.setItem('mathafilterBoradID', this.acessfor[Number(sel_index)]['boardID'].toString());
       // sessionStorage.setItem('mathaLog_UserType', this.acessfor[Number(sel_index)]['userType'].toString());

      if (this.acessfor[Number(sel_index)]['boardID'] == 0){
        //alert('done')
        if (sessionStorage.getItem('mathaUserName') != null){
          this.globals.log_userName = sessionStorage.getItem('mathaUserName');
        }
        else{
          this.globals.log_userName = '';
        }
        
      }
      else {
        //mathaLog_UserType
        if (sessionStorage.getItem('mathaLog_UserType') != null){
          if (sessionStorage.getItem('mathaUserName') != null){
            this.globals.log_userName = sessionStorage.getItem('mathaUserName');
          }
          else{
            this.globals.log_userName = '';
          }

          if (this.userType == 'Chairman') {
            this.router.navigate(['/chairman-dashboard']);
          }
          else if (this.userType == 'Secretary') {
            this.router.navigate(['/dashboard/secretary-dashboard']);
          }
          else if (this.userType == "Staff") {
            this.router.navigate(['dashboard/clerk-dashboard']);
          }

        }
        
        
        
      }
      
    }

  }
}
