<div class="container">
 <div class="row">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{listFor}}</li>
      </ol>
    </nav>
    </div>
</div>
 <!--Start Rpt Active LabList -->

 <div style="text-align: center;" >
    <button type="button" *ngIf="listType == '0' && isData == 'yes'" class="btn btn-outline-primary btn-xs" styleSheetFile="assets/css/table.css"
      [useExistingCss]="true" printSectionId="print-labactiveList" ngxPrint>Download Pdf</button>

      <button type="button" *ngIf="listType == '1' && isData == 'yes'" class="btn btn-outline-primary btn-xs" styleSheetFile="assets/css/table.css"
      [useExistingCss]="true" printSectionId="print-estactiveList" ngxPrint>Download Pdf</button>
  </div>
  <br>

    <!-- waiting modal -->

    <div class="container">

        <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <!-- Modal body -->
                    <div class="modal-body">

                        <div style="text-align: center;">

                            Please wait ...
                            <div class="spinner-grow text-success"></div>
                            <div class="spinner-grow text-info"></div>
                            <div class="spinner-grow text-warning"></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- waiting modal -->

   <div *ngIf="listType == '0'" class="container dashboardmain">
    <div id="print-labactiveList">
      <!-- *ngIf="show_LablisRpt === 'download'" -->
        <div  id="rpt_labactive_pdf"
            style="text-align: center;">
            <div style="page-break-after: always">
                {{set_srno_0()}}
                <table class="table table-bordered">
                    <tbody>

                        <tr>
                            <td style="text-align: center;" colspan="7">
                              
                                <h3 style="font-weight: bold;">पिंपरी चिंचवड माथाडी व असंरक्षित कामगार मंडळ</h3>

                                <!-- <p>चिंचवड</p>-->
                                                  
                            </td>

                        </tr>
                       

                        <tr>
                            <td style="text-align: center;font-weight: bold;">Sr.No.</td>
                            <td style="text-align: center;font-weight: bold;">Registration No. </td>
                            <td style="text-align: center;font-weight: bold;">Registration Date</td>
                            <td style="text-align: left;font-weight: bold;">Name</td>
                            <td style="text-align: center;font-weight: bold;">Date of Birth</td>
                            <td style="text-align: center;font-weight: bold;">Address</td>
                            <td style="text-align: right;font-weight: bold;">Status</td>
                        </tr>
                        <tr *ngFor='let rec of lab_ActiveList;let i = index'>
                            <td >{{add_srno()}}</td>
                            <td >{{rec.labRegNo}}</td>
                            <td >{{rec.labRegdt | date :'dd/MM/yyyy' }}</td>
                            <td style="text-align: left;">{{rec.labName}}</td>
                            <td >{{rec.dob | date :'dd/MM/yyyy'}}</td>
                            <td style="text-align: left;">{{rec.resiAdd1}}</td>
                            <td >{{rec.status}}</td>
                        </tr>
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>


   </div>

   <div *ngIf="listType == '1'" class="container dashboardmain">
    <div id="print-estactiveList">
      <!-- *ngIf="show_LablisRpt === 'download'" -->
        <div  id="rpt_estactive_pdf"
            style="text-align: center;">
            <div style="page-break-after: always">
                {{set_srno_0()}}
                <table class="table table-bordered">
                    <tbody>

                        <tr>
                            <td style="text-align: center;" colspan="7">
                              
                                <h3 style="font-weight: bold;">पिंपरी चिंचवड माथाडी व असंरक्षित कामगार मंडळ</h3>

                                <!-- <p>चिंचवड</p>-->
                                                  
                            </td>

                        </tr>
                       

                        <tr>
                            <td style="text-align: center;font-weight: bold;">Sr.No.</td>
                            <td style="text-align: center;font-weight: bold;">Registration No. </td>
                            <td style="text-align: center;font-weight: bold;">Registration Date</td>
                            <td style="text-align: left;font-weight: bold;">Name</td>
                            <td style="text-align: center;font-weight: bold;">Address</td>
                           
                        </tr>
                        <tr *ngFor='let rec of est_ActiveList;let i = index'>
                            <td >{{add_srno()}}</td>
                            <td >{{rec.estRegNo}}</td>
                            <td >{{rec.estRegdt | date :'dd/MM/yyyy' }}</td>
                            <td style="text-align: left;">{{rec.estName}}</td>
                            <td style="text-align: left;">{{rec.estAdd1}}</td>
                           
                        </tr>
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>


   </div>

