<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/login">Login</a></li>
      <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/forgotpassword">Forgot Password</a></li>
      <li class="breadcrumb-item active" aria-current="page">Verify Mobile</li>
    </ol>
  </nav>	
  <div class="col-sm-5 offset-sm-4 logindmain verifiedcode">	
  <img src="assets/images/login-forgotpassword-page-img.png" alt="login" title="login" class="img-fluid login-img">
  <h1 class="text-center">Verify Your Mobile</h1>
  <p class="title-message">We have sent OTP on 
    <span><strong>+91 {{mobno_slice}}</strong></span>
    <span>that you use to verify</span>
  
  </p>
  <form>
      <div class="form-group">
          <label for="email-mobile">Enter your OTP code</label>
          <input type="password" #otp id="otpval" class="form-control" />        
      </div>  
      <div class="clearfix"></div>
      <div class="login-bt-section"><button  type="button" (click)="verify_OTP(otp.value)" class="btn btn-primary">Verify Now</button></div>
      <span><div  slot="end" *ngIf="maxTime != 0" ><a href="javascript:void(0);"  class="otp-text">{{maxTime}}  </a></div></span>
      <div *ngIf="maxTime == 0" class="font-18 text-center">Don't receive the OTP? <a href="javascript:void(0)" (click)="resend_OTP()"><strong>Resend OTP</strong></a></div>    
  </form>
  </div>
  </div>
  <div class="clearfix"></div>
  
  
  