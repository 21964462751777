import { Component, OnInit,ElementRef ,ViewChild } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { Key } from 'protractor';
import { multicast } from 'rxjs/operators';

//import * as jsPDF from 'jspdf';

import jspdf from 'jspdf';

import html2canvas from 'html2canvas';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-wl-receipt-rmatha',
  templateUrl: './wl-receipt-rmatha.component.html',
  styleUrls: ['./wl-receipt-rmatha.component.css'],
  providers: [NgbAccordionConfig]

})
export class WlReceiptRmathaComponent implements OnInit {
  
  // title = 'angular-text-search-highlight';
  searchText = '';
  searchToli = '';
  characters = [
    'Ant-Man',
    'Aquaman',
    'Asterix',
    'The Atom',
    'The Avengers',
    'Batgirl',
    'Batman',
    'Batwoman'
  ];



  set_searchItem(selName) {
    this.searchText = '';
    this.toliInfo = selName;
  }


  getdate_ddmmyyy(date_) {
    var date_to_parse = new Date(date_);
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();
    //alert(day + '/' +  month + '/' + year);
    return day + '/' + month + '/' + year;
  }

  createPdf() {
    let rptChqNo: string = 'Cash'
    let rptChqDt: string = ''
    let getbody = [];


    if (this.bind_ChqList.length > 0) {
      rptChqNo = '';
      for (let c = 0; c < this.bind_ChqList.length; c++) {
        if (c == 0) {
          rptChqNo = this.bind_ChqList[c]['billNo'];
        }
        else {
          rptChqNo = rptChqNo + ',' + this.bind_ChqList[c]['billNo'];
        }

        rptChqDt = this.getdate_ddmmyyy(this.bind_ChqList[c]['billDt']);
      }
    }



    getbody = [[{ text: 'Received A/C ', style: 'colheader' }, { text: 'Amount', style: 'colheader', alignment: 'right' }]];
    for (let i = 0; i < this.transDtRec.length; i++) {

      if (this.transDtRec[i]['acctype'] == 'Wages') {
        getbody.push([
          { text: this.transDtRec[i]['crAccName'], style: 'value' },
          { text: Number(this.transDtRec[i]['cramt']), alignment: 'right' },

        ]);
      }
      else if (this.transDtRec[i]['acctype'] == 'Other Wages') {
        getbody.push([
          { text: this.transDtRec[i]['crAccName'], style: 'value' },
          { text: Number(this.transDtRec[i]['cramt']), alignment: 'right' },

        ]);
      }
      else if (this.transDtRec[i]['acctype'] == 'Levy Urban') {
        getbody.push([
          { text: this.transDtRec[i]['crAccName'], style: 'value' },
          { text: Number(this.transDtRec[i]['cramt']), alignment: 'right' },

        ]);
      }
      else if (this.transDtRec[i]['acctype'] == 'Levy Rural') {
        getbody.push([
          { text: this.transDtRec[i]['crAccName'], style: 'value' },
          { text: Number(this.transDtRec[i]['cramt']), alignment: 'right' },

        ]);
      }
      else if (this.transDtRec[i]['acctype'] == 'OtherLevy Urban') {
        getbody.push([
          { text: this.transDtRec[i]['crAccName'], style: 'value' },
          { text: Number(this.transDtRec[i]['cramt']), alignment: 'right' },

        ]);
      }
      else if (this.transDtRec[i]['acctype'] == 'OtherLevy Rural') {
        getbody.push([
          { text: this.transDtRec[i]['crAccName'], style: 'value' },
          { text: Number(this.transDtRec[i]['cramt']), alignment: 'right' },

        ]);
      }


    }
    getbody.push([
      { text: 'Total', style: 'total' },
      { text: Number(this.transAmt), alignment: 'right', style: 'total' },

    ]);

    let AmtInWord = this.inWords(Number(this.transAmt));

    var docDefinition = {
      content: [
        { text: 'रत्न्नागिरी', style: 'header', alignment: 'center' },
        { text: 'RECEIPT-WAGES ', style: 'subheader', alignment: 'center' },
        { text: 'Wage Year - Month : ' + this.filterYr + ' - ' + this.filterMonth, style: 'subheader', alignment: 'center' },
        {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: 200,
              text: 'Receipt No.- ' + this.receiptNO, style: 'leble', alignment: 'left',

            },

            {

              width: '*',
              text: 'Date : ' + this.getdate_ddmmyyy(this.recDate), style: 'leble', alignment: 'right'
            }
          ],
          // optional space between columns
          columnGap: 10
        },
        {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '*',
              text: 'Receipt From- ' + this.prinempname, style: 'leble', alignment: 'left'
            }
          ],
          // optional space between columns
          columnGap: 10
        },
        {
          columns: [

            {

              width: '*',
              text: 'The sum of Rupees : ' + Number(this.transAmt), style: 'leble', alignment: 'left'
            }
          ],
          // optional space between columns
          columnGap: 10
        },
        {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: 300,
              text: 'In Cash/Cheque No. - ' + rptChqNo, style: 'leble', alignment: 'left'
            },
            {

              width: '150',
              text: 'Date - ' + rptChqDt, style: 'leble', alignment: 'right'
            }
            ,
            {

              width: '*',
              text: 'Dr.On : ', style: 'leble', alignment: 'right'
            }
          ],
          // optional space between columns
          columnGap: 10
        },
        { text: 'on account of as per details given below :', style: 'value', alignment: 'left' },
        // {text: 'Opn. Bal. :' + Number(Number(this.pay_Details.grdOpnbal) + Number(this.pay_Details.brdOpnbal)).toFixed(2),style: 'value',alignment:'left'},
        // {text: 'PF Int.Rate' ,style: 'value',alignment:'right'},

        { text: '', style: 'leble', alignment: 'center' },
        {
          style: 'itemsTable',
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [400, 100],

            body: getbody
          }

        },
        { text: 'Amount In Word : ' + AmtInWord, style: 'leble', alignment: 'left' },
        { text: 'Narration :', style: 'leble', alignment: 'left' },
        { text: 'Receipt Stamp Exempted  vide R. & F.D. No. STP--1372/204317N. Dated 10th September 1973 ', style: 'value', alignment: 'left' },
        { text: 'This Receipt will be Valid after Releasation of Cheque . If Cheque is not released it will be cancelled. ', style: 'value', alignment: 'left' },
        { text: 'Receivers Signature', style: 'leble', alignment: 'right' }

      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
        },
        subheader: {
          fontSize: 13,
          bold: true,
          margin: [0, 15, 0, 5]
        },
        colheader: {
          fontSize: 11,
          bold: true,
          margin: [0, 5, 0, 5]
        },
        leble: {
          fontSize: 11,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        value: {
          fontSize: 10,
          margin: [0, 5, 0, 0]
        },
        total: {
          fontSize: 10,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        story: {
          italic: true,
          alignment: 'center',
          width: '50%',
        }
      }
    }

    pdfMake.createPdf(docDefinition).open();
  }
  n: string;
  a = ['zero ', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  inWords(num): string {
    num = Math.floor(num * 100);
    if ((num = num.toString()).length > 11) { return 'overflow'; }
    let n;
    n = ('00000000' + num).substr(-11).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})(\d{1})(\d{1})$/);
    if (!n) { return; } let str = '';

    // tslint:disable-next-line:triple-equals
    str += (n[1] != 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'crore ' : '';
    // tslint:disable-next-line:triple-equals
    str += (n[2] != 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'lakh ' : '';
    // tslint:disable-next-line:triple-equals
    str += (n[3] != 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'thousand ' : '';
    // tslint:disable-next-line:triple-equals
    str += (n[4] != 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) : 'hundred';
    // tslint:disable-next-line:triple-equals
    str += (n[5]) ? (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' + this.a[n[5][1]]) : '';
    // tslint:disable-next-line:triple-equals
    str += (n[6]) ? ((str != '') ? 'point ' : '') + (this.a[Number(n[6])] || this.b[n[6][0]] + ' ' + this.a[n[6][1]]) : '';
    // tslint:disable-next-line:triple-equals
    str += (n[7] != 0) ? (this.a[Number(n[7])] || this.b[n[7][0]] + ' ' + this.a[n[7][1]]) : '';

    return str;
  }

  // @ViewChild('htmlData') htmlData:ElementRef;
  frm_newChqBank: FormGroup;
  submitted = false;

  frmchq: FormGroup;

  showModal: boolean;
  content: string;
  title: string;

  expandContent = true;
  public dasbordfor: string = '';
  public tlList: any = [];
  public tlLocist: any = [];
  public debitAcList: any = [];
  public filterFayear;
  public filterYr: number = 0;
  public filterMonth: number = 0;
  public filterBoradID: number = 0;
  public filterprinAccID: number = 0;
  public filterrectype: number = 0;

  public wagesAmt = 0;
  public anamtAmt = 0;
  public levyAmt = 0;

  public act_wagesAmt = 0;
  public act_anamatAmt = 0;
  public act_levyAmt = 0;

  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';
  wlmstdata: any = [];
  prinempid: number = 0;
  prinempname: string = '';
  prinempno: string = '';
  chqbankname: string = '';
  chqbranchname: string = '';
  chqcityname: string = '';
  chqdate;
  recDate;
  ifsccode: string = '';

  docID;
  toliID;
  receiptNO;
  docdt;
  transAmt;
  wageyr;
  wageMon;
  locationID;
  prinEmpID;
  flag = 'Rec';
  naration;
  remark = 'Entry From Board Wages Levy';
  levyCode;
  fromDt;
  toDt;
  recFor;
  fayear;
  userID;
  companyID;
  prinEmpGrdName;
  dedParaCode;
  talukaID;
  servTaxParaCode;
  agencyID;
  noExPrinemp;
  noExGrd;
  isRecGenrate;
  regNo;
  pEmpName;

  txtcity: string = '';
  txtbankname: string = '';
  txtbranchname: string = '';
  recTypeList: any = [];
  rpt_print:string = '';

  constructor(
    config: NgbAccordionConfig,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,

  ) {
    this.recTypeList = [{
      typeVal: 0,
      rectype: 'Cheque'
    },
    {
      typeVal: 1,
      rectype: 'Cash'
    }
    ]
    this.docID = Number(this.route.snapshot.queryParamMap.get('docid'));
    this.toliID = Number(this.route.snapshot.queryParamMap.get('toliID'));
    this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));
    this.rpt_print = this.route.snapshot.queryParamMap.get('print').toString();


    config.closeOthers = true;
    config.type = 'info';

    this.filterBoradID = 0;
    this.filterMonth = 0;
    this.filterYr = 0;
    //this.filterFayear = 0;
    this.dasbordfor = 'Labour';

    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
    this.userID = this.Log_UserID;


    //this.fill_Allocaton();

  }

  show_serchCnt() {
    if (this.searchText == '') {
      this.searchText = 'yes';
    }
    else {
      this.searchText = '';
    }
  }

  // public openPDF():void {
  //   let DATA = this.htmlData.nativeElement;
  //   let doc = new jsPDF('p','pt', 'a4');
  //   doc.fromHTML(DATA.innerHTML,15,15);
  //   doc.output('dataurlnewwindow');
  // }
  // public downloadPDF():void {
  //   let DATA = this.htmlData.nativeElement;
  //   let doc = new jsPDF('p','pt', 'a4');

  //   let handleElement = {
  //     '#editor':function(element,renderer){
  //       return true;
  //     }
  //   };
  //   doc.fromHTML(DATA.innerHTML,15,15,{
  //     'width': 200,
  //     'elementHandlers': handleElement
  //   });

  //   doc.save('angular-demo.pdf');
  // }
  ngOnInit(): void {

    this.frm_newChqBank = this.formBuilder.group({
      nbank: ['', Validators.required],
      nbranch: ['', Validators.required],
      ncity: ['', Validators.required],
      nifsccode: ['', [Validators.required, Validators.minLength(11)]]
    });

    this.ngxService.start();
    // let mon, yr;
    // let dt = new Date();
    // mon = this.recDate.getMonth() + 1;
    // yr = this.recDate.getFullYear();
    // // alert(yr);
    // if (mon < 4) {
    //   this.filterFayear = yr - 1;
    // }
    // else {
    //   this.filterFayear = yr;
    // }
    // let d = new Date();
    //     let currDate = d.getDate();
    //     let currMonth = d.getMonth()+1;
    //     let currYear = d.getFullYear();
    //     this.chqdate = new Date(currYear + "-" + ((currMonth<10) ? '0'+currMonth : currMonth )+ "-" + ((currDate<10) ? '0'+currDate : currDate ));

    this.chqdate = new Date().toISOString().substring(0, 10);
    this.recDate = new Date().toISOString().substring(0, 10);

    this.chqAddUpdate = "Add";
    this.frmchq = this.formBuilder.group({
      selchqtype: [''],
      txtchqno: ['', Validators.required],
      txtchqamt: ['', Validators.required],
      txtifsccode: [''],
      txtchqdate: [this.chqdate]
      //txtchqdate:[this.chqdate]
    });



    // this.fill_debitAcList();
    this.fill_AddMstData();

    if (this.docID == 0 || this.docID == '' || this.docID == null) {
      this.recDate = new Date().toISOString().substring(0, 10);
      this.set_wageYr_Month();

    }
    // else {
    //   this.Bind_Data();
    // }

    this.ngxService.stop();


  }
  set_wageYr_Month() {
    var date_to_parse = new Date(this.recDate);
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();

    if (Number(month) == 1) {
      this.filterMonth = 12;
      this.filterYr = Number(year) - 1;
    }
    else {
      this.filterMonth = Number(month) - 1;
      this.filterYr = Number(year);
    }
  }
  invalidDate='';
  vlaidateDate(event) {
    this.invalidDate='invalid';
    let chkDt = new Date(event.target.value);
   // 
     if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
      this.invalidDate = 'invalid';
    }
    else{
      if (chkDt.getFullYear()){
        this.invalidDate='';
      }
      else{

      }
      
    }
  }
  invalidYear='';
  invalidMonth = '';
  vlaidateMonth(event) {
    this.invalidMonth='invalid';
    let chkval = event.target.value;
   // 
     if (Number(chkval) < 1 || Number(chkval) > 12){
      this.invalidMonth = 'invalid';
    }
    else{
      if (chkval){
        this.invalidMonth='';
      }
    }
  }
  vlaidateYear(event) {
    this.invalidYear='invalid';
    let chkval = event.target.value;
   // 
     if (Number(chkval) < 1980 || Number(chkval) > 2099){
      this.invalidYear = 'invalid';
    }
    else{
      if (chkval){
        this.invalidYear='';
      }
    }
  }
  setDate(event) {
   // this.recDate = event.target.value;
 
   let chkDt = new Date(event.target.value);
//

   if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
   }
   else{
    this.recDate = new Date(chkDt).toISOString().substring(0, 10);;
    this.set_wageYr_Month();
   }
 
  }

  toliInfo: string = '';
  toliserchCnt = '';

  estInfo: string = '';
  estserchCnt = '';

  marInfo: string = '';
  marserchCnt = '';
  marketID;

  compInfo: string = '';


  toli_List = [];
  est_List = [];
  filter_est = [];

  marcom_List = [];
  filter_marcom = [];

  chqbank_List = [];
  filter_chqbank = [];

  levyPara_list = [];
  tot_LevyPer = 0;
  tot_AnamatPar = 0;
  fill_AddMstData() {
    this.estID = 0;

    this.toli_List = [];
    this.filter_toli = [];
    this.toliInfo = '';
    this.estInfo = '';

    this.est_List = [];
    this.filter_est = [];

    this.marcom_List = [];
    this.filter_marcom = [];
    this.marInfo = '';
    this.compInfo = '';
    this.marketID = 0;
    this.companyID = 0;

    this.chqbank_List = [];
    this.filter_chqbank = [];

    this.levyPara_list = [];
    this.levyCode = 0;
    this.tot_LevyPer = 0;
    this.tot_AnamatPar = 0;

    this.recPaid = 'No';

    ///Fill Toli List
    //let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/gettoliList';

    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/getWlRecMasterData/' + this.filterFayear;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.toli_List = data.wlRecToliDt;
        
        if (Number(this.toliID) > 0) {
          this.filter_toli = this.toli_List.filter(x => x.toliId == this.toliID);
          
          if (this.filter_toli.length > 0) {
            this.toliInfo = this.filter_toli[0]['mukName'];
            this.toliserchCnt = '';
          }
          else {
            this.toliserchCnt = 'yes';
          }
        }

        ///Est List

        this.est_List = data.wlRecEstDt;
        
        if (Number(this.toliID) > 0) {
          this.filter_est = this.est_List.filter(x => x.toliId == this.toliID);
        
          if (this.filter_est.length > 0) {
            if (this.filter_est.length == 1) {
              this.estID = this.filter_est[0]['estID'];
              this.estInfo = this.filter_est[0]['filterval'];
              this.estserchCnt = '';
            }
            else {
              this.estserchCnt = 'yes';
            }

          }
          else {
            this.estserchCnt = '';
          }

        }

        ///Market  Company list

        this.marcom_List = data.wlRecMarComDt;
        
        if (Number(this.estID) > 0) {
          this.filter_marcom = this.marcom_List.filter(x => x.estID == this.estID);
        
          if (this.filter_marcom.length > 0) {
            if (this.filter_marcom.length == 1) {
              this.marketID = this.filter_marcom[0]['marketID'];
              this.marInfo = this.filter_marcom[0]['filterval'];
              this.marserchCnt = '';
              this.compInfo = this.filter_marcom[0]['companyname'];
              this.companyID = this.filter_marcom[0]['companyID'];
            }
            else {
              this.marserchCnt = 'yes';
            }

          }
          else {
            this.marserchCnt = '';
          }

        }
        else {
          this.filter_marcom = [];
        }

        this.allocList = [];
        this.allocList = data.wlAllocation;
        
        this.fill_CrAcList();

        this.levyPara_list = data.wlLevyPara;
        
        this.set_levyCode(0);

        this.debitAcList = [];
        this.debitAcList = data.wlDbAcList;
        
        this.get_filter_debitAcList(0);

        this.fill_chqBankDt_List('');

        if (this.docID > 0){
          this.Bind_Data();
        }

      });

    ///Fill ChqBank List
    


  }
  set_levyCode(levyCode) {
    this.levyCode = 0;
    this.tot_LevyPer = 0;
    this.tot_AnamatPar = 0;
    let filterLevyCode = [];
    filterLevyCode = this.levyPara_list.filter(x => x.filterLevyCode == Number(levyCode));
    if (filterLevyCode.length > 0) {
      this.levyCode = filterLevyCode[0]['levyCode'];
      this.tot_LevyPer = filterLevyCode[0]['lParaPer'];
      this.tot_AnamatPar = filterLevyCode[0]['anamatPer'];

    }
  }
  fill_chqBankDt_List(filterIfscCode: string) {
    this.chqbank_List = [];
    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/getchqbankDt';
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.chqbank_List = data;
        
        if (filterIfscCode != '') {
          let filterifsc = [];
          filterifsc = this.chqbank_List.filter(x => x.ifscCode == filterIfscCode);
        
          if (filterifsc.length > 0) {
            this.set_searchChqBank(filterifsc[0]['ifscCode'], filterifsc[0]['branchID'], filterifsc[0]['estBankID'], filterifsc[0]['estBankName'], filterifsc[0]['branchName'], filterifsc[0]['city']);
          }
        }
      });
  }

  filter_toli = [];
  serch_toli() {
    this.filter_est = [];
    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    this.toliID = 0;
    this.toliInfo = '';

    this.filter_toli = this.toli_List.filter((item) => {
      return ((item.mukName.toString()).indexOf(this.searchToli.toString().toLowerCase()) > -1);
    });

    
  }
bind_toli_est_mar(toliID,estID,marketID){
  this.toliID = 0;
  this.toliInfo = '';

  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';

  this.searchToli = '';
  this.toliID = 0;
  this.toliInfo = '';
  this.toliserchCnt = '';


      let filter_tl = this.toli_List.filter(x => x.toliId == toliID);
      if (filter_tl.length > 0) {
        this.toliID = filter_tl[0]['toliId'];
        this.toliInfo = filter_tl[0]['mukName'];
      }

      let filter_est = this.est_List.filter(x => x.estID == estID);
     
      if (filter_est.length > 0) {
        this.estID = filter_est[0]['estID'];
        this.estInfo = filter_est[0]['filterval'];
        this.estserchCnt = '';
      }

      let filter_marcom = this.marcom_List.filter(x => x.marketID == marketID);
      if (filter_marcom.length > 0) {
        this.marketID = filter_marcom[0]['marketID'];
        this.marInfo = filter_marcom[0]['filterval'];
        this.marserchCnt = '';
        this.compInfo = filter_marcom[0]['companyname'];
        this.companyID = filter_marcom[0]['companyID'];
      }

}
  set_searchToli(toliID, toliNo, toliName) {
    this.toliID = 0;
    this.toliInfo = '';

    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    this.searchToli = '';
    this.toliID = toliID;
    this.toliInfo = toliName;
    this.toliserchCnt = '';
    this.serch_est(toliID);
  }
  show_serchtoli_Cnt() {
    this.toliserchCnt = 'yes';
    this.filter_toli = this.toli_List;
  }

  /////filter est
  searchEst = '';
  serch_est(toliID) {
    this.filter_marcom = [];

    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    if (Number(toliID) > 0) {

      this.filter_est = this.est_List.filter(x => x.toliId == toliID);
      
      if (this.filter_est.length > 0) {
        if (this.filter_est.length == 1) {
          this.estID = this.filter_est[0]['estID'];
          this.estInfo = this.filter_est[0]['filterval'];
          this.estserchCnt = '';

          this.serch_mar(this.estID);
        }
        else {
          this.estserchCnt = 'yes';
        }

      }
      else {
        this.estserchCnt = '';
      }

    }
    else {
      this.filter_est = this.est_List.filter(x => x.toliId == this.toliID);

      this.filter_est = this.filter_est.filter((item) => {
        return ((item.filterval.toString()).indexOf(this.searchEst.toString().toLowerCase()) > -1);
      });
    }


    
  }
  estID;
  set_searchEst_Cnt(estID, estNo, estName) {
    this.filter_marcom = [];

    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';

    this.searchEst = '';
    this.estID = estID;
    this.estInfo = estName;
    this.estserchCnt = '';

    this.serch_mar(this.estID);
  }
  show_serchest_Cnt() {

    if (this.toliID > 0) {
      //this.filter_est = this.est_List;
      if (this.filter_est.length > 1) {
        this.estserchCnt = 'yes';
      }

    }
    else {
      alert("Select Toli");
    }

  }

  /////filter market
  searchMar = '';
  serch_mar(estID) {

    if (Number(estID) > 0) {

      this.filter_marcom = this.marcom_List.filter(x => x.estID == estID);
      
      if (this.filter_marcom.length > 0) {
        if (this.filter_marcom.length == 1) {
          this.marketID = this.filter_marcom[0]['marketID'];
          this.marInfo = this.filter_marcom[0]['filterval'];
          this.marserchCnt = '';
          this.compInfo = this.filter_marcom[0]['companyname'];
          this.companyID = this.filter_marcom[0]['companyID'];
        }
        else {
          this.marserchCnt = 'yes';
        }

      }
      else {
        this.marserchCnt = '';
      }

    }
    else {

      this.filter_marcom = this.marcom_List.filter(x => x.estID == this.estID);

      this.filter_marcom = this.filter_marcom.filter((item) => {
        return ((item.filterval.toString()).indexOf(this.searchMar.toString().toLowerCase()) > -1);
      });

    }

  }

  set_searchMar_Cnt(marketID, marNo, marName, compID, CompName) {
    this.marketID = marketID;
    this.marInfo = marName;
    this.marserchCnt = '';
    this.compInfo = CompName;
    this.companyID = compID;
  }
  show_serchmar_Cnt() {

    if (this.estID > 0) {

      if (this.filter_marcom.length > 1) {
        this.marserchCnt = 'yes';
      }

    }
    else {
      alert("Select Establishment");
    }

  }


  /////////
  searchIfsc;

  serch_ifsc() {
    this.branchID = 0;
    this.estBankID = 0;
    this.estBankName = '';
    this.branchName = '';
    this.city = '';

    this.txtbankname = '';
    this.txtbranchname = '';
    this.txtcity = '';


    this.filter_chqbank = this.chqbank_List.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(this.searchIfsc.toString().toLowerCase()) > -1);
    });

  }
  
  branchID;
  estBankID;
  estBankName;
  branchName;
  city
  set_searchChqBank(ifscCode, branchID, estBankID, estBankName, branchName, city) {

    this.searchIfsc = ifscCode;
    this.branchID = branchID;
    this.estBankID = estBankID;
    this.estBankName = estBankName;
    this.branchName = branchName;
    this.city = city;

    this.txtbankname = estBankName;
    this.txtbranchname = branchName;
    this.txtcity = city;

    this.filter_chqbank = [];

  }
  recPaidCnt_disabled = false;
  recPaid = 'No';
  rpt_Wages;
  rpt_Levy;
  rpt_chqNo;
  rpt_debitAc;
  

  Bind_Data() {
    this.rpt_chqNo = '';
    this.rpt_Wages = '';
    this.rpt_Levy = '';
  this.rpt_chqNo = '';
  this.rpt_debitAc = '';
    this.bind_ChqList = [];
    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/wlrecbind/' + this.docID;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.wlmstdata = data.transMst;
        
        this.filterYr = Number(this.wlmstdata[0]["wageyr"]);
        this.filterMonth = Number(this.wlmstdata[0]["wageMon"]);
        this.docID = this.wlmstdata[0]["docID"];
        this.receiptNO = this.wlmstdata[0]["receiptNO"];
        this.docdt = new Date(this.wlmstdata[0]["docdt"]).toISOString().substring(0, 10);

        let date: Date;
        date = new Date(this.wlmstdata[0]["docdt"]);
        date.setDate(date.getDate() + 1);
        this.recDate = new Date(date).toISOString().substring(0, 10);
         this.transAmt = Number(this.wlmstdata[0]["transAmt"]);
        this.wageyr = Number(this.wlmstdata[0]["wageyr"]);
        this.wageMon = Number(this.wlmstdata[0]["wageMon"]);
        this.flag = 'Rec';
        this.naration = 'Being Collection As Per Receipt Register For ';
        this.remark = 'Auto Entry';
        this.levyCode = this.wlmstdata[0]["levyCode"];
        this.fromDt = new Date(this.wlmstdata[0]["docdt"]).toISOString().substring(0, 10);
        this.toDt = new Date(this.wlmstdata[0]["docdt"]).toISOString().substring(0, 10);
        this.recFor = '';
        //this.fayear = this.wlmstdata[0]["fayear"];
        this.userID = this.Log_UserID;
        this.companyID = this.wlmstdata[0]["companyID"];
        this.isRecGenrate = 'Yes';
        this.lessAdvance = this.wlmstdata[0]["lessAdvance"];
        this.recPaid = this.wlmstdata[0]["recPaid"];
          if (this.recPaid == 'Yes'){
            this.recPaidCnt_disabled = true;
          }
          else{
            this.recPaidCnt_disabled = false;
          }
        this.bind_toli_est_mar(this.wlmstdata[0]["toliID"],this.wlmstdata[0]["estID"],this.wlmstdata[0]["marketID"]);

        let transChqDt = data.transChq;
        let chqtype = 0;
        this.filterrectype = 0;
        if (transChqDt.length > 0) {
          for (let k = 0; k < transChqDt.length; k++) {
            if (k == 0){
              this.rpt_chqNo =  transChqDt[k]['billNo'];
            }
            else{
              this.rpt_chqNo = this.rpt_chqNo + "," + transChqDt[k]['billNo'];
            }
            
            this.bind_ChqList.push({
              docID: transChqDt[k]['docID'],
              transChqID: 0,
              bankBranchID: transChqDt[k]['bankBranchID'],
              estBankID: transChqDt[k]['estBankID'],
              city: '',
              bankName: transChqDt[k]['bankName'],
              branchName: transChqDt[k]['branchName'],
              selchqtypeval: chqtype,
              depositType: transChqDt[k]['depositType'],
              ifscCode: transChqDt[k]['ifscCode'],
              chqdate: transChqDt[k]['billDt'],
              bindchqdate: transChqDt[k]['billDt'],
              chqno: transChqDt[k]['billNo'],
              chqamt: transChqDt[k]['chqAmt'],
              billNo: transChqDt[k]['billNo'],
              billDt: transChqDt[k]['billDt'],
              bounce: 'No',
              payDocID: 0,
              clear: 'Yes',
              clrDocID: 0,
              instrumentType: '',
              chequeClearingDt: "1900-01-01T00:00:00",
              isedit:'',
            });

          }
          this.filterrectype = 0;

          
        }
        else {
          this.filterrectype = 1;
          this.get_filter_debitAcList(1);
        }


        

        this.tot_ChqAmt = this.bind_ChqList.reduce((sum, curr) => sum + Number(curr.chqamt), 0);

        let transDt = data.transDt;
        if (transDt.length > 0) {
          for (let k = 0; k < transDt.length; k++) {
            for (let i = 0; i < this.transDtRec.length; i++) {
              if (transDt[k]['crID'] == this.transDtRec[i]['crID']) {

                this.transDtRec[i]['docDID'] = transDt[k]['docDID'];
                this.transDtRec[i]['docID'] = transDt[k]['docID'];
                this.transDtRec[i]['dbID'] = transDt[k]['dbID'];
                this.transDtRec[i]['cramt'] = transDt[k]['cramt'];
                this.transDtRec[i]['dbamt'] = transDt[k]['cramt'];
                this.transDtRec[i]['actRecNo'] = transDt[k]['actRecNo'];

                if (this.transDtRec[i]['acctype'] == 'Wages') {
                  this.act_wagesAmt = Number(transDt[k]['cramt']);
                }
                else if (this.transDtRec[i]['acctype'] == 'Levy') {
                  this.act_levyAmt = Number(transDt[k]['cramt']);
                }
                else if (this.transDtRec[i]['acctype'] == 'Anamat') {
                  this.act_anamatAmt = Number(transDt[k]['cramt']);
                }

              }
            }
          }
          this.filterprinAccID = transDt[0]['dbID']

          let filter_debitAcList = [];
          filter_debitAcList = this.debitAcList.filter(indx => indx.accID == this.filterprinAccID);
          if (filter_debitAcList.length > 0) {
            this.rpt_debitAc = this.filter_debitAcList[0]['accName']
          }

          this.rpt_Wages = this.act_wagesAmt.toString();
          this.rpt_Levy = this.act_levyAmt.toString();
        }

        this.act_wagesAmt = Number(this.act_wagesAmt) + Number(this.lessAdvance);
        this.act_WageAmt = Number(this.act_wagesAmt);

        this.cal_TransAmt();
      });

  }
  get_allocationform(accID) {
    let filter_alloc = [];
    filter_alloc = this.allocList.filter(indx => indx.accID === accID);
    if (filter_alloc.length > 0) {
      return filter_alloc[0]['allocationFrom']
    }
    return '';
  }
  public allocList: any = [];
  // fill_Allocaton() {
  //   this.allocList = [];
  //   let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getWagesLevyAccounts';
  //   this._userService.get_Dashborad_Data(url).subscribe(
  //     (data) => {
  //       this.allocList = data;
  //       this.fill_CrAcList();
  //     });
  // }
  public transDtRec: any = [];
  fill_CrAcList() {
    this.transDtRec = [];
    this.get_CrAcList('Wages', this.wagesAmt, '');
    this.get_CrAcList('Levy', this.levyAmt, '');
    this.get_CrAcList('Anamat', this.anamtAmt, '');

    this.cal_TransAmt();
    
  }
  set_Cr_AmtChange(event, index) {
    // alert(event.target.value);
    this.transDtRec[index]['cramt'] = event.target.value;
    this.transDtRec[index]['dbamt'] = event.target.value;
    
    this.cal_TransAmt();
  }
  get_CrAcList(allocationFrom, cramt, disabled) {
    // alert(allocationFrom);
    let filter_alloc = [];
    filter_alloc = this.allocList.filter(indx => indx.allocationFrom === allocationFrom);
    if (filter_alloc.length > 0) {
      // alert(filter_alloc[0]['accName']);
      this.set_CrAc(filter_alloc[0]['accID'], filter_alloc[0]['accName'], allocationFrom, cramt, disabled)
    }

  }
  set_CrAc(accid, accname, acctype, cramt, disabled) {

    this.transDtRec.push(
      {
        docDID: 0,
        docID: 0,
        crID: accid,
        dbID: 0,
        cramt: cramt,
        dbamt: cramt,
        actRecNo: "",
        crAccName: accname,
        dbAccName: "",
        acctype: acctype,
        disabled: disabled,
        ActRecNo: "",
        FDAcType: "",
        BillDate: "01/01/1900"

      }
    );
  }
  recType = 0;
  // fill_debitAcList() {
  //   this.debitAcList = [];
  //   this.ngxService.start();
  //   let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getCashOrBankAcc';
  //   this._userService.get_Dashborad_Data(url).subscribe(
  //     (data) => {
  //       this.debitAcList = data;
  //       this.recType = 0;
  //       this.get_filter_debitAcList('0');

  //     });
  //   this.ngxService.stop();
  // }
  filter_debitAcList: any = [];
  get_filter_debitAcList(chqcash) {
    let stractype = '';
    if (chqcash == 0) {
      stractype = 'Bank'
      this.filterrectype = 0;
    }
    else {
      stractype = 'Cash'
      this.filterrectype = 1;
    }
    this.recType = chqcash
    this.filter_debitAcList = [];
    this.filter_debitAcList = this.debitAcList.filter(indx => indx.cashOrBank == stractype);
    if (this.filter_debitAcList.length > 0) {
      this.filterprinAccID = this.filter_debitAcList[0]['accID']
    }

  }
  set_debitAc(event) {
    // alert(event.target.value);
    this.filterprinAccID = event.target.value;
    // alert(this.filterprinAccID);
  }
  chq_list: any = [];
  get_chqbank(event) {
    // this.clear_Data();
    this.txtcity = '';
    this.txtbankname = '';
    this.txtbranchname = '';
    this.chq_list = [];
    this.ngxService.start();
    let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getBankDetailsByIFSCCode/' + event.target.value;
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        this.chq_list = data;
        this.txtcity = this.chq_list[0]['city'];
        this.txtbankname = this.chq_list[0]['bankName'];
        this.txtbranchname = this.chq_list[0]['branchName'];
      });
    this.ngxService.stop();

  }
  bind_ChqList: any = [];
  add_chqDt(selchqtype, txtchqno, txtchqdate, txtchqamt) {
    //alert(txtchqdate);

    //

    selchqtype = this.frmchq.controls['selchqtype'].value;

    if (txtchqdate == '') {
      alert('Enter Cheque Date');
    }
    else if (txtchqamt == '' || txtchqamt == '0') {
      alert('Enter Cheque Amount');
    }
    else if (txtchqno == '') {
      alert('Enter Cheque Number.');
    }
    else {
      let depositType = selchqtype; //this.get_deposit_Type(selchqtype);
      if (this.chqEditIndex == -1) {
        if (Number(this.branchID) > 0) {
          this.bind_ChqList.push({
            docID: 0,
            transChqID: 0,
            bankBranchID: this.branchID,
            estBankID: this.estBankID,
            city: this.city,
            bankName: this.estBankName,
            branchName: this.branchName,
            selchqtypeval: selchqtype,
            depositType: depositType,
            ifscCode: this.searchIfsc,
            chqdate: txtchqdate,
            chqno: txtchqno,
            chqamt: txtchqamt,
            billNo: txtchqno,
            billDt: txtchqdate,
            bindchqdate: txtchqdate,
            bounce: 'No',
            payDocID: 0,
            clear: 'Yes',
            clrDocID: 0,
            instrumentType: '',
            chequeClearingDt: "1900-01-01T00:00:00",
            isedit:'yes',
          });

          this.frmchq.setValue({
            selchqtype: '',
            txtchqno: '',
            txtchqamt: '',
            txtifsccode: '',
            txtchqdate: this.chqdate
          });

          this.branchID = 0;
          this.estBankID = 0;
          this.estBankName = '';
          this.branchName = '';
          this.city = '';

          this.txtbankname = '';
          this.txtbranchname = '';
          this.txtcity = '';
        }
        else {
          alert('Enter valid ifsc code');
        }
        this.chqEditIndex = -1
      }
      else {
        if (this.branchID > 0) {
          this.bind_ChqList[Number(this.chqEditIndex)]['bankBranchID'] = this.branchID;
          this.bind_ChqList[Number(this.chqEditIndex)]['estBankID'] = this.estBankID;
          //estBankID
          this.bind_ChqList[Number(this.chqEditIndex)]['city'] = this.city;
          this.bind_ChqList[Number(this.chqEditIndex)]['bankName'] = this.estBankName;
          this.bind_ChqList[Number(this.chqEditIndex)]['branchName'] = this.branchName;
          this.bind_ChqList[Number(this.chqEditIndex)]['selchqtypeval'] = selchqtype;
          this.bind_ChqList[Number(this.chqEditIndex)]['depositType'] = depositType;
          this.bind_ChqList[Number(this.chqEditIndex)]['ifscCode'] = this.searchIfsc;
          this.bind_ChqList[Number(this.chqEditIndex)]['chqdate'] = txtchqdate;
          this.bind_ChqList[Number(this.chqEditIndex)]['bindchqdate'] = txtchqdate;
          this.chqdate = txtchqdate;
          //bindchqdate: transChqDt[k]['billDt'],
          this.bind_ChqList[Number(this.chqEditIndex)]['chqno'] = txtchqno;
          this.bind_ChqList[Number(this.chqEditIndex)]['chqamt'] = txtchqamt;
          this.bind_ChqList[Number(this.chqEditIndex)]['billNo'] = txtchqno;
          this.bind_ChqList[Number(this.chqEditIndex)]['billDt'] = txtchqdate;
          this.bind_ChqList[Number(this.chqEditIndex)]['isedit'] ='yes';
          
          this.frmchq.setValue({
            selchqtype: '',
            txtchqno: '',
            txtchqamt: '',
            txtifsccode: '',
            txtchqdate: this.chqdate
          });

          this.branchID = 0;
          this.estBankID = 0;
          this.estBankName = '';
          this.branchName = '';
          this.city = '';

          this.txtbankname = '';
          this.txtbranchname = '';
          this.txtcity = '';

          this.chqAddUpdate = "Add";
          this.chqEditIndex = -1

        }
        else {
          alert('BranchID not get')
        }
      }


      this.tot_ChqAmt = this.bind_ChqList.reduce((sum, curr) => sum + Number(curr.chqamt), 0);
      
      //this.chq_list = [];
    }

  }
  public chqEditIndex: Number = -1;
  public chqAddUpdate: string = "Add";
  public tot_ChqAmt: Number = 0;
  set_chqEditVal(editindex, ifscCode, chqno, chqdate: Date, chqamt, depositType) {
    this.txtbankname = this.bind_ChqList[editindex]['bankName'];
    this.estBankName = this.bind_ChqList[editindex]['bankName'];
    this.txtbranchname = this.bind_ChqList[editindex]['branchName'];
    this.txtcity = this.bind_ChqList[editindex]['city'];
    this.branchID = this.bind_ChqList[editindex]['bankBranchID'];
    this.estBankID = this.bind_ChqList[editindex]['estBankID'];
    this.searchIfsc = ifscCode;

    //chqdate = this.bind_ChqList[editindex]['bindchqdate'];

    

    this.set_searchChqBank(ifscCode, this.branchID, this.estBankID, this.txtbankname, this.txtbranchname, this.txtcity)

    this.chqEditIndex = editindex;
    this.chqAddUpdate = "Update";
    let date: Date;
    date = new Date(chqdate);
    
    if (this.bind_ChqList[editindex]['isedit'] == 'yes'){
    }
    else{
      date.setDate(date.getDate() + 1);
    }
    
    date.setDate(date.getDate());
    this.chqdate = new Date(date).toISOString().substring(0, 10);;
    this.frmchq.setValue({
      selchqtype: depositType,
      txtchqno: chqno,
      txtchqamt: chqamt,
      txtifsccode: ifscCode,
      txtchqdate: this.chqdate
    });

    // this.chq_list = [];
    // this.ngxService.start();
    // let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getBankDetailsByIFSCCode/' + ifscCode;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.chq_list = data;
    //     this.chqEditIndex = editindex;
    //     this.chqAddUpdate = "Update";
    //     let date: Date;
    //     date = new Date(chqdate);
    //     date.setDate(date.getDate() + 1);
    //     this.chqdate = new Date(date).toISOString().substring(0, 10);;
    //     this.frmchq.setValue({
    //       txtchqno: chqno,
    //       txtchqamt: chqamt,
    //       txtifsccode: ifscCode,
    //       txtchqdate: this.chqdate
    //     });

    //   });
    // this.ngxService.stop();

  }
  get_deposit_Type(val) {
    if (val == 0) {
      return '';
    }
    else if (val == 1) {
      return 'Direct Deposit';
    }
    else if (val == 2) {
      return 'RTGS';
    }
    else if (val == 3) {
      return 'Transfer';
    }
    else if (val == 4) {
      return 'NEFT';
    }
    else if (val == 5) {
      return 'IMPS';
    }
  }


  cal_TransAmt() {
    this.transAmt = 0;
    if (this.transDtRec.length > 0) {
      for (let k = 0; k < this.transDtRec.length; k++) {
        // if (this.transDtRec[k]['acctype'] == 'Wages') {
        //   this.wagesAmt = this.transDtRec[k]['cramt']

        // }
        // else if (this.transDtRec[k]['acctype'] == 'Levy') {
        //   this.levyAmt = this.transDtRec[k]['cramt'];

        // }
        // else if (this.transDtRec[k]['acctype'] == 'Anamat') {
        //   this.anamtAmt = this.transDtRec[k]['cramt'];

        // }

        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(0);
      }

    }
    
  }
  rec_submit:boolean = false;
  insert_update(dbAccID) {
    let isSave: boolean = true;

    if (Number(this.filterYr.toString().length) != 4) {
      alert('Enter valid wage year');
      isSave = false;
      return;
    }

    if (Number(this.filterYr) <= 0) {
      alert('Enter valid wage year');
      isSave = false;
      return;
    }

    if (Number(this.filterMonth) > 0 && Number(this.filterMonth) < 13) {
    }
    else {
      alert('Enter valid wage month');
      isSave = false;
      return;
    }

    if (Number(dbAccID) == 0 || dbAccID == '') {
      isSave = false;
      alert('Select Debit A/C')
      return;
    }


    if (this.recType == 1) {
      let cashAccID_FromAllocation = 0;
      let filter_alloc = [];
      filter_alloc = this.allocList.filter(indx => indx.allocationFrom === 'Cash A/C');
      if (filter_alloc.length > 0) {
        cashAccID_FromAllocation = filter_alloc[0]['accID'];
      }
      if (Number(cashAccID_FromAllocation) > 0) {
        if (Number(dbAccID) != Number(cashAccID_FromAllocation)) {
          alert('Cash A/C from allocation and selected debit a/c not same.')
          isSave = false;
          return;
        }
      }
      else {
        alert('Cash A/C Not get from allocation.')
        isSave = false;
        return;
      }

    }

    this.transAmt = 0;
    if (this.transDtRec.length > 0) {
      for (let k = 0; k < this.transDtRec.length; k++) {
        if (Number(this.transDtRec[k]['cramt']) < 0) {
          alert("Check amount < 0 not valid")
          isSave = false;
          return;
        }
        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(0);
      }
    }


    let TotChqAmt = 0;
    let transChqDt: any = [];
    if (this.recType == 0) {
      if (this.bind_ChqList.length > 0) {
        for (let k = 0; k < this.bind_ChqList.length; k++) {
          if (Number(this.bind_ChqList[k]['chqamt']) < 0) {
            alert("Check cheque amount < 0 not valid")
            isSave = false;
            return;
          }

          TotChqAmt += Number(this.bind_ChqList[k]['chqamt'])
        }
        if (Number(this.transAmt) - Number(TotChqAmt) != 0) {
          alert("Check Cheque Amount And Debit Amount Has Diffrance.")
          isSave = false;
          return;
        }
      }
      else {
        alert("Enter Cheque Details.");
        isSave = false;
        return;
      }

    }
    else {
      this.bind_ChqList = [];
    }



    transChqDt = [];

    let mon, yr;
    mon = new Date(this.recDate).getMonth() + 1;
    yr = new Date(this.recDate).getFullYear();

    if (mon < 4) {
      this.fayear = yr - 1;
    }
    else {
      this.fayear = yr;
    }

    if (this.fayear != this.filterFayear) {
      alert('Enter date selected finacial year.')
      isSave = false;
      return;
    }

    let dd, mm, yy;
    dd = new Date(this.recDate).getDate();
    mm = new Date(this.recDate).getMonth() + 1;
    yy = new Date(this.recDate).getFullYear();

    let strPayDt = this.recDate;//dd + '-' + mm + '-' + yy
    
    let transRec = {
      "DocID": this.docID,
      "ReceiptNO": 0,
      "Docdt": strPayDt,
      "IsRecGenrate": "Yes",
      "TransAmt": this.transAmt,
      "Billno": "",
      "BillDt": strPayDt,
      "Naration": "Being Collection As Per Receipt Register For " + this.searchMar,
      "Wageyr": this.filterYr,
      "WageMon": this.filterMonth,
      "MarketID": this.marketID,
      "EstID": this.estID,
      "CompanyID": this.companyID,
      "UserID": this.userID,
      "Fayear": this.fayear,
      "Flag": "Rec",
      "Bounce": "No",
      "Remark": "Auto Entry",
      "ChequeClearingDt": strPayDt,
      "ToliID": this.toliID,
      "LevyCode": this.levyCode,
      "EstBankAccID": 0,
      "FromDt": strPayDt,
      "ToDt": strPayDt,
      "SalesID": 0,
      "RecFor": "",
      "EmpName": "",
      "SubEstMstID": 0,
      "LessAdvance": this.lessAdvance,
    }

    

    for (let k = 0; k < this.transDtRec.length; k++) {
      this.transDtRec[k]['dbID'] = dbAccID;
    }


    if (isSave == true) {
      this.rec_submit = true;
      let WageLevy: any = {
        "TransMst": transRec,
        "TransDt": this.transDtRec,
        "TransChq": this.bind_ChqList

      }
      

      //return;

      this.ngxService.start();
      let body = new FormData();
      body = WageLevy;
      let url = 'api/' + this._userService.get_trans_controlerName(this.Log_BoardID) + '/savetrans';
      //alert(url);
      this._userService.post_data(url, body).subscribe(
        (data) => {
          if (Number(data) > 0) {
            this.docID = Number(data);
            this.Bind_Data();
            alert('Record saved successfully.');
           this.rpt_print = 'yes'
           this.rec_submit = false;
           // this.generatePdf();
            //this.router.navigate(['/dashboard/clerk-dashboard']);
          }
          else{
            alert(data);
            this.rec_submit = false;
          }
        });
      this.ngxService.stop();
    }
    
  }

  check() {
    alert(this.filterprinAccID);
  }
  del_chq(index) {
    if (window.confirm('Are sure you want to delete this entry ?')) {
      this.bind_ChqList.splice(index, 1);
      this.tot_ChqAmt = this.bind_ChqList.reduce((sum, curr) => sum + Number(curr.chqamt), 0);

      this.chqAddUpdate = "Add";
      this.chqEditIndex = -1
      this.frmchq.setValue({
        selchqtype: '',
        txtchqno: '',
        txtchqamt: '',
        txtifsccode: '',
        txtchqdate: this.chqdate
      });

      this.branchID = 0;
      this.estBankID = 0;
      this.estBankName = '';
      this.branchName = '';
      this.city = '';

      this.txtbankname = '';
      this.txtbranchname = '';
      this.txtcity = '';
    }

  }
  delte_entry() {
    if (Number(this.docID) > 0) {
      if (window.confirm('Are sure you want to delete this entry')) {
        this.ngxService.start();

        let url = 'api/' + this._userService.get_trans_controlerName(this.Log_BoardID) + '/deltransmstdt/' + this.docID;
        //alert(url);
        this._userService.Delete_Enrty_ByID(url).subscribe(
          (data) => {
            alert(data);
            if (data == 'Record deleted successfully.') {
              this.router.navigate(['/dashboard/clerk-dashboard']);
            }
          });
        this.ngxService.stop();
      }
    }

  }

  lessAdvance = 0;
  act_WageAmt: number = 0;
  cal_levy(event, acctype) {
    
    if ((event.keyCode == 13 || event.keyCode == 9) && acctype == "Wages") {
      this.lessAdvance = 0;
      this.act_WageAmt = 0;
      let lper = Number(this.tot_LevyPer);

      let lamt = Number((Number(event.target.value) * Number(lper)) / 100).toFixed(0);

      this.act_WageAmt = Number(event.target.value);

      this.set_levyAmt(Number(event.target.value), lamt);
    }
    else if (event.keyCode == 40 && acctype == "Wages") {
      this.lessAdvance = 0;
      this.act_WageAmt = 0;

      let lper = Number(this.tot_LevyPer) + 100;

      let lamt = Number((Number(event.target.value) / Number(lper)) * 100).toFixed(0);

      lamt = Number(Number(event.target.value) - Number(lamt)).toFixed(0);
      let wageAmt = Number(Number(event.target.value) - Number(lamt)).toFixed(0);

      this.act_WageAmt = Number(wageAmt);

      this.set_levyAmt(wageAmt, lamt);
    }
  }
  set_levyAmt(wagesamt, lamt) {
    if (this.transDtRec.length > 0) {
      this.transAmt = 0;
      for (let k = 0; k < this.transDtRec.length; k++) {
        if (this.transDtRec[k]['acctype'] == 'Wages') {
          this.transDtRec[k]['cramt'] = wagesamt;
          this.transDtRec[k]['dbamt'] = wagesamt;

        }
        if (this.transDtRec[k]['acctype'] == 'Levy') {
          this.transDtRec[k]['cramt'] = lamt;
          this.transDtRec[k]['dbamt'] = lamt;
        }
        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(0);
      }
    }
  }

  set_wages_after_LessAdv(event) {
    if (this.transDtRec.length > 0) {
      this.transAmt = 0;
      for (let k = 0; k < this.transDtRec.length; k++) {
        if (this.transDtRec[k]['acctype'] == 'Wages') {
          this.transDtRec[k]['cramt'] = Number(Number(this.act_WageAmt) - Number(this.lessAdvance)).toFixed(0);
        }
        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(0);
      }
    }
  }
  setReciptNo(event) {
    this.receiptNO = event.target.value;
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  // public downloadAsPDF() {
  //   const doc = new jsPDF();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const pdfTable = this.pdfTable.nativeElement;

  //   doc.fromHTML(pdfTable.innerHTML, 15, 15, {
  //     width: 190,
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save('tableToPdf.pdf');
  // }


  save_newChqBank(ifsccode) {

    var reg = /^([a-zA-Z]){4}([0-9]){7}?$/;
    if (!reg.test(ifsccode)) {
      alert('Invalid ifsc code');
      return;
    }


  }

  get f() { return this.frm_newChqBank.controls; }
  onSubmit_newChqBank() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.frm_newChqBank.invalid) {
      // alert('Invalid!! :-)')
      return;
    }

    // let ifsccode = this.frm_newChqBank.controls['nifsccode'].value;
    // var reg = /^([a-zA-Z]){4}([0-9]){7}?$/;
    // if(!reg.test(ifsccode)) {

    //   return;
    // }

    // let bankName:string;
    // let branchName:string; 
    // let cityName:string;
    let ifscCode: string;

    // bankName = this.frm_newChqBank.controls['nbank'].value;
    // branchName = this.frm_newChqBank.controls['nbranch'].value;
    // cityName = this.frm_newChqBank.controls['ncity'].value;
    ifscCode = this.frm_newChqBank.controls['nifsccode'].value;

    let newChqBnk: any = {
      "EstBankName": this.frm_newChqBank.controls['nbank'].value,
      "BranchName": this.frm_newChqBank.controls['nbranch'].value,
      "City": this.frm_newChqBank.controls['ncity'].value,
      "IFSCCode": this.frm_newChqBank.controls['nifsccode'].value
    }

    let body = new FormData();
    body = newChqBnk;
    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/savenewchqBank';
    //alert(url);
    this._userService.post_data(url, body).subscribe(
      (data) => {

        if (data == "Record Saved Sucessfully") {
          alert(data);
          this.fill_chqBankDt_List(ifscCode);
          this.hide()
          this.clear_newChq_Val()
        }
        else {
          alert(data);
        }
      });
    this.ngxService.stop();

  }
  clear_newChq_Val() {
    this.submitted = false;
    this.frm_newChqBank.controls['nbank'].setValue('');
    this.frm_newChqBank.controls['nbranch'].setValue('');
    this.frm_newChqBank.controls['ncity'].setValue('');
    this.frm_newChqBank.controls['nifsccode'].setValue('');

  }
  show() {
    this.showModal = true; // Show-Hide Modal Check
    this.content = '';// "This is content!!"; // Dynamic Data
    this.title = "";    // Dynamic Data
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

  public generatePdf()
  {
  var data = document.getElementById('contentToConvert');
  html2canvas(data).then(canvas => {
  // Few necessary setting options
  var imgWidth =208;
  var pageHeight = 295;
  var imgHeight = canvas.height * imgWidth / canvas.width;

  imgHeight = 84;

  var heightLeft = imgHeight;
   
  const contentDataURL = canvas.toDataURL('image/png')
  let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
  var position = 10;
  pdf.addImage(contentDataURL, 'PNG',0, position, imgWidth, imgHeight)
  let flName = this.docID.toString() +"wlRec.pdf"
  pdf.save(flName); // Generated PDF
  });

  //this.rpt_print = '';
 // this.docID = 0;

  this.router.navigate(['/dashboard/clerk-dashboard']);

  }
  

}
