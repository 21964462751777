//#region  imports
import { Component, OnInit,ElementRef ,ViewChild } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Key } from 'protractor';
import { multicast } from 'rxjs/operators';

//import * as jsPDF from 'jspdf';

import jspdf from 'jspdf';

import html2canvas from 'html2canvas';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslationWidth } from '@angular/common';
import { runInThisContext } from 'vm';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
//#endregion
@Component({
  selector: 'app-wl-receipt-pimmatha',
  templateUrl: './wl-receipt-pimmatha.component.html',
  styleUrls: ['./wl-receipt-pimmatha.component.css']
})
export class WlReceiptPimmathaComponent implements OnInit {

  // title = 'angular-text-search-highlight';
  searchText = '';
  searchToli = '';
  characters = [
    'Ant-Man',
    'Aquaman',
    'Asterix',
    'The Atom',
    'The Avengers',
    'Batgirl',
    'Batman',
    'Batwoman'
  ];

  //#region amt. in words
  n: string;
  a = ['zero ', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  inWords(num): string {
    num = Math.floor(num * 100);
    if ((num = num.toString()).length > 11) { return 'overflow'; }
    let n;
    n = ('00000000' + num).substr(-11).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})(\d{1})(\d{1})$/);
    if (!n) { return; } let str = '';

    // tslint:disable-next-line:triple-equals
    str += (n[1] != 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'crore ' : '';
    // tslint:disable-next-line:triple-equals
    str += (n[2] != 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'lakh ' : '';
    // tslint:disable-next-line:triple-equals
    str += (n[3] != 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'thousand ' : '';
    // tslint:disable-next-line:triple-equals
    str += (n[4] != 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) : 'hundred';
    // tslint:disable-next-line:triple-equals
    str += (n[5]) ? (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' + this.a[n[5][1]]) : '';
    // tslint:disable-next-line:triple-equals
    str += (n[6]) ? ((str != '') ? 'point ' : '') + (this.a[Number(n[6])] || this.b[n[6][0]] + ' ' + this.a[n[6][1]]) : '';
    // tslint:disable-next-line:triple-equals
    str += (n[7] != 0) ? (this.a[Number(n[7])] || this.b[n[7][0]] + ' ' + this.a[n[7][1]]) : '';

    return str;
  }

  //#endregion

  //#region variables
  frm_newChqBank: FormGroup;
  submitted = false;

  frmchq: FormGroup;

  showModal: boolean;
  content: string;
  title: string;

  expandContent = true;
  public dasbordfor: string = '';
  public tlList: any = [];
  public tlLocist: any = [];
  public debitAcList: any = [];
  public filterFayear;
  public filterYr: number = 0;
  public filterMonth: number = 0;
  public filterBoradID: number = 0;
  public filterprinAccID: number = 0;
  public filterTrAccID: number = 0;
  public filterrectype: number = 0;

  public wagesAmt = 0;
  public anamtAmt = 0;
  public levyAmt = 0;
  public admenstrative = 0;

  public act_wagesAmt = 0;
  public act_anamatAmt = 0;
  public act_levyAmt = 0;
  public act_admenstrative = 0;

  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  public Log_accessFor: string = '';
  wlmstdata: any = [];
  prinempid: number = 0;
  prinempname: string = '';
  prinempno: string = '';
  chqbankname: string = '';
  chqbranchname: string = '';
  chqcityname: string = '';
  chqdate;
  recDate;
  ifsccode: string = '';

  docID;
  toliID;
  levy: string = 'Yes';
  receiptNO;
  docdt;
  transAmt;
  wageyr;
  wageMon;
  locationID;
  prinEmpID;
  flag = 'Rec';
  naration;
  remark = 'Entry From Board Wages Levy';
  levyCode;
  fromDt;
  toDt;
  recFor;
  fayear;
  userID;
  companyID;
  prinEmpGrdName;
  dedParaCode;
  talukaID;
  servTaxParaCode;
  agencyID;
  noExPrinemp;
  noExGrd;
  isRecGenrate;
  regNo;
  pEmpName;

  txtcity: string = '';
  txtbankname: string = '';
  txtbranchname: string = '';
  recTypeList: any = [];
  rpt_print:string = '';

  ///////////

  toliInfo: string = '';
  toliserchCnt = '';

  estInfo: string = '';
  estserchCnt = '';

  subestInfo: string = '';
  subestName: string = '';
  subestserchCnt = '';

  marInfo: string = '';
  marserchCnt = '';
  marketID;

  compInfo: string = '';

  toli_List = [];
  est_List = [];
  subest_List = [];
  filter_est = [];
  filter_subest = [];

  marcom_List = [];
  filter_marcom = [];

  chqbank_List = [];
  filter_chqbank = [];

  levyPara_list = [];
  tot_LevyPer = 0;
  tot_AnamatPar = 0;

  loading:string = '';

  /////

  public user_AuthList:any=[];

  //#endregion
  
  //#region constructor
  constructor(
    config: NgbAccordionConfig,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
  ) {
    this.recTypeList = [{
      typeVal: 0,
      rectype: 'Cheque'
    },
    {
      typeVal: 1,
      rectype: 'Cash'
    }
    ,
    {
      typeVal: 2,
      rectype: 'Cash In Bank'
    }
    ]
    this.docID = Number(this.route.snapshot.queryParamMap.get('docid'));
    this.toliID = Number(this.route.snapshot.queryParamMap.get('toliID'));
    this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));
    this.rpt_print = this.route.snapshot.queryParamMap.get('print').toString();

   // this.rpt_print ='yes';
    config.closeOthers = true;
    config.type = 'info';

    this.filterBoradID = 0;
    this.filterMonth = 0;
    this.filterYr = 0;
    //this.filterFayear = 0;
    this.dasbordfor = 'Labour';

    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }
    this.userID = this.Log_UserID;


    //this.fill_Allocaton();
   }
   //#endregion
 
 //#region ngOnInit

  ngOnInit(): void {
    this.frm_newChqBank = this.formBuilder.group({
      nbank: ['', Validators.required],
      nbranch: ['', Validators.required],
      ncity: ['', Validators.required],
      nifsccode: ['', [Validators.required, Validators.minLength(11)]]
    });

    this.ngxService.start();
   
    this.chqdate = new Date().toISOString().substring(0, 10);
    this.recDate = new Date().toISOString().substring(0, 10);

    this.chqAddUpdate = "Add";
    this.frmchq = this.formBuilder.group({
      selchqtype: [''],
      txtchqno: ['', Validators.required],
      txtchqamt: ['', Validators.required],
      txtifsccode: [''],
      txtchqdate: [this.chqdate],
      txtnewsubEstName: [''],
      //txtchqdate:[this.chqdate]
    });

    this.user_AuthList = this._userService.get_userAuthDt();
    //console.log('user_AuthList : ',this.user_AuthList)

    // this.fill_debitAcList();
    
      this.fill_AddMstData();

    // if (this.docID == 0 || this.docID == '' || this.docID == null) {
    //   // this.recDate = new Date().toISOString().substring(0, 10);
    //   // this.set_wageYr_Month();
    // }
    // else {
    //   this.Bind_Data();
    // }


    this.ngxService.stop();
  }

  //#endregion

  //#region search filter
set_searchItem(selName) {
  this.searchText = '';
  this.toliInfo = selName;
}
show_serchCnt() {
  if (this.searchText == '') {
    this.searchText = 'yes';
  }
  else {
    this.searchText = '';
  }
  
}
filter_toli = [];
serch_toli() {
  this.filter_est = [];
  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';

  this.toliID = 0;
  this.toliInfo = '';

  this.filter_toli = this.toli_List.filter((item) => {
    return ((item.mukName.toString()).indexOf(this.searchToli.toString().toLowerCase()) > -1);
  });

  
}
get_toli_byToliNo(event) {
  let toliNo = Number(event.target.value);
 // console.log('enter tolino',toliNo)
  this.filter_est = [];
  this.filter_LabBank_Type_List=[];
  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';

  this.toliID = 0;
  this.toliInfo = '';
  this.bind_ChqList=[];

  let filter_tl = this.toli_List.filter(x => x.toliNo == Number(toliNo));
    if (filter_tl.length == 1) {
      this.toliID = filter_tl[0]['toliId'];
      this.toliInfo = filter_tl[0]['mukName'];
      this.levy = filter_tl[0]['levy'];
      this.serch_est(this.toliID,'');
      this.fill_labBankType(this.toliID);
    }
    else if (filter_tl.length > 1) {
      this.filter_toli = filter_tl;
    }
    else{
      alert('Enter valid toli no.');
    }

  
}
fill_labBankType(toliID){
  this.filter_LabBank_Type_List=[];
  this.filter_LabBank_Type_List = this.LabBank_Type_List.filter(x => x.toliId == Number(toliID));
  console.log('this.filter_LabBank_Type_List',this.filter_LabBank_Type_List);
}
set_searchToli(toliID, toliNo, toliName,levy) {
  this.toliID = 0;
  this.toliInfo = '';

  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';

  this.searchToli = '';
  this.toliID = toliID;
  this.toliInfo = toliName;
  this.levy = levy;
  this.toliserchCnt = '';
  this.bind_ChqList=[];
  this.filter_LabBank_Type_List=[];
  this.serch_est(toliID,'');
  this.fill_labBankType(toliID);
}
show_serchtoli_Cnt() {
  this.toliserchCnt = 'yes';
  this.filter_toli = this.toli_List;
}

/////filter est
searchEst = '';
serch_est(toliID,event) {
  
  this.filter_marcom = [];

  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';
//alert('done')
  if (Number(toliID) > 0) {

    this.filter_est = this.est_List.filter(x => x.toliId == toliID);
    
    if (this.filter_est.length > 0) {
      if (this.filter_est.length == 1) {
        this.estID = this.filter_est[0]['estID'];
        this.estInfo = this.filter_est[0]['filterval'];
        this.estserchCnt = '';

        this.serch_mar(this.estID);
      }
      else {
        this.estserchCnt = 'yes';
      }

    }
    else {
      this.estserchCnt = '';
    }

  }
  else {

    this.searchEst = event.target.value;

    this.filter_est = this.est_List.filter(x => x.toliId == this.toliID);

  //  console.log('search text: ',this.searchEst.toString().toLowerCase())

    this.filter_est = this.filter_est.filter((item) => {
      return ((item.filterval.toString().toLowerCase()).indexOf(this.searchEst.toString().toLowerCase()) > -1);
    });

   // console.log('this.filter_est',this.filter_est)

  }


  
}
estID;
subestID;
set_searchEst_Cnt(estID, estNo, estName) {
  this.filter_marcom = [];

  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';

  this.searchEst = '';
  this.estID = estID;
  this.estInfo = estName;
  this.estserchCnt = '';

 // this.serch_subest(this.estID);
 // this.serch_mar(this.estID);
  
}
get_est_byEstNo(event) {
  let estNo = Number(event.target.value);
 // console.log('enter estno',estNo)
  
  this.filter_marcom = [];

  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';

  this.searchEst = '';
  //this.estserchCnt = '';

  let filter_ = this.filter_est.filter(x => x.estRegNo == Number(estNo));
  //console.log('filter',filter_);
    if (filter_.length == 1) {

      this.estserchCnt = '';

      this.estID = filter_[0]['estID'];
      this.estInfo = filter_[0]['filterval'];
     
     // this.serch_subest(this.estID);
     // this.serch_mar(this.estID);

    }
    else if (filter_.length > 1) {
      this.estserchCnt = 'yes';
    }
    else{
      alert('Enter valid est no.');
    }

  
}
show_serchest_Cnt() {

  if (this.toliID > 0) {
    //this.filter_est = this.est_List;
    if (this.filter_est.length > 1) {
      this.estserchCnt = 'yes';
    }
    this.estserchCnt = 'yes';

  }
  else {
    alert("Select Toli");
  }

}

/////filter subest
searchsubEst = '';
serch_subest(estID) {
  this.newsubEstName = '';
  this.subestInfo = ''
  this.subestName = '';
  this.newsubEstName='';
  this.selected_SubEstName='';

  this.subestID = 0;
  
  if (Number(estID) > 0) {

    this.filter_subest = this.subest_List.filter(x => x.estID == estID);
    console.log('this.filter_subest',this.filter_subest)
    if (this.filter_subest.length > 0) {
      if (this.filter_subest.length == 1) {
        this.subestID = this.filter_subest[0]['ourSubEstID'];
        this.estInfo = this.filter_subest[0]['filterval'];
        this.selected_SubEstName = this.filter_subest[0]['filterval'];
        this.subestserchCnt = '';

      }
      else {
        this.subestserchCnt = 'yes';
      }

    }
    else {
      this.subestserchCnt = '';
    }

  }
  else {
    this.filter_subest = this.subest_List.filter(x => x.estID == this.estID);

    this.filter_subest = this.filter_subest.filter((item) => {
      return ((item.filterval.toString()).indexOf(this.searchsubEst.toString().toLowerCase()) > -1);
    });
  }


  
}
selected_SubEstName = '';
set_searchsubEst_Cnt(estID, estNo, estName) {
 this.newsubEstName = '';
 this.selected_SubEstName = '';
  this.subestInfo = ''
  this.subestName = '';
  this.newsubEstName='';
  this.subestID = 0;
  
  this.searchsubEst = '';
  this.subestID = estID;
  this.subestInfo = estName;
  this.subestName = estName;
  this.subestserchCnt = '';
  this.selected_SubEstName = estName;
  

}
show_serchsubest_Cnt() {

  if (this.estID > 0) {
    //this.filter_est = this.est_List;
    if (this.filter_subest.length > 1) {
      this.subestserchCnt = 'yes';
    }

  }
  
}

/////filter market
searchMar = '';
serch_mar(estID) {

  if (Number(estID) > 0) {

    this.filter_marcom = this.marcom_List.filter(x => x.estID == estID);
    
    if (this.filter_marcom.length > 0) {
      if (this.filter_marcom.length == 1) {
        this.marketID = this.filter_marcom[0]['marketID'];
        this.marInfo = this.filter_marcom[0]['filterval'];
        this.marserchCnt = '';
        this.compInfo = this.filter_marcom[0]['companyname'];
        this.companyID = this.filter_marcom[0]['companyID'];
      }
      else {
        this.marserchCnt = 'yes';
      }

    }
    else {
      this.marserchCnt = '';
    }

  }
  else {

    this.filter_marcom = this.marcom_List.filter(x => x.estID == this.estID);

    this.filter_marcom = this.filter_marcom.filter((item) => {
      return ((item.filterval.toString()).indexOf(this.searchMar.toString().toLowerCase()) > -1);
    });

  }

}

set_searchMar_Cnt(marketID, marNo, marName, compID, CompName) {
  this.marketID = marketID;
  this.marInfo = marName;
  this.marserchCnt = '';
  this.compInfo = CompName;
  this.companyID = compID;
}
show_serchmar_Cnt() {

  if (this.estID > 0) {

    if (this.filter_marcom.length > 1) {
      this.marserchCnt = 'yes';
    }

  }
  else {
    alert("Select Establishment");
  }

}

//////

/////////
searchIfsc;

serch_ifsc() {
  this.branchID = 0;
  this.estBankID = 0;
  this.estBankName = '';
  this.branchName = '';
  this.city = '';

  this.txtbankname = '';
  this.txtbranchname = '';
  this.txtcity = '';


  this.filter_chqbank = this.chqbank_List.filter((item) => {
    return ((item.filterval.toString().toLowerCase()).indexOf(this.searchIfsc.toString().toLowerCase()) > -1);
  });

}

branchID;
estBankID;
estBankName;
branchName;
city
set_searchChqBank(ifscCode, branchID, estBankID, estBankName, branchName, city) {

  this.searchIfsc = ifscCode;
  this.branchID = branchID;
  this.estBankID = estBankID;
  this.estBankName = estBankName;
  this.branchName = branchName;
  this.city = city;

  this.txtbankname = estBankName;
  this.txtbranchname = branchName;
  this.txtcity = city;

  this.filter_chqbank = [];

  //console.log(ifscCode, branchID, estBankID, estBankName, branchName, city);

}
set_NA_ChqBank(event) {
if (event.target.value == '' || event.target.value =='0'){
  this.searchIfsc = event.target.value;
  this.branchID = 0;
  this.estBankID = 0;
  this.estBankName = 'NA';
  this.branchName = 'NA';
  this.city = 'NA';

  this.txtbankname = 'NA';
  this.txtbranchname = 'NA';
  this.txtcity = 'NA';

  this.filter_chqbank = [];
}
  

  //console.log(ifscCode, branchID, estBankID, estBankName, branchName, city);

}
//#endregion

  //#region  comon functions


  getdate_ddmmyyy(date_) {
    var date_to_parse = new Date(date_);
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();
    //alert(day + '/' +  month + '/' + year);
    return day + '/' + month + '/' + year;
  }

  set_wageYr_Month() {
    var date_to_parse = new Date(this.recDate);
    var year = date_to_parse.getFullYear().toString();
    var month = (date_to_parse.getMonth() + 1).toString();
    var day = date_to_parse.getDate().toString();

    if (Number(month) == 1) {
      this.filterMonth = 12;
      this.filterYr = Number(year) - 1;
    }
    else {
      this.filterMonth = Number(month) - 1;
      this.filterYr = Number(year);
    }
  }

  invalidDate='';
  vlaidateDate(event) {
    this.invalidDate='invalid';
    let chkDt = new Date(event.target.value);
   // 
     if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
      this.invalidDate = 'invalid';
    }
    else{
      if (chkDt.getFullYear()){
        this.invalidDate='';
      }
      else{

      }
      
    }
  }
  invalid_ChqDate='';
  validate_ChqDate(event) {
    this.invalid_ChqDate='invalid';
    let chkDt = new Date(event.target.value);
   // 
     if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
      this.invalid_ChqDate = 'invalid';
    }
    else{
      if (chkDt.getFullYear()){
        this.invalid_ChqDate='';
      }
      else{

      }
      
    }
  }
  set_ChqDate(event) {
    // this.recDate = event.target.value;
  
    let chkDt = new Date(event.target.value);
 //
 
    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
    }
    else{
     this.chqdate = new Date(chkDt).toISOString().substring(0, 10);;
     
    }
  
   }
  invalidYear='';
  invalidMonth = '';
  vlaidateMonth(event) {
    this.invalidMonth='invalid';
    let chkval = event.target.value;
   // 
     if (Number(chkval) < 1 || Number(chkval) > 12){
      this.invalidMonth = 'invalid';
    }
    else{
      if (chkval){
        this.invalidMonth='';
      }
    }
  }
  vlaidateYear(event) {
    this.invalidYear='invalid';
    let chkval = event.target.value;
   // 
     if (Number(chkval) < 1980 || Number(chkval) > 2099){
      this.invalidYear = 'invalid';
    }
    else{
      if (chkval){
        this.invalidYear='';
      }
    }
  }
  setDate(event) {
   // this.recDate = event.target.value;
 
   let chkDt = new Date(event.target.value);
//

   if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099){
   }
   else{
    this.recDate = new Date(chkDt).toISOString().substring(0, 10);;
    this.set_wageYr_Month();
   }
 
  }
  //#endregion

  //#region fill add mode data
  est_ApiAmtList:any=[];
  get_estUpAmt(){
    if (window.confirm('Are you sure to get amount.?')) {
      this.show_loadingModal(true);
      this.est_ApiAmtList = [];
      let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/getbankestUPIexcel/' + this.toliID + '/' + this.filterYr + '/' + this.filterMonth;
      this._userService.get_Data(url).subscribe(
        (data) => {
          this.est_ApiAmtList = data;
          console.log('this.est_ApiAmtList',this.est_ApiAmtList);
          if (Number(this.est_ApiAmtList.length) > 0 && Number(this.bind_ChqList.length) > 0){
            //this.bind_ChqList[Number(this.chqEditIndex)]['subEstName']
            let filter_aupiAmt:any=[];
            for (let k = 0; k < this.bind_ChqList.length; k++) {
              this.bind_ChqList[k]['isUpiAmt'] = '';
              filter_aupiAmt = this.est_ApiAmtList.filter(x => Number(x.estID) == Number(this.bind_ChqList[k]['estID']));
              if (Number(filter_aupiAmt.length) > 0){
                this.bind_ChqList[k]['chqamt'] = filter_aupiAmt[0]['amt'];
                this.bind_ChqList[k]['isUpiAmt'] = 'Yes';
              }
            }
          }
          
          console.log('this.bind_ChqList',this.bind_ChqList);
          this.show_loadingModal(false);
          this.tot_ChqAmt = this.bind_ChqList.reduce((sum, curr) => sum + Number(curr.chqamt), 0);
        },
        (err: HttpErrorResponse) => {
          this.show_loadingModal(false);
          alert(err);
          return;
        });
    }
    
  }
  LabBank_Type_List:any=[];
  filter_LabBank_Type_List:any=[];
  fill_AddMstData() {

    this.estID = 0;

    this.toli_List = [];
    this.filter_toli = [];
    this.LabBank_Type_List = [];
    this.filter_LabBank_Type_List = [];
    this.toliInfo = '';
    this.estInfo = '';
    this.selected_SubEstName='';

    //this.toliID = 0;
    this.toliInfo = '';
  
    this.estInfo = ''
    this.estID = 0;
    this.marketID = 0;
    this.marInfo = '';
  
    this.searchToli = '';
    //this.toliID = 0;
    this.toliInfo = '';
    this.toliserchCnt = '';

    this.newsubEstName = '';
    this.est_List = [];
    this.subest_List=[];
    this.filter_est = [];
    this.filter_subest = [];

    this.marcom_List = [];
    this.filter_marcom = [];
    this.marInfo = '';
    this.compInfo = '';
    this.marketID = 0;
    this.companyID = 0;

    this.chqbank_List = [];
    this.filter_chqbank = [];
    this.bind_ChqList=[];


    this.levyPara_list = [];
    this.levyCode = 0;
    this.tot_LevyPer = 0;
    this.tot_AnamatPar = 0;
    this.receiptNO = 0;

    this.recPaid = 'No';

    this.loading='y';
    this.show_loadingModal(true);
    
    this.recDate = new Date().toISOString().substring(0, 10);
    this.set_wageYr_Month();

    ///Fill Toli List
    //let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/gettoliList';

    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/getWlRecMasterData/' + this.filterFayear;
    this._userService.get_Data(url).subscribe(
      (data) => {
        console.log('data : ',data)
        this.toli_List = data.wlRecToliDt;
        this.LabBank_Type_List = data.wlLabBankType;
       
        if (Number(this.toliID) > 0) {
          this.filter_toli = this.toli_List.filter(x => x.toliId == this.toliID);
          
          if (Number(this.filter_toli.length) > 0) {
            this.toliInfo = this.filter_toli[0]['mukName'];
            this.toliserchCnt = '';
            this.set_searchToli(this.filter_toli[0]['toliId'],this.filter_toli[0]['toliNo'],this.filter_toli[0]['mukName'],this.filter_toli[0]['levy'])
          }
          else {
            this.toliserchCnt = 'yes';
          }
        }

        ///Est List

        this.est_List = data.wlRecEstDt;
        
        if (Number(this.toliID) > 0) {
          this.filter_est = this.est_List.filter(x => x.toliId == this.toliID);
        
          if (this.filter_est.length > 0) {
            if (this.filter_est.length == 1) {
              this.estID = this.filter_est[0]['estID'];
              this.estInfo = this.filter_est[0]['filterval'];
              this.estserchCnt = '';
            }
            else {
              this.estserchCnt = 'yes';
            }

          }
          else {
            this.estserchCnt = '';
          }

        }

        

         ///SubEst List

         this.subest_List = data.wlRecSubEstDt;

         //
        
         if (Number(this.estID) > 0) {
           this.filter_subest = this.subest_List.filter(x => x.estID == this.estID);
        // console.log('this.filter_subest ',this.filter_subest)
           if (this.filter_subest.length > 0) {
             if (this.filter_subest.length == 1) {
               this.subestID = this.filter_subest[0]['ourSubEstID'];
               this.subestInfo = this.filter_subest[0]['subEstName'];
               this.subestName = this.filter_subest[0]['subEstName'];
               this.selected_SubEstName = this.filter_subest[0]['subEstName'];
               this.subestserchCnt = '';
             }
             else {
               this.subestserchCnt = 'yes';
             }
 
           }
           else {
             this.subestserchCnt = '';
           }
 
         }

        ///Market  Company list

        this.marcom_List = data.wlRecMarComDt;
        
        if (Number(this.estID) > 0) {
          this.filter_marcom = this.marcom_List.filter(x => x.estID == this.estID);
        
          if (this.filter_marcom.length > 0) {
            if (this.filter_marcom.length == 1) {
              this.marketID = this.filter_marcom[0]['marketID'];
              this.marInfo = this.filter_marcom[0]['filterval'];
              this.marserchCnt = '';
              this.compInfo = this.filter_marcom[0]['companyname'];
              this.companyID = this.filter_marcom[0]['companyID'];
            }
            else {
              this.marserchCnt = 'yes';
            }

          }
          else {
            this.marserchCnt = '';
          }

        }
        else {
          this.filter_marcom = [];
        }

        this.allocList = [];
        this.allocList = data.wlAllocation;
        
        this.fill_CrAcList();

        this.levyPara_list = data.wlLevyPara;
        
        this.set_levyCode(0);

        this.debitAcList = [];
        this.debitAcList = data.wlDbAcList;
        
        this.get_filter_debitAcList(0);

        this.fill_chqBankDt_List('');

        // if (this.docID > 0){
        //   this.Bind_Data();
        // }
        // else{
        //   this.set_searchChqBank('',0,0,'NA','NA','NA');
        // }

        

        this.loading='';
        this.show_loadingModal(false);

      },
      (err: HttpErrorResponse) => {
        alert(err);
        return;
      });

    ///Fill ChqBank List
    


  }
  set_levyCode(levyCode) {
    this.levyCode = 0;
    this.tot_LevyPer = 0;
    this.tot_AnamatPar = 0;
    let filterLevyCode = [];

    filterLevyCode = this.levyPara_list.filter(x => x.filterLevyCode == Number(levyCode));
    if (filterLevyCode.length > 0) {
      this.levyCode = filterLevyCode[0]['levyCode'];
      this.tot_LevyPer = filterLevyCode[0]['lParaPer'];
      this.tot_AnamatPar = filterLevyCode[0]['anamatPer'];

    }
  }
  fill_chqBankDt_List(filterIfscCode: string) {
    this.show_loadingModal(true);
    this.chqbank_List = [];
    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/getchqbankDt';
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.chqbank_List = data;
        
        if (filterIfscCode != '') {
          let filterifsc = [];
          filterifsc = this.chqbank_List.filter(x => x.ifscCode == filterIfscCode);
        
          if (filterifsc.length > 0) {
            this.set_searchChqBank(filterifsc[0]['ifscCode'], filterifsc[0]['branchID'], filterifsc[0]['estBankID'], filterifsc[0]['estBankName'], filterifsc[0]['branchName'], filterifsc[0]['city']);
          }
        }


        if (this.docID > 0){
          this.Bind_Data();
        }
        else{
          this.set_searchChqBank('',0,0,'NA','NA','NA');
        }


      });
  }
  //#endregion
  
  bind_toli_est_mar(toliID,estID,marketID,subEstID){
    this.toliID = 0;
    this.toliInfo = '';

    this.estInfo = ''
    this.estID = 0;

    this.subestID = 0;
          this.subestInfo = '';
          this.subestName='';
          this.selected_SubEstName = '';
          this.subestserchCnt = '';
          this.newsubEstName = '';

    this.marketID = 0;
    this.marInfo = '';

    this.searchToli = '';
    this.toliID = 0;
    this.toliInfo = '';
    this.toliserchCnt = '';


        let filter_tl = this.toli_List.filter(x => x.toliId == toliID);
        if (filter_tl.length > 0) {
          this.toliID = filter_tl[0]['toliId'];
          this.toliInfo = filter_tl[0]['mukName'];
          this.levy = filter_tl[0]['levy'];
        }

        let filter_est = this.est_List.filter(x => x.estID == estID);
      
        if (filter_est.length > 0) {
          this.estID = filter_est[0]['estID'];
          this.estInfo = filter_est[0]['filterval'];
          this.estserchCnt = '';
        }

        

        let filter_subest = this.subest_List.filter(x => x.ourSubEstID == subEstID);
      
        if (Number(filter_subest.length) > 0) {
          //console.log('filter_subest : ',filter_subest)

          this.subestID = filter_subest[0]['ourSubEstID'];
          this.subestInfo = filter_subest[0]['filterval'];
          this.subestName = filter_subest[0]['subEstName'];
          this.selected_SubEstName = filter_subest[0]['filterval'];
          this.subestserchCnt = '';

        }

        let filter_marcom = this.marcom_List.filter(x => x.marketID == marketID);
        if (filter_marcom.length > 0) {
          this.marketID = filter_marcom[0]['marketID'];
          this.marInfo = filter_marcom[0]['filterval'];
          this.marserchCnt = '';
          this.compInfo = filter_marcom[0]['companyname'];
          this.companyID = filter_marcom[0]['companyID'];
        }

  }

  
  recPaidCnt_disabled = false;
  recPaid = 'No';
  rpt_Wages;
  rpt_Levy;
  rpt_anamat;
  rpt_chqNo;
  rpt_debitAc;
  rpt_deposittype;

  Bind_Data() {
    this.loading='y';
    this.show_loadingModal(true);
    this.rpt_chqNo = '';
    this.rpt_Wages = '';
    this.rpt_Levy = '';
  this.rpt_chqNo = '';
  this.rpt_debitAc = '';
  this.rpt_deposittype = '';
  this.rpt_anamat = '';

  this.filterTrAccID = 0;
  this.show_trdbAc = '';
  this.show_loadingModal(true);
  let conBnkAccID = 0;
    this.bind_ChqList = [];
    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/wlrecbind/' + this.docID;
    this._userService.get_Data(url).subscribe(
      (data) => {
        this.wlmstdata = data.transMst;

        console.log('bind wlData',data);
        
        if (Number(this.wlmstdata[0]["conBnkAccID"]) > 0){
          conBnkAccID = Number(this.wlmstdata[0]["conBnkAccID"]);
        }
        
        this.filterYr = Number(this.wlmstdata[0]["wageyr"]);
        this.filterMonth = Number(this.wlmstdata[0]["wageMon"]);
        this.docID = this.wlmstdata[0]["docID"];
        this.receiptNO = this.wlmstdata[0]["receiptNO"];
        this.docdt = new Date(this.wlmstdata[0]["docdt"]).toISOString().substring(0, 10);

        let date: Date;
        date = new Date(this.wlmstdata[0]["docdt"]);
        date.setDate(date.getDate() + 1);
        this.recDate = new Date(date).toISOString().substring(0, 10);
         this.transAmt = Number(this.wlmstdata[0]["transAmt"]);
        this.wageyr = Number(this.wlmstdata[0]["wageyr"]);
        this.wageMon = Number(this.wlmstdata[0]["wageMon"]);
        this.flag = 'Rec';
        this.naration = 'Being Collection As Per Receipt Register For ';
        this.remark = 'Auto Entry';
        this.levyCode = this.wlmstdata[0]["levyCode"];
        this.fromDt = new Date(this.wlmstdata[0]["docdt"]).toISOString().substring(0, 10);
        this.toDt = new Date(this.wlmstdata[0]["docdt"]).toISOString().substring(0, 10);
        this.recFor = '';
        //this.fayear = this.wlmstdata[0]["fayear"];
        this.userID = this.Log_UserID;
        this.companyID = this.wlmstdata[0]["companyID"];
        this.isRecGenrate = 'Yes';
        this.lessAdvance = this.wlmstdata[0]["lessAdvance"];
        this.recPaid = this.wlmstdata[0]["recPaid"];


          if (this.recPaid == 'Yes'){
            this.recPaidCnt_disabled = true;
          }
          else{
            this.recPaidCnt_disabled = false;
          }

          this.show_loadingModal(true);

        this.bind_toli_est_mar(this.wlmstdata[0]["toliID"],this.wlmstdata[0]["estID"],this.wlmstdata[0]["marketID"],this.wlmstdata[0]["subEstMstID"]);

        this.fill_labBankType(this.wlmstdata[0]["toliID"]);


        let transChqDt = data.transChq;
        let chqtype = 0;
        this.filterrectype = 0;

       // console.log('transChqDt.length',transChqDt.length);
        console.log('transChqDt.list',transChqDt);
        
        this.bind_ChqList = [];

        if (transChqDt.length > 0) {
          for (let k = 0; k < transChqDt.length; k++) {
            if (k == 0){
              this.rpt_chqNo =  transChqDt[k]['billNo'];
              this.rpt_deposittype = transChqDt[k]['depositType'];
            }
            else{
              this.rpt_chqNo = this.rpt_chqNo + "," + transChqDt[k]['billNo'];
            }
            
            this.bind_ChqList.push({
              docID: transChqDt[k]['docID'],
              transChqID: 0,
              bankBranchID: transChqDt[k]['bankBranchID'],
              estBankID: transChqDt[k]['estBankID'],
              city: '',
              bankName: transChqDt[k]['bankName'],
              branchName: transChqDt[k]['branchName'],
              selchqtypeval: chqtype,
              depositType: transChqDt[k]['depositType'],
              ifscCode: transChqDt[k]['ifscCode'],
              chqdate: transChqDt[k]['billDt'],
              bindchqdate: transChqDt[k]['billDt'],
              chqno: transChqDt[k]['billNo'],
              chqamt: transChqDt[k]['chqAmt'],
              billNo: transChqDt[k]['billNo'],
              billDt: transChqDt[k]['billDt'],
              bounce: 'No',
              payDocID: 0,
              clear: 'Yes',
              clrDocID: 0,
              instrumentType: '',
              chequeClearingDt: "1900-01-01T00:00:00",
              isedit:'',
              estID:transChqDt[k]['estID'],
              estName:transChqDt[k]['estName'],
              subEstID:transChqDt[k]['subEstID'],
              subEstName:transChqDt[k]['subEstName'],
              isUpiAmt: transChqDt[k]['isUpiAmt'],
            });

          }

          this.filterrectype = 0;
          
        }
        else {
          this.filterrectype = 1;
          this.get_filter_debitAcList(1);
        }


        this.show_loadingModal(true);

        this.tot_ChqAmt = this.bind_ChqList.reduce((sum, curr) => sum + Number(curr.chqamt), 0);

        let transDt = data.transDt;
        if (transDt.length > 0) {
          for (let k = 0; k < transDt.length; k++) {
            for (let i = 0; i < this.transDtRec.length; i++) {
              if (transDt[k]['crID'] == this.transDtRec[i]['crID']) {

                this.transDtRec[i]['docDID'] = transDt[k]['docDID'];
                this.transDtRec[i]['docID'] = transDt[k]['docID'];
                this.transDtRec[i]['dbID'] = transDt[k]['dbID'];
                this.transDtRec[i]['cramt'] = transDt[k]['cramt'];
                this.transDtRec[i]['dbamt'] = transDt[k]['cramt'];
                this.transDtRec[i]['actRecNo'] = transDt[k]['actRecNo'];

                if (this.transDtRec[i]['acctype'] == 'Wages') {
                  this.act_wagesAmt = Number(transDt[k]['cramt']);
                }
                else if (this.transDtRec[i]['acctype'] == 'Levy') {
                  this.act_levyAmt = Number(transDt[k]['cramt']);
                }
                else if (this.transDtRec[i]['acctype'] == 'Anamat') {
                  this.act_anamatAmt = Number(transDt[k]['cramt']);
                }

              }
            }
          }
          this.filterprinAccID = transDt[0]['dbID']
          if (conBnkAccID > 0){
            this.get_filter_debitAcList(1);
            this.filterTrAccID = conBnkAccID;
            this.show_trdbAc = 'yes';
            this.filterrectype = 2;
          }

          let filter_debitAcList = [];
          filter_debitAcList = this.debitAcList.filter(indx => indx.accID == this.filterprinAccID);
          if (filter_debitAcList.length > 0) {
            this.rpt_debitAc = this.filter_debitAcList[0]['accName']
          }

          this.rpt_Wages = this.act_wagesAmt.toString();
          this.rpt_Levy = this.act_levyAmt.toString();
          this.rpt_anamat = this.act_anamatAmt.toString();
        }

        this.act_wagesAmt = Number(this.act_wagesAmt) + Number(this.lessAdvance);
        this.act_WageAmt = Number(this.act_wagesAmt);

        this.cal_TransAmt();

        this.loading='';
        this.show_loadingModal(false);
      });

  }
  get_allocationform(accID) {
    let filter_alloc = [];
    filter_alloc = this.allocList.filter(indx => indx.accID === accID);
    if (filter_alloc.length > 0) {
      return filter_alloc[0]['allocationFrom']
    }
    return '';
  }
  public allocList: any = [];
  // fill_Allocaton() {
  //   this.allocList = [];
  //   let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getWagesLevyAccounts';
  //   this._userService.get_Dashborad_Data(url).subscribe(
  //     (data) => {
  //       this.allocList = data;
  //       this.fill_CrAcList();
  //     });
  // }
  public transDtRec: any = [];
  fill_CrAcList() {
    this.transDtRec = [];
    this.get_CrAcList('Wages', this.wagesAmt, '');
    this.get_CrAcList('Levy', this.levyAmt, '');
    this.get_CrAcList('Anamat', this.anamtAmt, '');
    this.get_CrAcList('Admenstrative', this.admenstrative, '');

    this.cal_TransAmt();
    
  }
  set_Cr_AmtChange(event, index) {
    // alert(event.target.value);
    this.transDtRec[index]['cramt'] = event.target.value;
    this.transDtRec[index]['dbamt'] = event.target.value;
    
    this.cal_TransAmt();
  }
  get_CrAcList(allocationFrom, cramt, disabled) {
    // alert(allocationFrom);
    let filter_alloc = [];
    filter_alloc = this.allocList.filter(indx => indx.allocationFrom === allocationFrom);
    if (filter_alloc.length > 0) {
       //alert(disabled);
      this.set_CrAc(filter_alloc[0]['accID'], filter_alloc[0]['accName'], allocationFrom, cramt, disabled)
    }

  }
  set_CrAc(accid, accname, acctype, cramt, disabled) {
 
    this.transDtRec.push(
      {
        docDID: 0,
        docID: 0,
        crID: accid,
        dbID: 0,
        cramt: cramt,
        dbamt: cramt,
        actRecNo: "",
        crAccName: accname,
        dbAccName: "",
        acctype: acctype,
        disabled: disabled,
        ActRecNo: "",
        FDAcType: "",
        BillDate: "01/01/1900"

      }
    );
  }
  recType = 0;
  // fill_debitAcList() {
  //   this.debitAcList = [];
  //   this.ngxService.start();
  //   let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getCashOrBankAcc';
  //   this._userService.get_Dashborad_Data(url).subscribe(
  //     (data) => {
  //       this.debitAcList = data;
  //       this.recType = 0;
  //       this.get_filter_debitAcList('0');

  //     });
  //   this.ngxService.stop();
  // }

  filter_debitAcList: any = [];
  filter_debitTrAcList: any = [];
  get_filter_debitAcList(chqcash) {
    let stractype = '';
    this.show_trdbAc = '';
    if (chqcash == 0) {
      stractype = 'Bank'
      this.filterrectype = 0;
    }
    else if (chqcash == 1) {
      stractype = 'Cash'
      this.filterrectype = 1;
    }
    else if (chqcash == 2) {
      stractype = 'Cash'
      this.filterrectype = 2;
      this.show_trdbAc = 'yes';
    }
    this.recType = chqcash
    this.filter_debitAcList = [];
    this.filter_debitTrAcList = [];

    this.filter_debitAcList = this.debitAcList.filter(indx => indx.cashOrBank == stractype);
    if (this.filter_debitAcList.length > 0) {
      this.filterprinAccID = this.filter_debitAcList[0]['accID']
    }

    this.filter_debitTrAcList = this.debitAcList.filter(indx => indx.cashOrBank == 'Bank');
    if (this.filter_debitTrAcList.length > 0) {
      this.filterTrAccID = this.filter_debitTrAcList[0]['accID']
    }

  }
  set_debitAc(event) {
    // alert(event.target.value);
    this.filterprinAccID = event.target.value;
    // alert(this.filterprinAccID);
  }
  show_trdbAc:string = '';
  set_debit_TrandferAc(event) {
    // alert(event.target.value);
    this.filterTrAccID = event.target.value;
    // alert(this.filterprinAccID);
  }
  chq_list: any = [];
  get_chqbank(event) {
    // this.clear_Data();
    this.txtcity = '';
    this.txtbankname = '';
    this.txtbranchname = '';
    this.chq_list = [];
    this.ngxService.start();
    let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getBankDetailsByIFSCCode/' + event.target.value;
    this._userService.get_Dashborad_Data(url).subscribe(
      (data) => {
        this.chq_list = data;
        this.txtcity = this.chq_list[0]['city'];
        this.txtbankname = this.chq_list[0]['bankName'];
        this.txtbranchname = this.chq_list[0]['branchName'];
      });
    this.ngxService.stop();

  }
  bind_ChqList: any = [];
  add_chqDt(selchqtype, txtchqno, txtchqdate, txtchqamt,txtnewsubEstName) {
   
    selchqtype = this.frmchq.controls['selchqtype'].value;
    
let chkDate:Date = new Date(txtchqdate);
let chkYr:number = chkDate.getFullYear()
    // alert(chkDate.getFullYear());
    // return '';

    // if (chkYr < 1900 || chkYr > 2060){
    //   alert('Enter Valid Cheque Date');
    // }

    // return;

    if (Number(this.estID) == 0 || this.estID == '') {
      alert('Select establishment.');
    }
    else if (txtchqdate == '') {
      alert('Enter Cheque Date');
    }
    else if (chkYr < 1900 || chkYr > 2060){
      alert('Enter Valid Cheque Date');
    }
    // else if (txtchqamt == '' || txtchqamt == '0') {
      //chqAmt also get from upiList est wsie
    //   alert('Enter Cheque Amount');
    // }
    else if (txtchqno == '') {
      alert('Enter Cheque Number.');
    }
    else {
      //alert(Number(this.branchID));

      let depositType = selchqtype; //this.get_deposit_Type(selchqtype);
      if (this.chqEditIndex == -1) {
        if (Number(this.branchID) >= 0) {
          this.bind_ChqList.push({
            docID: 0,
            transChqID: 0,
            bankBranchID: this.branchID,
            estBankID: this.estBankID,
            city: this.city,
            bankName: this.estBankName,
            branchName: this.branchName,
            selchqtypeval: selchqtype,
            depositType: depositType,
            ifscCode: this.searchIfsc,
            chqdate: txtchqdate,
            chqno: txtchqno,
            chqamt: txtchqamt,
            billNo: txtchqno,
            billDt: txtchqdate,
            bindchqdate: txtchqdate,
            bounce: 'No',
            payDocID: 0,
            clear: 'Yes',
            clrDocID: 0,
            instrumentType: '',
            chequeClearingDt: "1900-01-01T00:00:00",
            isedit:'yes',
            estID:this.estID,
            estName:this.estInfo,
            subEstID:this.subestID,
            subEstName:txtnewsubEstName,
            isUpiAmt: '',
          });

          this.frmchq.setValue({
            selchqtype: '',
            txtchqno: '',
            txtchqamt: '',
            txtifsccode: '',
            txtchqdate: this.chqdate,
            txtnewsubEstName:'',
          });

          this.branchID = 0;
          this.estBankID = 0;
          this.estBankName = '';
          this.branchName = '';
          this.city = '';

          this.txtbankname = '';
          this.txtbranchname = '';
          this.txtcity = '';

          this.estID = 0;
          this.estInfo = '';
          this.subestID = 0;
          this.subestName = '';
          this.newsubEstName = '';

          this.estserchCnt = 'yes';
        }
        else {
          alert('Enter valid ifsc code');
        }
        this.chqEditIndex = -1
        this.set_searchChqBank('',0,0,'NA','NA','NA');
      }
      else {
        if (this.branchID >= 0) {
          this.bind_ChqList[Number(this.chqEditIndex)]['bankBranchID'] = this.branchID;
          this.bind_ChqList[Number(this.chqEditIndex)]['estBankID'] = this.estBankID;
          //estBankID
          this.bind_ChqList[Number(this.chqEditIndex)]['city'] = this.city;
          this.bind_ChqList[Number(this.chqEditIndex)]['bankName'] = this.estBankName;
          this.bind_ChqList[Number(this.chqEditIndex)]['branchName'] = this.branchName;
          this.bind_ChqList[Number(this.chqEditIndex)]['selchqtypeval'] = selchqtype;
          this.bind_ChqList[Number(this.chqEditIndex)]['depositType'] = depositType;
          this.bind_ChqList[Number(this.chqEditIndex)]['ifscCode'] = this.searchIfsc;
          this.bind_ChqList[Number(this.chqEditIndex)]['chqdate'] = txtchqdate;
          this.bind_ChqList[Number(this.chqEditIndex)]['bindchqdate'] = txtchqdate;
          this.chqdate = txtchqdate;
          //bindchqdate: transChqDt[k]['billDt'],
          this.bind_ChqList[Number(this.chqEditIndex)]['chqno'] = txtchqno;
          this.bind_ChqList[Number(this.chqEditIndex)]['chqamt'] = txtchqamt;
          this.bind_ChqList[Number(this.chqEditIndex)]['billNo'] = txtchqno;
          this.bind_ChqList[Number(this.chqEditIndex)]['billDt'] = txtchqdate;
          this.bind_ChqList[Number(this.chqEditIndex)]['isedit'] ='yes';


          this.bind_ChqList[Number(this.chqEditIndex)]['estID'] = this.estID;
          this.bind_ChqList[Number(this.chqEditIndex)]['estName'] = this.estInfo;
          this.bind_ChqList[Number(this.chqEditIndex)]['subEstID'] = this.subestID;
          this.bind_ChqList[Number(this.chqEditIndex)]['subEstName'] = txtnewsubEstName;

          
          this.frmchq.setValue({
            selchqtype: '',
            txtchqno: '',
            txtchqamt: '',
            txtifsccode: '',
            txtchqdate: this.chqdate,
            txtnewsubEstName:'',
          });

          this.branchID = 0;
          this.estBankID = 0;
          this.estBankName = '';
          this.branchName = '';
          this.city = '';

          this.txtbankname = '';
          this.txtbranchname = '';
          this.txtcity = '';

          this.estID = 0;
          this.estInfo = '';
          this.subestID = 0;
          this.subestName = '';
         this.newsubEstName='';

         this.estserchCnt = 'yes';

          this.chqAddUpdate = "Add";
          this.chqEditIndex = -1

          this.set_searchChqBank('',0,0,'NA','NA','NA');

        }
        else {
          alert('BranchID not get')
        }
      }


      this.tot_ChqAmt = this.bind_ChqList.reduce((sum, curr) => sum + Number(curr.chqamt), 0);
      
      //this.chq_list = [];
    }

  }
  public chqEditIndex: Number = -1;
  public chqAddUpdate: string = "Add";
  public tot_ChqAmt: Number = 0;
  set_chqEditVal(editindex, ifscCode, chqno, chqdate: Date, chqamt, depositType,newsubEstName) {
    console.log('this.bind_ChqList ',this.bind_ChqList)
    this.txtbankname = this.bind_ChqList[editindex]['bankName'];
    this.estBankName = this.bind_ChqList[editindex]['bankName'];
    this.txtbranchname = this.bind_ChqList[editindex]['branchName'];
    this.txtcity = this.bind_ChqList[editindex]['city'];
    this.branchID = this.bind_ChqList[editindex]['bankBranchID'];
    this.estBankID = this.bind_ChqList[editindex]['estBankID'];
    this.searchIfsc = ifscCode;

    this.estID = this.bind_ChqList[editindex]['estID'];
    this.estInfo = this.bind_ChqList[editindex]['estName'];
    this.subestID=this.bind_ChqList[editindex]['subEstID'];
    this.newsubEstName=this.bind_ChqList[editindex]['subEstName'];

    //chqdate = this.bind_ChqList[editindex]['bindchqdate'];

    

    this.set_searchChqBank(ifscCode, this.branchID, this.estBankID, this.txtbankname, this.txtbranchname, this.txtcity)

    this.chqEditIndex = editindex;
    this.chqAddUpdate = "Update";
    let date: Date;
    date = new Date(chqdate);
    
    if (this.bind_ChqList[editindex]['isedit'] == 'yes'){
    }
    else{
      date.setDate(date.getDate() + 1);
    }
    
    date.setDate(date.getDate());
    this.chqdate = new Date(date).toISOString().substring(0, 10);;
    this.frmchq.setValue({
      selchqtype: depositType,
      txtchqno: chqno,
      txtchqamt: chqamt,
      txtifsccode: ifscCode,
      txtchqdate: this.chqdate,
      txtnewsubEstName:newsubEstName,
    });

    // this.chq_list = [];
    // this.ngxService.start();
    // let url = 'api/' + this._userService.get_clerkDashBoard_controlerName(this.Log_BoardID) + '/getBankDetailsByIFSCCode/' + ifscCode;
    // this._userService.get_Dashborad_Data(url).subscribe(
    //   (data) => {
    //     this.chq_list = data;
    //     this.chqEditIndex = editindex;
    //     this.chqAddUpdate = "Update";
    //     let date: Date;
    //     date = new Date(chqdate);
    //     date.setDate(date.getDate() + 1);
    //     this.chqdate = new Date(date).toISOString().substring(0, 10);;
    //     this.frmchq.setValue({
    //       txtchqno: chqno,
    //       txtchqamt: chqamt,
    //       txtifsccode: ifscCode,
    //       txtchqdate: this.chqdate
    //     });

    //   });
    // this.ngxService.stop();

  }
  get_deposit_Type(val) {
    if (val == 0) {
      return '';
    }
    else if (val == 1) {
      return 'Direct Deposit';
    }
    else if (val == 2) {
      return 'RTGS';
    }
    else if (val == 3) {
      return 'Transfer';
    }
    else if (val == 4) {
      return 'NEFT';
    }
    else if (val == 5) {
      return 'IMPS';
    }
  }


  cal_TransAmt() {
    this.transAmt = 0;
    if (this.transDtRec.length > 0) {
      for (let k = 0; k < this.transDtRec.length; k++) {
        // if (this.transDtRec[k]['acctype'] == 'Wages') {
        //   this.wagesAmt = this.transDtRec[k]['cramt']

        // }
        // else if (this.transDtRec[k]['acctype'] == 'Levy') {
        //   this.levyAmt = this.transDtRec[k]['cramt'];

        // }
        // else if (this.transDtRec[k]['acctype'] == 'Anamat') {
        //   this.anamtAmt = this.transDtRec[k]['cramt'];

        // }

        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(2);
      }

    }
    
  }
 
  newsubEstName ='';
  rec_submit:boolean = false;
  insert_update(dbAccID) {
    let isSave: boolean = true;
    
    if (Number(this.filterYr.toString().length) != 4) {
      alert('Enter valid wage year');
      isSave = false;
      return;
    }

    if (Number(this.filterYr) <= 0) {
      alert('Enter valid wage year');
      isSave = false;
      return;
    }

    let sub_EstID = 0;
    // if (this.newsubEstName == '' || this.newsubEstName == null){
    //   if (this.selected_SubEstName != ''){
    //     sub_EstID = this.subestID;
    //   }
    //   else{
    //     sub_EstID = 0;
    //   }
    // }
    // else{
    //   sub_EstID = 0;
      
    // }

    // if (this.selected_SubEstName != ''){
    //   if (Number(sub_EstID) == 0 ){
    //     alert("Select Sub-Establishment.")
    //     isSave = false;
    //     return;
    //   }
    // }

    if (Number(this.filterMonth) > 0 && Number(this.filterMonth) < 13) {
    }
    else {
      alert('Enter valid wage month');
      isSave = false;
      return;
    }

    if (Number(dbAccID) == 0 || dbAccID == '') {
      isSave = false;
      alert('Select Debit A/C')
      return;
    }


    if (this.recType == 1) {
      let cashAccID_FromAllocation = 0;
      let filter_alloc = [];
      
      filter_alloc = this.allocList.filter(indx => indx.allocationFrom === 'Cash A/C');
     // console.log('filter_alloc',filter_alloc);
      if (filter_alloc.length > 0) {
        cashAccID_FromAllocation = filter_alloc[0]['accID'];
      }
      if (Number(cashAccID_FromAllocation) > 0) {
        if (Number(dbAccID) != Number(cashAccID_FromAllocation)) {
          alert('Cash A/C from allocation and selected debit a/c not same.')
          isSave = false;
          return;
        }
      }
      else {
        alert('Cash A/C Not get from allocation.')
        isSave = false;
        return;
      }

    }

    this.transAmt = 0;
    if (this.transDtRec.length > 0) {
      for (let k = 0; k < this.transDtRec.length; k++) {
        if (Number(this.transDtRec[k]['cramt']) < 0) {
          alert("Check amount < 0 not valid")
          isSave = false;
          return;
        }
        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(2);
      }
    }


    let TotChqAmt = 0;
    let transChqDt: any = [];

    
      if (this.bind_ChqList.length > 0) {
        for (let k = 0; k < this.bind_ChqList.length; k++) {
          if (Number(this.bind_ChqList[k]['estID']) < 0) {
            alert("Select establishment.")
            isSave = false;
            return;
          }

          if (Number(this.bind_ChqList[k]['chqamt']) < 0) {
            alert("Check cheque amount < 0 not valid")
            isSave = false;
            return;
          }

          TotChqAmt += Number(this.bind_ChqList[k]['chqamt'])
        }
        if (this.recType == 0) {
          if (Number(this.transAmt) - Number(TotChqAmt) != 0) {
            alert("Check Cheque Amount And Debit Amount Has Diffrance.")
            isSave = false;
            return;
          }
        }
        
      }
      else {
        alert("Enter Establishment/Cheque Details.");
        isSave = false;
        return;
      }

    
    

    // if (this.recType == 0) {
    //   if (this.bind_ChqList.length > 0) {
    //     for (let k = 0; k < this.bind_ChqList.length; k++) {
    //       if (Number(this.bind_ChqList[k]['estID']) < 0) {
    //         alert("Select establishment.")
    //         isSave = false;
    //         return;
    //       }

    //       if (Number(this.bind_ChqList[k]['chqamt']) < 0) {
    //         alert("Check cheque amount < 0 not valid")
    //         isSave = false;
    //         return;
    //       }

    //       TotChqAmt += Number(this.bind_ChqList[k]['chqamt'])
    //     }
    //     if (Number(this.transAmt) - Number(TotChqAmt) != 0) {
    //       alert("Check Cheque Amount And Debit Amount Has Diffrance.")
    //       isSave = false;
    //       return;
    //     }
    //   }
    //   else {
    //     alert("Enter Establishment/Cheque Details.");
    //     isSave = false;
    //     return;
    //   }

    // }
    // else {
    //   this.bind_ChqList = [];
    // }

    if (Number(this.toliID) > 0 ){

    }
    else{
      alert("Select toli.");
      isSave = false;
      return;
    }
    
    //estID in transCheqDt
// if (Number(this.estID) > 0 ){

// }
// else{
//   alert("Select estblishment.");
//   isSave = false;
//   return;
// }
this.marketID = 1
this.companyID = 1
// if (Number(this.marketID) > 0 ){

// }
// else{
//   alert("Select market.");
//   isSave = false;
//   return;
// }
// if (Number(this.companyID) > 0 ){

// }
// else{
//   alert("Select division.");
//   isSave = false;
//   return;
// }

    transChqDt = [];

    let mon, yr;
    mon = new Date(this.recDate).getMonth() + 1;
    yr = new Date(this.recDate).getFullYear();

    if (mon < 4) {
      this.fayear = yr - 1;
    }
    else {
      this.fayear = yr;
    }

    if (this.fayear != this.filterFayear) {
      alert('Enter date selected finacial year.')
      isSave = false;
      return;
    }

    let dd, mm, yy;
    dd = new Date(this.recDate).getDate();
    mm = new Date(this.recDate).getMonth() + 1;
    yy = new Date(this.recDate).getFullYear();

    let wgYrMonth_Date = new Date(Number(this.filterYr) + '-' + Number(this.filterMonth) + '-' + '01')

    if (new Date(this.recDate) < new Date(wgYrMonth_Date)) {
      alert('Enter valid date.Date below wage year month not allowed.')
      isSave = false;
      return;
    }

    

    // var alignFillDate = new Date("2015-06-09");
    // var pickUpDate = new Date("2015-05-10");
    //var alignFillDate = new Date(wgYrMonth_Date);
   // var pickUpDate = new Date(this.recDate);

   dd = new Date().getDate();
   mm = new Date().getMonth() + 1;
   yy = new Date().getFullYear();

   var chkDt = new Date(Number(yy) + '-' + Number(mm) + '-' + dd);

    if (Number(this.docID) == 0) {
    

    let today_Date = new Date().toISOString().substring(0, 10);
           if (this.recDate < today_Date) {
             alert("Check receipt date is smaller than today.")
                isSave = false;
                return;
           }
    }

    let entryType = "";
    if (this.recType == 0){
      entryType = "cheque";
    }
    else if (this.recType == 2){
      entryType = "cashbank";
    }

    dd = new Date(this.recDate).getDate();
   mm = new Date(this.recDate).getMonth() + 1;
   yy = new Date(this.recDate).getFullYear();
   
    let strPayDt = this.recDate;//dd + '-' + mm + '-' + yy
    
    let transRec = {
      "DocID": this.docID,
      "ReceiptNO": 0,
      "Docdt": strPayDt,
      "IsRecGenrate": "Yes",
      "TransAmt": this.transAmt,
      "Billno": "",
      "BillDt": strPayDt,
      "Naration": "Being Collection As Per Receipt Register For " + this.searchMar,
      "Wageyr": this.filterYr,
      "WageMon": this.filterMonth,
      "MarketID": this.marketID,
      "EstID": this.estID,
      "CompanyID": this.companyID,
      "UserID": this.userID,
      "Fayear": this.fayear,
      "Flag": "Rec",
      "Bounce": "No",
      "Remark": "",
      "ChequeClearingDt": strPayDt,
      "ToliID": this.toliID,
      "LevyCode": this.levyCode,
      "EstBankAccID": 0,
      "FromDt": strPayDt,
      "ToDt": strPayDt,
      "SalesID": 0,
      "RecFor": "",
      "EmpName": this.newsubEstName,
      "SubEstMstID": sub_EstID,
      "LessAdvance": this.lessAdvance,
      "conEntry":this.show_trdbAc,
      "conBnkAccID":this.filterTrAccID,
      "RecType":entryType,
      
    }

    

    for (let k = 0; k < this.transDtRec.length; k++) {
      this.transDtRec[k]['dbID'] = dbAccID;
    }

   


    if (isSave == true) {

      this.rec_submit = true;

      let WageLevy: any = {
        "TransMst": transRec,
        "TransDt": this.transDtRec,
        "TransChq": this.bind_ChqList
      }

      
 ///////////////

 let actionType: string = 'na';
 actionType = 'add';
 if (Number(this.docID) > 0){
   actionType = 'edit';
 }


let auth_List:any = [];
let userTypeID: Number = -1;
if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
 userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
}

let Log_BoardID: Number = -1;
if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
 Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
}

let authFor = "Wages/Levy Rec";

this.show_loadingModal(true);

let url_ = 'getUserAuthDt/' + Log_BoardID
 this._userService.get_Dashborad_Data(url_).subscribe((data) => {
   
  // console.log('retData',data);
  auth_List = data;
  //console.log('auth_List:', auth_List)
  if (authFor != ''){
   let filterList = [];
   if (actionType.toString().toLocaleLowerCase() == 'add'){
     filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
   }
   else if (actionType.toString().toLocaleLowerCase() == 'edit'){
     filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
   }
   else if (actionType.toString().toLocaleLowerCase() == 'delete'){
     filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
   }
   else{
    this.show_loadingModal(false);
     alert('You have no authority.')
     return;
   }
  
   if (Number(filterList.length) > 0){
     //alert(Number(filterList.length));

     let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/chkiwaypostdone/' + yr + '/' + mon;
    this._userService.get_Data(url).subscribe((data) => {
   if (data != '') {
    this.show_loadingModal(false);
     alert(data);
     isSave = false;
     return;
   }
   else{

    this.ngxService.start();
      let body = new FormData();
      body = WageLevy;

      console.log('wl-save',WageLevy);

      //return;
      this.show_loadingModal(true);

       url = 'api/' + this._userService.get_trans_controlerName(this.Log_BoardID) + '/savetrans';
      //alert(url);
      this._userService.post_data(url, body).subscribe(
        (data) => {
          if (Number(data) > 0) {
            this.show_loadingModal(false);
            this.docID = Number(data);
            this.Bind_Data();
            //this.toliID = 0;
            //this.fill_AddMstData();
            alert('Record saved successfully.');

            this.rec_submit = false;

            this.rpt_print = 'yes'

           // this.generatePdf();
            //this.router.navigate(['/dashboard/clerk-dashboard']);
          }
          else{
            this.show_loadingModal(false);
            alert(data);
            this.rec_submit = false;
          }
        });
      this.ngxService.stop();
     
   }
 },
   (err: HttpErrorResponse) => {
    this.ngxService.stop();
    this.show_loadingModal(false);
     alert(err);
     isSave = false;
     return;
   });
    
     
      
   }
   else{
     alert('You have no authority.')
     return;
   }

 }
 else {
   alert('You have no authority.')
   return;
 }
 },
 (err: HttpErrorResponse) => {
   alert('You have no authority.')
   return;
 });

 //////////////////

      // this.ngxService.start();
      // let body = new FormData();
      // body = WageLevy;
      // let url = 'api/' + this._userService.get_trans_controlerName(this.Log_BoardID) + '/savetrans';
      // //alert(url);
      // this._userService.post_data(url, body).subscribe(
      //   (data) => {
      //     if (Number(data) > 0) {
      //       this.docID = 0;// Number(data);
      //       //this.Bind_Data();
      //       this.fill_AddMstData();
      //       alert('Record saved successfully.');

      //       this.rec_submit = false;

      //      //this.rpt_print = 'yes'
      //      // this.generatePdf();
      //       //this.router.navigate(['/dashboard/clerk-dashboard']);
      //     }
      //     else{
      //       alert(data);
      //       this.rec_submit = false;
      //     }
      //   });
      // this.ngxService.stop();
    }
    
  }

  check() {
    alert(this.filterprinAccID);
  }
  del_chq(index) {
    if (window.confirm('Are sure you want to delete this entry ?')) {
      this.bind_ChqList.splice(index, 1);
      this.tot_ChqAmt = this.bind_ChqList.reduce((sum, curr) => sum + Number(curr.chqamt), 0);

      this.chqAddUpdate = "Add";
      this.chqEditIndex = -1
      this.frmchq.setValue({
        selchqtype: '',
        txtchqno: '',
        txtchqamt: '',
        txtifsccode: '',
        txtchqdate: this.chqdate,
        txtnewsubEstName:'',
      });

      this.branchID = 0;
      this.estBankID = 0;
      this.estBankName = '';
      this.branchName = '';
      this.city = '';

      this.txtbankname = '';
      this.txtbranchname = '';
      this.txtcity = '';
    }

  }
  delte_entry() {

    ///////////////

 let actionType: string = 'na';
 actionType = 'delete';


let auth_List:any = [];
let userTypeID: Number = -1;
if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
 userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
}

let Log_BoardID: Number = -1;
if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
 Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
}
this.show_loadingModal(true);

let authFor = "Wages/Levy Rec";

let url_ = 'getUserAuthDt/' + Log_BoardID
 this._userService.get_Dashborad_Data(url_).subscribe((data) => {
   
  // console.log('retData',data);
  auth_List = data;
  //console.log('auth_List:', auth_List)
  if (authFor != ''){
   let filterList = [];
   if (actionType.toString().toLocaleLowerCase() == 'add'){
     filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
   }
   else if (actionType.toString().toLocaleLowerCase() == 'edit'){
     filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
   }
   else if (actionType.toString().toLocaleLowerCase() == 'delete'){
     filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
   }
   else{
    this.show_loadingModal(false);
     alert('You have no authority.')
     return;
   }
   this.show_loadingModal(false);
   if (Number(filterList.length) > 0){
     //alert(Number(filterList.length));
     if (Number(this.docID) > 0) {
      if (window.confirm('Are sure you want to delete this entry')) {
        let mon, yr;
        mon = new Date(this.recDate).getMonth() + 1;
        yr = new Date(this.recDate).getFullYear();

        this.ngxService.start();
        this.show_loadingModal(true);

        let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/chkiwaypostdone/' + yr + '/' + mon
        this._userService.get_Data(url).subscribe((data) => {
       if (data != '') {
         this.ngxService.stop();
         alert(data);
         return false;
       }
       else{
        let url = 'api/' + this._userService.get_trans_controlerName(this.Log_BoardID) + '/deltransmstdt/' + this.docID;
        //alert(url);
        this._userService.Delete_Enrty_ByID(url).subscribe(
          (data) => {
            this.show_loadingModal(false);
            alert(data);
            if (data == 'Record deleted successfully.') {
              this.router.navigate(['/dashboard/clerk-dashboard']);
            }
          });
       }
     },
       (err: HttpErrorResponse) => {
        this.show_loadingModal(false);
         this.ngxService.stop();
         alert(err);
         return false;
       });


       
      //  this.ngxService.stop();
      }
    }
      
   }
   else{
    this.show_loadingModal(false);
     alert('You have no authority.')
     return;
   }

 }
 else {
  this.show_loadingModal(false);
   alert('You have no authority.')
   return;
 }
 },
 (err: HttpErrorResponse) => {
  this.show_loadingModal(false);
   alert('You have no authority.')
   return;
 });

 

  }

  lessAdvance = 0;
  act_WageAmt: number = 0;
  cal_levy(event, acctype) {
    
    if ((event.keyCode == 13 || event.keyCode == 9) && acctype == "Wages") {
      this.lessAdvance = 0;
      this.act_WageAmt = 0;
      let lper = Number(this.tot_LevyPer);
      if (this.levy == 'No'){
        lper = Number(this.tot_AnamatPar);
      }

      //let lamt = Number((Number(event.target.value) * Number(lper)) / 100).toFixed(2);

      let lamt = Number((Number(event.target.value) * Number(lper)) / 100).toFixed(0);

      this.act_WageAmt = Number(event.target.value);

      this.set_levyAmt(Number(event.target.value), lamt);
    }
    else if (event.keyCode == 40 && acctype == "Wages") {
      this.lessAdvance = 0;
      this.act_WageAmt = 0;

      let lper = Number(this.tot_LevyPer) + 100;

      if (this.levy == 'No'){
        lper = Number(this.tot_AnamatPar) + 100;
      }

      //let lamt = Number((Number(event.target.value) / Number(lper)) * 100).toFixed(2);

      let lamt = Number((Number(event.target.value) / Number(lper)) * 100).toFixed(2);

    //  let number = 20.125;
let decimalValue = lamt.toString().indexOf(".");
let result = lamt.toString().substring(decimalValue+1);
//orignal number
//console.log('orignal number',lamt)
//Decimal part of Number

result = '0.' + result;

//console.log(result);

      lamt = Number(Number(event.target.value) - Number(lamt)).toFixed(2);

      // let lamt = Number((Number(event.target.value) / Number(lper)) * 100);

      // lamt = Number(Number(event.target.value) - Number(lamt));
      let wgAmt = Number(lamt).toFixed(0);

      let wageAmt = Number(Number(event.target.value) - Number(wgAmt)).toFixed(0);

      this.act_WageAmt = Number(wageAmt);

      //lamt = Number(Number(lamt) + Number(result)).toFixed(0);

      lamt = Number(Number(Number(event.target.value)) - Number(wageAmt)).toFixed(0);

      this.set_levyAmt(wageAmt, lamt);
    }
  }
  set_levyAmt(wagesamt, lamt) {
    if (this.transDtRec.length > 0) {
      this.transAmt = 0;
      for (let k = 0; k < this.transDtRec.length; k++) {
        if (this.transDtRec[k]['acctype'] == 'Wages') {
          this.transDtRec[k]['cramt'] = wagesamt;
          this.transDtRec[k]['dbamt'] = wagesamt;

        }
        if (this.levy == 'No'){
          if (this.transDtRec[k]['acctype'] == 'Anamat') {
            this.transDtRec[k]['cramt'] = lamt;
            this.transDtRec[k]['dbamt'] = lamt;
          }
        }
        else{
          if (this.transDtRec[k]['acctype'] == 'Levy') {
            this.transDtRec[k]['cramt'] = lamt;
            this.transDtRec[k]['dbamt'] = lamt;
          }
        }
        
        
        //Anamat
        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(2);
      }
    }
  }

  set_wages_after_LessAdv(event) {
    if (this.transDtRec.length > 0) {
      this.transAmt = 0;
      for (let k = 0; k < this.transDtRec.length; k++) {
        if (this.transDtRec[k]['acctype'] == 'Wages') {
          this.transDtRec[k]['cramt'] = Number(Number(this.act_WageAmt) - Number(this.lessAdvance)).toFixed(2);
        }
        this.transAmt = Number(Number(this.transAmt) + Number(this.transDtRec[k]['cramt'])).toFixed(2);
      }
    }
  }
  setReciptNo(event) {
    this.receiptNO = event.target.value;
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onlyDecimalNumberKey(event) {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  // public downloadAsPDF() {
  //   const doc = new jsPDF();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const pdfTable = this.pdfTable.nativeElement;

  //   doc.fromHTML(pdfTable.innerHTML, 15, 15, {
  //     width: 190,
  //     'elementHandlers': specialElementHandlers
  //   });

  //   doc.save('tableToPdf.pdf');
  // }


  save_newChqBank(ifsccode) {

    var reg = /^([a-zA-Z]){4}([0-9]){7}?$/;
    if (!reg.test(ifsccode)) {
      alert('Invalid ifsc code');
      return;
    }


  }

  get f() { return this.frm_newChqBank.controls; }
  onSubmit_newChqBank() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.frm_newChqBank.invalid) {
      // alert('Invalid!! :-)')
      return;
    }

    // let ifsccode = this.frm_newChqBank.controls['nifsccode'].value;
    // var reg = /^([a-zA-Z]){4}([0-9]){7}?$/;
    // if(!reg.test(ifsccode)) {

    //   return;
    // }

    // let bankName:string;
    // let branchName:string; 
    // let cityName:string;
    let ifscCode: string;

    // bankName = this.frm_newChqBank.controls['nbank'].value;
    // branchName = this.frm_newChqBank.controls['nbranch'].value;
    // cityName = this.frm_newChqBank.controls['ncity'].value;
    ifscCode = this.frm_newChqBank.controls['nifsccode'].value;

    let newChqBnk: any = {
      "EstBankName": this.frm_newChqBank.controls['nbank'].value,
      "BranchName": this.frm_newChqBank.controls['nbranch'].value,
      "City": this.frm_newChqBank.controls['ncity'].value,
      "IFSCCode": this.frm_newChqBank.controls['nifsccode'].value
    }

    let body = new FormData();
    body = newChqBnk;
    let url = 'api/' + this._userService.get_wl_rec_controlerName(this.Log_BoardID) + '/savenewchqBank';
    //alert(url);
    this._userService.post_data(url, body).subscribe(
      (data) => {

        if (data == "Record Saved Sucessfully") {
          alert(data);
          this.fill_chqBankDt_List(ifscCode);
          this.hide()
          this.clear_newChq_Val()
        }
        else {
          alert(data);
        }
      });
    this.ngxService.stop();

  }
  clear_newChq_Val() {
    this.submitted = false;
    this.frm_newChqBank.controls['nbank'].setValue('');
    this.frm_newChqBank.controls['nbranch'].setValue('');
    this.frm_newChqBank.controls['ncity'].setValue('');
    this.frm_newChqBank.controls['nifsccode'].setValue('');

  }
  show() {
    this.showModal = true; // Show-Hide Modal Check
    this.content = '';// "This is content!!"; // Dynamic Data
    this.title = "";    // Dynamic Data
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

  public generatePdf()
  {
  var data = document.getElementById('contentToConvert');
  html2canvas(data).then(canvas => {
  // Few necessary setting options
  var imgWidth =208;
  var pageHeight = 295;
  var imgHeight = canvas.height * imgWidth / canvas.width;

  imgHeight = 84;

  var heightLeft = imgHeight;
   
  const contentDataURL = canvas.toDataURL('image/png')
  let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
  var position = 10;
  pdf.addImage(contentDataURL, 'PNG',0, position, imgWidth, imgHeight)
  let flName = this.docID.toString() +"wlRec.pdf"
  pdf.save(flName); // Generated PDF
  });

  //this.rpt_print = '';
 // this.docID = 0;

  this.router.navigate(['/dashboard/clerk-dashboard']);

  }

  showpsw_Modal: boolean;
 show_pswModal(showhide: boolean) {
   this.showpsw_Modal = showhide;
 }
 showloading_Modal: boolean;
 show_loadingModal(showhide: boolean) {
   this.showloading_Modal = showhide;
 }
 hide_show_Print(hide_showPrint:string){
   this.rpt_print = hide_showPrint;
   if (hide_showPrint == ''){
     //this.cancle_entry();
     this.router.navigate(['/dashboard/clerk-dashboard']);
   }
 }
 cancle_entry(){
  this.docID = 0;
  this.toliID = 0;
  this.rpt_print = '';

  this.estID = 0;
  this.toliInfo = '';
  this.estInfo = '';
  this.selected_SubEstName='';
  this.toliInfo = '';
  this.estInfo = ''
  this.estID = 0;
  this.marketID = 0;
  this.marInfo = '';
  this.searchToli = '';
   this.toliInfo = '';
  this.toliserchCnt = '';
  this.newsubEstName = '';
   this.marInfo = '';
  this.compInfo = '';
  this.marketID = 0;
  this.companyID = 0;
  this.receiptNO = 0;
  this.tot_ChqAmt = 0;

  this.recPaid = 'No';

  this.bind_ChqList = [];

  this.fill_CrAcList();
  this.set_levyCode(0);
  this.get_filter_debitAcList(0);
  this.fill_chqBankDt_List('');

  this.set_searchChqBank('',0,0,'NA','NA','NA');

  //this.fill_AddMstData();
 }
}
