import { Component, OnInit,ElementRef ,ViewChild } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';

import jspdf from 'jspdf';

import html2canvas from 'html2canvas';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-lab-paidbank-list-rmatha',
  templateUrl: './lab-paidbank-list-rmatha.component.html',
  styleUrls: ['./lab-paidbank-list-rmatha.component.css'],
  providers: [NgbAccordionConfig]
})
export class LabPaidbankListRmathaComponent implements OnInit {
  toliID;
  filterFayear;
  rpt_print;
  constructor(config: NgbAccordionConfig,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,) { 
      config.closeOthers = true;
      config.type = 'info';

    this.toliID = Number(this.route.snapshot.queryParamMap.get('toliID'));
    this.filterFayear = Number(this.route.snapshot.queryParamMap.get('fayear'));
    this.rpt_print = this.route.snapshot.queryParamMap.get('print').toString();
    }

  ngOnInit(): void {
  
  }

}
