import { Component, OnInit,ElementRef ,ViewChild } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

@Component({
  selector: 'app-set-userauth',
  templateUrl: './set-userauth.component.html',
  styleUrls: ['./set-userauth.component.css']
})
export class SetUserauthComponent implements OnInit {
  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  constructor(config: NgbAccordionConfig,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,) { 
      if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
        this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
      }
      if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
        this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
      }
      
    }

  ngOnInit(): void {
   
  }
  auth_Dt=[];
  public authFor:string="";
  fill_userAuthDt(event){
   this.authFor = event.target.value;
    this.auth_Dt = [];
    let auth_List = [];
    if (Number(this.Log_BoardID) > 0){
      this.ngxService.start(); 
      let url = 'getUserAuthDt/' + this.Log_BoardID
      this._userService.get_Dashborad_Data(url).subscribe((data) => {
         auth_List = data;
         console.log(auth_List);
        this.ngxService.stop();
        if (this.authFor != ''){
          let filterList = auth_List.filter(x => (x.authFor).toString() == this.authFor);
          if (Number(filterList.length) > 0){
            this.auth_Dt = filterList;
          }
          else{
            let filterList = auth_List.filter(x => (x.authFor).toString() == "");
            this.auth_Dt = filterList;
          }
          
        }
        
        //console.log(this.auth_Dt);
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        //alert(err);
        alert('Something went wrong.');
        return;
      });
    }
    else{
      alert('BoardID not get.')
    }
    
  }
  set_checkStatus(i,colName){
    if (colName == 'Add'){
      if (this.auth_Dt[i]['add'] == true) {
        this.auth_Dt[i]['add'] = false;
      }
      else {
        this.auth_Dt[i]['add'] = true;
      }
    }
    else if (colName == 'Edit'){
      if (this.auth_Dt[i]['edit'] == true) {
        this.auth_Dt[i]['edit'] = false;
      }
      else {
        this.auth_Dt[i]['edit'] = true;
      }
      
    }
    else if (colName == 'Delete'){
      if (this.auth_Dt[i]['delete'] == true) {
        this.auth_Dt[i]['delete'] = false;
      }
      else {
        this.auth_Dt[i]['delete'] = true;
      }
      
    }
  }
  submit(){
    if (this.authFor == ''){
      alert('Select Authority For.')
      return '';
    }
    else if (Number(this.auth_Dt.length) <= 0){
      alert('Something went wrong.')
      return '';
    }
    else{

      for (let i = 0; i < this.auth_Dt.length; i++) {
        this.auth_Dt[i]['authFor'] = this.authFor
      }

      let saveData: any = {
        "UserAuthDt": this.auth_Dt,
       }

      let body = new FormData();
      body = saveData;

      this.ngxService.start();

      let url = 'api/dashboard/saveUserAuthDt';
      this._userService.post_data(url, body).subscribe(
        (data) => {
          this.ngxService.stop();
          alert(data);
          if (data == 'Record saved successfully.') {
            this.auth_Dt = [];
          }

        },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          alert(err);
        });

    }
  }

}
