import { Injectable } from '@angular/core';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Http, Response, Headers, RequestOptions,ResponseContentType, ResponseType } from '@angular/http';
import { Observable, from } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Globals } from '../Globals'
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()

export class UserService {
  headers: Headers;
  constructor(private _http: Http,
    private globals: Globals,
    private router: Router,) { }

  get_allBoard() {
    return this._http.get(this.globals.BaseURL + 'api/dashboard/getAllBoardName', this.getApiOptionData_withoutTokan()).pipe(map(res => res.json()));
  }
  get_Dashborad_Data(apiName: string) {
    //console.log(this.globals.BaseURL + 'api/dashboard/' + apiName);
    var headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });
    return this._http.get(this.globals.BaseURL + 'api/dashboard/' + apiName, options).pipe(map(res => res.json()));

  }

  userData: any = [];
  send_nextPage(userid, boardid, usertype) {
    //  alert(usertype)
    
    this.userData = [];
    if (usertype == 'Staff' || usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector') {
      let url = 'getLoginUserDt/' + userid + '/' + "''";
      this.get_Dashborad_Data(url).subscribe((data: any) => {
        this.userData = data

        sessionStorage.setItem('mathaLog_Email', this.userData[0]['emailID']);
        sessionStorage.setItem('mathaLog_UserTypeID', this.userData[0]['userTypeID']);
        sessionStorage.setItem('mathaLog_UserMob', this.userData[0]['mobileNo']);
        sessionStorage.setItem('mathaUserName', this.userData[0]['userName']);
        sessionStorage.setItem('mathaLog_BoardID', this.userData[0]['boardID']);
        sessionStorage.setItem('mathaLog_accessFor', this.userData[0]['accessFor']);
        sessionStorage.setItem('mathafilterBoradID', this.userData[0]['boardID']);
        sessionStorage.setItem('mathaLog_UserType', usertype);

        if (this.userData.length == 1) {
          this.globals.log_userName = this.userData[0]['userName'];
          this.globals.is_login = true;

          if (usertype == 'Chairman' && this.userData[0]['userTypeID'] == 2){
            // if (this.userData[0]['accessFor'] == 'HO'){
            //   sessionStorage.setItem('mathaLog_BoardID', '0');
            //   sessionStorage.setItem('mathafilterBoradID', '0');
            // }
            this.router.navigate(['dashboard']);
          }
          else if (usertype == 'Secretary'  && this.userData[0]['userTypeID'] == 6){
            // if (this.userData[0]['accessFor'] == 'HO'){
            //   sessionStorage.setItem('mathaLog_BoardID', '0');
            //   sessionStorage.setItem('mathafilterBoradID', '0');
            // }
            
            this.router.navigate(['dashboard']);
          }
          else if (usertype == 'Inspector'  && this.userData[0]['userTypeID'] == 4){
            // if (this.userData[0]['accessFor'] == 'HO'){
            //   sessionStorage.setItem('mathaLog_BoardID', '0');
            //   sessionStorage.setItem('mathafilterBoradID', '0');
            // }
            
            this.router.navigate(['dashboard']);
          }
          else if (usertype == 'HO'  && this.userData[0]['userTypeID'] == 7){
            if (this.userData[0]['accessFor'] == 'HO'){
              sessionStorage.setItem('mathaLog_BoardID', '0');
              sessionStorage.setItem('mathafilterBoradID', '0');
            }
            
            this.router.navigate(['dashboard']);
          }
          else if (usertype == 'Staff'){
            this.router.navigate(['dashboard/clerk-dashboard']);
          }
          
        }
        else {
          sessionStorage.setItem('mathaLog_login', '0');
          this.router.navigate(['/sellogin'])
          this.globals.log_userName = this.userData[0]['userName'];
          this.globals.is_login = true;
        }

      });


    }

  }

  staff_login(mobemail, psw, usertype, boardid) {
    
    this.UserAuthentication(mobemail, psw).subscribe((data: any) => {
      if (data.UserID > 0) {
        // 
        // return;
        //alert('done');
        sessionStorage.setItem('mathaUserToken', data.access_token);
        sessionStorage.setItem('mathaLog_UserID', data.UserID);
        sessionStorage.setItem('mathaLog_UserType', usertype);
        this.send_nextPage(data.UserID, boardid, usertype)
      }
      else {
        alert('Invalid UserID Or Password.')
      }
    });
    return;
  }

  UserAuthentication(userName, password) {
    // var data = "UserName=" + userName + "&Password=" + password + "&grant_type=password&MobileNo=" + userName + "&IsWebLogin=True";
    var data = "UserName=" + userName + "&Password=" + password + "&grant_type=password&TokenFor=&MobileNo=" + userName + "&BoardID= 0&BranchID=0";
    var headers = new Headers({ 'Content-Type': 'application/x-www-urlencoded' });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.globals.BaseURL + 'token', data, options).pipe(map(res => res.json()));
  }
  Otp_UserAuthentication(userName, password) {
    var data = "UserName=" + userName + "&Password=" + password + "&grant_type=password&MobileNo=" + userName + "&IsWebLogin=True";
    var headers = new Headers({ 'Content-Type': 'application/x-www-urlencoded' });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.globals.BaseURL + 'token', data, options).pipe(map(res => res.json()));
  }

  getProfileDetails(url) {
    var headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });
    return this._http.get(this.globals.BaseURL + 'api/dashboard/getuserprofiledetails', options).pipe(map(res => res.json()));

  }

  updateProfileDetails(bodyString) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.globals.BaseURL + 'api/dashboard/updateuserdetails', bodyString, options)
      .pipe(map((response: Response) => response.json()));
  }

  changePassword(bodyString) {
    var headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });

    return this._http.post(this.globals.BaseURL + 'api/dashboard/updatepassword', bodyString, options).pipe(map(res => res.json()));
  }

  //Commond method to getnerate API option
  getApiOptionData() {
    var headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    return new RequestOptions({ headers: headers });

  }

  getStates() {
    return this._http.get(this.globals.BaseURL + 'api/dashboard/getallstates', this.getApiOptionData()).pipe(map(res => res.json()));
  }

  getDistrict(states) {
    return this._http.get(this.globals.BaseURL + 'api/dashboard/getdistrictbystateids/' + states, this.getApiOptionData()).pipe(map(res => res.json()));
  }


  getMobileotp(mobile) {
    let otpval = (Math.floor(Math.random() * 9000) + 1000).toString();
    var headers = new Headers()
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let options = new RequestOptions({ headers: headers });
     let sms_url = 'https://sms.happysms.in/api/sendhttp.php?authkey=363372A5aL0uyItx60d9aa30P1&mobiles='
     + mobile + '&message=Your OTP from KIT is ' + otpval + '&sender=KITLtd&route=4&country=91&DLT_TE_ID=1707163937683822686';
      this.get_smsApi_method(sms_url)
      .then(data => {
        //console.log('smsData : ', data);

      })
      .catch(err => {
       // console.log('sms_error : ', err);
        //this.showErrorAlert(err);

      }); 

    //this._http.get(sms_url, options).pipe(map(res => res.json()));
    return otpval;

  }
  public data:any;
  public async get_smsApi_method(endurl) {
    const url = endurl;
    //const token = await this.getToken();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    //headers.append('Authorization', token);
    const options = new RequestOptions({ headers });

    return new Promise((resolve, error) => {
      this._http.get(url, options)
        .pipe(map(res => res.json()))
        .subscribe(data => {
         // this.data = data;
         // resolve(this.data);
        },
          err => {
           // console.log('error : ', err);
            // if (err.status == 401) {
            //   err = JSON.stringify('Your session has expired please try to login again.');
            //   error(err);
              
            // } else if (err.status == 500) {
            //   err = JSON.stringify('An error has occurred.');
            //   error(err);
            // } else {
            //   error(err);
            // }
          });
    });
  }

  send_chat_Notification(bodyString) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.globals.BaseURL + 'api/account/SendChatNotification', bodyString, options)
      .pipe(map((response: Response) => response.json()));
  }

  get_login_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMatha'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMatha'
    }
    else if (BoarID == 6) {
      return 'PimMatha'
    }
  }
  get_clerkDashBoard_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaClerkDashboard'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaClerkDashboard'
    }
    else if (BoarID == 6) {
      return 'PimMathaClerkDashboard'
    }
  }
  get_wl_rec_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaWLRec'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaWLRec'      
    }
    else if (BoarID == 6) {
      return 'PimMathaWLRec'
    }
  }
  get_labmaster_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaLabMst'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaLabMst'      
    }
    else if (BoarID == 6) {
      return 'PimMathaLabMst'
    }
  }
  get_est_master_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaEstMst'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaEstMst'      
    }
    else if (BoarID == 6) {
      return 'PimMathaEstMst'
    }
  }

  get_trans_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaTrans'
    }
    
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaTrans'      
    }
    else if (BoarID == 6) {
      return 'PimMathaTrans'
    }
  }
  get_labpay_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaLabPayment'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaLabPayment'      
    }
    else if (BoarID == 6) {
      return 'PimMathaLabPayment'
    }
  }
  get_paidbankList_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaLabPaidBankList'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaLabPaidBankList'      
    }
    else if (BoarID == 6) {
      return 'PimMathaLabPaidBankList'
    }
  }
  get_labpay_Reports_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaRptLabPayment'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaRptLabPayment'      
    }
    else if (BoarID == 6) {
      return 'PimMathaRptLabPayment'
    }
  }

  get_insCompDashboard_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaLICCompDashboard'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaLICCompDashboard'      
    }
    else if (BoarID == 6) {
      return 'PimMathaLICCompDashboard'
    }
  }
  get_insCompDashboard_DocFolderName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaLicLabDoc'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaLicLabDoc'      
    }
    else if (BoarID == 6) {
      return 'PimMathaLicLabDoc'
    }
  }

  get_patCompDashboard_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaPatCompDashboard'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaPatCompDashboard'      
    }
    else if (BoarID == 6) {
      return 'PimMathaPatCompDashboard'
    }
  }
  get_unionCompDashboard_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaUnionDashboard'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaUnionDashboard'      
    }
    else if (BoarID == 6) {
      return 'PimMathaUnionDashboard'
    }
  }
  get_patCompDashboard_DocFolderName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaPatLabDoc'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaPatLabDoc'      
    }
    else if (BoarID == 6) {
      return 'PimMathaPatLabDoc'
    }
  }
  get_unionCompDashboard_DocFolderName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaUnionLabDoc'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaUnionLabDoc'      
    }
    else if (BoarID == 6) {
      return 'PimMathaUnionLabDoc'
    }
  }
  get_rpt_controlerName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaReports'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaReports'      
    }
    else if (BoarID == 6) {
      return 'PimMathaReports'
    }
  }
  get_LabMst_DocFolderName(BoarID) {
    if (BoarID == 1) {
      return ''
    }
    else if (BoarID == 2) {
      return 'NskMathaLabDoc'
    }
    else if (BoarID == 3) {
      return ''
    }
    else if (BoarID == 4) {
      return 'RMathaLabDoc'      
    }
    else if (BoarID == 6) {
      return 'PimMathaLabDoc'
    }
  }
  getApiOptionData_withoutTokan() {
    // var headers = new Headers({ 'Content-Type': 'application/json','Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    // return  new RequestOptions({ headers: headers });

    let headers = new Headers({ 'Content-Type': 'application/json' });
    return new RequestOptions({ headers: headers });
  }
  get_Data(apiName: string) {
    var headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });
    return this._http.get(this.globals.BaseURL + apiName, options).pipe(map(res => res.json()));

  }
  get_DataFile(apiName: string) {
    var headers = new Headers({'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken')});
    let options = new RequestOptions({headers: headers,responseType:ResponseContentType.Blob  });
    return this._http.get(this.globals.BaseURL + apiName, options).pipe(map((response: Response) => response));
  }
  post_data_with_body(url, bodyString) {
    
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers,params:bodyString });
    return this._http.get(this.globals.BaseURL + url,  options)
      .pipe(map((response: Response) => response.json()));
  }
  post_data(url, bodyString) {
    
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.globals.BaseURL + url, bodyString, options)
      .pipe(map((response: Response) => response.json()));
  }
  delete_data(url, bodyString) {
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });
    return this._http.delete(this.globals.BaseURL + url, bodyString)
      .pipe(map((response: Response) => response.json()));
  }
  Delete_Enrty_ByID(url) {
    let body = {};
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.globals.BaseURL + url, body, options)
      .pipe(map((response: Response) => response.json()));
  }

  post_uploadImage(url, bodyString) {
    
     let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
     let options = new RequestOptions({ headers: headers });
    // return this._http.post(this.globals.BaseURL + url, bodyString, options)
    //   .pipe(map((response: Response) => response.json()));

      return this._http.post(this.globals.BaseURL + url,bodyString,options);
  }
  
 public async get_userAuthDt(){
  let Log_BoardID: Number = -1;
  if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
    Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
  }
   let url = 'getUserAuthDt/' + Log_BoardID
    this.get_Dashborad_Data(url).subscribe((data) => {
     // console.log('retData',data);
       return data;
    });
 }
 public async check_userAuth(authFor:string,actionType:string){
   let auth_List:any = [];
   let userTypeID: Number = -1;
  if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
    userTypeID = Number(sessionStorage.getItem('mathaLog_UserTypeID'));
  }

  let Log_BoardID: Number = -1;
  if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
    Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
  }

  // console.log('userTypeID:', userTypeID)
  // console.log('authFor:', authFor)
  // console.log('actionType:', actionType)
 
  

   let url = 'getUserAuthDt/' + Log_BoardID
    this.get_Dashborad_Data(url).subscribe((data) => {
      
     // console.log('retData',data);
     auth_List = data;
     console.log('auth_List:', auth_List)
     if (authFor != ''){
      let filterList = [];
      if (actionType.toString().toLocaleLowerCase() == 'add'){
        filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.add).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
      }
      else if (actionType.toString().toLocaleLowerCase() == 'edit'){
        filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.edit).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
      }
      else if (actionType.toString().toLocaleLowerCase() == 'delete'){
        filterList = auth_List.filter(x => (x.authFor).toString() == authFor && (x.delete).toString().toLocaleLowerCase() == 'true' && Number(x.userTypeID) == userTypeID);
      }
      else{
        return false;
      }
     
      if (Number(filterList.length) > 0){
        return true;
      }
      else{
        return false;
      }

    }
    else {
      return false;
    }
    },
    (err: HttpErrorResponse) => {
      return false;
    });

   // return false;
  
}

get_public_API_Data(apiName: string) {
  return this._http.get(apiName);
  
  var headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('UserToken') });
  let options = new RequestOptions({ headers: headers });
  return this._http.get(apiName, options).pipe(map(res => res.json()));

}

}