import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../service/UserService';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
//import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../Globals'
import { Injectable } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  IsUserLoggedIn: boolean = false;
  log_userName:string='';
 public boardName = '';
 show_LablisRpt = '';
 lab_ActiveList:any=[];
  constructor(public _userService: UserService,
    public http: Http,
    private formBuilder: FormBuilder,
    // private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals) {
      this.log_userName = this.globals.log_userName;
    }

  ngOnInit(): void {
    if (sessionStorage.getItem('mathaUserName') != null){
      this.globals.log_userName = sessionStorage.getItem('mathaUserName');
      this.globals.is_login = true;
      this.IsUserLoggedIn= true;
    }
    else{
      this.globals.log_userName = '';
      this.globals.is_login = false;
      this.IsUserLoggedIn= false;
    }
    
    if (this.globals.BaseURL == 'https://webapi.pcmcmathadi.in:9443/'){
     // this.boardName= 'Mathadi And Unprotected Labour Board';
     this.boardName = this.globals.boardName
      //
    }
    else{
      this.boardName = 'Testing Application';
    }
    
  }
  LogOut() {
        this.globals.log_userName ='';
        this.globals.is_login = false;
        sessionStorage.setItem('mathaUserToken', '');
        sessionStorage.setItem('mathaLog_Email','');
        sessionStorage.setItem('mathaLog_UserTypeID', '');
        sessionStorage.setItem('mathaLog_UserMob', '');
        sessionStorage.setItem('mathaUserName', '');
        sessionStorage.setItem('mathaLog_BoardID', '');
        sessionStorage.setItem('mathaLog_accessFor', '');
        sessionStorage.setItem('mathafilterBoradID', '');
        sessionStorage.setItem('mathaLog_UserType', '');
        sessionStorage.clear();
        this.IsUserLoggedIn = false;
        this.globals.log_userName = '';
        this.globals.is_login = false;
        this.IsUserLoggedIn= false;
        window.location.replace('/');
  }
  userList:any=[];
  fill_userList(){
    this.userList = [
      { id: 11, name: 'Dr Nice' },
      { id: 12, name: 'Narco' },
      { id: 13, name: 'Bombasto' },
      { id: 14, name: 'Celeritas' },
      { id: 15, name: 'Magneta' },
      { id: 16, name: 'RubberMan' },
      { id: 17, name: 'Dynama' },
      { id: 18, name: 'Dr IQ' },
      { id: 19, name: 'Magma' },
      { id: 20, name: 'Tornado' }
    ];
  }
  goto_userAuthPage(){
    if (sessionStorage.getItem('mathaLog_UserTypeID') != null && sessionStorage.getItem('mathaLog_UserTypeID') != '') {
      if(Number(sessionStorage.getItem('mathaLog_UserTypeID')) == 1){
        this.router.navigate(['admin/set-userauth']);
      }
      else{
        alert('You have no authority.');
      }
    }
    else{
      alert('You have no authority.');
    }
    
  }

  goto_dashboard() {
    
    if (sessionStorage.getItem('mathaLog_UserType') != null){
      let usertype = sessionStorage.getItem('mathaLog_UserType')
      if (usertype == 'HO' || usertype == 'Chairman' || usertype == 'Secretary' || usertype == 'Inspector') {
        this.router.navigate(['/dashboard']);
      }
      else if (sessionStorage.getItem('mathaLog_UserType') == "Staff") {
        this.router.navigate(['dashboard/clerk-dashboard']);
      }
      else if (sessionStorage.getItem('mathaLog_UserType') == "Insurance") {
        this.router.navigate(['dashboard/inscomp-Dashboard']);
      }
      else if (sessionStorage.getItem('mathaLog_UserType') == "Patpedi") {
        this.router.navigate(['dashboard/patcomp-Dashboard']);
      }
    }
  }
  view_PdfFile(fileName): any {
//alert('done');
//un comment when pimpri changes upload
//Only applicable for Pimpri
  
  //this.fill_activeLabList();
   
 // window.open("https://webapi.pcmcmathadi.in/" + fileName);

  if (fileName =='ActiveLabList.pdf'){
    let parameters = {
      listType: 0,
    };
    
    this.router.navigate(['download-list'], { queryParams: parameters });
    
  }
  else if (fileName == 'ActiveEstList.pdf'){
    let parameters = {
      listType: 1,
    };
    
    this.router.navigate(['download-list'], { queryParams: parameters });
   
  }
  else{
    window.open("https://webapi.pcmcmathadi.in:9443/" + fileName);
  }
 }
chk_fun(){
  alert('done');
}
}
