import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wl-recrpt',
  templateUrl: './wl-recrpt.component.html',
  styleUrls: ['./wl-recrpt.component.css']
})
export class WlRecrptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
