<div class="container dashboardmain" >
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Patpedi Office Dashboard</li>
                <!-- <li>{{tempdate}}</li> -->
            </ol>
        </nav>
    </div>

    <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
        <ngb-panel title="Upload File &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{lastUploadMonth}}" id="config-panel-one" > 
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-10">
                        <h3 style="color: black;">Sample file format click here to download <span><a style="color: blue;text-decoration: underline;" (click)="download_file_excelFormat()" > Download</a> columns in red are mandatory.</span> </h3>  
                    </div>
                    <div class="col-md-2"></div>

                 

                </div>

                <div class="row" *ngIf="loading ==''">
                    <div class="col-md-2">
                        <label for="Financial-Year">Year </label>
                        <input type="text" (keypress)="onlyNumberKey($event)" [(ngModel)]="uploadYr" value="{{uploadYr}}"  class="form-control"  maxlength="4" disabled="" />
                      </div>
                      <div class="col-md-2">
                        <label for="month-year">Month </label>
                        <input type="text" (keypress)="onlyNumberKey($event)" [(ngModel)]="uploadMon" value="{{uploadMon}}" class="form-control"  maxlength="2" disabled="" />
                      </div>
                      <div class="col-md-4">
                        <label for="month-year">Browse excel file </label>
                        <input type="file" style="display: inline-block;" (change)="sel_uploadfile($event)" (click)="$event.target.value=null" placeholder="Upload file" accept=".xlsx">
                    
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-2" style="margin-top: 18px;">
                        <button type="button" class="btn btn-info" (click)="fill_patpedi_fromDb()" >Upload</button>
                    </div>
                    
                </div>

                <div class="row" *ngIf="loading!=''">
                    <div  class="col-md-12" style="margin-top: 18px; text-align: center;">
                            
                        File Uploading...
                        <div class="spinner-grow text-success"></div>
                        <div class="spinner-grow text-info"></div>
                        <div class="spinner-grow text-warning"></div>
                    </div>
                   </div>

                <div class="row" *ngIf="new_patpediLabList.length > 0 && noLabGet_fromDb_List.length == 0">
                     <h1 style="margin-left: 50px;margin-top: 20px; color: red;">Please search for labour below.</h1>
                    <table style="margin-left: 10px;margin-right: 10px;" class="table table-hover table-responsive-xl labour-table">
                        <thead>
                          <!-- <th> </th> -->
                          <th style="text-align: left;">Reg.No. </th>
                          <th style="text-align: left;">Name </th>
                          <th style="text-align: left;">Patpedi Holder Name </th>
                          <!-- <th style="text-align: center;">Policy No. </th> -->
                          <th style="text-align: left;">Deduction Amt.</th>
                          <th style="text-align: left;">Select documnet</th>
                         </thead>
                        <tr *ngFor='let rec of new_patpediLabList;let i = index' class="sub-tr border" [ngStyle]="{'color': rec.LabourID == 0 ? 'red' : null }">
                            <!-- <td>
                                <label class="check-input">
                                    <input type="checkbox" class="checkbox"  (change)="set_addNewLab_Check($event,i)" [checked]="rec.isSave"  >
                                    <span class="checkmark"></span>
                                </label>
                            </td> -->
                            <td >{{rec.RegNo}} </td>
                            <td *ngIf="rec.isEdit == false">{{rec.LabName}} <i class="fa fa-edit" ngbTooltip="Edit labour" aria-hidden="true" style="color: blue;" (click)="set_upload_editcontrol(i)"></i> </td>
                            <td *ngIf="rec.isEdit == true"><input type="text"  (input)="search_upload_labname($event,i)"  class="form-control" placeholder="Search by name or regno"  /> 

                                <!-- <td *ngIf="rec.isEdit == true"><input type="text" [(ngModel)]="updateLabName" (input)="search_upload_labname()" value="{{updateLabName}}" class="form-co -->
                                
                                <ul class="list-group list-group-flush anyClass" *ngIf="filter_uploadLabName.length > 0 && selcted_cntrIndex == i">
                                    <li class="list-group-item nav-item" *ngFor="let serchrec of filter_uploadLabName" appHighlight
                                      [searchedWord]="serchrec.labRegNo + ' - ' + serchrec.labName" [content]="serchrec.labRegNo + ' - ' + serchrec.labName" [classToApply]="'font-weight-bold'"
                                      [setTitle]="'true'"
                                      (click)="set_upload_editlabNamel(serchrec.labName,serchrec.labRegNo,serchrec.labourID,i);"
                                      style="background-color: #dcf9f6;text-align: left;color: blue;">
                                      {{serchrec.labRegNo}} - {{serchrec.labName}} 
                                    </li>
                                  </ul>


                                <!-- <i class="fa fa-floppy-o" ngbTooltip="Update labour" style="color: blue;"  (click)="set_upload_editlabNamel(i)" aria-hidden="true"></i> -->
                                 </td> 
                                 <td >{{rec.exlLabName}} </td>
                            <!-- <td>{{rec.PolicyNo}} </td> -->
                            <td >{{rec.DedAmt}} </td>
                           <td><input type="file" style="display: inline-block;" (change)="set_uploadfile($event,i,rec.LabourID)"  (click)="$event.target.value=null" placeholder="Upload file" ></td>
                           <!--  -->
                          </tr>

                          
                    </table>

                   
                </div>
                
                <div style="text-align: center;" *ngIf="new_patpediLabList.length > 0 && noLabGet_fromDb_List.length == 0">
                    <button type="button" class="btn btn-info" (click)="upload_excel_file()">Submit</button>
                    &nbsp;&nbsp; <button type="button" class="btn btn-danger" (click)="cancle_update_AddNewLab()">Cancle</button>
                </div>
                

                <div class="row" *ngIf="noLabGet_fromDb_List.length > 0">
                    <h1 style="margin-left: 50px;margin-top: 20px; color: red;">Check Patpedi details from selected excle file invalid labour.</h1>
                   <table style="margin-left: 20px;" class="table table-hover table-responsive-xl labour-table">
                       <thead>
                         <th >Reg.No. </th>
                         <th >Name </th>
                         <th >Remark </th>
                         
                         <th style="text-align: center;"></th>
                        </thead>
                       <tr *ngFor='let rec of noLabGet_fromDb_List;let i = index' class="sub-tr border" >
                           <td >{{rec.RegNo}} </td>
                           <td >{{rec.LabName}}  </td>
                           <td>{{rec.Flag}} </td>
                           
                          <td></td>
                         </tr>
                   </table>
                   
               </div>
               <div style="text-align: center;" *ngIf="noLabGet_fromDb_List.length > 0">
                <button  type="button" class="btn btn-danger" (click)="cancle_update_AddNewLab()">Cancle</button>
               </div>

                <!-- Excel Data List -->

                <!-- <div class="row" *ngIf="excle_policyLabList.length > 0">
                    <h1 style="margin-left: 50px;margin-top: 20px; color: green;">Policy details from selected excle file</h1>
                   <table class="table table-hover table-responsive-xl labour-table">
                       <thead>
                         <th style="text-align: center;">Reg.No. </th>
                         <th style="text-align: center;">Name </th>
                         <th style="text-align: center;">Policy No. </th>
                         <th style="text-align: center;">Policy Amt.</th>
                         <th style="text-align: center;"></th>
                        </thead>
                       <tr *ngFor='let rec of excle_policyLabList;let i = index' class="sub-tr border" >
                           <td >{{rec.RegNo}} </td>
                           <td >{{rec.LabName}}  </td>
                           <td>{{rec.PolicyNo}} </td>
                           <td >{{rec.PolicyAmt}} </td>
                          <td></td>
                         </tr>
                   </table>
               </div> -->

               
            </ng-template>
        </ngb-panel>
        <ngb-panel title="Approval Pending From Board">
            <ng-template ngbPanelContent>
               <div class="row">
                <div class="col-md-12" style="margin-top: 18px;text-align: center;">
                    <button type="button" class="btn btn-primary" (click)="fill_approval_pendingList()" >Show</button>
                </div>
               </div>

               <div class="row" *ngIf="lab_approval_pendList.length > 0">
                <h1 style="margin-left: 50px;margin-top: 20px; color: blue;">Patpedi Approval Pending (To be approved by board)</h1>
               <table style="margin-left: 15px;" class="table table-hover table-responsive-xl labour-table">
                   <thead>
                     <!-- <th> </th> -->
                     <th >Reg.No. </th>
                     <th >Name </th>
                     <th >Ded. Amt.</th>
                     <th >Status</th>
                     <th >Remark</th>
                     <th >Document</th>
                     <th></th>
                     <th></th>
                     <th></th>
                     <th></th>
                    </thead>
                   <tr *ngFor='let rec of lab_approval_pendList;let i = index' class="sub-tr border" >
                     
                       <td >{{rec.labRegNo}} </td>
                       <td *ngIf="rec.isEdit == false">{{rec.labName}}  </td> 
                      
                       <td *ngIf="rec.isEdit == true" style="width: 380px;"><input type="text"  (input)="search_approval_upload_labname($event,i)"  class="form-control" value="{{rec.labName}}"  /> 
                        <ul class="list-group list-group-flush anyClass" *ngIf="filter_approval_uploadLabName.length > 0 && selcted_approval_cntrIndex == i">
                            <li class="list-group-item nav-item" *ngFor="let serchrec of filter_approval_uploadLabName" appHighlight
                              [searchedWord]="serchrec.labRegNo + ' - ' + serchrec.labName" [content]="serchrec.labRegNo + ' - ' + serchrec.labName" [classToApply]="'font-weight-bold'"
                              [setTitle]="'true'"
                              (click)="set_approval_editlabNamel(serchrec.labName,serchrec.labRegNo,serchrec.labourID,i);"
                              style="background-color: #dcf9f6;text-align: left;color: blue;">
                              {{serchrec.labRegNo}} - {{serchrec.labName}} 
                            </li>
                          </ul>
                         </td> 
                       <!-- <td *ngIf="rec.isEdit == false">{{rec.policyNo}} </td>
                       <td *ngIf="rec.isEdit == true" style="width: 180px;"><input type="text" (input)="set_approval_updateData($event,'policyno',i,rec.labourID,'','')"  class="form-control" value="{{rec.policyNo}}"  /> </td> -->
                       <td *ngIf="rec.isEdit == false">{{rec.dedAmt}} </td>
                       <td *ngIf="rec.isEdit == true" style="width: 130px;"><input type="text" (input)="set_approval_updateData($event,'dedAmt',i,rec.labourID,'','')" class="form-control" value="{{rec.dedAmt}}"  /> </td>
                       <td >{{rec.status}}</td>
                      <td >{{rec.remark}}</td>
                      <td *ngIf="rec.isEdit == false"><i  class="fa fa-file" ngbTooltip="view file" (click)="view_uploadFile(rec.docName)" aria-hidden="true" style="color:black;" > </i></td>
                      <td *ngIf="rec.isEdit == true"><input type="file" style="display: inline-block;" (change)="set_approval_updateData($event,'doc',i,rec.labourID,'','')"  (click)="$event.target.value=null" placeholder="Upload file" > </td>
                      <td *ngIf="rec.isEdit == false"><i class="fa fa-edit" ngbTooltip="Edit file" aria-hidden="true" style="color: blue;" (click)="set_approval_pendList_editcontrol(i,true)"></i> </td>
                      <td ><a *ngIf="rec.isEdit == true" style="color: blue;" (click)="update_approval_pending_List(i)">Update</a></td>
                      <td ><i *ngIf="rec.isEdit == true" class="fas fa-times" ngbTooltip="Cancle edit" aria-hidden="true" style="color: red;" (click)="set_approval_pendList_editcontrol(i,false)"></i></td>
                      <td></td>



                     </tr>

                     
               </table>

              
           </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel title="Download Deduction List">
            <ng-template ngbPanelContent>

                <form class="search">
                    <div class="row">
            
                        <div class="col-md-3">
                            <label for="Financial-Year">Year</label>
                            <select (change)="Set_Fayear($event)" class="form-control select-t" id="Financial-Year">
                                <option *ngFor='let yr of YrList' [value]="yr.dedYear" [selected]="yr.dedYear=== filterFayear">
                                    {{yr.dedYear}}</option>
                            </select>
            
                            
                        </div>
                        <div class="col-md-3">
                            <label for="month-year">Month</label>
                            <select (change)="Set_FiterYrMon($event)" class="form-control select-t" id="month-year">
                                <option *ngFor='let mon of MonthList' [value]='mon.mon' [selected]="mon.mon=== filterMonth">
                                    {{mon.dispmon}}</option>
                            </select>
                        </div>
            
                        <div class="col-md-3">
                            <button type="button" class="btn btn-primary" style="margin-top: 28px;" (click)="fill_patpediDedList()" >Show</button>
                           
                        </div>
                        <div class="col-md-3">
                           
                            <button type="button" *ngIf="pat_DedList.length > 0" class="btn btn-info" style="margin-top: 28px;" (click)="exportexcel()">Download Excel File</button>
                        </div>
                    </div>
                </form>

                <table id="excel-table" class="table table-bordered" *ngIf="pat_DedList.length > 0"> 
                    
                    <thead class="thead-dark">
                        <tr>
                            <td colspan="5" style="text-align: center;">
                                <b>{{boardName}}</b>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5" style="text-align: center;">
                                <b>Patpedhi Deduction List {{strMonthName}} / {{filterYr}}</b> 
                            </td>
                        </tr>
                        <tr>       
                       <th>Sr.No.</th> 
                       <th>RegNo.</th>  
                       <th>Name</th> 
                       <th>Ded. Amt.</th>   
                    </tr>  
                    </thead>
                      <tbody>
                           <tr *ngFor='let rec of pat_DedList;let i = index;'>
                   
                       <td>{{i + 1}} </td>  
                       <td>{{rec.labRegNo}}</td> 
                       <td>{{rec.labName}}</td> 
                       <td>{{rec.dedAmt}}</td> 
                    </tr> 
                      </tbody>
                      <tfoot>
                        <tr Class="table-footer">
                            <td></td>
                            <td></td>
                            <td><b>Total</b></td>
                            <td><b>{{getTotal(pat_DedList,'dedAmt')}}</b></td>
                           
                        </tr>
                    </tfoot>
                    
                </table>
            </ng-template>
        </ngb-panel>

    </ngb-accordion>

    <!-- <button class="btn-info" (click)="show_modal_patpediRej(0,'')">  Test</button> -->

   <!-- New Patpedhi Reject -->
<div class="container">

    <div class="modal" id="patRejModal" [style.display]="showModal_patpediRej ? 'block' : 'none'">
        <div class="modal-body" style="text-align: center;background-color:transparent;">
            <div class="spinner-grow text-success"></div>
            <div class="spinner-grow text-info"></div>
            <div class="spinner-grow text-warning"></div>
        </div>
    </div>
</div>

</div>

<!-- Loading -->
<div class="container">

    <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">

                    <div style="text-align: center;">

                        Please wait ...
                        <div class="spinner-grow text-success"></div>
                        <div class="spinner-grow text-info"></div>
                        <div class="spinner-grow text-warning"></div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
